// Online stockists

.online-stockists {
    margin: 15px -15px;
}

.online-stockists__stockist {
    display: block;
    padding: 15px;

    img {
        width: 100%;
    }
}

// Stockists

.stockists {
    position: relative;
    transition: min-height 0.1s $transition-function;
}

.stockists__stockist {
    position: relative;
    text-align: left;
    border-bottom: 1px solid $c-section-border;

    &:last-child {
        border-bottom: none;
    }
}

.stockists__stockist__link {
    position: relative;
    display: block;
    padding: 35px 0;
    line-height: 1.5;
    border-right: 1px solid $c-section-border;

    .stockists__stockist--active & {
        background: $c-grey--dark;
        color: $c-white;

        &:hover {
            background: $c-grey--dark;
        }
    }

    &:hover {
        background: $c-secondary-background;
    }

    .container {
        padding-right: 70px;
    }

    &:after {
        position: absolute;
        right: 35px;
        top: 50%;
        margin-top: -5px;
        @extend .fa;
        content: "\f054";
    }

    .stockists__stockist__link__distance {
        display: block;
        float: right;
    }

    .stockists__stockist__link__title {
        border-bottom: 1px solid $c-link;
    }
}

.stockists__stockist__detail {
    position: absolute;
    display: block;
    left: 100%;
    top: 0;
    width: 100%;
    transform: translateX(-1px);
    text-align: left;
    border-left: 1px solid $c-section-border;

    .stockists__stockist--hide & {
        display: none;
    }
}

.stockists__stockist__detail__section {
    border-top: 1px solid $c-section-border;
    text-align: left;
    padding: 20px;

    & > .container > *:last-child {
        margin-bottom: 0;
    }

    .stockists__stockist:first-child &:first-child {
        border-top: none;
    }

    p {
        @include copy;

        strong {
            text-transform: uppercase;
        }
    }
}

    .stockists__stockist__detail__section--logo {
        padding: 20px;

        img {
            max-width: 125px;
        }
    }

.stockists__map {
    display: none;
    height: 400px;
}

@include breakpoint(tablet) {
    .stockists {
        transition: none;
    }

    .stockists__stockist__link {
        border-right: none;

        .container {
            max-width: none;
            width: auto;
            padding-left: 20px;
        }
    }

    .stockists__stockist--active {
        .stockists__stockist__link:after {
            content: "\f078";
        }
    }

    .stockists__stockist__detail {
        position: static;
        transition: none;
        transform: none;

        .stockists__stockist--hide & {
            display: none;
        }
    }
}

/**
 * Google Maps/Autocomplete Styling
 */

.gm-style-iw {
    border-radius: 0;
}

.pac-icon {
    display: none;
}

.pac-item {
    padding: 12px 24px;
}

.pac-item,
.pac-item-query {
    font-family: $f-default-stack;
    @include rem(16px);
    font-weight: 400;
    color: $c-copy;
}

.pac-logo:after {
    display: none;
}
