.workshop-form {
    padding: 45px 55px;
    background-color: $c-import-background;

    .field label {
        @include apply-map($f-field);
        @include lightText;
    }

    .field {
        margin-bottom: 10px;
    }

    .field--required {
        .field__optional {
            display: none;
        }
    }

    textarea.input {
        height: 100px;
    }

    .form-privacy {
        @include font(12px, 20px);
        margin-bottom: 0;
    }
}

    .workshop-form__submit {
        margin-top: 5px;
        margin-bottom: 15px;
    }

.workshop-form__heading {
    @include font(18px, 22px);
    color: $c-black;
}

.workshop-form_note {
    @include font(12px, 19px);
    @include lightText;
    color: #a5a5a5;
    margin-bottom: 15px;
}
