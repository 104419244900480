.import-quantity {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 40px;
	background: $c-white;
	border-radius: 3px;

	@include breakpoint(tablet) {
		padding: 18px 20px;
		border-radius: 0;
	}

	.button {
		margin-bottom: 18px;
	}
}

	.import-quantity__title {
		@include apply-map($f-title);
		@include font(32px, 46px);

		margin-bottom: 23px;
		color: $c-black;

		@include breakpoint(tablet) {
			@include font(22px, 27px);

			margin-bottom: 15px;
			padding: 0 54px;
		}
	}

	.import-quantity__product {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 30px;
		padding: 20px;
		background: $c-import-background;

		@include breakpoint(tablet) {
			width: calc(100% + 40px);
			margin-right: -20px;
			margin-bottom: 23px;
			margin-left: -20px;
		}
	}

		.import-quantity__product-image {
			@include aspect(1);

			flex: 0 0 120px;
			margin-right: 20px;

			img {
				width: 100%;
				height: auto;
			}

			@include breakpoint(tablet) {
				flex-basis: 90px;
				margin-right: 15px;
			}
		}

		.import-quantity__product-content {
			flex: 1 1 auto;
		}

			.import-quantity__product-title {
				@include apply-map($f-title);
				@include font(20px, 20px);

				margin-bottom: 18px;
				color: $c-black;

				@include breakpoint(tablet) {
					@include font(16px, 19px);

					margin-bottom: 15px;
				}
			}

			.import-quantity__product-meta {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: -7px;

				dt,
				dd {
					margin-bottom: 7px;
				}

				dt {
					@include font(14px, 18px);

					width: 200px;
					padding-right: 20px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: $c-grey--mid-text;

					@include breakpoint(tablet) {
						@include font(12px, 18px);

						width: 160px;
						padding-right: 10px;
					}
				}

				dd {
					@include font(16px, 18px);

					width: calc(100% - 200px);
					color: $c-grey--mid;

					@include breakpoint(tablet) {
						@include font(14px, 18px);

						width: calc(100% - 160px);
					}
				}
			}

	.import-quantity__action {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	.import-quantity__divide {
		display: none;

		.import-quantity--two-options & {
			position: relative;
			margin: 15px 0 25px;
			width: 100%;
			display: flex;
			justify-content: center;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background-color:$c-border;
			}

			span {
				@include lightText;
				@include font(14px, 19px);

				z-index: 2;
				padding: 0 5px;
				text-transform: uppercase;
				background-color: $c-white;
			}
		}
	}

	.import-quantity__text {
		@include font(16px, 19px);

		margin-bottom: 26px;
		text-align: center;
	}

	.import-quantity__text-extra {
		@include f-light();
		@include font(14px, 17px);

		margin-bottom: 26px;
		text-align: center;
	}

	.import-quantity__link {
		@include font(14px, 17px);
		@include lightText;

		text-decoration: underline;
		transition: color .3s ease;

		&:hover {
			color: $c-brand;
		}
	}

	.import-quantity__replace-image {
		@include aspect(1);
		width: 100%;

		margin-bottom: 26px;

		img {
			width: 100%;
			height: auto;
		}
	}
