.context-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(tablet) {
        flex-wrap: wrap;
    }

    @include breakpoint(mobile) {
        display: block;
    }
}

    .context-bar__left,
    .context-bar__center,
    .context-bar__right {
        display: flex;
        align-items: center;
        width: 33.3333%;

        @include breakpoint(tablet) {
            width: 50%;
        }

        @include breakpoint(mobile) {
            width: 100%;
            justify-content: center;
        }
    }

    .context-bar__left.context-bar__left {
        @include breakpoint(mobile) {
            margin-bottom: 15px;
        }

        .field {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 8px;

            &::after {
                display: none;
            }
        }

        label {
            margin: 0;
            position: relative;
            top: unset;
            left: unset;
            color: $c-black;
        }

        select.input {
            height: auto;
            width: 50px;
            padding: 0;
            border: none;
            background-color: transparent;
        }
    }

    .context-bar__right {
        justify-content: flex-end;

        @include breakpoint(tablet) {
            display: none;
        }
    }
