.product-showcase--video {
    position: relative;
    @include product_showcase_padding(20px, 106px, 42px);
}

    .product-showcase-video {
        padding: 10px;
        background: $c-product-showcase-video-bg;
    }

        .product-showcase-video__wrapper {
            position: relative;
            width: 100%;
            padding-bottom: calc(9 / 16 * 100%);
        }

        .product-showcase-video__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
