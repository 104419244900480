.compatible-shade__image {
    aspect-ratio: 1;
    margin-bottom: 15px;
    display: block;
    max-width: 305px;

    img {
        width: 100%;
        height: auto;
    }
}

.compatible-shade__title,
.compatible-shade__meta {
    @include font(18px, 26px);

    margin-bottom: 5px;

    @include breakpoint(tablet) {
        @include font(16px, 24px);
    }
}
