.technical-details {
    padding: 100px 0;

    @include breakpoint(tablet) {
        padding: 25px 0;
    }
}

.technical-details--a {
    background: $c-technical-details--a;
}

.technical-details--b {
    background: $c-technical-details--b;
}

.technical-details--c {
    background: $c-technical-details--c;
}

    .technical-details__title {
        @include font(50px, 46px);
        @include thinText;

        text-align: center;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(30px, 37px);
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin: 35px 0;
            background: $c-black;

            @include breakpoint(tablet) {
                margin: 25px 0;
            }
        }
    }
