.journal-preview {
    display: flex;
    align-items: center;
    color: $c-black;
    gap: 20px;
    overflow: hidden;
}

.journal-preview--featured {
    @include lightondark;

    position: relative;
    margin-bottom: 0;
    color: $c-white;
}

.journal-preview--detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

    .journal-preview__image {
        aspect-ratio: 4 / 3;
        width: 100%;

        .journal-preview--detail & {
            flex: 0 0 192px;
            margin-right: 40px;

            @include breakpoint(tablet) {
                flex-basis: 120px;
                margin-right: 20px;
            }
        }

        img {
            width: 100%;
            height: auto;
            transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

            .journal-preview:hover & {
                transform: scale(1.2);

                @include breakpoint(tablet) {
                    transform: none;
                }
            }
        }
    }

    .journal-preview__thumbnail {
        aspect-ratio: 4 / 3;
        flex-basis: 190px;
        flex-shrink: 0;

        @include breakpoint(tablet) {
            flex-basis: 120px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .journal-preview__content {
        .journal-preview--featured & {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding: 60px;
            z-index: 1;
            padding-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2);

            @include breakpoint(wrap) {
                padding-left: $site-gutter;
            }

            @include breakpoint(tablet) {
                padding: 25px 20px;
                padding-left: $site-gutter--mobile;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 230px;
                background: linear-gradient(0deg, rgba($c-black, 0) 0%, $c-black 100%);
                opacity: 0.3;
                z-index: -1;
            }
        }

        .journal-preview--detail & {
            flex: 1 1 auto;
        }
    }

        .journal-preview__meta {
            @include font(12px, 15px);

            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }

            .journal-preview--featured & {
                @include font(14px, 17px);
            }

            p + p {
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    width: 1px;
                    height: 15px;
                    margin: 0 10px;
                    background: currentColor;
                }
            }
        }

        .journal-preview__title {
            @include font(20px, 28px);

            transition: color .3s ease;

            @include breakpoint(tablet) {
                @include font(16px, 24px);
            }

            .journal-preview--featured & {
                @include font(40px, 48px);

                @include breakpoint(tablet) {
                    @include font(20px, 28px);
                }
            }

            .journal-preview:not(.journal-preview--featured):hover & {
                color: $c-brand;

                @include breakpoint(tablet) {
                    color: inherit;
                }
            }
        }
