.block--callout-bullets {
    color: var(--block-text-color, $c-black);
    background-color: var(--block-background, transparent);

    @include breakpoint(tablet) {
        padding: 40px 0 !important;
    }

    .block__image {
        @include aspect(3 / 5);

        position: relative;
        margin-right: -50px;
        flex-grow: 1 !important;

        img {
            @include cover;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        @include breakpoint(tablet) {
            @include aspect(4 / 3);
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .block__content {
        padding: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

        .block__title {
            @include font(40px, 46px);
            margin-bottom: 30px;
            font-weight: 400;

            @include breakpoint(tablet) {
                @include font(30px, 36px);
            }
        }

        .block__item {
            &:not(:last-child) {
                margin-bottom: 50px;
            }

            .block__title {
                @include font(18px, 22px);
                letter-spacing: 4px;
                text-transform: uppercase;
                font-weight: 300;
                color: $c-body;
                margin-bottom: 18px;
            }

            .block__text {
                margin-bottom: 15px;
            }

            figure {
                position: relative;
                cursor: pointer;
                @include aspect(50 / 13);

                img {
                    width: 100%;
                    height: auto;
                }

                .block__zoom-icon {
                    position: absolute;
                    top: 10px;
                    right: 15px;

                    svg {
                        display: block;
                        width: 24px;
                        height: 24px;
                        fill: $c-grey--dark;

                        &:hover {
                            fill: $c-brand;
                        }
                    }
                }
            }

            .block__bullets {
                li {
                    @include font(16px, 24px);
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    gap: 15px;

                    &:before {
                        content: "";
                        width: 7px;
                        height: 7px;
                        display: inline-block;
                        border-radius: 50%;
                        background-color: $c-grey--dark;
                        margin-top: 7px;
                        align-self: start;
                        flex-shrink: 0;
                    }

                    .block__bullets-row {}

                        .block__bullets-label {
                            font-weight: 400;
                        }

                        .block__bullets-label,
                        .block__bullets-value {
                            display: inline;
                        }

                        svg {
                            fill: $c-brand;
                            margin-left: 15px;
                        }
                }
            }

            .block__note {
                @include font(14px, 24px);
                font-weight: 300;
            }
        }

    &.block--reverse {
        //.block__content {
        //    padding-right: 50px;
        //    padding-left: 0;
        //}

        .block__image {
            margin-right: 0;
            margin-left: -50px;

            @include breakpoint(tablet) {
                margin-right: -15px;
                margin-left: -15px;
            }
        }
    }
}
