.block--categories {
    --richtext-color: var(--block-text-color, $c-black);
    background: var(--block-background, transparent);
    overflow: hidden;
}

.block--categories.block--padded {
    padding: 100px 0;

    @include breakpoint(tablet) {
        padding: 40px 0;
    }
}
