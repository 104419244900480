
.listing-article {
    padding: 30px;
    color: $c-link;
    text-align: left;
    min-height: 100px;
    border-bottom: 1px solid $background-grey;

    @include breakpoint(tablet) {
        padding: 15px 0;
    }

    &:hover {
        color: $c-link--hover;
    }

    &:nth-child(2n + 1) {
        border-right: 1px solid $background-grey;
        direction: rtl;

        > * {
            direction: ltr;
        }
    }

    &:nth-last-child(1),
    &:nth-last-child(2):not(:nth-child(even)) {
        border-bottom: none;
    }

    &:nth-last-child(2):not(:nth-child(even)) {

        @include breakpoint(mobile) {
            border-bottom: 1px solid $background-grey;
        }
    }
}

.listing-article--homepage,
.listing-article--large-thumbnail {
    padding: 0;

    &:nth-child(2n + 1) {
        direction: ltr;
        border-right: none;
    }
}

.listing-article--large-thumbnail {
    margin-bottom: 30px;
}

    .listing-article__container {
        width: calc(90vw / 2 - 30px);
        max-width: 600px - 30px;

        @include breakpoint(tablet) {
            width: 90%;
            max-width: 90%;
            margin: 0 auto;
        }

        @include breakpoint(mobile) {
            width: 100%;
            min-width: 300px;
            max-width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
        }

        .listing-article--large-thumbnail & {

            @include breakpoint(tablet) {
                width: 100%;
                max-width: 100%;
            }

            @include breakpoint(mobile) {
                padding: 0;
            }
        }
    }

        .listing-article__link {
            display: flex;

            .listing-article--homepage & {
                display: block;
            }

            .listing-article--large-thumbnail & {

                @include breakpoint(mobile) {
                    display: block;
                }
            }
        }

    .listing-article--popular {
        margin-bottom: 15px;
    }

    .listing-article__image {
        flex: 0 0 133px;
        margin-right: 25px;

        @include breakpoint(mobile) {
            flex-basis: 80px;
            margin-right: 15px;
        }

        .listing-article--homepage & {
            flex: none;
            margin-right: 0;
            margin-bottom: 30px;
        }

        .listing-article--large-thumbnail & {
            flex-basis: 290px;

            @include breakpoint(mobile) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .listing-article__title {
        @include linktitle;
        @include rem(16px);

        line-height: calc(19 / 16);
        margin-bottom: 12px;
        text-transform: none;
        overflow: hidden;

        @include breakpoint(mobile) {
            @include rem(14px);

            line-height: calc(17 / 14);
        }

        .listing-article--homepage & {
            @include rem(22px);
            text-transform: uppercase;
        }

        .listing-article--large-thumbnail & {
            @include rem(18px);

            text-transform: uppercase;
        }

        .listing-article:hover & {
            color: $c-link--hover;
        }
    }

    .listing-article__category {
        @include rem(11px);

        line-height: calc(14 / 11);
        letter-spacing: .5px;
        text-transform: uppercase;
        margin-bottom: 8px;
        color: $c-black;
    }
    .listing-article__intro {
        @include copy;
        @include rem(12px);

        line-height: calc(20 / 12);
        color: $footer-grey;

        .listing-article--homepage & {
            @include rem(20px);
        }

        .listing-article--large-thumbnail & {
            @include rem(16px);
        }
    }

    .featured-article__featured {
        text-align: left;
        float: left;
        padding-bottom: 0;
    }

    .featured-article__image {
        float: right;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .featured-article__content {
        padding-top: 0;
        text-align: left;
        float: left;
    }

    .featured-article__title {
        @include linktitle;
        margin-bottom: 15px;
    }

@include breakpoint (tablet) {
    .featured-article__image {
        padding-top: 0;
        margin-bottom: 20px;
    }
}

.blog__popular {
    padding-bottom: 0;
    overflow: hidden;
}

.popular-article {
    text-align: left;
}
    .popular-article__image {
        margin-bottom: 15px;
    }
    .popular-article__title {
        @include linktitle;
        margin-bottom: 15px;
    }

.article {

}
    .article__body {
        text-align: left;
    }
    .article__intro {
        @include f-light;

        margin-bottom: 40px;
        color: $footer-grey;
    }
    .article__social {
        margin-top: 40px;
    }

    .article__download {
        margin-top: 30px;
        font-weight: 500;
        i {
            margin-right: 10px;
        }
        span {
            border-bottom: 1px solid $c-grey--mid;
        }
    }

    .article__nav {

    }
        .article__prev,
        .article__next {
            position: relative;
            display: block;
            padding-top: 30px;
            padding-bottom: 30px;

            i {
                position: absolute;
                top: 17px;
                font-size: 64px;
            }

            &:hover i {
                color: $c-link--hover;
            }
        }
        .article__prev {
            text-align: left;
            padding-left: 50px;

            i {
                left: 0
            }
        }
        .article__next {
            text-align: right;
            padding-right: 50px;

            i {
                right: 0;
            }
        }
        .article__nav__title {
            display: inline-block;
            margin-bottom: 10px;
            @include link;
            @include rem(14px);
            text-transform: uppercase;
        }
        .article__nav__category {
            @include f-light;
            @include rem(14px);
            display: block;
            margin: 0;
        }

.article__products {
    // text-align: center;
}
    .article__products__title,
    .article__related__title {

    }


.article__related {
    text-align: left;
}

.article__related--new {
    padding-top: 15px;
    border-top: 1px solid $c-border-light;

    .article__related__title {
        @include font(18px, 22px);
        @include lightText;

        margin-bottom: 35px;
        text-transform: uppercase;
        color: $c-black;
        letter-spacing: 4px;

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            margin-bottom: 25px;
            letter-spacing: 3px;
        }
    }
}

.blog-video {
    display: block;
    width: 100%;
    height: 390px;
    max-width: 640px;
    margin: 0 auto;
}
