.product-listing {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 15px;
    padding: 50px 0;
    scroll-margin-top: calc($header-height + 50px);

    @include breakpoint(tablet) {
        padding: 15px 0 30px;
        grid-template-columns: 1fr;
    }

    button[data-filter-clear] {
        display: none;
    }

    &[data-filtered] button[data-filter-clear] {
        display: block;
    }
}

    .product-listing__result-bar {
        grid-column: 2 / 5;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(tablet) {
            grid-column: 1 / 2;
            position: relative;
            padding-top: 13px;

            &::after {
                content: '';
                position: absolute;
                left: -$site-gutter--mobile;
                right: -$site-gutter--mobile;
                top: 0;
                height: 1px;
                background-color: $c-border-light; 
            }
        }

        p {
            @include font(12px, 20px);
            @include lightText;
        }
    }

        .product-listing__view-filter {
            display: flex;
            align-items: center;

            label {
                flex-shrink: 0;
            }
        }

    .product-listing__toggle-filters {
        display: none;

        @include breakpoint(tablet) {
            all: unset;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            border: 1px solid $c-black;
            border-radius: 3px;
        }
    }

    .product-listing__view-results {
        display: none;

        .product-listing--overlay-open & {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            background-color: $c-white;
            position: fixed;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 15px $site-gutter--mobile;
            z-index: 100;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
        }

        button[data-close-filter-overlay] {
            all: unset;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            flex-basis: 100%;
            padding: 10px 15px;
            border: 1px solid $c-black;
            border-radius: 3px;
            cursor: pointer;
        }
    }

    .product-listing__items {
        grid-column: 2 / 5;
        grid-row: 2 / 5;
        display: grid;
        grid-template-columns: subgrid;
        row-gap: 30px;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 15px;
            grid-column: 1 / 2;
            grid-row: auto;
            grid-auto-flow: dense;
            margin-bottom: 20px;
        }

        .span-2 {
            @include breakpoint(tablet) {
                grid-column: span 2;
            }
        }
    }

    .product-listing__pagination {
        grid-column: 2 / 5;

        @include breakpoint(tablet) {
            grid-column: 1 / 2;
        }
    }
