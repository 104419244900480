.segmentation-popup {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    background-color: $light-grey;
    padding: 35px 30px 30px 30px;

    @include breakpoint(tablet) {
        padding: 20px;
    }
}

    .segmentation-popup__title {
        @include font(28px, 38px);
        @include regularText;
        margin-bottom: 35px;
        text-align: center;
        color: #222222;

        span {
            @include lightText;
        }

        @include breakpoint(tablet) {
            @include font(20px, 28px);
            margin-bottom: 20px;
        }
    }

    .segmentation-popup__notice {
        @include lightondark;
        @include font(28px, 38px);
        @include lightText;

        padding: 40px 35px;
        color: $c-white;
        background: $c-brand;
        text-align: center;

        @include breakpoint(tablet) {
            @include font(20px, 28px);
            padding: 30px;
        }

        span {
            @include regularText;
        }
    }

    .segmentation-popup-section {
        display: flex;
        flex-direction: column;

        &--row {
            flex-direction: row;
            padding: 0 40px 20px;

            @include breakpoint(tablet) {
                flex-direction: column;
                padding: 0;
            }

            > * {
                width: 50%;

                @include breakpoint(tablet) {
                    width: 100%;
                }
            }
        }
    }

        .segmentation-popup-section__image {
            @include aspect(5 / 3);

            position: relative;
            border-radius: 3px 3px 0 0;

            img {
                @include coverImage;

                width: 100%;
                height: 100%;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

            .segmentation-popup-section__title {
                @include font(26px, 32px);

                color: $c-black;
                margin-bottom: 15px;

                span {
                    @include lightText;
                }

                @include breakpoint(tablet) {
                    @include font(18px, 24px);
                }
            }

        .segmentation-popup-section__content {
            flex: 1 1 auto;
            padding: 30px;
            background: $c-white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 0 0 3px 3px;

            .segmentation-popup-section--row & {
                padding: 30px 50px;
                border-left: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                @include breakpoint(tablet) {
                    padding: 20px;
                }
            }

            @include breakpoint(tablet) {
                border-left: none;
                padding: 20px;
            }

            .segmentation-popup .grid:not(.grid--reverse) > :first-child & {
                border-left: none;
            }

            .segmentation-popup .grid.grid--reverse > :last-child & {
                border-left: none;
            }
        }

            .segmentation-popup-section__text {
                margin-bottom: 25px;
            }

            .segmentation-popup-section__actions {

                .segmentation-popup-section--row & {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;

                    @include breakpoint(tablet) {
                        flex-direction: column;
                    }
                }
            }

                .segmentation-popup-section__button {
                    margin-right: 20px;

                    &:not(:only-child) {
                        width: 100%;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    @include breakpoint(tablet) {
                        margin-right: 0;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    span {
                        @include breakpoint(tablet) {
                            @include font(12px, 18px);
                        }
                    }
                }
