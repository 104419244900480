.docs-page-container {
    background: $c-import-background;
}

    .docs-page {
        background: $c-white;
        min-height: 500px;
        padding: 85px 110px;

        @include breakpoint(tablet) {
            margin: 0 15px;
            padding: 20px;
        }
    }

        .docs-page__logo {
            text-align: right;
        }

        .docs-page__title {
            margin: 30px 0;
            @include font(40px, 62px);
            color: $c-black;

            @include breakpoint(tablet) {
                @include font(24px, 32px);
            }
        }

    .doc-info-container {
        border-bottom: 1px solid $c-border;
    }

        .docs-info-section {}

            .docs-info-section__title {
                @include font(16px, 28px);
                color: $c-black;
                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    @include font(14px, 26px);
                }
            }

            .docs-info-section__label {
                @include font(12px, 26px);
                color: $c-grey--mid-text;
                letter-spacing: 1px;
                text-transform: uppercase;

                @include breakpoint(tablet) {
                    @include font(12px, 24px);
                }
            }

            .docs-info-section__detail {
                @include font(16px, 26px);
                @include f-light;
                color: $c-black;

                @include breakpoint(tablet) {
                    @include font(14px, 24px);
                }
            }

    .doc-detail__section {

        &:not(:last-child) {
            padding-bottom: 20px;
        }
    }

@media print {
    @page {
        size: 1095px 1550px;
        margin: 0;
    }
}
