.wrap {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width;
    padding: 0 $site-gutter;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile;
    }
}

.wrap--responsive {
    @include breakpoint(tablet) {
        padding: 0;
    }
}

.wrap--half {
    max-width: $site-max-width / 2;
}

.wrap--pdp-left {
    padding-right: 30px;
    max-width: calc((#{$site-max-width} / 12) * 7);
    margin: 0;
    margin-left: auto;

    @include breakpoint(pdp-medium) {
        padding-right: 20px;
    }

    @include breakpoint(tablet) {
        max-width: none;
        padding: 0;
        margin: 0;
    }
}

.wrap--pdp-right {
    padding-left: 0;
    max-width: calc((#{$site-max-width} / 12) * 5);
    margin: 0;
    margin-right: auto;

    @include breakpoint(pdp-medium) {
        padding-left: 20px;
    }

    @include breakpoint(tablet) {
        max-width: none;
        padding: 0;
        margin: 0;
    }
}

.wrap--block-split-large-left {
    max-width: 660px;
    margin: 20px 0 0 auto;
    padding-right: 40px;
    padding-left: 0;

    @include breakpoint(pdp-large) {
        max-width: none;
        margin: 0;
        padding: 0;
    }
}

.wrap--block-split-large-right {
    max-width: 700px;
    margin: 20px auto 0 0;
    padding-right: 40px;
    padding-left: 40px;

    @include breakpoint(pdp-large) {
        max-width: none;
        margin: 0;
        padding: 0;
    }
}

.wrap--block-split-large-left {
    margin: 0 0 0 auto;
    max-width: 680px;

    .grid--reverse & {
        margin: 0 auto 0 0;
        padding-right: 0;
        padding-left: 40px;

        @include breakpoint(pdp-large) {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }
}

.wrap--block-split-large-right {
    .grid--reverse & {
        margin: 20px 0 0 auto;

        @include breakpoint(pdp-large) {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }
}

.wrap--right {
    margin-right: 0;

    @include breakpoint(tablet) {
        margin-right: auto;
    }
}

.wrap--left {
    margin-left: 0;

    @include breakpoint(tablet) {
        margin-left: auto;
    }
}

.wrap--page {
    max-width: 1240px;
}

.wrap--medium {
    max-width: 910px;
}

.wrap--narrow {
    max-width: 680px;
}

.wrap--slim {
    max-width: 580px;
}

.wrap--import {
    max-width: 950px;
}

.wrap--docs {
    max-width: 1095px;
}

.wrap--flush {
    padding: 0;
}

.wrap--relative {
    position: relative;
}

.wrap--no-overflow {
    overflow: hidden;
}

.wrap--m-flush {
    @include breakpoint(tablet) {
        padding: 0;
    }
}

.wrap--account-left {
    padding-right: 0;
    padding-left: 40px;

    @include breakpoint(tablet) {
        padding: 0;
    }
}

.wrap--account-middle {
    padding: 0;

    @include breakpoint(tablet) {
        padding: 0 20px;
    }
}

.wrap--account-right {
    padding-left: 0;
    padding-right: 40px;

    @include breakpoint(tablet) {
        padding: 0 20px;
    }
}
