.fancy-grid--journal {
    margin-bottom: -53px;

    @include breakpoint(tablet) {
        margin-bottom: -33px;
    }

    &[loading="true"] {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($c-white, 0.8);
        }
    }

    > :nth-child(9n+2),
    > :nth-child(9n+3),
    > :nth-child(9n+6) {
        align-self: flex-start;
    }

    > :nth-child(9n+4),
    > :nth-child(9n+7),
    > :nth-child(9n+8) {
        align-self: flex-end;
    }

    > * {
        margin-bottom: 53px;

        @include breakpoint(tablet) {
            margin-bottom: 33px;
        }
    }
}

.fancy-grid--inspiration {
    margin-bottom: -30px;

    > :nth-child(6n+2),
    > :nth-child(6n+3) {
        align-self: flex-start;
    }

    > :nth-child(6n+4),
    > :nth-child(6n+5) {
        align-self: flex-end;

        @include breakpoint(tablet) {
            align-self: flex-start;
        }
    }

    > * {
        margin-bottom: 30px;
    }
}

    .fancy-grid__dummy {
        opacity: 0;
        pointer-events: none;
    }
