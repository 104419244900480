// Titles
@mixin page-title {
    @include apply-map($f-title);
    @include font(52px, 58px);

    color: $f-title-color;

    @include breakpoint(tablet) {
        @include font(36px, 40px);
    }
}

@mixin large-title {
    @include apply-map($f-title);
    @include font(38px, 44px);

    color: $f-title-color;

    @include breakpoint(tablet) {
        @include font(26px, 32px);
    }
}

@mixin medium-title {
    @include apply-map($f-title);
    @include font(26px, 32px);

    color: $f-title-color;

    @include breakpoint(tablet) {
        @include font(22px, 28px);
    }
}

@mixin small-title {
    @include apply-map($f-title);
    @include font(18px, 24px);

    color: $f-title-color;

    @include breakpoint(tablet) {
        @include font(16px, 22px);
    }
}

@mixin tiny-title {
    @include apply-map($f-title);
    @include font(16px, 22px);

    color:$f-title-color;

    @include breakpoint(tablet) {
        @include font(14px, 20px);
    }
}

@mixin label {
    @include apply-map($f-label);
    @include font(16px, 20px);

    color: $f-label-color;
}

/// Define the font weight for thin text
///
/// @author Joe Adcock <joe@d3r.com>

@mixin thinText {
    font-weight: 100;
}

/// Define the font weight for light text
///
/// @author Joe Adcock <joe@d3r.com>

@mixin lightText {
    font-weight: 200;
}

/// Define the font weight for regular text
///
/// @author Joe Adcock <joe@d3r.com>

@mixin regularText {
    font-weight: 400;
}

/// Define the font weight for bold text
///
/// @author Joe Adcock <joe@d3r.com>

@mixin boldText {
    font-weight: 700;
}

//////////

@mixin f-thin {
    font-family: $f-default-stack;
    font-weight: 100;
}

@mixin f-light {
    font-family: $f-default-stack;
    font-weight: 200;
}

@mixin f-regular {
    font-family: $f-default-stack;
    font-weight: 400;
}

@mixin f-bold {
    font-family: $f-default-stack;
    font-weight: 700;
}

@mixin copy {
    @include rem(16px);
    @include f-light;
    line-height: 1*26/16;
    color: $c-copy;
}

@mixin copySmall {
    @include rem(14px);
    @include f-light;
    line-height: calc(22 / 14);
    color: $c-copy;
}

@mixin intro {
    @include copy;
    @include f-thin;
}

@mixin feature {
    @include copy;
    @include rem(23px);
    line-height: 1*35/23;
}

@mixin link {
    text-decoration: underline;
    line-height: 1.333;
}
@mixin linktitle {
    @include f-regular;
    @include rem(16px);
    text-transform: uppercase;

    > * {
        display: inline-block;
        @include link;
    }
}

@mixin underlined-link {
    @include link;
    text-decoration: none;
    color: $grey2;
    position: relative;

    &:hover {
        color: $c-black;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $c-link;
    }
}
