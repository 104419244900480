.product-browse-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
    }
}

    .product-browse-info__title {
        @extend .heading4;

        line-height: calc(53 / 32);
        flex: 0 1 auto;
        margin-right: 20px;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            @include rem(24px);

            line-height: calc(29 / 24);
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;

            @include breakpoint(tablet) {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
        }
    }

        .product-browse-info__highlight {
            @include f-light;

            font-style: italic;
            color: $c-grey--mid;
        }

    .product-browse-info__actions {
        flex: 0 0 240px;

        @include breakpoint(tablet) {
            flex-basis: auto;
            min-width: 240px;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    .product-browse-info__form {
        display: flex;
        flex: 0 1 800px;
        justify-content: flex-end;
        align-items: center;

        &--auto {
            flex: 0 1 auto;

            #partner-sub-filter_replace {
                min-width: 150px;
            }
        }

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            margin: 0 auto;
            flex-basis: auto;
        }

        @include breakpoint(mobile) {
            display: block;
            width: 100%;
        }

        > * {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

        .product-browse-info__input {
            flex: 1 1 auto;

            #partner-category-filter_replace {
                min-width: 260px;

                @include breakpoint(tablet) {
                    min-width: 0;
                }
            }

            #sort_replace {
                min-width: 130px;

                @include breakpoint(tablet) {
                    min-width: 0;
                }
            }
        }
