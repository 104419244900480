.basket-preview {
    position: relative;
}

    .basket-preview__link {
        display: block;
    }

    .basket-preview__label {
        @include rem(14px);
        @include f-light;

        line-height: calc(17 / 14);
        text-transform: uppercase;
        letter-spacing: 0.2px;
        margin-right: 10px;

        .basket-preview__link:hover & {
            @include f-regular;

            color: $c-black;
            letter-spacing: 0;
        }
    }

    .basket-preview__icon {
        display: inline-block;
        background: url(/images/asset_basket.svg) no-repeat 0 0;
        background-size: cover;
        width: 18px;
        height: 15px;

        .basket-preview__link:hover & {
            background-image: url(/images/asset_basket_hover.svg);
        }
    }
