.block--large-callout {
    padding: 0;
    position: relative;
    min-height: 450px;

    .block__image2 {
        @include aspect(calc(10 / 3));

        @include breakpoint(phablet) {
            @include aspect(7 / 4);
        }

        img {
            @include cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include breakpoint(tablet) {
                position: relative;
                width: 100%;
                height: auto;
                top: unset;
                left: unset;
                object-fit: unset;
            }
        }
    }

    .block__content {
        background: $c-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 50px 80px;
        width: 100%;
        max-width: 850px;
        text-align: center;

        @include breakpoint(tablet) {
            position: relative;
            top: unset;
            left: unset;
            transform: none;
            padding: 35px 15px 0;
            max-width: 100%;
            text-align: left;
        }
    }

        .block__title {
            @include font(38px, 44px);

            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(26px, 32px);
            }
        }

        .block__text {
            margin-bottom: 20px;
        }
}
