.product-showcase {
    background: $c-white;
}

.product-transition-line {}

    .product-transition-line--complete .product-transition-line__piece {
        transition: none;
    }

.product-transition-line--1 {
    position: relative;
    min-height: 50vh;

    @include breakpoint(tablet) {
        min-height: 0;
    }
}

.product-transition-line--2 {
    position: absolute;
    top: 16px;
    left: 50%;
    width: 2px;
    height: 112px;
    margin-left: -1px;
    background: transparent;

    .admin & {
        height: 112px + 44px;
    }

    .debug & {
        height: 112px + 20px;
    }

    .debug.admin & {
        height: 112px + 44px + 20px;
    }

    @include breakpoint(tablet) {
        top: 13px;

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            height: 40px;
        }
    }

    @include breakpoint(mobile) {
        top: 5px;

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            height: 18px;
        }
    }
}

.product-transition-line--3 {
    position: absolute;
    top: 9px;
    left: 50%;
    width: 300px;
    height: 96px;
    margin-left: -300px;

    .admin & {
        height: 96px + 44px;
    }

    .debug & {
        height: 96px + 20px;
    }

    .debug.admin & {
        height: 96px + 44px + 20px;
    }

    @include breakpoint(tablet) {
        top: 0;

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            height: 43px;
        }
    }

    @include breakpoint(mobile) {
        top: 0px;

        &,
        .admin & ,
        .debug &,
        .debug.admin & {
            height: 18px;
        }
    }
}

    .product-transition-line__piece {
        position: absolute;
        background: $c-product-showcase-line;
    }

    .product-transition-line__piece--horizontal {
        width: 0;
        height: 2px;
        transition: width 500ms linear;

        .product-transition-line--active & {
            width: 100%;
        }
    }

    .product-transition-line__piece--vertical {
        width: 2px;
        height: 0;
        transition: height 500ms linear;

        .product-transition-line--active & {
            height: 100%;
        }
    }

    .product-transition-line__piece--l-only {

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .product-transition-line__piece--1_1 {
        top: -130px;
        left: -75px;
        background: $c-product-showcase-line--alt;
        transform-origin: 0 1px 0;
        transform: rotate(60deg);

        .product-transition-line--active & {
            width: 150px;

            @include breakpoint(tablet) {
                top: -86px;
                left: -50px;
                width: 100px;
            }

            @include breakpoint(mobile) {
                top: -43px;
                left: -25px;
                width: 50px;
            }
        }
    }

    .product-transition-line__piece--1_2 {
        top: 0;
        left: 0;
        background: $c-product-showcase-line--alt;
        transition-delay: 500ms;
    }

    .product-transition-line__piece--1_3 {
        top: 0;
        right: 0;
        transition-delay: 1000ms;
    }

    .product-transition-line__piece--1_4 {
        right: 0;
        bottom: 0;
        transition-delay: 1500ms;

        .product-transition-line--active & {
            width: calc(100% - 210px);
        }
    }

    .product-transition-line__piece--1_5 {
        top: 100%;
        left: 210px;
        transition-delay: 2000ms;

        @include breakpoint(tablet) {
            left: calc(-250px + 50vw);
            margin-left: -1px;
            transition-delay: 1000ms;
        }

        @media (max-width : 690px) {
            left: calc(100% + 62px - 50vw);
        }

        @include breakpoint(mobile) {
            left: calc(-40px + 50vw);
        }

        .product-transition-line--active & {
            height: 60px;

            @include breakpoint(tablet) {
                height: 94px;
            }

            @include breakpoint(mobile) {
                height: 26px;
            }
        }
    }

    .product-transition-line__piece--2_1 {
        top: 0;
        left: 0;

    }

    .product-transition-line__piece--3_1 {
        top: 0;
        right: 0;

        .product-transition-line--active & {
            height: 40px;

            @include breakpoint(tablet) {
                height: 100%;
            }
        }
    }

    .product-transition-line__piece--3_2 {
        top: 40px;
        right: 0;
        transition-delay: 500ms;
    }

    .product-transition-line__piece--3_3 {
        top: 40px;
        left: 0;
        transition-delay: 1000ms;

        .product-transition-line--active & {
            height: 56px;

            .admin & {
                height: 56px + 44px;
            }

            .debug & {
                height: 56px + 20px;
            }

            .debug.admin & {
                height: 56px + 44px + 20px;
            }
        }
    }

.product-transition-line--4 {
    position: absolute;
    top: 16px;
    left: 50%;
    width: 2px;
    height: 32px;
    margin-left: -1px;
    background: transparent;

    .admin & {
        height: 32px + 44px;
    }

    .debug & {
        height: 32px + 20px;
    }

    .debug.admin & {
        height: 32px + 44px + 20px;
    }

    @include breakpoint(tablet) {
        top: 13px;

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            height: 40px;
        }
    }

    @include breakpoint(mobile) {
        top: 5px;

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            height: 18px;
        }
    }
}

    .product-transition-line__piece--4_1 {
        top: 0;
        left: 0;
    }
