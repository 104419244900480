@import 'swiper/swiper.scss';
@import 'swiper/modules/navigation.scss';
@import 'swiper/modules/effect-fade.scss';
@import 'swiper/modules/thumbs.scss';

.swiper-pdp-gallery {
    padding-left: 110px;
    // CSS Grid/Flexbox bug size workaround
    // @see https://github.com/nolimits4web/swiper/issues/3599
    min-height: 0;
    min-width: 0;

    @include breakpoint(tablet) {
        padding-left: 0;
        margin-bottom: 15px;
    }

    .swiper-slide {
        aspect-ratio: 1;

        img {
            width: 100%;
            height: auto;
        }

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .swiper-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 36px;
        height: 36px;
        display: grid;
        place-items: center;
        border-radius: 50%;
        padding: 0;
        background-color: transparent;
        border: 1px solid $c-swatch-border;
        transition: border 200ms ease-in;

        svg {
            fill: $c-black;
        }

        &:not(.swiper-button-disabled):hover {
            border-color: $c-black;
        }

        &.swiper__arrow--next svg {
            margin-left: 2px;
        }

        &.swiper__arrow--prev svg {
            margin-right: 2px;
        }

        &.swiper-button-disabled {
            opacity: 0.35;
        }
    }

    .swiper-arrow--prev {
        left: 15px;
    }

    .swiper-arrow--next {
        right: 15px;
    }
}

.swiper-pdp-thumbnails {
    position: absolute;
    width: 90px;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    // CSS Grid/Flexbox bug size workaround
    // @see https://github.com/nolimits4web/swiper/issues/3599
    min-height: 0;
    min-width: 0;

    @include breakpoint(tablet) {
        position: relative;
        top: unset;
        left: unset;
        width: auto;
        padding-left: $site-gutter--mobile;
        padding-right: $site-gutter--mobile;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 50px;
        pointer-events: none;
        bottom: 0;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        z-index: 2;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    // When the carousel scrolls down we want to add the before to the nav
    &:not(:has(.swiper-slide.swiper-slide-active:first-child))::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 50px;
        pointer-events: none;
        top: 0;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        z-index: 2;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .swiper-wrapper {
        flex-direction: column;

        @include breakpoint(tablet) {
            flex-direction: row;
        }
    }

    .swiper-slide {
        box-sizing: border-box;
        aspect-ratio: 1;
        width: 90px;
        height: 90px;
        position: relative;
        border-radius: 2px;
        border: 1px solid $c-swatch-border;
        outline: 1px solid $c-white;
        outline-offset: -2px;
        cursor: pointer;
        transition: border 150ms ease-in;

        @include breakpoint(tablet) {
            width: 50px;
            height: 50px;
        }

        &:hover {
            border-color: $c-thumb-border;
        }

        &.swiper-slide-thumb-active {
            border-color: $c-black;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        svg.icon-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: $c-white;
        }
    }

    .swiper-thumb-arrow {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        background: none;
        padding: 0;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(tablet) {
            display: none;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .swiper-thumb-arrow--up {
        top: 0;
    }

    .swiper-thumb-arrow--down {
        bottom: 0;
    }
}
