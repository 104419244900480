.justify {
    display: block;
    width: 100%;
    text-align: justify;
    text-justify: distribute; // ie fix
    @include remove-white-space;
    // Use psudo element to force a line wrap necessary for text-align justify to work
    &:after {
        content: "";
        display: inline-block;
        width: 95%;
        height: 0;
    }

    > * {
        display: inline-block;
        width: auto;
        text-align: left;
    }
}

// Flow items right to left
.justify--reverse {
    direction: rtl;

    > * {
        direction: ltr;
    }
}

.justify--top {
    > * {
        vertical-align: top;
    }
}

.justify--middle {
    > * {
        vertical-align: middle;
    }
}

.justify--bottom {
    > * {
        vertical-align: bottom;
    }
}

// Justify until a specified breakpoint, at which point the immediate children will display:block

@each $name, $breakpoint in $sizes {

    .justify--until-#{$name} > * {

        @include breakpoint($breakpoint) {
            display: block;
        }
    }
}
