.header-logo {
    margin-top: -5px;

    @include breakpoint(tablet) {
        margin-top: 0;
        margin-right: auto;
    }

    svg {
        display: block;
        fill: var(--header-content-colour);
        max-width: 117px;

        @include breakpoint(tablet) {
            max-width: 105px;
        }
    }
}

.header-logo--desktop {
    @include breakpoint(tablet) {
        display: none;
    }
}
