.download-action {
    display: flex;
    background: $background-grey;
    border-radius: 5px;
    overflow: hidden;

    .product-browse-info__form--auto & {
        min-width: 240px;
    }
}

.download-action--white {
    background: $c-white;
}

    .download-action__label {
        @include rem(11px);
        @include f-bold;

        line-height: calc(13 / 11);
        letter-spacing: .5px;
        text-transform: uppercase;
        display: flex;
        flex: 1 1 auto;
        padding: 10px 15px 10px 20px;
        color: $c-grey--mid;
        transition: color 0.2s;

        @include breakpoint(mobile) {
            @include rem(12px);

            line-height: calc(20 / 12);
        }

        .download-action:not(.download-action--added):hover & {
            color: $c-black;
        }
    }

    .download-action__icon {
        @include rem(16px);

        line-height: calc(33 / 16);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 33px;
        color: $c-white;
        background: $c-brand;
        box-sizing: border-box;
        transition: all 0.2s;

        @include breakpoint(mobile) {
            line-height: calc(40 / 16);
        }

        .download-action:hover & {
            background: $c-black;
        }

        i {
            display: block;
        }
    }

        .download-action__status {
            @include rem(11px);

            position: absolute;
            right: 3px;
            bottom: 3px;
            padding: 1px;
            background: $c-brand;
            transition: all 0.2s;

            .download-action:hover & {
                background: $c-black;
            }
        }
