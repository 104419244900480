.feature-image {
    padding-bottom: 100px;
    background: linear-gradient(145deg, $c-casambi-gradient-1 0%, $c-casambi-gradient-2 100%);

    @include breakpoint(phablet) {
        padding-bottom: 50px;
    }

    img {
        display: block;
        width: 60%;
        max-width: 100%;
        height: auto;
        margin: 0 auto;

        @include breakpoint(phablet) {
            width: 80%;
        }

        @include breakpoint(mobile) {
            width: 90%;
        }
    }
}
