.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    height: $hero-min-height;
    padding: #{80px + $header-height} 80px 80px;
    color: $c-white;
    background-color: var(--hero-background-color, $c-black);
    overflow: hidden;

    // Set background colours as CSS vars so hero variations can apply them appropriately
    @each $name, $value in $block-background-colours {
        &.hero--background-#{$name} {
            --hero-background-color: #{$value};
        }
    }

    @media (min-height: $hero-min-height) {
        height: 100vh;

        &.hero--height-fixed {
            height: $hero-max-height;

            @include breakpoint(tablet) {
                height: $hero-short-height;
            }
        }

        &.hero--height-short {
            height: $hero-short-height;
        }
    }

    .has-notice-bar & {
        @media (min-height: $hero-min-height) {
            height: calc(100vh - #{$notice-height});
            margin-top: $notice-height;

            &.hero--height-fixed {
                height: $hero-max-height;

                @include breakpoint(tablet) {
                    height: $hero-short-height;
                }
            }

            &.hero--height-short {
                height: $hero-short-height;
            }

            @include breakpoint(tablet) {
                height: 100vh;
                margin-top: 0;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: #{40px + $header-top-height} 20px 40px;
        height: $hero-short-height !important;
    }
}

.hero--dark-text {
    color: $c-black;
}

.hero--feature {
    flex-direction: row;
    padding: 0;

    .hero__media,
    .hero__container {
        flex-basis: 50%;
        max-width: none;
        height: 100%;
    }

    .hero__container {
        background-color: var(--hero-background-color, $c-white);
        display: flex;
        justify-content: center;
        padding-left: 70px;
        padding-right: 70px;

        & > * {
            max-width: $max-content-width;
            align-self: center;
        }
    }

    .hero__media {
        position: relative;
    }

    &.hero--gradient-left:after {
        left: 50%;
        z-index: 2;
    }

    &.hero--gradient-left.hero--horizontal-right:after {
        left: 0;
    }

    @include breakpoint(tablet) {
        height: auto;
        max-height: none;
        padding: 0;
        flex-direction: column;

        .hero__media {
            order: -1;
        }

        .hero__media,
        .hero__container {
            flex-basis: auto;
            max-width: none;
            height: auto;
        }

        .hero__container {
            display: flex;
            justify-content: center;
            padding: 30px $site-gutter--mobile 0;

            & > * {
                max-width: none;
                align-self: center;
            }
        }

        .hero__buttons {
            margin-bottom: 15px;
        }

        &.hero--gradient-left:after {
            display: none;
        }
    }
}

.hero--height-fixed,
.hero--height-short {
    @include breakpoint(phablet) {
        padding: 0;
    }

    .hero__container.hero__container {
        @include breakpoint(phablet) {
            position: absolute;
            left: $site-gutter--mobile;
            right: $site-gutter--mobile;
            bottom: 80px;
        }
    }
}

.hero--overlay {
    padding: 90px;

    @media (min-height: $hero-min-height) {
        height: calc(100vh - #{$hero-overlay-above-fold-height});
    }

    .has-notice-bar & {

        @media (min-height: $hero-min-height) {
            height: calc(100vh - #{$notice-height} - #{$hero-overlay-above-fold-height});
            margin-top: $notice-height;

            @include breakpoint(tablet) {
                height: auto;
                margin-top: 0;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 30px 20px;
        height: auto;
    }
}

.hero--gradient-top {

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 200px;
        background: linear-gradient(180deg, $c-black 0%, rgba($c-black, 0) 100%);
        opacity: 0.5;
        z-index: 1;
    }
}

.hero--gradient-left {

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50%;
        background: linear-gradient(90deg, $c-black 0%, rgba($c-black, 0) 100%);
        opacity: 0.45;
        z-index: 1;

        @include breakpoint(tablet) {
            top: auto;
            width: 100%;
            height: 50%;
            background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.6;
        }
    }
}

.hero--horizontal-left,
.hero--horizontal-left .hero__wrap {
    align-items: flex-start;
}

.hero--horizontal-center,
.hero--horizontal-center .hero__wrap {
    align-items: center;
}

.hero--horizontal-right,
.hero--horizontal-right .hero__wrap {
    align-items: flex-end;
}

.hero--vertical-top,
.hero--vertical-top .hero__wrap {
    justify-content: flex-start;

    @include breakpoint(tablet) {
        justify-content: flex-end;
    }
}

.hero--vertical-center,
.hero--vertical-center .hero__wrap {
    justify-content: center;

    @include breakpoint(tablet) {
        justify-content: flex-end;
    }
}

.hero--vertical-bottom,
.hero--vertical-bottom .hero__wrap {
    justify-content: flex-end;
}

    .hero__media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        .hero--feature & {
            @include breakpoint(tablet) {
                @include aspect(4 / 3);
                position: static;
            }
        }

        .hero--height-fixed & {
            @include breakpoint(phablet) {
                @include aspect(1);

                position: static;
                width: 100%;
            } 
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .hero--feature &,
            .hero--height-fixed & {
                @include breakpoint(tablet) {
                    position: static;
                    height: auto;
                }
            }
        }

        img,
        video {
            @include cover;
        }

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%, -50%);
        }

        .hero__toggle {
            width: 60px;
            height: 60px;
            z-index: 10;
            position: absolute;
            bottom: 40px;
            right: 40px;
            cursor: pointer;

            &:before {
                content: '';
                display: inline-block;
                width: 60px;
                height: 60px;
                background-color: $c-white;
                opacity: 0.3;
                border-radius: 30px;
            }

            &:hover:before {
                background-color: $c-grey--dark;
                opacity: unset;
            }

            svg {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                //width: 30px;
                //height: 30px;
                display: none;
                z-index: 10;
                fill: $c-white;
            }

            @include breakpoint(tablet) {
                width: 45px;
                height: 45px;
                right: 20px;
                bottom: 40px;

                &:before {
                    width: 45px;
                    height: 45px;
                }
            }

            &--white {
                &:before {
                    opacity: unset;
                }

                svg {
                    fill: $c-grey--mid;
                }

                &:hover {
                    svg {
                        fill: $c-white;
                    }
                }


            }
        }

        &[data-video-action="pause"] {
            svg.icon-pause {
                display: block;
            }
        }

        &[data-video-action="play"] {
            svg.icon-play {
                display: block;
                left: 3px;
            }
        }
    }

    .hero__container.hero__container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto; // Override slick
        max-width: 820px;
        z-index: 2;

        .hero--align-left & {
            align-items: flex-start;
        }

        .hero--align-center & {
            align-items: center;

            @include breakpoint(tablet) {
                align-items: flex-start;
            }
        }

        .hero--align-right & {
            align-items: flex-end;

            @include breakpoint(tablet) {
                align-items: flex-start;
            }
        }

        .hero--overlay & {
            max-width: 1200px;
            min-height: 300px;
            align-items: center;
            justify-content: center;
            background-color: $c-white;
            opacity: 0.9;
            flex-grow: 1;
            width: 100%;

            @include breakpoint(tablet) {
                width: 100%;
                max-width: none;
                min-height: unset;
                padding: 30px 20px;
                text-align: center;
            }
        }
    }

        .hero__titles-outer {
            margin-bottom: 15px;
            width: 100%;

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }

            .hero--overlay & {
                margin-bottom: 0;

                @include breakpoint(tablet) {
                    margin-bottom: 30px;
                }
            }
        }

                .hero__subtitle {
                    @include thinText;
                    @include font(18px, 22px);
                    margin-bottom: 25px;

                    text-transform: uppercase;
                    letter-spacing: 4px;

                    @include breakpoint(tablet) {
                        @include font(14px, 17px)
                    }

                    .hero--align-center & {
                        text-align: center;
                    }

                    .hero--align-right & {
                        text-align: right;
                    }
                }

                .hero__title {
                    @include font(52px, 58px);

                    @include breakpoint(tablet) {
                        @include font(36px, 40px);
                    }

                    span {
                        @include thinText;
                    }

                    .hero--overlay & {
                        font-family: $f-alternate-stack;
                        @include font(50px, 78px);

                        @include breakpoint(tablet) {
                            @include font(40px, 34px);
                        }
                    }

                    .hero--align-center & {
                        text-align: center;
                    }

                    .hero--align-right & {
                        text-align: right;
                    }
                }

                    .hero__title--font-minion {
                        font-family: $f-minion-stack;
                    }

                    .hero__title--extra-margin {
                        margin-bottom: 25px;
                    }

        .hero__content {
            width: 100%;

            .hero--overlay & {
                width: 690px;
                text-align: center;

                @include breakpoint(tablet) {
                    width: 100%;
                }
            }

            .hero--align-center & {
                text-align: center;
            }

            .hero--align-right & {
                text-align: right;
            }
        }

            .hero__text {
                @extend .richtext;
                @extend .richtext--dark;
                @extend .richtext--flush;

                margin-bottom: 25px;

                @include breakpoint(tablet) {
                    margin-bottom: 15px;
                }

                .hero--dark-text & {
                    color: $c-black;
                }

                .hero--overlay & {
                    font-weight: normal;
                    margin-bottom: 0;

                    P:last-child {
                        @include font(14px, 24px);
                    }
                }
            }

            .hero__buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 30px;

                .hero--align-center & {
                    justify-content: center;
                }

                .hero--align-right & {
                    justify-content: flex-end;
                }
            }

            .hero__buttons--feature {
                display: none;

                @include breakpoint(tablet) {
                    display: flex;
                }
            }
