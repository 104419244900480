.site-switch {
    @include apply-map($f-field);
    @include font(12px, 15px);
    @include lightText;

    color: inherit;
    transition: color .3s ease;
    display: flex;
    align-items: center;
    cursor: pointer;

    .header--clear:not(:hover):not(.header--active) & {
        color: $c-white;
    }

    &:hover {
        color: $c-brand;
    }

    &--active {
        @include regularText;
        position: relative;
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            bottom: -5px;
            height: 1px;
            background: #D2D2D2;
        }
    }
}
