.blocks .block.block--sus-cards {
    margin: 0;
}

.block--sus-cards {
    position: relative;
    padding: 80px 0 45px;
    background: $c-border-light;

    @include breakpoint(tablet) {
        padding: 160px 0 0;
    }

    .block__header {
        max-width: 50%;
        margin: 0 auto 40px;
        text-align: center;
        position: relative;
        z-index: 2;

        @include breakpoint(tablet) {
            max-width: 700px;
        }
    }

        .block__title {
            @include font(54px, 62px);

            color: $c-black;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(32px, 40px);

                margin-bottom: 10px;
            }
        }

        .block__intro {
            @include font(18px, 26px);

            color: $c-black;

            @include breakpoint(tablet) {
                @include font(16px, 26px);
            }
        }

    .block__item {
        padding: 35px 20px 0;
        background: $c-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2;

        @include breakpoint(tablet) {
            padding-top: 20px;
        }
    }

        .block__subtitle {
            @include font(22px, 28px);
        
            color: $c-black;
            margin-bottom: 20px;
        }

        .block__text {
            @include lightText;
            @include font(16px, 24px);

            color: $c-black;
            margin-bottom: auto;
        }
        
        .block__image {
            @include aspect(7 / 4);

            margin: 30px 0 0 -20px;
            width: calc(100% + 40px);

            img {
                position: relative;
                width: 100%;
                height: auto;
            }
        }

        .block__spinner-wrap {
            position: absolute;
            top: -20px;
            width: 350px;
            height: 350px;

            @include breakpoint(pdp-medium) {
                width: 300px;
                height: 300px;
            }

            @include breakpoint(tablet) {
                width: 270px;
                height: 270px;
            }
        }
    
        .block__spinner-wrap--left {
            left: 20px;

            @include breakpoint(tablet) {
                left: 50%;
                top: -125px;
                transform: translate(-50%, 0);
            }
        }
    
        .block__spinner-wrap--right {
            right: 20px;

            @include breakpoint(tablet) {
                display: none;
            }
        }
      
            .block__spinner-bulb,
            .block__spinner-stems {
                position: absolute;
                width: 100%;
                height: 100%;
                
                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            .block__spinner-bulb {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 120px;

                    @include breakpoint(pdp-medium) {
                        width: 95px;
                        height: 95px;
                    }
        
                    @include breakpoint(tablet) {
                        width: 85px;
                        height: 85px;
                    }
                }
            }
      
            .block__spinner-bulb--left {
                z-index: 1;
                animation: rotation 30s linear infinite reverse;
            }
            
            .block__spinner-bulb--right {
                z-index: 1;
                animation: rotation 30s linear infinite;
            }
            
            .block__spinner-stems--left {
                animation: rotation 30s linear infinite;
            }
            
            .block__spinner-stems--right {
                animation: rotation 30s linear infinite reverse;
            }

            .block__spinner-bulb,
            .block__spinner-stems {

                @media (prefers-reduced-motion) {
                    animation: none;
                }
            }
            
            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(359deg);
                }
            }

}
