.block--twin-image {
    padding: 0;

    .wrap {

        @include breakpoint(tablet) {
            padding: 0;
        }

        .wrap & {
            padding: 0;
        }

            .grid {
                overflow: visible;
            }
    }

    .block__image {
        @include aspect(1);

        margin: 0;
        overflow: visible;

        img {
            @include coverImage;

            width: 100%;
            height: 100%;
        }
    }

    .block__caption {
        @include font(12px, 22px);
        @include lightText;

        color: $c-block-caption;
        text-align: right;
    }

    .block__button {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 5px 0 5px 10px;
        background: white;

        span {
            @include font(12px, 22px);
            @include lightText;

            color: $c-label;
            letter-spacing: 2px;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                fill: $c-grey--dark;
                margin-right: 5px;
                height: 12px;
                width: 12px;
                margin-top: 2px;
            }
        }
    }
}
