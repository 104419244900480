// Login modal

.body--forgot-password-modal {
    height: 100%;
    overflow: hidden;
}

.forgot-password-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map-get($z-indexes, login-modal);
    background: $c-login-bg;
    overflow: auto;
    transition: opacity .3s ease;
}

.forgot-password-modal--activating {
    display: block;
    opacity: 0;
}

.forgot-password-modal--active {
    opacity: 1;
}

.forgot-password-modal__close {
    position: absolute;
    top: 30px;
    right: 30px;

    .admin & {
        top:60px;
    }

    .debug & {
        top:40px;
    }

    .admin.debug & {
        top:80px;
    }

    .login-modal--restricted & {
        display: none;
    }

    svg {
        fill: $c-grey--dark;
    }
}

.forgot-password-modal__container {
    display: flex;
}

    .forgot-password-modal__box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 600px + 40px;
        min-height: 100vh;
        margin: 0 auto;
        padding: 20px;
    }

    .forgot-password-modal__title {
        @include font(54px, 62px);

        margin-bottom: 40px;
        color: $c-black;
        text-align: center;

        @include breakpoint(tablet) {
            @include font(32px, 40px);
        }
    }

    .forgot-password-modal__intro {
        @include font(18px, 27px);

        margin-bottom: 30px;
        color: $c-black;
        text-align: center;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .forgot-password-modal__form {
        padding: 60px 60px 50px;
        background: $c-white;
        border-radius: 3px;

        @include breakpoint(tablet) {
            padding: 20px;
        }
    }

        .forgot-password-modal__form-message {
            @include font(16px, 24px);
            margin-bottom: 5px;
            text-align: left;
            color: #5a5a5a;
            padding-bottom: 25px;
            display: none;
        }

        .forgot-password-modal__form-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            .forgot-password-modal__form-actions {
                display: flex;
                flex-direction: column;

                a {
                    @include font(14px, 26px);
                    @include lightText;

                    text-decoration: underline;
                }
            }
