$base-column-gutter: 0;
$c-inactive: #ddd;
@mixin carousel-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    gap: $base-column-gutter;
    width: 100%;
    padding-bottom: 40px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    &::-webkit-scrollbar {
        scrollbar-width: thin;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: $c-grey--button;
        border-radius: 10px;

        @include breakpoint(tablet) {
            border-right: 15px white solid;
            border-left: 15px white solid;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: $c-grey--dark;
        border-radius: 10px;

        @include breakpoint(tablet) {
            border-right: 15px transparent solid;
            border-left: 15px transparent solid;
            background-clip: padding-box;
        }
    }
}

@mixin carousel-item {
    flex-basis: calc(25%);
    flex-shrink: 0;
    scroll-snap-align: start;

    @include breakpoint(tablet) {
        flex-basis: 60%;
        max-width: none;
    }
}

.carousel {
    &.carousel--relative {
        position: relative;
    }

    &.carousel--spaced {
        @include breakpoint(mobile) {
            margin-right: -10px;
            margin-left: -10px;
        }
    }

    &.carousel--products {
        margin-bottom: 20px;
    }
}

.carousel__header {
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    padding-right: $site-gutter--tablet;

    @include breakpoint(full) {
        padding-left: $site-gutter;
        padding-right: $site-gutter;
    }

    @include breakpoint(tablet) {
        padding-left: $site-gutter--tablet;
        padding-right: $site-gutter--tablet;
    }

    @include breakpoint(pdp-medium) {
        gap: 0 20px;
    }

    .carousel--inactive & {
        text-align: center;

        @include breakpoint(tablet) {
            text-align: left;
        }
    }
}

.carousel__navigation {
    margin-left: auto;
    gap: 20px;
    grid-row: 1/3;
    align-self: flex-end;
    display: none;

    & > button {
        padding: 0;
        background-color: transparent;
        height: 25px;

        svg {
            transition: fill 150ms ease-in;
        }

        &:hover {
            svg {
                fill: $c-inactive;
            }
        }
    }
}

.carousel__navigation--active {
    display: flex;
}

.carousel__navigation--lower {
    grid-row: 2/3;
}

.carousel__title {
    @include medium-title;
    margin-bottom: 5px;
    grid-row: 1/1;
}

.carousel__intro {
    grid-row: 2/3;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    svg {
        margin-top: 2px;
        width: 16px;
        height: 16px;
    }
}

.carousel__container {
    @include carousel-container;

    .carousel--inactive & {
        justify-content: center;

        @include breakpoint(tablet) {
            justify-content: flex-start;
        }
    }
}

.carousel__item {
    @include carousel-item;
}

.carousel__footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel--testimonials {
    .slick-prev,
    .slick-next {
        position: absolute;
        z-index: 2;
        bottom: -50px; // Matches bottom padding of the block
        transform: translate(-50%, 50%); // Shift the arrow down by half its height
        background-color: $c-white;

        @include breakpoint(tablet) {
            bottom: -30px;
        }

        span {
            @include slick-arrow;
        }
    }

    .slick-prev {
        left: calc(50% - 30px);
        padding: 0 5px 0 20px;
    }

    .slick-next {
        left: calc(50% + 30px);
        padding: 0 20px 0 5px;
    }

    .slick-disabled {
        opacity: 1;
    }
}

.carousel--block--feature {
    .slick-list,
    .slick-track,
    .slick-slide > div {
        height: 100%;
    }

    .slick-arrow {
        position: absolute;
        bottom: 25px;
        padding: 15px;
        background: transparent;
        z-index: 1;

        @include breakpoint(tablet) {
            bottom: 10px;
            padding: 10px;
        }

            svg {
                display: block;
                fill: $c-white;

                @include breakpoint(tablet) {
                    width: 14px;
                    height: 25px;
                }
            }
    }

    .slick-prev {
        right: 25px + 48px;

        @include breakpoint(tablet) {
            right: 10px + 34px;
        }
    }

    .slick-next {
        right: 25px;

        @include breakpoint(tablet) {
            right: 10px;
        }
    }
}

.carousel--block--lifecycle,
.carousel--block--image-third {
    @include breakpoint(phablet) {

        .slick-list {
            padding-right: 40%;
            margin: 0 -10px;
        }

        .slick-slide {
            margin: 0 10px;
        }

        .slick-list,
        .slick-track,
        .slick-slide > div {
            height: 100%;
        }

        .slick-arrow {
            position: absolute;
            top: -55px;
            padding: 12px;
            background: transparent;
            z-index: 1;
    
            svg {
                display: block;
                fill: $c-grey--dark
            }
        }
    
        .slick-prev {
            right: 30px;
        }
    
        .slick-next {
            right: -12px;
        }
    }
}

.carousel--block--lifecycle {
    .slick-prev {
        right: 42px;
    }

    .slick-next {
        right: 0px;
    }
}

.carousel--category-slider-stacked,
.carousel--block--get-the-look,
.carousel--block--products,
.carousel--finishes {
    .slick-track {
        margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2);

        @include breakpoint(wrap) {
            margin-left: $site-gutter;
        }

        @include breakpoint(tablet) {
            margin-left: calc($site-gutter--mobile - 10px);
        }
    }
}

.carousel--category-slider-stacked,
.carousel--category-slider-inline,
.carousel--block--get-the-look,
.carousel--block--products,
.carousel--feature-grid,
.carousel--finishes {
    .slick-track {
        gap: 16px;

        &::after,
        &::before {
            display: none;
        }

        @include breakpoint(tablet) {
            margin-left: $site-gutter--mobile;
        }
    }

    .slick-list {
        padding-right: calc(2 * $site-gutter);

        @include breakpoint(tablet) {
            padding-right: 15%;
        }
    }

    // This is based on the 1440px content max width
    // The carousel item is exactly a quarter of the width once the gap is accounted for
    .slick-slide {
        width: 348px;
        max-width: 348px;

        @include breakpoint(wrap) {
            width: auto;
            max-width: none;
        }
    }

    .slick-arrow {
        @include slick-arrow;

        position: absolute;
        top: 0;
        transform: translateY(calc(-100% - 20px));

        span {
            display: flex;
        }

        &.slick-disabled {
            opacity: 0.35;
        }
    }

    .slick-prev {
        right: calc(((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2) + 36px + 10px);

        @include breakpoint(wrap) {
            right: calc(36px + 10px + #{$site-gutter});
        }
    }

    .slick-next {
        right: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2);

        @include breakpoint(wrap) {
            right: $site-gutter;
        }
    }
}

.carousel--category-slider-inline {
    .slick-list {
        padding-right: 80px;
    }
}

.carousel--feature-grid {
    .slick-track {
        @include breakpoint(tablet) {
            margin-left: $site-gutter--mobile;
        }
    }

    .slick-list {
        margin: 0 -16px;
        padding-right: 15%;
    }
}

.carousel--finishes {
    .slick-arrow {
        border-color: rgba($c-black, 0.1);

        &:hover {
            border-color: $c-black;
        }
    }
}

.carousel--twin-callout {
    .slick-slide {
        margin: 0 10px;

        @include breakpoint(tablet)  {
            margin: 0 8px;
        }
    }

    .slick-track {
        margin-left: calc(((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2) - 10px);

        @include breakpoint(wrap) {
            margin-left: calc($site-gutter - 10px);
        }

        @include breakpoint(tablet) {
            margin-left: $site-gutter--mobile;
        }
    }

    .slick-list {
        padding-right: 15%;

        @include breakpoint(tablet)  {
            margin: 0 -8px;
        }
    }

    .slick-arrow {
        @include slick-arrow;

        span {
            display: flex;
        }

        &.slick-disabled {
            opacity: 0.35;
        }
    }
}

.carousel--awards {
    .slick-slide {
        margin: 0 10px;
    }

    .slick-track {
        @include breakpoint(tablet) {
            margin-left: $site-gutter--mobile;
        }
    }

    .slick-arrow {
        @include slick-arrow;

        span {
            display: flex;
        }

        &.slick-disabled {
            opacity: 0.35;
        }
    }
}

.carousel--hero {
    .slick-slide {
        display: flex;
    }

    .slick-dots {
        position: absolute;
        right: $site-gutter;
        bottom: 44px;
        display: flex;
        gap: 10px;

        @include breakpoint(tablet) {
            right: $site-gutter--mobile;
            bottom: 40px;
        }

        li {
            position: relative;
            width: 22px;
            height: 22px;
            font-size: 0;
            border: 1px solid $c-white;
            border-radius: 50%;

            @include breakpoint(tablet) {
                width: 16px;
                height: 16px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                width: 10px;
                height: 10px;
                background-color: transparent;

                @include breakpoint(tablet) {
                    width: 8px;
                    height: 8px;
                }
            }

            &:focus-visible {
                outline: none;
            }

            &:only-child {
                display: none;
            }
        }

        li.slick-active::after {
            background-color: $c-white;
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 1;

            &:focus-visible {
                outline: none;
            }
        }
    }
}
