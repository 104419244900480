@import "../../vendor/d3r/core/resources/sass/_mixins.scss";

/// Better antialiasing for light text on a dark background
/// @author Dom Morgan <dom@d3r.com>
@mixin lightondark {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/// Apply a map of keys to an element
///
/// @param {map} $props
/// @author Neil Brayfield <neil@d3r.com>
@mixin apply-map($props) {
    @each $name in map-keys($props) {
        @if $name == "tracking" {
            @include tracking(map-get($props, $name));
        }
        @else {
            #{$name}: map-get($props, $name)
        }
    }
}

/// PNG fallback for SVG background images
///
/// @parameter {string} $filename
/// @author Dan Graves <dan@d3r.com>
// @mixin svg($filename) {
//     background: url('/images/#{$filename}.png') no-repeat;

//     .svg & {
//         background-image: url('/images/#{$filename}.svg');
//     }
// }

@mixin hover() {
    &:hover {
        @content;
    }
}

@mixin product_showcase_padding($desktop, $tablet, $mobile) {
    padding-top: 65px + $desktop;

    @include breakpoint(tablet) {
        padding-top: $tablet;
    }

    @include breakpoint(mobile) {
        padding-top: $mobile;
    }
}

@mixin product_showcase_margin($desktop, $tablet, $mobile) {
    margin-top: 65px + $desktop;
    box-sizing: border-box;

    .admin & {
        margin-top: 65px + 44px + $desktop;
    }

    .debug & {
        margin-top: 65px + 20px + $desktop;
    }

    .debug.admin & {
        margin-top: 65px + 44px + 20px + $desktop;
    }

    @include breakpoint(tablet) {

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            margin-top: $tablet;
        }
    }

    @include breakpoint(mobile) {

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            margin-top: $mobile;
        }
    }
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin remove-white-space {
    font-size: 0 !important;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        @include rem(15px);
    }
}

@mixin trackPartImage($src, $png: false) {
    @if $png == true {
        background-image: url(/images/track-configurator/#{$src}.png?m=1);
    } @else {
        background-image: url(/images/track-configurator/#{$src}.svg?m=1);
    }
}

@mixin trackDirectionImage($direction) {
    background-image: url(/images/track-configurator/direction-#{$direction}.svg?m=1);
}

@mixin thumbRotation($rotation) {
    $options: 0, 90, 180, 270;

    @for $i from 1 through length($options) {
        [data-screen-rotation="#{nth($options, $i)}"] & {
            @if index($options, $rotation) + ($i - 1) < length($options) + 1 {
                transform: rotate(#{nth($options, index($options, $rotation) + ($i - 1))}deg);
            } @else {
                transform: rotate(#{nth($options, index($options, $rotation) + ($i - 1) - length($options))}deg);
            }
        }
    }
}

@mixin inputPlaceholderColor($color) {
    &::placeholder {
        color: $color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: $color;
    }

    &::-ms-input-placeholder {
        color: $color;
    }
}

/// Set the value of a numeric property at each breakpoint, and consider the prescence of admin/debug bars
///
/// @param {Array} $value Array of numeric values, one per breakpoint
/// @param {String} $property CSS property
/// @author Joe Adcock <joe@d3r.com>

@mixin allowForBars($value: 0, $property: top) {
    $value--default: 0;
    $value--tablet: 0;
    $value--phablet: 0;

    @if type-of($value) == map {
        @if type-of(map-get($value, default)) == number {
            $value--default: map-get($value, default);
        } @else if map-get($value, default) == false {
            $value--default: false;
        }
        @if type-of(map-get($value, tablet)) == number {
            $value--tablet: map-get($value, tablet);
        } @else if map-get($value, tablet) == false {
            $value--tablet: false;
        } @else {
            $value--tablet: $value--default;
        }
        @if type-of(map-get($value, phablet)) == number {
            $value--phablet: map-get($value, phablet);
        } @else if map-get($value, phablet) == false {
            $value--phablet: false;
        } @else {
            $value--phablet: $value--tablet;
        }
    } @else if type-of($value) == number {
        $value--default: $value;
        $value--tablet: $value;
        $value--phablet: $value;
    }

    @if $value--default {
        #{$property}: $value--default;
    }

    @if $value--tablet and $value--tablet != $value--default {
        @include breakpoint(tablet) {
            #{$property}: $value--tablet;
        }
    }

    @if $value--phablet and $value--phablet != $value--tablet {
        @include breakpoint(phablet) {
            #{$property}: $value--phablet;
        }
    }

    .body--debug & {
        @if $value--default {
            #{$property}: $value--default + $debug-height;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet;
            }
        }
    }

    .body--admin & {
        @if $value--default {
            #{$property}: $value--default + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $admin-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }

    .has-notice-bar & {
        @if $value--default {
            #{$property}: $value--default + $notice-height;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }

    .has-notice-bar.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $notice-height + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $admin-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }

    .body--debug.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $debug-height + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $admin-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }
}

/// Change the styles for the placeholder text in an input
///
/// @author Joe Adcock <joe@d3r.com>

@mixin placeholderStyles() {

    &::placeholder {
        @content;

        opacity: 1;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-ms-input-placeholder {
        @content;
    }
}

/// Stretch an image to cover its container
///
/// @author Joe Adcock <joe@d3r.com>

@mixin coverImage {
    object-fit: cover;
}

/// Allow an image to be displayed to fit its container
///
/// @author Joe Adcock <joe@d3r.com>

@mixin objectFitImage {
    @include coverImage;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
}

/// Set a relative font sizse and a unitless line height, based on the required line height and a font size
///
/// @param {Number} $fontSize Font size
/// @param {Number} $lineHeight Line height
/// @author Joe Adcock <joe@d3r.com>

@mixin font($fontSize, $lineHeight) {
    @include rem($fontSize);

    line-height: calc($lineHeight / $fontSize);
}

/// Hide an element safely, i.e. the element is not visible to the user, but is visible to a screen reader
///
/// @param {Boolean} $retain Do we want to keep the element where it is? e.g. hide a checkbox but keep it in place on the page
/// @author Joe Adcock <joe@d3r.com>

@mixin hideSafely($retain: false) {
    @if $retain {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    } @else {
        position: absolute;
        top: -100vh;
        left: -100vw;
    }
}

/// Grid gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @param {Number} $gutter-y vertical spacing
/// @author Brendan Patterson <brendan@d3r.com>

@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v) {
    margin-left: -($gutter-x);
    > * {
        padding-left: $gutter-x;
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
}

/// Grid horizontal gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @author Joe Adcock <joe@d3r.com>

@mixin grid-gutter-horizontal($gutter-x:$grid-gutter-h) {
    margin-left: -($gutter-x);
    > * {
        padding-left: $gutter-x;
    }
}

/// Grid vertical gutters
///
/// @param {Number} $gutter-y vertical spacing
/// @author Joe Adcock <joe@d3r.com>

@mixin grid-gutter-vertical($gutter-y:$grid-gutter-v) {
    > * {
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
}

/// Stretch an element to cover its container
///
/// @author Joe Adcock <joe@d3r.com>

@mixin cover() {
    object-fit: cover;
}


/// Set styles for when viewing in a short viewport
///
/// @author Joe Adcock <joe@d3r.com>

@mixin shortViewport() {
    @media (max-height: $short-viewport) {
        @content;
    }
}

/// Add bottom border line grow hover effect
///
/// @author Adam Jarrold <adam.jarrold@d3r.com>

@mixin border-line-grow($border-color: $c-black, $is-visible-border: true) {
    position: relative;
    padding-right: 0;
    padding-left: 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: $border-color;
    }

    &::before {
        @if $is-visible-border {
            opacity: 0.2;
        } @else {
            opacity: 0;
        }
    }
    
    &::after {
        width: 0;
        transition: width 150ms linear;
    }
}

@mixin border-line-trigger {
    &::after {
        width: 100%;
    }
}

@mixin slick-arrow {
    width: 36px;
    height: 36px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    padding: 0;
    background-color: transparent;
    border: 1px solid var(--block-arrow-border-color, $c-border-light);
    transition: all 200ms ease-in;
    opacity: 0.7;

    &:hover:not(.slick-disabled),
    &:hover:not([disabled]) {
        opacity: 1;
    }

    svg {
        fill: var(--block-arrow-color, $c-black);
    }

    &.slick-next svg,
    &.swiper__arrow--next svg {
        margin-left: 2px;
    }

    &.slick-prev svg,
    &.swiper__arrow--prev svg {
        margin-right: 2px;
    }
}
