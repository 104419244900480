// Table

.table {
    width: 100%;
    text-align: left;

    td, th {
        @include copy;
        color: $c-black;
        vertical-align: middle;
        padding: 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    th {
        @include rem(14px);
        @include f-light;
        text-transform: uppercase;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.table--bordered {
    position: relative;

    > thead > tr,
    > tbody > tr {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            height: 1px;
            background: $c-grey--light;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                right: 0;
                left: 0;
            }
        }
    }

    &.table--bordered-no-top {
        > thead > tr {
            &:after {
                display: none;
            }
        }
    }
}

.table--simple-border {
    @include simple-infinity-border(top);
    @include simple-infinity-border(top);

    td, th {
        @include simple-infinity-border(bottom);
        @include simple-infinity-border(bottom);
    }

    td td, td th {
        @include clear-infinity-border;
    }
}

.table--bordered-no-bottom {
    tr:last-child {
        td, th {
            @include clear-infinity-border;
        }
    }
}

.table--thick {
    td, th {
        padding: 30px 20px;
    }
}

.table--zebra {
    tr:nth-child(odd) td {
        background: mix($c-white, $background-grey, 50%);
    }
}

.table--centered {
    th,
    td {
        text-align: center;
    }
}

.table--non-flush {
    th,
    td {
        &:first-child {
            padding-left: 10px;
        }
        &:last-child {
            padding-right: 10px;
        }
    }
}

.table__cell--middle {

    > div,
    > span {
        vertical-align: middle;
    }
}

.td-flush {
    padding: 0;
}

.table--v-bordered {
    th,
    td {
        border-right: 1px solid $c-section-border;

        &:last-child {
            border-right: none;
        }
    }
}

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .table.#{$name}-block {
            &, tbody, td, tr {
                display: block;
            }

            &.table--bordered {
                td, th {
                    @include clear-infinity-border;
                }

                tr {
                    @include infinity-border(bottom, left);
                    @include infinity-border(bottom, right);
                    padding: 10px 0;
                }

                &.table--bordered-no-bottom {
                    tr:last-child {
                        @include clear-infinity-border;
                    }
                }
            }

            &.table--simple-border {
                td, th {
                    @include clear-infinity-border;
                }

                tr {
                    @include simple-infinity-border(bottom);
                    @include simple-infinity-border(bottom);
                    padding: 10px 0;
                }

                &.table--bordered-no-bottom {
                    tr:last-child {
                        @include clear-infinity-border;
                    }
                }

                &.table--bordered-no-top {
                    tr:first-child {
                        @include clear-infinity-border;
                    }
                }
            }

            thead, th {
                display: none;
            }

            td {
                padding: 5px 0;
            }
        }
    }
}

// Stock summary
.stock-summary {

    &--headings {
        position: sticky;
        top: 0;
        background-color: $c-white;
    }

    tr {

        @include breakpoint(phablet) {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            padding-bottom: 5px;
            border-bottom: 1px solid $light-grey;

            td:first-child {
                width: 100%;
            }

            td {
                padding-top: 0;
                padding-bottom: 13px;
                width: 60%;

                &:nth-child(2n+3) {
                    width: 40%;
                }
            }
        }
    }

    td, th {
        vertical-align: top;
        padding-left: 5px;
        padding-right: 5px;
        &:first-child {
            padding-left: 5px;
        }
        &:last-child {
            padding-right: 5px;
        }
    }

    th {
        @include font(12px, 20px);

        text-transform: uppercase;
        letter-spacing: 1px;
        color: $c-listing-table-header;
    }

    td {
        @include font(14px, 20px);
        @include lightText;

        padding-top: 15px;
        padding-bottom: 20px;
        color: $c-listing-table-cell;
    }
}

    tr.stock-summary__row.stock-summary__row--active {

        td {
            color: $c-white;
            background: $c-brand;
        }
    }

.stock-summary__cell--description {
    word-break: break-word;
}

.stock-summary__new {
    color: $c-success;
    font-weight: bold;
}

.stock-summary-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .field {
        flex: 1 1 auto;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    .button {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.account-table {

    label {
        display: none;
    }

    @include breakpoint(phablet) {

        td {
            &:nth-last-child(2) {
                padding: 5px 0 20px;
            }

            &:last-child {
                float: right;
                margin-top: -51px;
            }
        }

        label {
            display: inline-block;
            width: 33%;
            @include f-light;

            &.text--regular {
                @include f-regular;
            }
        }
    }
}
