.header-back {
    @include font(12px, 20px);
    @include allowForBars((
        default: $header-height,
        tablet: $header-top-height--mobile,
    ), top);

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 12px 40px;
    z-index: 1;
    max-width: $site-max-width;
    margin: 0 auto;

    @include breakpoint(tablet) {
        padding: 12px $site-gutter--mobile;
    }

    &:hover {
        text-decoration: underline;

        svg {
            transform: translateX(-5px);
        }
    }

    svg {
        transition: transform 200ms ease-in;
        fill: currentColor;
    }
}
