.collection-feature {
    @include lightondark;

    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    @include breakpoint(tablet) {
        aspect-ratio: 7 / 5;
    }
}

    .collection-feature__link {
        display: block;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.3;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 75%;
            background: linear-gradient(90deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.3;
            z-index: 1;
        }
    }

        .collection-feature__image {
            img {
                @include cover;

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .collection-feature__link:hover & {
                    transform: scale(1.2);
                }
            }
        }

        .collection-feature__content {
            position: absolute;
            right: 30px;
            bottom: 25px;
            left: 30px;
            z-index: 2;
        }

        .collection-feature__title {
            @include font(30px, 36px);

            color: $c-white;
            margin-bottom: 15px;

            &.collection-feature__title--dark {
                color: $c-black;
            }
        }
