.import-header {
	@include font(14px, 17px);
	@include lightText;

	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;

	@include breakpoint(tablet) {
		margin-bottom: 17px;
	}

	a {
		display: inline-flex;
		align-items: center;
		text-decoration: underline;
		transition: color .3s ease;

		svg {
			margin-right: 10px;
			fill: $c-border;
		}

		&:hover {
			color: $c-brand;
		}
	}
}
