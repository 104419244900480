.downloads {
    padding: 50px;
    border-left: 1px solid $c-grey--button;

    @include breakpoint(tablet) {
        padding: 40px 20px;
        border-top: 1px solid $c-grey--button;
        border-left: none;
    }
}

.downloads--space-before {
    margin-top: 20px;
}

.downloads--clean {
    padding: 0;
    border: none;

    @include breakpoint(tablet) {
        padding: 0;
        border: none;
    }
}

    .downloads__items {
        max-width: 630px;
        margin: 0 auto 20px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;

        @include breakpoint(phablet) {
            max-width: none;
            grid-template-columns: 1fr;
        }
    }
