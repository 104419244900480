@import 'colours.json';

/// Base pixel size for rem calculations
///
/// @author Dom Morgan <dom@d3r.com>
$base-px: 16px;

// Grid styles:
$grid-gutter-h:                  30px;
$grid-gutter-v:                  30px;
$grid-gutter-h--semi-tight:      20px;
$grid-gutter-v--semi-tight:      20px;
$grid-gutter-h--tight:           15px;
$grid-gutter-v--tight:           15px;
$grid-gutter-h--loose:           60px;
$grid-gutter-v--loose:           60px;
$grid-gutter-h--extra-loose:     80px;
$grid-gutter-v--extra-loose:     80px;

// Site layout sizes:
$site-max-width:                 1600px;
$site-min-width:                 300px;
$site-gutter:                    80px;
$site-gutter--mobile:            15px;
$site-gutter--tablet:            20px;
$site-width:                     100%;
$max-content-width:              550px;

// Colours

$grey2: #999999; // Phase out
$grey3: #ebebeb; // Phase out
$grey4: #d9d9d9; // Phase out
$grey5: #777777; // Phase out
$blue1: #556c7a; // Phase out
$yellow: #FBBB61;
$gold: #E6A42D;
$light-green: #A7C9BD;
$light-grey: #EAEAEA;

$background-grey: #f3f3f3;
$search-results: #e6e6e6;

$footer-grey : #5f5f5f;
$footer-border : #e3e3e3;

$c-copy: $c-black;
$c-link: $c-grey--mid;
$c-link--hover: $c-black;
$c-link-coloured: #8499a5;
$c-footer-bg: #f3f3f3;
$c-secondary-background: $background-grey;
$c-secondary-background-hover: #DDDDDD;
$c-lines: #cccccc;
$c-muted-lines: #ebebeb;
$c-badge-grey: $grey2;

$f-default-stack: "Brown", arial, sans-serif;

// $c-site-bg: #FFF;

$c-section-bg: #FFF;
$c-section-bg--alt: $background-grey;
$c-section-bg--highlight: $c-brand;

$c-section-border: $background-grey;
$c-section-border--alt: $c-white;
$c-section-border--highlight: $c-white;

$c-social-bg: $grey4;
$c-social-bg--hover: $grey2;
$c-social-icon: $c-white;

$c-product-showcase-navigation: $grey4;
$c-product-showcase-navigation--active: $c-brand;
$c-product-showcase-video-bg: $c-brand;
$c-product-showcase-form-heading: $blue1;
$c-product-showcase-form-details: $c-white;
$c-product-showcase-form-bg: $c-brand;
$c-product-showcase-line: #ddd;
$c-product-showcase-line--alt: $blue1;

$c-architectural-border: $grey4;
$c-architectural-number: $grey3;
$c-architectural-red: #D16446;

$c-color-choice-border: #979797;
$c-color-choice-border-active: $c-brand;

$c-toggle-button-inactive: #9b9b9b;
$c-toggle-button-active: $c-brand;

$c-account-nav-mobile: #e6e6e6;

$c-track-configurator-tab: #cccccc;
$c-track-configurator-tab-active: #f3f3f3;
$c-track-configurator-divider: #dddddd;
$c-track-configurator-overlay: #0b0b0b;
$c-track-configurator-step-text: #555555;

$c-guiding-principle-line: #d2d2d2;

$c-account-notice: #DD5D5A;
$c-account-border: #9b9b9b;

$c-stockist-result-active: #EBEBEB;

$admin-height: 44px;
$debug-height: 20px;
$notice-height: 100px;

// Transitions

$transition-function: cubic-bezier(0.24,0.63,0.44,1);

// Z-indexes
// Ignore anything below a 10, as that should be simple element ordering

$z-indexes: (
    // Tooltips - 10+
    tooltips: 15,

    // Navs - 20+
    sticky-nav: 24,
    primary-header: 25,

    // Modals - 30+
    login-modal: 35,

    // Debug - 40+
    debug-bar: 45
);

// Fonts
$f-primary-stack:                Brown, Arial, Helvetica, sans-serif;
$f-primary:                      (font-family: $f-primary-stack, line-height: 1.7);
$f-primary-color:                $c-body;

$f-title-stack:                  Brown, Arial, Helvetica, sans-serif;
$f-title:                        (font-family: $f-title-stack, line-height: 1.2, text-transform: none, font-weight: 400, letter-spacing: normal);
$f-title-color:                  $c-title;

$f-label-stack:                  $f-primary-stack;
$f-label:                        (font-family: $f-label-stack, font-weight: 400, text-transform: none, line-height: 1.5);
$f-label-color:                  $c-label;

$f-field-stack:                  $f-primary-stack;
$f-field:                        (font-family: $f-field-stack, line-height: 1.4);
$f-field-color:                  $c-black;

$f-button:                       $f-title;

$f-alternate-stack:              "Charter", Arial, Helvetica, sans-serif;

$f-minion-stack:                 "Minion", "Times New Roman", Times, serif;

// Forms
$field-outline:                  rgba(0, 0, 0, .1) 0 0 20px 0px !default;

$header-top-height: 45px;
$header-top-height--mobile: 70px;
$header-bottom-height: 70px;
$header-height: $header-top-height + $header-bottom-height;

$hero-min-height: 600px;
$hero-max-height: 600px; // Full bleed beneath header
$hero-short-height: 450px;

$hero-overlay-above-fold-height: 110px;

$short-viewport: 720px;

$track-configurator-nav-height: 45px;
$track-configurator-toggle-height: 120px;

$track-configurator-pieces: 'track-1m', 'track-2m', 'live-connector', 'live-end', 't-connector-left-nearside', 't-connector-left-farside', 't-connector-right-nearside', 't-connector-right-farside', 'corner-connector-left', 'corner-connector-right', 'x-connector', 'end-to-end-connector';
$track-configurator-pieces-visual: 't-connector-left-nearside', 't-connector-left-farside', 't-connector-right-nearside', 't-connector-right-farside', 'corner-connector-left', 'corner-connector-right', 'x-connector', 'end-to-end-connector';
$track-configurator-pieces-start: 'live-connector', 'live-end';
$track-configurator-top-height: 73px;

$cookie-fg: $c-grey--light;
$cookie-nav-bg: $c-white;
$cookie-footer-bg: $c-white;
$cookie-fontcolor: $c-black;
$cookie-altfontcolor: $c-black;
$cookie-border: $c-border;
$cookie-fontcolor: $c-black;
$cookie-switch-fg: $c-black;
$cookie-switch-bg: $c-white;

@import "cookie-centre/cookie-settings";

:root {
    --scrollbar-width: 0;
}
