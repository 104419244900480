.detail {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: flex-start;
    padding: 40px 0 80px;

    @include breakpoint(tablet) {
        padding: 0 0 40px;
        grid-template-columns: minmax(0, 1fr);
        gap: 20px;
    }
}

    .detail__left {
        position: relative;

        @include breakpoint(tablet) {
            margin-left: -$site-gutter--mobile;
            margin-right: -$site-gutter--mobile;
        }
    }

        .detail__photoswipe-trigger {
            display: block;
            position: absolute;
            top: 25px;
            right: 25px;
        }

    .detail__right {
        padding-left: 40px;

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

    .detail__notice {
        background-color: $c-black;
        padding: 20px;
        text-align: center;
        border-radius: 2px;
        margin-bottom: 20px;

        @include breakpoint(pdp-large) {
            padding: 20px 80px;
        }

        @include breakpoint(mobile)  {
            padding: 20px;
        }

        p {
            @include font(16px, 22px);

            color: $c-white;
        }
    }

    .detail__title {
        @include large-title;

        color: $c-black;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            @include font(26px, 46px);

            margin-bottom: 5px;
        }
    }

    .detail__secondary-title {
        @include font(16px, 22px);
        @include lightText;

        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }

        > * {
            &:last-child::after {
                display: none;
            }

            &:after {
                content: '|';
                margin: 0 10px;
                color: $c-detail-subtitle-separator;
            }
        }
    }

    .detail__tertiary-title {
        @include font(18px, 22px);
        @include lightText;

        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        color: $c-pdp-grey;

        > * {

            &:last-child {

                &:after {
                    display: none;
                }
            }

            &:after {
                content: '|';
                margin: 0 15px;
                color: $c-detail-subtitle-separator;
            }
        }
    }

    .detail__description {
        margin-bottom: 30px;
        color: $c-body;

        .detail__description-mto-text {
            @include lightText;
            @include font(12px, 18px);
        }

        .detail__description-mto-title {
            @include regularText;
            @include font(14px, 17px);
        }

        .detail__description-moq-text {
            @include font(12px, 18px);
            color: $c-black;
        }

        .detail__description-mto-info {
            display: flex;
            align-items: center;
            gap: 11px;
            margin-top: -5px; // slightly reduce margin from detail__section
            margin-bottom: 9px;
        }

        .detail__description-leadtime-text {
            @include regularText;
            @include font(12px, 18px);

            color: $c-black;
            background-color: $c-white;
        }
    }

    .detail__description--bg {
        background-color: $c-login-bg;
        padding: 20px;
        margin-bottom: 20px;
    }

    .detail__description-leadtime {
        background: $c-border-light;
        border-radius: 15px;
        height: 30px;
        max-width: fit-content;
        padding: 6px 15px;
    }

    .detail__show-bv-stock {
        margin-top: 10px;

        &--flush {
            margin-top: 0;
        }
    }

    .detail__uk-only {
        @include font(12px, 26px);

        color: $c-body;
        margin-bottom: 30px;
        font-weight: 300;
    }

    .detail__section {
        margin-bottom: 20px;
    }

    .detail__section--small-margin {
        margin-bottom: 15px;
    }

        .detail__copy {
            @include copy;
            @include rem(14px);

            color: $c-pdp-grey;
        }

    .detail__options {
        margin-bottom: 30px;
    }

        .detail__options__title {
            @include copy;
            @include rem(14px);
            font-weight: 300;
            color: $c-black;
            text-align: left;
            padding-bottom: 5px;
        }

        .detail__options__option {
            margin-right: 10px;
        }

        .detail__details__downloads {
            text-align: left;
        }

            .detail__details__downloads__item {
                @include copy;
                margin-bottom: 15px;
            }

        .detail__actions {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 16px;

            & > * {
                margin-bottom: 10px;
            }
        }

            .detail__actions__info {
                flex: 1 1 auto;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;

                @include breakpoint(mobile) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

                .detail__actions__price {
                    @include font(24px, 24px);

                    color: $c-black;

                    span {
                        @include font(14px, 14px);
                        @include lightText;
                    }
                }

            .detail__actions__form {
                display: flex;

                @include breakpoint(tablet) {
                    order: 1;
                }
            }

                .detail__actions__form__qty {
                    margin-right: 20px;
                }

            .detail__actions__uk-only {
                font-weight: 300;
                margin-top: 0;
                flex: 100%;
                color: $c-body;
            }
            
            .detail__actions__stock {
                margin-top: 5px;
                flex-basis: 100%;

                @include breakpoint(mobile) {
                    margin: 15px 0;
                }
            }

            .detail__actions__stock-due {
                margin-top: 9px;
                padding-top: 22px;
                position: relative;
                flex: 100%;

                &::after {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background: $c-border-light;
                }
            }

            .detail__details__title {
                @include copy;
                @include f-regular;
                color: $c-black;
                border-bottom: 1px solid $c-black;
                padding-bottom: 5px;
                margin-bottom: 30px;
                text-align: left;
            }

        .detail__guest-message {
            @include feature;
            margin-top: 20px;

            .link {
                @include f-regular;
                white-space: nowrap;
            }
        }

        .detail__tabs {
            margin: 40px 0;

            @include breakpoint(mobile) {
                margin: 25px 0;
            }
        }

        .detail__where-buy {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            margin-bottom: 25px;
            gap: 20px;

            .button--border {
                display: flex;

                span {
                    @include font(16px, 16px);

                    padding-bottom: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .detail__where-buy--flush {
            margin: 0;

            > * {
                margin: 0;
            }
        }

        .detail__where-buy__button--m-top {
            @include breakpoint(mobile) {
                margin-top: 10px;
            }
        }

        .detail__technical-drawing-wrap {
            padding: 30px;
        }

    .detail__dimensions {
        .link {
            @include f-regular;
        }
    }

.detail__view-more-collection {
    position: relative;
    text-align: center;
    display: block;
    margin-bottom: 40px;
    // Silly, but to match the above slider nav
    margin-right: 20px;
}

    .detail__view-more-collection__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($c-black, 0.4);

        &:hover {
            background-color: rgba($c-black, 0.5);
        }
    }

    .detail__view-more-collection__title {
        @include rem(12px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        color: $c-white;
        max-width: 60px;
        line-height: 1.3;

        > svg {
            fill: $c-white;
            max-width: 8px;
            margin-bottom: -7px;
            margin-right: 5px;
        }
    }

.detail__select {
    margin-bottom: 0;

    .compare-max &:not(.field--checked) {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.detail__select--link {
    display: flex;
    align-items: center;

    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }

    label {
        position: static;

        &:after {
            content: "Add to compare";
            display: inline;
            text-decoration: underline;
            white-space: nowrap;
            color: $c-black;
        }
    }

    &:has(input:checked) {
        label:after {
            content: "Remove from compare";
        }
    }

    a {
        @include font(12px, 16px);
        text-decoration: underline;
    }
}
