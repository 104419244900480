.bio-group {
    @extend .grid;
    @extend .grid--flush;
    @extend .grid--flex;

    background: $c-grey--button;
}

    .bio-group__item {
        @extend .l-one-half;
        @extend .m-full;

        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background: $c-white;

            @include breakpoint(tablet) {
                top: auto;
                bottom: 0;
                width: 100%;
                height: 1px;
            }
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }
