.browse-by {
    text-align: justify;
    font-size: 1px;

    & > * {
        vertical-align: middle;
        display: inline-block;
        font-size: 16px;
    }

    label {
        font-size: 16px;
        padding: 10px 0;
        margin-right: 20px;
    }

    .input {
        @include rem(14px);
        @include inputPlaceholderColor($grey2);

        line-height: calc(17 / 14);
        margin: auto;
        padding-top: 13px;
        padding-bottom: 13px;
        -webkit-appearance: none;
        border-radius: 0;
    }

    .field {
        width: 100%;
        margin: 0;
    }

    .field--search {

        button {
            border: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 45px;
            height: 100%;
            padding: 0;
            background: $c-brand;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            i {
                color: $c-white;
            }
        }
    }
}

.type-search {
    border-top: 1px solid $c-border;
    padding: 40px 130px 0px;

    @include breakpoint(tablet) {
        padding: 40px 20px 0;
    }
}

    .product-dl-list {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        background: $c-border;
        

        @include breakpoint(tablet) {
            .border--left {
                border-left: none;
                margin-left: 0;
                .product-dl-list__detail {
                    padding-left: 0;
                }
            }
            .border--right {
                border-right: none;
                margin-right: 0;
                .product-dl-list__detail {
                    padding-right: 0;
                }
            }

            .border--top-right:after {
                left: -30px;
            }
        }
    }

    .product-dl-list--grid {
        font-size: 0;
    }

    .product-dl-list--flex {
        display: flex;
        flex-wrap: wrap;
    }

    @include breakpoint(tablet) {
        .container--right-half.product-dl-list {
            max-width: none;
            margin: 0 auto;
        }
    }

    .product-dl-list__detail {
        padding: 30px 0 25px 0;

        .border--left & {
            padding-left: 30px;
        }
        .border--right & {
            padding-right: 30px;
        }

        .product-dl-list--grid & {
            display: inline-block;
            width: 50%;
            padding: 20px 30px;
            border-bottom: 1px solid $background-grey;
            box-sizing: border-box;

            @include breakpoint(tablet) {
                display: block;
                width: 100%;
                padding-right: 0;
                padding-left: 0;
            }

            &:nth-child(2n) {
                padding-right: 0;
            }

            &:nth-child(2n + 1) {
                padding-left: 0;
                border-right: 1px solid $background-grey;

                @include breakpoint(tablet) {
                    border-right: none;
                }
            }
        }

        .product-dl-list--flush & {

            &:nth-last-child(1),
            &:nth-last-child(2):not(:nth-child(even)) {
                border-bottom: none;
            }

            &:nth-last-child(2):not(:nth-child(even)) {

                @include breakpoint(tablet) {
                    border-bottom: 1px solid $background-grey;
                }
            }
        }

        &:nth-child(2n + 1) {
            direction: rtl;

            @include breakpoint(mobile) {
                direction: ltr;
            }

            > * {
                direction: ltr;
            }
        }
    }

    .product-dl-list__detail--equal {
        padding: 30px 0;
    }

    .product-dl-list__detail--header {
        color: $footer-grey;
    }

    .product-dl-list__container {
        flex: 1 1 calc(50% - 10px);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 30px;

        &:nth-child(odd) {
            margin-right: 10px;
        }

        &:nth-child(even) {
            margin-left: 10px;
        }

        @include breakpoint(tablet) {
            width: 100%;
            max-width: 100%;
        }

        .product-dl-list--grid & {
            box-sizing: border-box;

            @include breakpoint(tablet) {
                min-width: 300px;
                max-width: 90%;
                margin: 0 auto;
            }

            @include breakpoint(mobile) {
                max-width: 100%;
                padding-right: 16px;
                padding-left: 16px;
            }
        }
    }

    .product-dl-list__basket {

    }

    .product-dl-list__link {
        padding: 16px 0;
        color: $c-grey--mid;
        display: inline-block;
        position: relative;
        line-height: 1.3;
        span {
            text-transform: uppercase;
            text-decoration: underline;
            @include rem(14px);
            padding-left: 30px;
            display: inline-block;
            text-align: left;
        }
        &--no-pad {
            padding: 0;
        }
    }
    .product-dl-list__link--center {
        float: none;
        margin: 0;
        display: inline-block;
    }
    .product-dl-list__link--view {
        .icon-stack__corner {
            height: 9px;
            line-height: 9px;
            bottom: -7px;
        }
    }

    .product-dl-list__link--highlight {

        i {
            color: $c-brand;
        }
    }

    .product-dl-list__pad {
        padding: 16px 0;
    }

    .product-dl-list__link--right {
        margin-right: 0;
        margin-left: 0;
    }
    .product-dl-list__large-title {
        @include rem(17px);

        display: flex;
        align-items: flex-start;
        color: $c-grey--mid;
        padding: 12px 0;

        @include breakpoint(mobile) {
            @include rem(14px);

            padding: 0;
        }
    }
    .product-dl-list__underline {
        text-decoration: underline;
    }
    .product-dl-list__count {
        @include f-light;

        font-style: italic;
    }
    .product-dl-list__title__single {
        @include f-regular;

        padding-top: 9px;
    }
    .product-dl-list__title__icon {
        @include rem(26px);
        margin-right: 16px;
        vertical-align: middle;
        line-height: 1;
    }

    .product-dl-list__title__icon--large {
        @include rem(30px);
        margin-top: 5px;
        float: left;
    }

    .product-dl-list__title {
        @include rem(16px);

        color: $c-grey--mid;
        line-height: 1.4;
        text-align: left;

        @include breakpoint(mobile) {
            @include rem(14px);
        }

        img {
            float: left;
            width: 62px;
            margin-right: 20px;
            margin-top: -7px;
            margin-bottom: -2px;

            @include breakpoint(mobile) {
                margin-right: 15px;
            }
        }

        &--uppercase {
            text-transform: uppercase;
        }
    }

    .product-dl-list__title--regular {
        @include f-regular;
    }

        .product-dl-list__description {
            @include copy;
            @include f-light;
            @include rem(14px);

            text-transform: none;
            display: block;
            color: $footer-grey;

            @include breakpoint(mobile) {
                @include rem(12px);
            }
        }

    .product-dl-list__thumbnail {
        @include aspect(1);

        display: inline-block;
        vertical-align: middle;
        width: 67px;

        @include breakpoint(mobile) {
            width: 60px;
            margin-bottom: 15px;
        }
    }

    .product-dl-list__content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;
        flex-grow: 1;
    }

        .product-dl-list__text {

        }

            .product-dl-list__title {
                @include font(16px, 30px);

                color: $c-black;
            }

            .product-dl-list__subtitle {
                @include font(14px, 22px);
                @include lightText;

                color: $c-pdp-grey;
            }

    .product-dl-list__image {
        margin-right: 15px;
    }

    .product-dl-list__buttons {
        display: flex;
        flex-flow: row nowrap;

        a:first-of-type {
            margin-right: 25px;
        }
    }

    .product-dl-list__content--can-view {
        margin-left: 0;

        @include breakpoint(mobile) {
            width: calc(100% - 67px - 15px);
            margin: 0 15px 5px 0;
        }
    }

    .product-dl-list__content--nothing-to-view {
        width: calc(100% - 200px - 15px);
        margin-left: 0;

        @include breakpoint(mobile) {
            width: 100%;
            margin: 0 0 5px;
        }
    }

    .product-dl-list__view {
        display: inline-block;
        vertical-align: middle;
        width: 67px;
        margin-right: 15px;

        @include breakpoint(mobile) {
            margin: 0 0 15px;
        }
    }

    .product-dl-list__action {
        display: inline-block;
        vertical-align: middle;
        width: 200px;

        @include breakpoint(mobile) {
            width: 100%;
        }

        > * {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .product-dl-list__show-bundle {
        @include f-light;
        @include rem(14px);

        line-height: calc(24 / 14);
        text-decoration: underline;
        color: $c-grey--mid;
        transition: color 0.2s;

        &:hover {
            color: $c-black;
        }

        @include breakpoint(mobile) {
            line-height: calc(50 / 14);
        }
    }

    .product-dl-list__link--no-icon span {
        padding-left: 0;
    }

.product-dl-switch {
    font-size: 0;
}



.icon-stack {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    margin-right: 10px;
}
    .icon-stack--pull {
        margin-top: -13px;
        left: -1px;
    }
    .icon-stack__corner {
        position: absolute;
        right: -6px;
        padding: 1px;
        bottom: -8px;
        background-color: white;
        border-radius: 99px;
        @include rem(12px);

    }
    .icon-stack__cascade {
        position: absolute;
        left: 4px;
        top: 1px;
        background-color: white;
        line-height: 1;
    }

.product-dl-filters {
    text-align: justify;
    font-size: 1px;
    &:after {
        content: "";
        width: 100%;
        height: 0;
        overflow: hidden;
        display: inline-block;
    }
}
.product-dl-filter {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 30px;
}
    .product-dl-filter--right {
        margin-right: 0;
    }


    .download-reference__label {
        line-height: 1.5;
        margin-right: 40px;
        text-align: left;
        display: inline-block;

        @include breakpoint(mobile) {
            display: block;
            margin-right: 0;
        }

        span {
            @include f-light;

            color: $grey2;
        }
    }

.partners-import-message {
    margin-top: 40px;
    max-width: 800px;
    background-color: $c-brand;
    padding: 20px;
    color: $c-white;
    @include font(16px, 22px);
}

.partners-order-list,
.partners-import-table {
    padding: 40px 0 35px;

    .product-stock {
        margin-top: 20px;
    }

    .product-stock-warning {
        margin-top: 20px;
    }
}

.partners-import__due-stock {
    margin-top: 20px;
    padding-bottom: 8px;
    flex: 100%;

    border-top: 1px solid $c-border-light;
    border-bottom: 1px solid $c-border-light;
}

.partners-order-list {
    padding: 35px 0;
    color: $footer-grey;
}

.partners-order-list--flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(tablet) {
        flex-wrap: wrap;
        padding: 10px 0;
    }
}

    .partners-order-list__col {
        flex: 0 0 auto;
        margin-right: 30px;
        margin-left: 30px;
        text-align: center;
        font-size: 0;

        @include breakpoint(nav) {
            margin-right: 20px;
            margin-left: 20px;
        }

        @include breakpoint(tablet) {
            margin: 10px;
        }

        @include breakpoint(mobile) {
            flex-basis: 50%;
            margin-right: 0;
            margin-left: 0;
            text-align: center;
        }

        &:first-child {
            margin-left: 0;
            text-align: left;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .partners-order-list__col--flex {
        flex: 1 1 auto;

        @include breakpoint(tablet) {
            flex-basis: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .partners-order-list__col--date {
        flex-basis: 80px;
    }

    .partners-order-list__col--status {
        flex-basis: 130px;
    }

    .partners-order-list__col--delete {
        flex-basis: 80px;
    }

    .partners-order-list__col--details {
        flex-basis: 80px;
    }

    .partners-order-list__col--download {
        flex-basis: 132px;
    }

    .partners-order-list__col--date,
    .partners-order-list__col--status,
    .partners-order-list__col--delete,
    .partners-order-list__col--details,
    .partners-order-list__col--download {

        @include breakpoint(tablet) {
            flex-basis: auto;
        }

        @include breakpoint(mobile) {
            flex-basis: 50%;
        }
    }

        .partners-order-list__label {
            @include rem(14px);

            line-height: calc(17 / 14);
            text-transform: uppercase;
            color: $footer-grey;

            @include breakpoint(mobile) {
                @include rem(12px);

                line-height: calc(15 / 12);
            }
        }

        .partners-order-list__name {
            @include rem(16px);

            line-height: calc(19 / 16);
            color: $c-grey--mid;
        }

        .partners-order-list__date {
            @include f-light;
            @include rem(14px);

            line-height: calc(24 / 14);
            color: $footer-grey;

            @include breakpoint(mobile) {
                @include rem(12px);

                line-height: calc(24 / 12);
            }
        }

        .partners-order-list__link {
            @include rem(14px);

            line-height: calc(17 / 14);
            text-transform: uppercase;
            color: $c-black;
            transition: color 0.2s;

            &:hover {
                color: $c-black;
            }

            @include breakpoint(mobile) {
                @include rem(12px);

                line-height: calc(15 / 12);
            }
        }

        .partners-order-list__link--has-icon {

            > * {
                @include rem(14px);

                line-height: calc(17 / 14);
                display: inline-block;
                vertical-align: middle;

                @include breakpoint(mobile) {
                    @include rem(12px);

                    line-height: calc(15 / 12);
                }
            }
        }

            .partners-order-list__icon {
                margin-right: 10px;

                i {
                    color: $c-brand;
                    line-height: 17px;
                    vertical-align: initial;
                }
            }

.import-actions {
}

    .import-actions__action {
        display: inline-block;
        margin-bottom: 10px;
    }

.partners-order-table {
    display: table;
}
    .partners-order-table__cell {
        display: table-cell;
    }

.partners-import-table.line-ignored {
    color: #CCC;
}

    .partners-import-table.line-ignored div {
        text-decoration: line-through;
    }

.partner-band-title {
    @include rem(22px);
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 35px;
    line-height: 45px;
}

// Bundle file preview

.folder-items {
    display: none;
}

.folder-items--visible {
    display: block;
}

.view-folder-items {
    @include rem(14px);

    position: relative;
    text-transform: uppercase;
    padding-right: 30px;

    &:after {
        content: "\f107";
        display: block;
        position: absolute;
        right: 0;
        top: 3px;
        @extend .fa;
        @include rem(13px);
        pointer-events: none;
    }
}

.view-folder-items--open {
    &:after {
        transform: rotate(180deg);
    }
}

@include breakpoint(mobile) {
    .button--downloads {
        margin-bottom: 30px;
    }
}
