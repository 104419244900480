.select-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;

    background: #F4F4F4;
    border-top: #D2D2D2 solid 1px;

    z-index: 10;

    button {
        span {
            @include font(14px, 21px);
            padding: 8px 20px 9px 20px;
            white-space: nowrap;
        }

        &:after {
            display: none;
        }
    }

    @include breakpoint(tablet) {
        padding: 10px 15px;

        button {
            span {
                @include font(12px, 21px);
                padding: 3px 13px 4px 13px;
            }
        }

        svg {
            display: none;
        }
    }
}

.select-bar__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.select-bar__count {
    @include font(14px, 20px);

    @include breakpoint(tablet) {
        @include font(12px, 16px);
    }
}

.select-bar__limit {
    @include font(12px, 18px);
    font-weight: 300;

    @include breakpoint(tablet) {
        @include font(12px, 16px);
    }
}
.select-bar__limit--warning {
    color: #DD5D5A;
    font-weight: 700;
}

.select-bar__reset {
    text-decoration: underline;
}
