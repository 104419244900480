// Messages

.message {
    padding: 16px;
    margin-bottom: 20px;

    p {
        @include copy;
        @include f-regular;
        color: white;

        a {
            color: white;
            text-decoration: underline;
        }
    }
}

.message--error {
    background: $c-error;
}

.message--success {
    background: $c-success;
}

.message--info {
    background: $c-section-bg--highlight;
}

.message--flush {
    margin-bottom: 0;
}

.message--centered {
    text-align: center;
}

.message--hidden {
    display: none;
}

.message--mobile {
    @include breakpoint(desktop) {
        display: none;
    };
}
