.toggle-button {
    font-size: 0;
}

    .toggle-button__label {
        display: block;
        cursor: pointer;
    }

        .toggle-button__input {
            display: none;
        }

        .toggle-button__name {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px 0 0;

            @include breakpoint(tablet) {
                display: block;
                margin: 0 0 10px;
            }
        }

            .toggle-button__text {
                @include f-light;
                @include rem(14px);

                line-height: calc(17 / 14);
                position: relative;
                padding: 0 25px;
            }

                .toggle-button__icon {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @include breakpoint(tablet) {
                        display: block;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }

        .toggle-button__status {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 47px;
            height: 25px;
            background: $c-white;
            border-radius: 2px;
            transition: all .3s;
            border: 1px solid $c-toggle-button-inactive;

            .toggle-button__input:checked ~ & {
                background: $c-toggle-button-active;
                border-color: $c-toggle-button-active;
            }

            &:before {
                @include f-light;
                @include rem(11px);

                line-height: calc(14 / 11);
                content: 'No';
                position: absolute;
                top: 50%;
                left: 75%;
                color: $c-toggle-button-inactive;
                transform: translate(-50%, -50%);
                transition: all .3s;

                .toggle-button__input:checked ~ & {
                    @include lightondark;

                    content: 'Yes';
                    left: 25%;
                    color: $c-white;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: 21px;
                height: 21px;
                background: $c-toggle-button-inactive;
                border-radius: 2px;
                transition: all .3s;

                .toggle-button__input:checked ~ & {
                    left: 24px;
                    background: $c-white;
                }
            }
        }
