.import-info {
	padding: 0 40px;

	@include breakpoint(tablet) {
		padding: 20px 0;
	}
}

.import-info--right {
	padding-right: 0;

	@include breakpoint(tablet) {
		padding-bottom: 0;
	}
}

.import-info--left {
	padding-left: 0;

	@include breakpoint(tablet) {
		padding-top: 0;
	}
}

.import-info--border {
	border-right: 1px solid $c-import-border;

	@include breakpoint(tablet) {
		border-right: none;
		border-bottom: 1px solid $c-import-border;
	}
}

	.import-info__title {
		@include apply-map($f-title);
		@include font(14px, 26px);

		margin-bottom: 12px;
		color: $c-black;
	}

	.import-info__image {
		margin-bottom: 16px;
	}

	.import-info__list {
		@include font(14px, 26px);

		list-style-type: upper-alpha;
		list-style-position: inside;

		li::marker {
			color: $c-brand;
		}
	}

	.import-info__text {
		@include font(14px, 24px);
		@include lightText;

		margin-bottom: 6px;
	}

	.import-info__link {
		@include font(14px, 26px);
		@include lightText;

		text-decoration: underline;
	}
