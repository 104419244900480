.link-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -10px;

    > * {
        margin: 10px;
    }
}

    .link-list__title {
        @include font(12px, 20px);

        text-transform: uppercase;
        letter-spacing: 1px;
        color: $c-page-meta-key;
    }

    .link-list__item {
        @include font(16px, 20px);

        color: $c-black;
        text-decoration: underline;
        transition: color .3s ease;

        &:hover {
            color: $c-brand;
        }
    }
