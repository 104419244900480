.field {
    margin-bottom: 20px;
    position: relative;

    label {
        @include font(12px, 12px);
        @include lightText;

        position: absolute;
        display: block;
        top: 7px;
        left: 15px;
        color: $c-label;
        z-index: 1;
    }

    input.check_box {
        appearance: none;
        border: 1px solid $c-border;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background-color: $c-white;
        transition: outline .2s ease;
        outline-offset: -1px;
        outline-style: solid;
        outline-width: 2px;
        outline-color: transparent;
        flex-shrink: 0;
        cursor: pointer;

        &:disabled {
            background-color: var(--c-field-disabled);
        }

        &:focus {
            outline-color: $c-black;
        }

        &:checked {
            border-color: $c-black;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        } 
    }

    label.check_box {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        .check_box__text {
            line-height: 20px;
            font-size: 12px;
        }

        &.custom_style {
            @include regularText;
            align-items: start;

            span {
                @include lightText;

                &.note {
                    @include font(12px, 20px)
                }
            }
        }
    }

    &:has(select.input:not(.multi-select)) {
        &::after {
            content: '';
            position: absolute;
            right: 50px;
            top: 0;
            height: 50px;
            width: 1px;
            background-color: $c-border;
        }
    }

    &.field-error:has(select.input) {
        &::after {
            background-color: $c-error;
        }
    }

    &:has(select:focus) {
        &::after {
            background-color: $c-black;
        }
    }
}

.field--radio:not(.field--bool-radio-button) {
    input[type="radio"] {
        appearance: none;
        border: 1px solid $c-border;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        background-color: $c-white;
        transition: outline .2s ease;
        vertical-align: middle;
        outline-offset: -1px;
        outline-style: solid;
        outline-width: 2px;
        outline-color: transparent;

        &:disabled {
            background-color: var(--c-field-disabled);
        }

        &:focus {
            outline-color: $c-black;

        }

        &:checked {
            border-color: $c-black;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 18px 18px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3ccircle cx='10' cy='10' fill='%23000' r='5'/%3e%3c/svg%3e");
        }
    }
}

.field--checkbox,
.field--radio {
    label {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
    }

    input {
        &:focus {
            outline: none;
        }
    }
}

.field--radio {
    flex-direction: column;
    gap: 10px;

    label {
        display: inline-flex;
        gap: 8px;
        align-items: flex-start;
    }
}

.select_option_col label {
    @include apply-map($f-field);
    @include font(14px, 20px);
    @include lightText;

    position: relative;
    padding-left: 30px;
}

.field--close {
    margin-bottom: 15px;
}

.field--flush {
    margin-bottom: 0;
}

.field--flush-label {
    label {
        margin-bottom: 0;
    }
}

.field--hidden-label {
    > label {
        @include hideSafely;
    }
}

.field--hidden {
    display: none;
}

.field--radio {
    label {
        @include apply-map($f-field);
        @include font(14px, 20px);
        @include lightText;

        display: flex;
        align-items: center;
        color: $f-field-color;
    }
}

.field--radio {
    display: flex;

    label:not([for]) {
        margin-right: 10px;

        a {
            text-decoration: underline;
        }
    }
}

.field--bool-radio-button {
    grid-column: span 2;
    display: flex;
    flex-direction: row;
    gap: 10px;

    @include breakpoint(tablet) {
        grid-column: span 1;
    }

    label {
        @include font(12px, 20px);

        &:has(input[type="radio"]) {
            @include regularText;

            span {
                width: 50px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                background-color: $c-white;
                border: 1px solid $c-border;
            }

            input[type="radio"] {
                appearance: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        &:has(input[type="radio"]:checked) span {
            background-color: $c-black;
            color: $c-white;
            border-color: $c-black;
        }
    }
}

.input {
    @include apply-map($f-field);
    @include font(14px, 20px);

    margin: 0;
    color: $f-field-color;
    outline: none;
}

input.input,
textarea.input,
select.input:not(.multi-select) {
    @include font(14px, 20px);

    width: 100%;
    border: 1px solid $c-border;
    border-radius: 3px;
    padding: 20px 15px 0 15px;
    height: 50px;
    color: $c-black;

    &:not([disabled]):not([readonly]):focus {
        border-color: $c-grey--mid;
    }

    &[disabled] {
        background: $c-white;
        opacity: 0.3;
    }

    &[readonly] {
        background: $c-white;
        opacity: 0.6;
    }

    .field-error & {
        border-color: $c-error;
    }
}

textarea.input {
    height: 200px;
    max-width: 100%;
    padding-top: 30px;
}

select.input:not(.multi-select) {
    max-width: 100%;
    appearance: none;
    background-color: $c-white;
    padding-right: 55px;
    background-position: right 20px center;
    background-size: 10px 7px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10' height='7' viewBox='0 0 10 7'%3E%3Cpath d='M9.90008037,0.634364823 L9.39911346,0.105770677 C9.33235999,0.0351828234 9.25546364,0 9.1685297,0 C9.08180634,0 9.00494509,0.0351828234 8.93819162,0.105770677 L5.00005264,4.26115839 L1.06208915,0.105881781 C0.995300584,0.035293927 0.91843933,0.000111103653 0.831610682,0.000111103653 C0.744746937,0.000111103653 0.667885684,0.035293927 0.601132212,0.105881781 L0.100235497,0.634512961 C0.0333416395,0.704952677 0,0.786058343 0,0.877718857 C0,0.969305301 0.0334469289,1.05041097 0.100235497,1.12085068 L4.76957417,6.04814955 C4.83632765,6.1186263 4.913224,6.15384615 5.00005264,6.15384615 C5.08688129,6.15384615 5.16363726,6.1186263 5.23035563,6.04814955 L9.90008037,1.12085068 C9.96683384,1.05037393 10,0.969268267 10,0.877718857 C10,0.786058343 9.96683384,0.704952677 9.90008037,0.634364823 Z'/%3E%3C/svg%3E%0A");
    text-overflow: ellipsis;
}

.field--inline-select {
    display: flex;
    align-items: center;
    gap: 5px;

    label {
        position: relative;
        left: unset;
        top: unset;
    }

    select.input {
        @include font(12px, 20px);

        height: auto;
        padding: 0;
        padding-right: 15px;
        border: none;
        background-position: right 0 center;
        background-color: transparent;
    }

    &::after {
        display: none;
    }
}

.field--center-text {
    input,
    select.input {
        padding-top: 0;
        padding-bottom: 0;    
    }
}

.field-inline {
    font-size: 0;
}

.field.error,
.field-error {
    .input,
    .select-replace__value,
    .multi-select__value,
    select {
        border-color: $c-error;
    }

    p.error {
        @include font(12px, 20px);

        color: $c-error;
        margin-top: 5px;
    }
}

.js {
    .antispam-hide {
        display: none;
    }
    .field--antispam {
        display: none;
    }
}
