.category-callout {
    position: relative;
    overflow: hidden;

    a {
        display: block;

        &:hover {
            .category-callout__title {
                @include border-line-trigger;
            }

            img {
                transform: scale(1.2);
            }

            svg {
                opacity: 1;
                margin-right: 0;
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 130px;
        opacity: 0.4;
        background: linear-gradient(180deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);
        pointer-events: none;
    }
}

    .category-callout__image {
        aspect-ratio: 5 / 3;
        height: 100%;

        @include breakpoint(phablet) {
            margin: 0;
        }

        img {
            transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .category-callout--portrait & {
            aspect-ratio: 4 / 5
        }

        .category-callout--square & {
            aspect-ratio: 1;
        }
    }

    .category-callout__header {
        position: absolute;
        z-index: 1;
        top: 30px;
        left: 40px;
        right: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            opacity: 0;
            width: 12px;
            height: 22px;
            fill: $c-white;
            margin-right: 10px;
            transition: margin-right 0.3s, opacity .3s;
        }

        @include breakpoint(phablet) {
            position: relative;
            display: inline-block;
            top: unset;
            left: unset;
            right: unset;
            margin-top: 10px;

            svg {
                display: none;
            }

            .category-callout--ph-padded & {
                padding: 0 $site-gutter--mobile;
            }
        }
    }

    .category-callout__title {
        @include font(26px, 38px);
        @include border-line-grow($c-white, false);

        color: $c-white;

        @include breakpoint(phablet) {
            @include font(16px, 30px);
            @include border-line-grow;
            color: $c-black;
        }
    }
