@use 'sass:math';

.nav-collection-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(tablet) {
        padding-left: $site-gutter--mobile;    
    }

    a {
        @include breakpoint(tablet) {
            display: none;
        }
    }
}

    .nav-collection__header-title {
        @include font(16px, 16px);
    }

.nav-collection {
    display: block;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        opacity: 0.55;
        background: linear-gradient(0deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);

        @include breakpoint(tablet) {
            display: none;
        }
    }
}

    .nav-collection__image {
        @include aspect(math.div(5, 3));

        @include breakpoint(tablet) {
            display: none;
        }
        
        img {
            width: 100%;
            height: auto;
            transition: transform 300ms ease-in;

            .nav-collection:hover & {
                transform: scale(1.075);
            }
        }
    }

    .nav-collection__title {
        @include font(16px, 22px);

        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 10px;
        color: $c-white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(tablet) {
            @include lightText;

            padding: 6px $site-gutter--mobile;
            position: relative;
            left: unset;
            right: unset;
            bottom: unset;
            color: $c-black;

            svg {
                display: none;
            }
        }

        svg {
            opacity: 0;
            fill: $c-white;
            transform: translateX(-10px);
            transition: all 300ms ease-in;
            margin-top: 2px;

            .nav-collection:hover & {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
