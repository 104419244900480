.project-enquiry {

    &--padding-bottom {
        padding-bottom: 20px;
    }

    .field {
        margin-bottom: 10px;
    }

    .form-privacy {
        @include font(12px, 20px);
        margin-bottom: 0;
    }
}

    .project-enquiry__submit {
        margin-bottom: 15px;
    }
