.filter-icons {
    display: block;
    text-align: left;
    margin-bottom: 20px;
}

    .filter-icons--flush {
        margin-bottom: 0;
    }

    .filter-icons__icon {
        height: 30px;
        width: 30px;
        margin-right: 15px;

        &:nth-child(6) {
            margin-right: 0;
        }
    }

    .filter-icons__icon--white {
        fill: $c-white;
    }
