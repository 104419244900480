img[data-src] {
    transition: opacity 300ms cubic-bezier(.4,0,.22,1);
}
img.lazy {
    opacity: 0;
}
img.lazyloaded {
    opacity: 1;
}

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-hide, .grid__col.#{$name}-hide {
            display: none;
        }

        .#{$name}-show, .grid__col.#{$name}-show {
            display: block;
        }

        .#{$name}-show-inline {
            display: inline;
        }

        .#{$name}-show-inline-block {
            display: inline-block;
        }
    }
}

.busy {
    opacity: 0.4;
    transition: opacity 1000ms;
    cursor: 'wait';
    pointer-events: none;
}

.ajax-results {
    transition: opacity 1000ms;
}
