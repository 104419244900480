.compatible-item {
    a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }
}

.compatible-item__image {
    @include aspect(1);

    flex: 0 0 70px;
    margin-right: 20px;

    img {
        max-width: 100%;
        height: auto;
    }
}

.compatible-item__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h2 {
        @include font(18px, 26px);

        flex: 0 0 100%;
        text-align: left;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(14px, 20px);
        }
    }

    p.compatible-item__price {
        @include font(14px, 26px);

        color: $c-black;
    }

    p.compatible-item__divide {
        @include font(14px, 26px);
        @include lightText;

        margin: 0 10px;
        color: $c-compatible-item-divide;
    }

    p.compatible-item__meta {
        @include font(14px, 26px);
        @include lightText;

        color: $c-compatible-item-meta;
    }
}
