.grid--about {
    @include grid-gutter(40px, 40px);

    @include breakpoint(mobile) {
        @include grid-gutter-vertical(25px);
        @include grid-gutter-horizontal(0px);
    }
}

.about-intro {
    padding: 40px 0 30px;
    background: $c-white;
    text-align: center;
    overflow: hidden;

    @include breakpoint(tablet) {
        padding: 0;
    }
}

    .about-intro__title {
        @include font(54px, 62px);

        margin-bottom: 10px;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(32px, 40px);
        }
    }

    .about-intro__subtitle {
        @include lightText;
        @include font(18px, 22px);

        color: $c-black;
        letter-spacing: 4px;
        margin-bottom: 10px;
        text-transform: uppercase;

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            letter-spacing: 3px;
        }
    }

.about__sticky {
    flex-grow: 0;
    position: sticky;
    top: $header-height + 50px - 95px - 5px; //height of individual fixed header elements less padding
    padding: 95px 0px 280px 40px;
}

    .about__sticky-image {
        @include aspect(3 / 4);

        box-shadow: 10px 0 35px 0 rgba(0,0,0,0.08);

        img {
            width: 100%;
            height: auto;
        }
    }

.about__timeline {
    position: relative;
    padding: 95px 80px 120px 60px;
    margin-bottom: 95px;

    @include breakpoint(mobile) {
        padding: 0 0 60px 0;

        margin: 20px 0 0 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: -60px;
        bottom: 0;
        background-color: $c-block-instagram-bg;
        z-index: -1;

        @include breakpoint(mobile) {
            left: 0px;
        }
    }
}

    .about__article {}

        .about__article-content {
            position: relative;

            @include breakpoint(mobile) {
                padding: 0 20px;
            }
        }

        .about__article-content--overlap-text {
            z-index: 5;
        }

            .about__image {
                @include aspect(1);

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .about__title {
                @include font(40px, 52px);

                margin-bottom: 17px;
                color: $c-black;

                @include breakpoint(mobile) {
                    @include font(32px, 52px);
                    margin-bottom: 10px;
                }

                .about__article-content--overlap-text & {
                    margin-left: -80px;

                    @include breakpoint(mobile) {
                        margin-left: 0;
                    }
                }

            }

            .about__subtitle {
                @include font(14px, 17px);
                @include lightText;

                letter-spacing: 2.3px;
                margin-bottom: 3px;
                text-transform: uppercase;
                color: $c-black;

                @include breakpoint(mobile) {
                    margin-bottom: 0;
                }

                .about__article-content--overlap-text & {
                    margin-left: -80px;

                    @include breakpoint(mobile) {
                        margin-left: 0;
                    }
                }
            }

            .about__text {
                @include font(16px, 24px);

                color: $c-detail-subtitle;

                @include breakpoint(mobile) {
                    @include font(14px, 22px)
                }
            }

    .about__overlap-imgwrap {
        @include aspect(1);

        position: relative;
    }

        .about__overlap-image {
            position: absolute;

            &:first-child {
                top: 0;
                right: 0;
                width: 40%;
                z-index: 5;
            }

            &:last-child {
                left: 0;
                bottom: 0;
                width: 80%;
            }

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }


.about-video {
    border-top: 1px solid $c-border-light;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;

    .about-video__title {
        @include font(18px, 22px);
        @include lightText;

        margin-bottom: 35px;
        text-transform: uppercase;
        color: $c-black;
        letter-spacing: 4px;

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            margin-bottom: 25px;
            letter-spacing: 3px;
        }
    }
}
