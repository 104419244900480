.project-preview {
    @include lightondark;
    @include aspect(1);

    position: relative;
}

.project-preview--large {
    @include aspect(2 / 1);

    overflow: visible;

    @include breakpoint(tablet) {
        @include aspect(1);
    }
}

.project-preview--dummy {
    @include aspect(2 / 1);

    background: transparent;
}

    .project-preview__link {
        display: block;
        text-decoration: none;
        overflow: hidden;

        .project-preview--large & {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 200%;
            z-index: 1;

            @include breakpoint(tablet) {
                position: relative;
                height: auto;
            }
        }

        .project-preview--large.project-preview--attach-bottom & {
            top: auto;
            bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 50%;
            background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.65;
        }
    }

        .project-preview__image {
            @extend .image;

            position: relative;

            img {
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .project-preview__link:hover & {
                    transform: scale(1.2);
                }
            }

            .project-preview--large & {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                @include breakpoint(tablet) {
                    position: static;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;

                    @include breakpoint(tablet) {
                        position: static;
                    }
                }
            }
        }

        .project-preview__content {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 40px;
            z-index: 1;

            @include breakpoint(tablet) {
                padding: 15px;
            }
        }

        .project-preview__title {
            @include font(26px, 34px);

            color: $c-white;

            @include breakpoint(tablet) {
                @include font(18px, 24px);
            }

            span {
                @include thinText;
            }
        }
