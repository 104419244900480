.loadable {
    position: relative;
}

.loadable--loading {

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($c-white, 0.9);
        z-index: 1;
    }
}

.loadable-overlay {
    display: none;

    &--loading {
        position: fixed;
        background: rgba($c-white, 0.9);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        display: unset;
    }
}
