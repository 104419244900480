// Badges

.badge {
    @include lightondark;
    @include f-bold;
    @include font(13px, 24px);
    display: inline-block;
    background: $c-badge-grey;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    border-radius: 22px;
}

    .badge--full {
        width: 100%;
        box-sizing: border-box;
    }

    .badge--fixed {
        width: 130px;
    }

    .badge--loose {
        padding-left: 20px;
        padding-right: 20px;
    }

    .badge--blue {
        background: $c-brand;
    }

    .badge--red {
        background: $c-error;
    }

    .badge--yellow {
        background: $yellow;
    }

    .badge--light-green {
        background: $light-green;
    }

    .badge--green {
        background: $c-success;
    }
