.segmentation-preview {
    @include lightondark;

    position: relative;
    height: $hero-min-height;
    color: $c-white;

    @media (min-height: $hero-min-height) {
        height: 100vh;
    }

    @include breakpoint(tablet) {
        @include aspect(375 / 334);

        height: auto;
    }
}

    .segmentation-preview__image {
        height: 100%;
        overflow: hidden;

        img {
            @include coverImage;

            width: calc(100% + 2px);
            height: 100%;
            transform: translateX(-1px);
        }
    }

    .segmentation-preview__content {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 80px;
        z-index: 1;

        @include breakpoint(tablet) {
            padding: 20px;
        }
    }

        .segmentation-preview__title {
            @include font(60px, 78px);

            margin-bottom: 15px;

            @include breakpoint(tablet) {
                @include font(32px, 39px);
                margin-bottom: 12px;
            }

            @include breakpoint(mobile) {
                @include font(26px, 30px);
            }

            span {
                @include thinText;
            }
        }

        .segmentation-preview__text {
            max-width: 540px;
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                @include font(14px, 20px);
                margin-bottom: 12px;
            }

            @include breakpoint(mobile) {
                @include font(12px, 18px);
            }
        }

        .segmentation-preview__button {

            @include breakpoint(tablet) {
                margin-left: auto;
            }

            span {
                @include breakpoint(tablet) {
                    @include font(12px, 18px);
                }
            }
        }

    .segmentation-preview__gradient {
        position: absolute;
        right: 0;
        left: 0;
    }

    .segmentation-preview__gradient--top {
        top: 0;
        height: 217px;
        background: linear-gradient(180deg, $c-black 0%, rgba($c-black, 0) 100%);
        opacity: 0.3;

        @include breakpoint(tablet) {
            opacity: 0.5;
        }
    }

    .segmentation-preview__gradient--bottom {
        bottom: 0;
        height: 468px;
        background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0) 100%);
        opacity: 0.77;

        @include breakpoint(tablet) {
            height: 180px;
            opacity: 0.6;
        }
    }
