.downloads-list {
    overflow: hidden;

    .list {
        margin-bottom: 0;

        a {
            word-break: break-all;
        }
    }
}

    .downloads-list--closed {
        height: 124px; // 31 x amount to show
    }

.downloads-list-toggle {
    position: relative;
}

    .downloads-list-toggle__icon {
        right: -20px;
        top: 2px;
        position: absolute;
    }

.product-video {
    width: 100%;
    max-width: 560px;
    height: 315px;
    margin-bottom: 20px;
}

.zone-content {
    position: relative;
    text-align: left;
    padding-left: 50px;
    margin-bottom: 25px;
    font-size: 1.067em;
    h4 {
        margin-bottom: 15px;
        position: relative;
    }
    p {
        font-weight: 300;
        line-height: 1.375em;
    }
    &:after {
        background: #000000;
        border: solid 4px white;
        content: '';
        position: absolute;
        top: -8px;
        left: 0;
        height: 22px;
        width: 22px;
        opacity: 1;
        border-radius: 15px;
    }
    &.zone-content-one {
        &:after {
            background: #6FB1CB;
        }
    }
    &.zone-content-two {
        &:after {
            background: #C1DEE6;
        }
    }
    &.zone-content-three {
        &:after {
            background: #FFFFFF;
        }
    }
}

.zone-nb {
    text-align: left;
    p {
        font-weight: 300;
        padding-left: 50px;
        font-style: oblique;
        font-size: 0.933em;
        line-height: 1.5em;
    }
}

.product-stock {
    &.product-stock--inline {
        flex-direction: column;
    }

    &.product-stock--expanded {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

    .product-stock__item {
        @include font(14px, 17px);
        @include lightText;

        color: $c-body;

        margin-bottom: 5px;
        p {
            color: $c-black;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.product-stock__item--hidden {
            display: none;
        }

        .product-stock--inline & {
            span {
                font-weight: 300;
            }
        }

        .product-stock--expanded & {
            display: inline-block;
            width: 25%;
            padding-left: 15px;
            border-right: 1px solid $c-detail-subtitle-separator;

            &:last-child {
                margin-bottom: 10px;
                border-right: none;
            }

            &:nth-child(4n+1) {
                margin-left: -15px;
            }


            &:nth-child(4n) {
                border-right: 0;
            }

            > * {
                width: 100%;
            }

            &.product-stock__item--hidden {
                display: flex;
            }

            @include breakpoint(pdp-medium) {
                width: (100% / 3) * 1;
                &:nth-child(4n) {
                    border-right: 1px solid $c-detail-subtitle-separator;
                }

                &:nth-child(3n) {
                    border-right: 0;
                }

                &:nth-child(4n+1) {
                    margin-left: 0;
                }

                &:nth-child(3n+1) {
                    margin-left: -15px;
                }
            }

            @include breakpoint(tablet) {
                width: 25%;
                &:nth-child(4n) {
                    border-right: 0;
                }
                &:nth-child(3n) {
                    border-right: 1px solid $c-detail-subtitle-separator;
                }

                &:nth-child(3n+1) {
                    margin-left: 0;
                }

                &:nth-child(4n+1) {
                    margin-left: -15px;
                }
            }

            @include breakpoint(phablet) {
                width: (100% / 3) * 1;
                &:nth-child(4n) {
                    border-right: 1px solid $c-detail-subtitle-separator;
                }

                &:nth-child(3n) {
                    border-right: 0;
                }

                &:nth-child(4n+1) {
                    margin-left: 0;
                }

                &:nth-child(3n+1) {
                    margin-left: -15px;
                }
            }

            @include breakpoint(mobile) {
                width: 50%;

                &:nth-child(3n), &:nth-child(4n) {
                    border-right: 1px solid $c-detail-subtitle-separator;
                }

                &:nth-child(2n) {
                    border-right: 0;
                }

                &:nth-child(3n+1), &:nth-child(4n+1) {
                    margin-left: 0;
                }

                &:nth-child(2n+1) {
                    margin-left: -15px;
                }
            }
        }
    }

    .product-stock__item--flush {
        margin-bottom: 0;
    }

    .product-stock__rollout {
        display: none;
    }

    .product-stock__togglemore--inline {
        color: $c-black;
        @include font(12px, 15px);
        text-transform: uppercase;
        letter-spacing: 0.86px;
    }

        .product-stock__item--highlight {}

    .product-stock__message {
        @include font(12px, 18px);
        @include lightText;

        p {
            color: $c-black;
        }

    }

.product-stock-warning,
.order-stock-warning {
    @include rem(14px);
    color: $c-error;
    padding-left: 40px;
    line-height: 18px;
    max-width: 420px;
    position: relative;

    a {
        text-decoration: underline;
        color: $c-error;
    }

    .icon-warning {
        fill: $c-error;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.order-stock-warning {
    max-width: none;
}
