.available-finish {
    display: flex;
    flex-direction: column;

    p {
        @include font(18px, 26px);

        margin-bottom: 10px;
    }
}

.available-finish__image {
    aspect-ratio: 1;
    margin-bottom: 12px;

    img {
        width: 100%;
        height: auto;
    }
}

.available-finish__swatch {
    aspect-ratio: 1;
    width: 22px;
    height: 22px;
    display: flex;
    border-radius: 1px;
    border: 1px solid $c-black;
    outline: 1px solid $c-white;
    outline-offset: -2px;

    img {
        width: 100%;
        height: auto;
        border-radius: 1px;
    }
}
