.accordion {}

    .accordion__title {
        cursor: pointer;

        .accordion--disabled & {
            cursor: default;
        }
    }

        .accordion__icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            .accordion--disabled & {
                display: none;
            }

            svg {
                display: block;
            }
        }

        .accordion__icon--active {
            display: none;

            .accordion--active & {
                display: block;
            }

            .accordion--disabled & {
                display: none;
            }
        }

        .accordion__icon--inactive {

            .accordion--active & {
                display: none;
            }
        }

    .accordion__wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease;

        .accordion--disabled & {
            max-height: none;
        }
    }

        .accordion__content {}

.accordion--product-info {
    border-bottom: 1px solid $c-border;

    .accordion__title {
        @include font(12px, 20px);

        position: relative;
        padding: 15px 0;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

        .accordion__content {
            padding: 0 0 15px;
        }
}

.accordion--where-to-buy {
    border-bottom: 1px solid $c-border;

    .accordion__title {
        @include font(18px, 26px);

        position: relative;
        padding: 15px 0;
        color: $c-body;
    }

    .accordion__content {
        padding: 0 0 15px;
    }
}

.accordion--account-faq {
    border-bottom: 1px solid $c-border;

    .accordion__title {
        @include font(12px, 20px);

        position: relative;
        padding: 15px 15% 15px 0;
        text-transform: uppercase;
        letter-spacing: 1px;

        @include breakpoint(tablet) {
            padding: 15px 8% 15px 0;
        }
    }

    .accordion__content {
        padding: 0 15% 15px 0;

        @include breakpoint(tablet) {
            padding: 0 8% 15px 0;
        }
    }
}

.accordion--compatible-products {

    .accordion__title {
        @extend .band__title;

        margin: 0;
        padding: 55px 0;

        @include breakpoint(phablet) {
            padding: 30px 0;
            text-align: left;
        }

        .wrap {
            position: relative;
        }
    }

        .accordion__icon {
            right: $site-gutter;

            @include breakpoint(tablet) {
                right: $site-gutter--mobile;
            }

            svg {
                width: 20px;
                height: auto;
            }
        }

        .accordion__content {
            padding: 0 0 100px;

            @include breakpoint(tablet) {
                padding-bottom: 50px;
            }

            @include breakpoint(phablet) {
                padding-bottom: 30px;
            }
        }
}

.accordion--driver-calculator-criteria {
    margin-bottom: 40px;

    @include breakpoint(tablet) {
        border-bottom: 1px solid $c-border;
    }

    .accordion__title {
        @include medium-title;

        position: relative;
        color: $c-black;
        margin-bottom: 30px;

        span {
            @include thinText;
        }

        @include breakpoint(tablet) {
            @include font(14px, 20px);

            margin-bottom: 20px;
            text-align: left;
            text-transform: uppercase;
        }
    }

        .accordion__content {
            padding: 0 0 15px;
        }
}

.accordion--filters {
    .accordion__title {
        font-weight: 200;
        text-transform: uppercase;
        @include font(12px, 20px);
        letter-spacing: 1px;
        position: relative;
        color: $c-black;
        padding-bottom: 15px;
        margin-bottom: 20px;
        border-bottom: 1px solid $c-border;

        span {
            text-transform: none;
            letter-spacing: normal;
        }
    }

    .accordion__title--active {
        font-weight: 400;
    }

    .accordion__icon {
        top: 5px;
    }

    &.accordion--active {
        .accordion__wrapper {
            margin-bottom: 25px;
        }
    }
}

.accordion--additional-filters {

    @include breakpoint(phablet) {
        padding-bottom: 150px;
    }

    .accordion__title {
        display: none;

        @include breakpoint(tablet) {
            @include font(12px, 15px);

            position: relative;
            display: block;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $c-black;
            padding: 0 15px 15px;

            span {
                text-transform: none;
                letter-spacing: normal;
            }
        }
    }

    .accordion__icon {
        top: 10px;
        right: 15px;
        transform: translateY(-50%);

        svg {
            fill: $c-brand;
        }
    }
}

.accordion--import-group {

    .accordion__title {
        @include apply-map($f-title);
        @include font(18px, 20px);

        position: relative;
        padding: 20px 0;
        color: $c-black;
        border-bottom: 1px solid $c-border;

        @include breakpoint(tablet) {
            @include font(16px, 20px);

            padding: 16px 10px 16px 0;
        }

        span {
            @include lightText;
        }
    }

    &.accordion--active .accordion__title {
        border-bottom-color: $c-black;
    }

        .accordion__icon {
            width: 20px;
            height: 20px;

            @include breakpoint(tablet) {
                width: 10px;
                height: 10px;
            }

            svg {
                width: 100%;
                height: 100%;
                max-width: 20px;
                max-height: 20px;

                @include breakpoint(tablet) {
                    max-width: 10px;
                    max-height: 10px;
                }
            }
        }
}
