.block--testimonials {
    padding: 0;
    color: var(--block-text-color, $c-grey--dark);
    background: var(--block-background, transparent);
    text-align: center;
    position: relative;
    padding: 50px 0;

    @include breakpoint(tablet) {
        padding: 30px 0;
    }

    &[class^="block--background-"]:not(.block--background-white),
    &[class*=" block--background-"]:not(.block--background-white) {
        padding: 70px 0;

        @include breakpoint(tablet) {
            padding: 40px 0 60px;
        }

        .slick-arrow {
            background-color: transparent;
            transform: translate(-50%, 0);
            bottom: -50px;

            @include breakpoint(tablet) {
                bottom: -40px;
            }
        }

        &::before,
        &::after {
            display: none;
        }
    }

    // Pseudo borders
    &::before,
    &::after {
        content: '';
        position: absolute;
        height: 1px;
        right: calc(((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2));
        left: calc(((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2));
        background-color: $c-border-light;

        @include breakpoint(wrap) {
            right: $site-gutter;
            left: $site-gutter;
        }

        @include breakpoint(tablet) {
            right: $site-gutter--mobile;
            left: $site-gutter--mobile;
        }
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    .block__quote {
        @include font(26px, 38px);

        position: relative;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(tablet) {
            @include font(22px, 30px);
        }

        cite {
            display: block;
            margin-top: 20px;
        }
    }

    .block__source,
    .block__company {
        @include font(14px, 20px);

        margin-bottom: 5px;
        text-transform: uppercase;
        letter-spacing: 1.5px;

        @include breakpoint(tablet) {
            @include font(12px, 18px);
        }
    }
}
