.product-showcase--gallery {
	position: relative;

	@include product_showcase_padding(14px, 61px, 18px);
}

	.product-gallery {
		padding-bottom: 50px;
		overflow: hidden;
	}

		.product-gallery__item {
			position: relative;
			top: 100px;
			float: left;
			width: calc(33.3333% - 20px);
			margin: 10px;
			opacity: 0;
			transition: top 1000ms, opacity 1000ms;

			@include breakpoint(tablet) {
				width: calc(50% - 20px);
			}

			&:hover {

				.product-gallery__item__image--hover {
					opacity: 1;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.product-showcase--activated .product-gallery__item {
			top: 0;
			opacity: 1;

			&:nth-child(3n + 2) {
				top: 50px;

				@include breakpoint(tablet) {
					top: 0;
				}
			}

			&:nth-child(2n) {

				@include breakpoint(tablet) {
					top: 50px
				}
			}
		}

			.product-gallery__item__image--hover {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				transition: opacity .3s;
			}
