.account-header-container {
    position: relative;
}

.account-header-container--overlap {
    z-index: 2;
}

.account-header {}

.account-header--overlap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    @include breakpoint(nav) {
        position: static;
        top: auto;
        right: auto;
        left: auto;
    }
}

.account-header--plain {
    border-bottom: 1px solid $background-grey;
}

    .account-header__container {
        padding-top: 20px;
        padding-bottom: 20px;

        .account-header-container--has-nav .account-header__nav ~ & {

            @include breakpoint(tablet) {
                padding-top: 20px + 50px;
            }
        }
    }

    .account-header__container--tall {
        padding-top: 30px;
        padding-bottom: 30px;

        @include breakpoint(mobile) {
            padding-top: 15px;
        }
    }

    .account-header__container--flex {
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
            display: block;
        }
    }

        .account-header__button {
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
        }

        .account-header__downloads {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);

            @include breakpoint(tablet) {
                position: static;
                top: auto;
                display: inline-block;
                transform: none;
            }
        }

    .account-header__category-info {
        display: flex;
        background: $background-grey;
    }

        .account-header__copy {
            @include f-light;
            @include rem(16px);

            line-height: calc(24 / 16);
            flex: 1 1 auto;
            color: $footer-grey;
            margin-right: 30px;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
                margin-right: 0;
            }

            @include breakpoint(mobile) {
                @include rem(14px);

                line-height: calc(24 / 14);
            }
        }

    .account-header__article-info {
        background: $c-white;
    }

    .account-header__download-info {
        background: $background-grey;
    }

    .account-header__download-action {
        display: inline-block;
        vertical-align: middle;
        width: 200px;

        @include breakpoint(mobile) {
            width: 100%;
        }

        > * {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Specificity is needed
    .account-header__title {
        @extend .banner__title;
        @include rem(32px);

        line-height: calc(53 / 32);
        margin-bottom: 0;
        color: $c-white;

        @include breakpoint(mobile) {
            @include rem(24px);

            line-height: calc(29 / 24);
            margin-bottom: 10px;
        }

        .account-header__article-info &,
        .account-header__download-info & {
            margin: 0;
            color: $c-black;
        }

        .account-header--plain & {
            color: $c-black;
        }
    }

    .account-header__sub-title {
        @include rem(14px);
        @include f-regular;

        line-height: calc(17 / 14);
        letter-spacing: .5px;
        text-transform: uppercase;
    }

    .account-header__title {

        &::not(.account-header__title--flush):not(:last-child) {

            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }
        }
    }

    .account-header__nav {
        position: relative;
        background: $background-grey;
        font-size: 0;
        z-index: 2;

        @include breakpoint(tablet) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background: $c-account-nav-mobile;
        }
    }

    .account-header__nav--active {}

    .account-header__nav__item {
        @include lightondark;

        position: relative;
        text-transform: uppercase;
        display: inline-block;

        @include breakpoint(tablet) {
            display: none;
            border-bottom: 1px solid $background-grey;
        }

        &:last-child {
            margin-right: 0;

            @include breakpoint(tablet) {
                border-bottom: none;
            }
        }

        .account-header__nav__children & {
            display: block;
            margin-right: 0;
            text-align: left;

            @include breakpoint(tablet) {
                background: none;
            }
        }
    }

    .account-header__nav > .account-header__nav__item:not(.account-header__nav__item--highlight) {

        &:hover {
            background: $c-white;

            @include breakpoint(tablet) {
                background: transparent;
            }
        }
    }

    .account-header__nav__item--active:not(.account-header__nav__item--highlight) {
        background: $c-white;

        @include breakpoint(tablet) {
            background: transparent;
        }
    }

    .account-header__nav__item--highlight {
        color: $c-white;
        background: $c-brand;

        @include breakpoint(tablet) {
            color: $c-black;
            background: transparent;
        }

        &:hover {
            background: $blue1;

            @include breakpoint(tablet) {
                background: transparent;
            }
        }

        &.account-header__nav__item--active:hover {
            background: $c-brand;
        }
    }

    .account-header__nav--active .account-header__nav__item {

        @include breakpoint(tablet) {
            display: block
        }
    }

        .account-header__nav__name {
            @include rem(14px);
            @include f-light;

            line-height: calc(20 / 14);
            position: relative;
            display: block;
            padding: 20px 30px;
            color: inherit;

            @include breakpoint(tablet) {
                padding: 15px;
            }

            .account-header__nav__item--has-children &,
            .account-header__nav__item--download & {
                padding-right: 50px;
            }

            .account-header__nav__children & {
                @include rem(12px);

                line-height: calc(15 / 12);
                padding: 10px 0;
                color: $c-black;
            }

            .account-header__nav__children .account-header__nav__item--download & {
                padding-right: 20px;
            }
        }

        .account-header__nav__name--parent {
            cursor: pointer;

            .account-header__nav__item--hover & {

                @include breakpoint(tablet) {
                    border-bottom: 1px solid $c-account-nav-mobile;
                }
            }
        }

            .account-header__nav__arrow {

                &:after {
                    @extend .fa;
                    @include rem(20px);

                    content: '\f107';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 25px;
                    pointer-events: none;
                    transform: translate(50%, -50%);

                    .account-header__nav__item--hover & {
                        content: "\f106";
                    }
                }
            }

            .account-header__nav__download {
                position: absolute;
                top: 50%;
                right: 25px;
                pointer-events: none;
                transform: translate(50%, -50%);

                .account-header__nav__children & {
                    right: 10px;
                }

                svg {
                    display: block;
                    fill: $c-black;
                }
            }

        .account-header__nav__children {
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            padding: 10px 15px;
            display: none;
            background: $c-white;

            @include breakpoint(tablet) {
                position: static;
                top: auto;
                right: auto;
                left: auto;
            }

            .account-header__nav__item--hover & {
                display: block;
            }
        }

    .account-header__nav__toggle {
        display: none;

        @include breakpoint(tablet) {
            @include rem(16px);

            line-height: calc(20 / 16);
            display: block;
            padding: 15px;
            text-transform: uppercase;
            cursor: pointer;
            user-select: none;
            color: $c-black;
        }

        > * {
            position: relative;
            display: block;

            &:after {
                @extend .fa;
                @include rem(20px);

                content: "\f107";
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                pointer-events: none;
                transform: translateY(-50%);
            }
        }
    }

    .account-header__nav--active .account-header__nav__toggle > *:after {
        content: "\f106";
    }

    .account-header--driver-guide {
        background: white;
        display: flex;
        border-bottom: 1px solid $grey4;
        padding: 25px;
        justify-content: center;
        transition: all ease 400ms;
        top: 0;
        box-sizing: border-box;
        &.fixed {
            position: fixed;
            z-index: 3;
            width: 100%;
        }
    }
    .account-header--driver-guide-shadow {
        display: none;
        z-index: 3;
    }
    .account-header--driver-guide .account-header__nav__item {
        padding: 0;
        margin-right: 38px;
        @include breakpoint (mobile) {
            display: none;
        }
    }
    .account-header--driver-guide.account-header__nav--active {
        display: block;
    }
    .account-header--driver-guide.account-header__nav--active .account-header__nav__item {
        display: block;
        margin-right: 0;
        padding: 25px;
    }
