.blocks .block.block--sus-image-two-thirds {
    margin: 0 auto;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-image-two-thirds {
    background: $c-block-background-desert-springs;
    padding: 60px 0;

    @include breakpoint(tablet) {
        padding-bottom: 0;
    }

    .block__content {
        margin-right: 50px;

        @include breakpoint(tablet) {
            max-width: 700px;
            margin: 0 auto;
            text-align: center;
        }

        @include breakpoint(phablet) {
            text-align: left;
        }
    }

        .block__subtitle {
            @include lightText;
            @include font(18px, 22px);
        
            color: $c-black;
            margin-bottom: 13px;
            text-transform: uppercase;
            letter-spacing: 4px;
            padding-right: 60px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                letter-spacing: 3px;
                padding: 0;
                margin-bottom: 7px;
            }

            @include breakpoint(mobile) {
                @include font(13px, 16px);

                letter-spacing: 2px;
                white-space: nowrap;
            }
        }

        .block__title {
            @include font(54px, 62px);

            color: $c-black;
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                @include font(32px, 40px);

                margin-bottom: 15px;
            }
        }

        .block__text {
            @include lightText;
            @include font(16px, 24px);

            color: $c-black;
            margin-bottom: 80px;

            @include breakpoint(tablet) {
                @include font(14px, 22px);

                margin-bottom: 60px;
            }
        }

        .block__animation {
            height: 300px;
            position: relative;
            overflow: visible;

            @include breakpoint(mobile) {
                height: 260px;
            }
        }

            .block__circle,
            .block__arrows,
            .block__circle-text {
                position: absolute;
                height: 220px;
                width: 220px;
                left: 50%;
                transform: translateX(-50%);

                @include breakpoint(mobile) {
                    left: 47%;
                    height: 180px;
                    width: 180px;

                    svg {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .block__circle-text {
                width: 440px;
                height: auto;
                top: -35px;
                left: 55%;

                @include breakpoint(tablet) {
                    left: 53%;
                }

                @include breakpoint(mobile) {
                    top: -20px;
                    width: 340px;
                    height: auto;
                    left: 50%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .block__arrows {
                animation: rotationArrows 30s linear infinite;

                @include breakpoint(mobile) {
                    margin-left: -3px;
                }

                svg {
                    max-width: 100%;
                }
            }

            // Add the translate here to keep the element centered

            @keyframes rotationArrows {
                from {
                    transform: translate(-50%, 0) rotate(0deg);
                }
                to {
                    transform: translate(-50%, 0) rotate(359deg);
                }
            }

    .block__image {
        @include aspect(8 / 7);

        margin: 0 calc((1 / 12) * 100%) 0 -50px;

        @include breakpoint(tablet) {
            margin: 0;
            margin-left: -15px;
            width: calc(100% + 30px);
        }

        img {
            width: 100%;
            height: auto;
            position: relative;
        }
    }
}
