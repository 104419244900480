.category-previews {}

.category-previews--inline {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    gap: 20px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

    .category-previews__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        color: var(--block-text-color, $c-black);

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }

        .category-previews--inline & {
            justify-content: center;
            margin: 0;
        }
    }

        .category-previews__title {
            @include font(38px, 44px);

            color: inherit;
            font-family: var(--block-title-font-family, $f-primary-stack);

            @include breakpoint(tablet) {
                @include font(26px, 32px);
            }

            .category-previews--inline & {
                margin-bottom: 10px;
            }

            .category-previews--stacked & {
                @include font(26px, 38px);

                @include breakpoint(tablet) {
                    @include font(22px, 30px);

                    margin-bottom: 5px;
                }
            }
        }

        .category-previews__intro {
            .category-previews--inline & {
                padding-right: 60px;

                @include breakpoint(tablet) {
                    padding-right: 0;
                }
            }
        }

    .category-previews__container {
        margin-right: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);

        @include breakpoint(wrap) {
            margin-right: calc(#{$site-gutter} * -1);
        }
        
        @include breakpoint(tablet) {
            margin-left: calc(#{$site-gutter--mobile} * -1);
            margin-right: calc(#{$site-gutter--mobile} * -1);
            overflow: hidden;
        }

        .category-previews--inline & {
            margin-top: 60px;

            @include breakpoint(tablet) {
                margin-top: 0;
                order: -1;
            }
        }

        .category-previews--stacked & {
            margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);

            @include breakpoint(wrap) {
                margin-left: calc(#{$site-gutter} * -1);
            }

            @include breakpoint(tablet) {
                margin-left: calc(#{$site-gutter--mobile} * -1);
            }
        }

        &.hide-desktop-carousel {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            margin-right: 0;
            margin-left: 0;

            @include breakpoint(phablet) {
                margin-left: calc(#{$site-gutter--mobile} * -1);
                margin-right: calc(#{$site-gutter--mobile} * -1);
                display: block;
            }
        }
    }
