$cookie-fontsize: 16px;
$cookie-title-fontsize: 19px;
$cookie-title-fontweight: 700;
$cookie-btn-fontsize: 14px;
$cookie-btn-fontweight: 400;
$cookie-fontweight: 200;
$cookie-lineheight: 1.5;
// $cookie-bg: $c-background;
$cookie-radius: 3px;
// $cookie-secondary: $c-button;
// $cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
// $cookie-border: $c-border;
//
$cookie-fg: $c-grey--light;
$cookie-nav-bg: $c-white;
$cookie-footer-bg: $c-white;
$cookie-fontcolor: $c-black;
$cookie-altfontcolor: $c-brand;
$cookie-border: $c-border;
$cookie-fontcolor: $c-body;
$cookie-switch-fg: $c-black;
$cookie-switch-bg: $c-white;
$cookie-btn-bg: $c-grey--dark;
$cookie-btn-onhover: $c-brand;
$cookie-btn-fontcolor: #000000;

@import "cookie-centre/cookie-settings";
// @import "cookie-centre/cookie-audit";
@import "cookie-centre/cookie-onetrust";

#onetrust-consent-sdk * {
    font-weight: $cookie-fontweight !important;
    // margin: 0 auto !important;
    // width: $site-width !important;
    // max-width: 1820px !important;

    // @include breakpoint(tablet) {
    //     padding: 0 $site-gutter-mobile !important;
    // }
    //
    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler,
    #accept-recommended-btn-handler,
    .save-preference-btn-handler,
    .ot-pc-refuse-all-handler,
    #ot-sdk-btn {
        -webkit-font-smoothing: antialiased !important;
        font-weight: $cookie-btn-fontweight !important;
    }
}

#onetrust-policy-text a {
    text-decoration: underline !important;
    color: $cookie-btn-fontcolor !important;
}

#onetrust-banner-sdk #onetrust-policy-text {
    @include breakpoint (mobile) {
        font-size: $cookie-fontsize - 2px !important;
    }
}

#onetrust-button-group-parent {
    #onetrust-button-group {
        gap: 10px;

        #onetrust-accept-btn-handler,
        #onetrust-pc-btn-handler {
            @include breakpoint(mobile) {
                font-size: $cookie-btn-fontsize - 4px !important;
            }

            .otFlat & {
                @include breakpoint(mobile) {
                    margin: 0 !important;
                }
            }
        }
    }
    
    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler,
    #ot-sdk-btn {
        background: $cookie-btn-bg !important;
        height: 45px !important;

        &:hover {
            background: $cookie-btn-onhover !important;
            color: $c-white !important;
        }
    }

    #onetrust-pc-btn-handler {
        color: $cookie-btn-fontcolor !important;
        transition: color .3s ease;
        border: 1px solid #000000 !important;
        padding: 12px 23px !important;
        font-weight: bold !important;

        &:hover {
            color: $cookie-altfontcolor !important;
        }
    }
}

.cookie-preferences {

}

.cookie-bar {

}
.cookie-bar__action--accept,
.cookie-preferences__submit {
    
}
.cookie-audit-wrap {

}

// Preference centre

.otPcCenter {
    width: 50% !important;
    max-width: 650px !important;

    .ot-pc-header {
        padding-left: 40px !important;
    }

    .ot-pc-scrollbar {
        margin-left: 40px !important;
        padding-right: 40px !important;
        width: calc(100% - 83px) !important;
    }
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
    background: $c-brand--light !important;
}

.cookie-audit {

    #ot-sdk-btn {
        background: $cookie-btn-bg !important;
        height: 45px !important;
        color: $c-white !important;

        &:hover {
            background: $cookie-btn-onhover !important;
            color: $c-white !important;
        }
    }
    // .ot-sdk-cookie-policy {
    //     h1, h2, h3, h4, h5, h6 {
    //         font-weight: 700 !important;
    //     }
    // }
}


.cookie-preferences__panels {
}

#onetrust-banner-sdk .ot-sdk-container,
#onetrust-pc-sdk .ot-sdk-container,
#ot-sdk-cookie-policy .ot-sdk-container {

}
