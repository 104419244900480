.block--highlights {
    padding: 0;
    color: $c-black;

    .block__title {
        @include font(40px, 46px);
        text-align: center;
        margin-bottom: 30px;
        color: inherit;
    }
}
