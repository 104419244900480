.color-choice {
    display: inline-block;
    margin: 5px;
    font-size: 0;
}

    .color-choice__label {
        display: block;
        cursor: pointer;
    }

        .color-choice__input {
            display: none;
        }

        .color-choice__image {
            @include aspect(1);

            max-width: 58px;
            margin: 0 auto 5px;
            padding: 2px;
            border: 2px solid transparent;
            box-sizing: border-box;

            .color-choice__input:checked ~ & {
                border-color: $c-color-choice-border-active;
            }

            img, div {
                border: 1px solid $c-color-choice-border;
                box-sizing: border-box;
                width: 50px;
                height: 50px;
            }
        }

        .color-choice__name {
            @include f-light;
            @include rem(12px);

            line-height: calc(15 / 12);
        }
