.artist-profiles {
    position: relative;
    max-width: 900px;
}

.artist-profile {
    &:after {
        content: '';
        display: none;
        width: 20px;
        height: 20px;
        background: $c-white;
        position: relative;
        left: 50%;
        top: 10px;
        margin-left: -10px;
        transform: rotate(45deg);
    }
}

    .artist-profile__thumbnail {
        position: relative;
        @include aspect(400/400);
        cursor: pointer;

        &:hover > .artist-profile__image--rollover {
            display: block;
        }
    }

    .artist-profile--open {
        padding-bottom: 0;

        &:after {
            display: block;
        }
    }

    .artist-profile__image {
    }

        .artist-profile__image--rollover {
            position: absolute;
            top: 0;
            left: 0;
        }

        .artist-profile__image--hidden {
            display: none;
        }

    .artist-profile__description {
        @include copy;
        position: absolute;
        left: 0;
        right: 0;
        padding: 20px 30px;
        background: white;
        margin: 15px 15px -15px;
    }

    .artist-profile__description--hidden {
        display: none;
    }
