.locale-selector {
    @include lightText;
    @include font(12px, 12px);

    background-color: transparent;
    border: none;
    padding: 0;

    & + .header__link {
        white-space: nowrap;
    }
}
