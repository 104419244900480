.block--awards {
    @include breakpoint(tablet) {
        padding: 0;
    }

    .block__header {
        padding: 0 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .block__heading {
            @include font(26px, 38px);
            @include regularText;

            @include breakpoint(tablet) {
                @include font(22px, 28px);
            }
        }

        .block__carousel-nav {
            display: flex;
            gap: 10px;
        }

    .block__content {
        @include breakpoint(tablet) {
            margin-left: calc(#{$site-gutter--mobile} * -1);
        }
    }

        .block__image {
            max-width: 160px;

            img {
                max-width: 100%;
                height: auto;
            }
        }
}
