.stock-list-notice {
    @extend .richtext;
    @extend .richtext--flush;
    @extend .message;
    @extend .message--centered;
    @extend .message--flush;

    p {
        @include font(16px, 24px);
        @include lightText;
        color: $c-body;
    }
}

.stock-list-summary {
    background-color: $c-brand;
    padding: 15px 30px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 3px;

    &__item {
        display: inline-flex;
        margin-right: 20px;
        @include font(14px,17px);
        color: $c-white;

        &:last-child {
            margin-right: 0;
        }

        &--label {
            @include lightText;
            @include font(12px,17px);
            text-transform: uppercase;
            margin-right: 5px;
        }
    }

    @include breakpoint(phablet) {
        margin: -30px -15px 0;
        width: calc(100% + 30px);
        border-radius: unset;
        text-align: left;

        &__container {
            display: inline-flex;
            width: 100%;
            vertical-align: middle;
        }

        &__item {
            width: 23%;
            margin-right: 10px;
            @include font(12px,14px);
            display: block;

            &:last-child {
                width: 31%;
            }

            &--label {
                margin-bottom: 5px;
                span {
                    display: block;
                }
            }
        }
    }
}

.stock-list-downloads {
    background-color: $light-grey;
    padding: 12px 25px 20px;
    border-radius: 3px 3px 0 0;

    &__title {
        @include font(16px,30px);
        color: $c-black;
        margin-bottom: 10px;
    }

    &__buttons {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        .button {
            margin-right: 10px;
            flex: 1 1 0px;

            &:last-child {
                margin-right: 0;
            }

            @include breakpoint(phablet) {
                flex: none;
                width: calc(50% - 8px);
                margin-right: 0;
                margin-top: 15px;
            }

            &.button--download {
                span {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        @include breakpoint(phablet) {
            flex-wrap: wrap;

            & .button:nth-child(-n + 2) {
                margin-top: 0;
            }
        }
    }

    @include breakpoint(phablet) {
        margin: 0 -15px;
        width: calc(100% + 30px);
        border-radius: unset;
    }
}

.stock-list-report {
    border-left: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    position: relative;

    @include breakpoint(phablet) {
        margin: 0 -15px;
        width: calc(100% + 30px);
        border: none;
    }
}

    .stock-list-report__headers,
    .stock-list-report__data {
        padding: 0 25px;
    }

        .stock-list-col__qty {
            text-align: right;

            @include breakpoint(phablet) {
                text-align: left;
            }
        }

    .stock-list-report__data {
        max-height: 400px;
        overflow: hidden;
        overflow-y: scroll;

        @include breakpoint(phablet) {
            max-height: none;
            overflow: hidden;
        }
    }

        .stock-list-data__heading {
            display: none;
            @include font(12px,20px);
            letter-spacing: 1px;
            text-transform: uppercase;

            @include breakpoint(phablet) {
                display: block;
            }
        }

        .stock-list-data__content {
            @include breakpoint(phablet) {
                display: inline-flex;
                width: 100%;

                &--justified {
                    justify-content: space-between;
                }
            }
        }

        .stock-list-data__title {
            @include regularText;
        }

        .stock-list-data__box-qty {
            @include font(12px,20px);

            @include breakpoint(phablet) {
                margin-left: 5px;
            }
        }

        .stock-list-data__transit-details {
            line-height: 1;

            a {
                @include regularText;
                @include font(14px,20px);

                color: $c-brand;
                text-decoration: underline;

                .stock-summary__row--active & {
                    color: $c-white;
                }

                &:hover {
                    color: $c-black;
                }
            }
        }

.stock-list-search {
    width: 100%;
    padding-top: 20px;
    background-color: $c-white;
    z-index: 2;

    @include breakpoint(phablet) {
        position: sticky;

        @include allowForBars((
                default: false,
                tablet: $header-top-height
        ), top);
    }
}

    .stock-list-search__container {
        display: flex;
        border-radius: 3px;
        border: 1px solid $c-border;
        max-width: 50%;
        margin: 0 25px;

        @include breakpoint(phablet) {
            max-width: none;
            width: calc(100% - 50px);
        }
    }

        .stock-list-search__input-wrapper {
            flex: 1 1 auto;

            input.input {
                border: none;
            }
        }

            .stock-list-search__icon {
                position: relative;
                flex: 0 0 50px;
                height: 50px;
                border-left: 1px solid $c-border;

                svg {
                    @include center;

                    fill: $c-brand;
                }
            }
