.has-line {
    position: relative;
}

.has-line--1 {
    padding-left: 30px;

    @include breakpoint(tablet) {
        padding-left: 0;
    }
}

.has-line--3 {
    padding-right: 20px;
}

.line {
    position: absolute;
    background: $c-black;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 5px;
        height: 5px;
        background: $c-black;
        border-radius: 100%;
    }
}

.line--red {
    background: $c-architectural-red;

    &:after {
        background: $c-architectural-red;
    }
}

.line--1 {
    bottom: 0;
    left: 0;
    width: 1px;
    height: 200%;

    &:after {
        margin: 0 0 -2px -2px;
    }
}

.line--2 {
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;

    @include breakpoint(tablet) {
        display: block;
        position: relative;
        bottom: auto;
        left: auto;
        bottom: 0;
        width: 100%;
        margin-top: 20px;
    }

    &:after {
        left: 100%;
        margin: 0 0 -2px -2px;
    }
}

.line--3 {
    top: 100%;
    left: 100%;
    width: 100vw;
    height: 1px;

    &:after {
        margin: 0 0 -2px -2px;
    }
}

    .line--3--ascendors {
        transform: translateY(-7px);
    }
