.twin-callout {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 25px;
    }
}

.twin-callout__item {
    figure {
        aspect-ratio: 5 / 3;
        margin-bottom: 15px;
    }

    img {
        width: 100%;
        height: auto;
    }

    p {
        @include font(14px, 32px);

        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 5px;

        @include breakpoint(tablet) {
            @include font(12px, 22px);

            letter-spacing: 2px;
            margin-bottom: 0;
        }
    }

    h2 {
        @include font(25px, 32px);

        @include breakpoint(tablet) {
            @include font(22px, 28px);
        }
    }
}
