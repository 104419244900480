.block--journal {
    .block__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 40px;

        @include breakpoint(tablet) {
            column-gap: 20px;
        }

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
        }
    }

    .block__title {
        @include font(38px, 44px);

        grid-column: 1 / 3;
        text-align: center;
        margin-bottom: 25px;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(26px, 32px);

            margin-bottom: 20px;
        }

        @include breakpoint(phablet) {
            grid-column: 1 / 2;
        }
    }

    .block__feature {
        margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);

        @include breakpoint(wrap) {
            margin-left: calc(#{$site-gutter} * -1);
        }

        @include breakpoint(tablet) {
            margin-left: calc(#{$site-gutter--mobile} * -1);
        }

        @include breakpoint(phablet) {
            margin-right: calc(#{$site-gutter--mobile} * -1);
            margin-bottom: 40px;
        }
    }

    .block__content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
}
