:root {
    interpolate-size: allow-keywords;
}

details.filterable {
    overflow: hidden;

    @include breakpoint(tablet) {
        &[data-overlay-active] {
            display: block;
            background-color: $c-white;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
            overflow-y: auto;
            max-height: none;
            padding-bottom: 80px;

            summary {
                position: sticky;
                top: 0;
                background: $c-white;
            }
        }

        [data-filtered] & {
            &[data-overlay-active] {
                padding-bottom: 105px;
            }
        }
    }

    &::details-content {
        block-size: 0;
        transition: block-size 300ms, content-visibility 300ms;
        transition-behavior: allow-discrete;

        @include breakpoint(tablet) {
            transition: none;
        }
    }

    &[open]::details-content {
        block-size: auto;
    }

    &:first-of-type summary {
        border-bottom: 1px solid $c-border-light;
    }

    &:last-of-type[open] {
        border-bottom: 1px solid $c-border-light;

        .filterable__dimension {
            padding-bottom: 20px;
        }
    }

    summary {
        @include font(14px, 18px);
        @include lightText;

        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        letter-spacing: 1px;
        align-items: center;
        list-style: none;
        padding: 15px 0;
        border-bottom: 1px solid $c-border-light;

        @include breakpoint(tablet) {
            @include font(18px, 18px);
            @include regularText;

            padding: 20px $site-gutter--mobile;
            text-transform: none;
            letter-spacing: normal;
        }

        &::-webkit-details-marker {
            display: none;
        }

        span {
            @include lightText;

            margin-left: 5px;
            color: $c-label--light-alt;

            &:empty {
                display: none;
            }
        }
    }

    &[open] summary {
        border-bottom-color: $c-black;

        @include breakpoint(tablet) {
            @include font(22px, 28px);

            justify-content: center;
            border-bottom-color: $c-border-light;
        }
    }

    svg.icon-minus-light {
        display: none;
    }

    &[open] svg.icon-plus-light {
        display: none;
    }

    &[open] svg.icon-minus-light {
        display: block;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    svg {
        margin-left: auto;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    svg.icon-arrow-small-right {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }
    }

    &[open] svg.icon-arrow-small-right {
        @include breakpoint(tablet) {
            position: absolute;
            left: $site-gutter--mobile;
            transform: rotate(180deg);
            pointer-events: none;
            width: 11px;
            height: 20px;
            margin-top: 1px;
        }
    }
}

.filterable--unavailable {
    pointer-events: none;

    summary,
    summary span[data-filter-count]{
        color: $c-label--light;
    }
    
    summary svg {
        display: none;
    }
}

.filterable--checkbox.filterable--checkbox {
    padding-top: 20px;

    @include breakpoint(tablet) {
        padding-left: $site-gutter--mobile;
    }

    .check_box__text.check_box__text {
        @include font(14px, 18px);

        color: $c-black;

        @include breakpoint(tablet) {
            @include font(16px, 18px);
        }
    }
}

.filterable__dimension {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0 10px;

    @include breakpoint(tablet) {
        padding: 20px $site-gutter--mobile 0;
        gap: 15px;
    }
}

.filterable__title {
    display: none;

    @include breakpoint(tablet) {
        @include font(22px, 28px);

        display: block;
        text-align: center;
        padding: 20px 0;
        border-bottom: 1px solid $c-border-light;
        position: sticky;
        top: 0;
        background-color: $c-white;
    }
}

.filterable__field {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
        appearance: none;
        border: 1px solid $c-swatch-border;
        width: 22px;
        height: 22px;
        border-radius: 1px;
        background-color: $c-white;
        cursor: pointer;

        @include breakpoint(tablet) {
            width: 30px;
            height: 30px;
        }

        &:disabled {
            background-color: var(--c-field-disabled);
        }

        &:checked {
            border-color: $c-black;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }

    label {
        @include font(14px, 18px);
        @include lightText;

        color: $c-black;
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-left: 10px;
        margin-top: 1px;

        @include breakpoint(tablet) {
            @include font(16px, 18px);
        }

        span {
            color: $c-label--light-alt;
        }
    }

    &:has(input[type="checkbox"]:checked) label {
        @include regularText;

        span {
            color: $c-black;
        }
    }

    img {
        margin-left: 5px;
        height: 20px;
        width: 20px;
        border: 1px solid $c-swatch-border;
        border-radius: 1px;

        @include breakpoint(tablet) {
            width: 30px;
            height: 30px;
            margin-left: 10px;
        }
    }
}

.filterable__dimension__label--unavailable {
    opacity: 0.2;
    pointer-events: none;
}
