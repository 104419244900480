.product-preview {
    color: $c-black;
}

.product-preview--offset {
    position: relative;
    top: -80px;

    @include breakpoint(tablet) {
        top: auto;
    }
}

    .product-preview__image {
        @include aspect(4 / 3);

        background: $c-brand;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .product-preview__content {
        padding-top: 60px;
        padding-left: 80px;

        .product-preview--middle & {
            padding-top: 0;
        }

        .product-preview--reverse & {
            padding-right: 100px;
            padding-left: 0;
            text-align: right;

            @include breakpoint(tablet) {
                text-align: left;
            }
        }

        @include breakpoint(tablet) {
            margin: 15px 0 40px;
            padding: 0;
        }
    }

        .product-preview__title {
            @include font(50px, 46px);
            @include thinText;

            margin-bottom: 10px;

            @include breakpoint(tablet) {
                @include font(24px, 29px);

                margin-bottom: 0;
            }
        }

        .product-preview__text {
            @include font(16px, 24px);

            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                margin-bottom: 15px;
            }
        }
