.block--twin-callout {
    padding: 0;
    --richtext-color: var(--block-text-color, $c-black);
    color: var(--block-text-color, $c-black);
    background: var(--block-background, transparent);

    &--has-background {
        padding: 60px 0;
    }

    .block__header {
        padding: 0 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(tablet) {
            padding: 0 0 30px;
        }
    }

        .block__heading {
            @include font(38px, 44px);
            @include regularText;

            @include breakpoint(tablet) {
                @include font(26px, 32px);
            }
        }

        .block__carousel-nav {
            display: flex;
            gap: 10px;
        }

    .block__content {

        .grid--semi-tight {

        }
    }

        .block__carousel {
            margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);
            margin-right: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);

            @include breakpoint(wrap) {
                margin-left: calc(#{$site-gutter} * -1);
                margin-right: calc(#{$site-gutter} * -1);
            }

            @include breakpoint(tablet) {
                margin-left: calc(#{$site-gutter--mobile} * -1);
                margin-right: calc(#{$site-gutter--mobile} * -1);
                overflow: hidden;
            }
        }

    .block__button--carousel {
        margin-top: 45px;

        @include breakpoint(tablet) {
            margin-top: 15px;
        }

        &.has-carousel {
            @include breakpoint(tablet) {
                margin-top: 45px;
            }
        }
    }

        .block__item {
            display: flex;
            flex-direction: column;
            gap: 25px;
            color: var(--block-text-color, $c-black);

            &:hover {

                img {
                    transform: scale(1.2); 
                }
            }

            @include breakpoint(tablet) {
                gap: 15px;
            }
        }

            .block__image {
                @include aspect(calc(5 / 3));

                margin: 0;

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                    transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);
                }
            }

            .block__copy {
                @include breakpoint(tablet) {
                    margin-bottom: 10px;
                }
            }

                .block__subtitle {
                    @include regularText;
                    @include font(14px, 32px);

                    letter-spacing: 1.5px;
                    text-transform: uppercase;

                    @include breakpoint(tablet) {
                        @include font(12px, 22px);
                        letter-spacing: 2px;
                    }
                }

                .block__title {
                    @include font(25px, 32px);

                    @include breakpoint(tablet) {
                        @include font(22px, 28px);
                    }
                }

}
