.block--overlap-image {
    padding: 0;
    color: $c-black;

    .wrap & .wrap {
        padding: 0;
    }

    .block__image {
        @include aspect(4 / 3);

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__image--left {
        margin: -20px -20px 0 0;

        @include breakpoint(tablet) {
            margin: 0 0 20px 0;
        }
    }

    .block__image--right {
        margin: 0 0 -20px -20px;

        @include breakpoint(tablet) {
            margin: 0 0 20px 0;
        }
    }

    .block__header {
        padding: 40px 80px 40px 0;

        @include breakpoint(tablet) {
            padding: 0;
            margin-bottom: 20px;
        }
    }

        .block__title {
            @include font(40px, 52px);

            color: inherit;

            @include breakpoint(tablet) {
                @include font(30px, 34px);
            }
        }

        .block__subtitle {
            @include font(18px, 22px);
            @include lightText;

            margin-bottom: 12px;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: inherit;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                margin-bottom: 0;
                letter-spacing: 3px;
            }
        }

    .block__content {
        padding: 40px 0 40px 80px;

        @include breakpoint(tablet) {
            padding: 0;
            margin-bottom: 20px;
        }
    }

        .block__text {
            margin-bottom: 23px;
        }

}
