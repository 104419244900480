.variants {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -1px -5px 0;
    position: relative;
    overflow: hidden;

    &--compare {
        padding-bottom: 32px;
    }
}

    .variants__title {
        @include font(20px, 30px);

        margin-right: 20px;

        @include breakpoint(phablet) {
            flex: 0 0 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .variants__item {
        width: 22px;
        height: 22px;
        margin: 1px 0 1px 6px;
        border: 1px solid $c-swatch-border;
        border-radius: 1px;

        @include breakpoint(phablet) {
            width: 20px;
            height: 20px;
        }

        .variants--large & {
            width: 30px;
            height: 30px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 1px;
        }
    }

    .variants__item:hover,
    .variants__item--active {
        border: 1px solid $c-white;
        outline: $c-black solid 1px;
    }

    .variants__item--configurable-option {
        position: relative;

        img {
            pointer-events: none;
        }
    }

    .variants__item--disabled {

    }

    .variants__select {
        position: absolute;
        left: 6px;
        bottom: -999px;
        margin: 0;

        .variants__item--active + & {
            bottom: 0;
        }

        .compare-max &:not(.field--checked) {
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    .variants__unavailable-icon {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
            height: 100%;
        }

        .variants__item--disabled & {
            display: inline-block;
        }
    }

    .variants__configurable {
        margin-left: 12px;
        @include font(12px, 22px);
        font-weight: 300;

        @include breakpoint(tablet) {
            flex-basis: 100%;
            margin-left: 5px;
            margin-top: 10px;
        }
    }
