.collection-callout {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;

    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
}

.collection-callout__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 45px;

    @include breakpoint(tablet) {
        order: 1;
        grid-column: 1 / 3;
        padding: 0;
    }
}

.collection-callout__title {
    @include font(26px, 32px);

    margin-bottom: 20px;

    @include breakpoint(tablet) {
        @include font(22px, 28px);

        margin-bottom: 10px
    }
}

.collection-callout__text {
    @include lightText;

    margin-bottom: 25px;

    @include breakpoint(tablet) {
        @include font(14px, 22px);

        margin-bottom: 20px;
    }
}

.collection-callout__image {
    aspect-ratio: 7 / 5;

    img {
        width: 100%;
        height: auto;
    }
}

.collection-callout__video {
    aspect-ratio: 7 / 5;
    width: 100%;
    object-fit: cover;
}
