.filter-meta {
    overflow: visible;
}

.flexbox .grid--flex.grid--flush.filter-meta__inner {
    margin: 0 0 30px 0;
}

    .filter-meta__image-wrap {
        position: relative;

        @include breakpoint(tablet) {
            @include aspect(48/27);
        }
    }

    .filter-meta__content {
        padding: 50px;
        text-align: left;

        @include breakpoint(tablet) {
            padding: 35px;
            text-align: center;
        }
    }

    .filter-meta__content--centered {
        text-align: center;
    }

    .filter-meta__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
    }

    .filter-meta__title {
        @include rem(40px);
    }

    .filter-meta__description {
        color: $c-white;
    }
