.search-results {}

    .search-results__title {
        @include rem(20px);

        color: $c-grey--mid;

        @include breakpoint(tablet) {
            @include rem(16px);

            line-height: calc(20 / 16);
        }

        span {
            color: $c-black;
        }
    }

    .search-results__suggestions,
    .search-results__unavailable {
        background: $c-login-bg;
        padding: 40px 70px;

        @include breakpoint(tablet) {
            padding: 40px;
        }
    }

        .search-results__header {
            margin-bottom: 40px;
            border-bottom: 1px solid $c-border;
            padding-bottom: 30px;

        }

        .search-results__footer {
            margin-top: 20px;
            border-top: 1px solid $c-border;
            padding-top: 30px;
        }

            .search-results__title {
                @include regularText();
                @include font(32px, 46px);

                letter-spacing: 0px;
                color: $c-black;
                text-transform: capitalize;

                span {
                    @include thinText;
                    text-transform: lowercase;
                }

                @include breakpoint(tablet) {
                    @include font(24px, 32px);

                    text-align: left;
                }
            }

            .search-results__intro {
                text-align: left;
                margin-top: 10px;

                p {
                    @include font(16px, 24px);
                    @include f-light;
                }
            }

        .search-results__suggestion-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row nowrap;

            figure {
                @include aspect(1);
                flex: 0 0 70px;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

            .search-results__text {
                @include font(16px, 24px);

                margin-left: 20px;
                color: $c-black;
                transition: color .3s ease;

                a:hover & {
                    color: $c-brand;

                    @include breakpoint(tablet) {
                        color: inherit;
                    }
                }
            }

        .search-results__button {
            margin-top: 20px;
        }
