// Scrollers

.scrollable {
    text-align: center;
    position: relative;

  &.scrollable--static {
    .scrollable__directionals {
      display: none;
    }
  }
}

.scrollable__inner {
    overflow: hidden;
}

.scrollable__track {
    overflow: visible;
    will-change: transform;
    display:block;
    white-space: nowrap;
    font-size: 0;
    transition: transform 0.5s $transition-function;
    min-width: 100%;

    @include breakpoint(tablet) {
        transition-duration: 0.5s;
    }
}

.scrollable__slide {
    will-change: opacity;
    text-align: center;
    display: inline-block;
    white-space: normal;
}

.scrollable__slide--hidden {
    opacity: 0.5;
}

.scrollable--enabled .scrollable__slide {
    transition: opacity 0.25s $transition-function;
}

.scrollable__directionals {
    position: absolute;
    top: -70px;
    right: -10px;

    @include breakpoint(tablet) {
        position:relative;
        top: 0;
        right: 0;
    }
}

.scrollable__prev,
.scrollable__next {
    z-index: 2;
}

.scrollber--enabled .scrollable__prev,
.scrollber--enabled .scrollable__next {
    transition: opacity 0.1s linear;
}

.scrollable__prev, .scrollable__next {
    display: none;
    @include rem(50px);
    padding: 10px;

    @include breakpoint(tablet) {
        padding: 10px 25px;
    }
}

.scrollable--enabled .scrollable__prev,
.scrollable--enabled .scrollable__next {
    display: inline-block;
}

.scrollable__prev.scrollable--disabled,
.scrollable__next.scrollable--disabled {
    cursor: default;
    opacity: 0.15;
}

.scrollable__nav {
    @extend .slider__nav;
}

.scrollable__nav__item {
    @extend .slider__nav__item
}

.scrollable__nav__item--active {
    @extend .slider__nav__item--active
}

.scrollable--enabled .scrollable__nav {
    display: block;
}

// Scroller links

.scroller-link {
    margin: 20px 0 30px;
}
