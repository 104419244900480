// Catalogue form

.catalogue-listing-item {
    @include rem(16px);

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    position: relative;
    margin-bottom: 30px;
    transition: opacity .3s $transition-function;
}

    .catalogue-listing-item__image-wrap {
        align-self: stretch;
        position: relative;
        margin-bottom: 15px;
        overflow: hidden;
    }

        .catalogue-listing-item__image {
            @include aspect(5/7);

            background: $background-grey;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .catalogue-listing-item__image-wrap:hover & {
                    transform: scale(1.2);

                    @include breakpoint(tablet) {
                        transform: none;
                    }
                }
            }
        }

    .catalogue-listing-item__overlay {
        @include lightondark;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($c-black, .5);
        z-index: 2;
        opacity: 0;
        transition: opacity .3s ease;

        .catalogue-listing-item__image-wrap:hover & {
            opacity: 1;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

        .catalogue-listing-item__overlay-actions {
            padding: 20px;

            .catalogue-listing-item__image-wrap:hover & {

                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .button:not(:last-child) {
                margin-bottom: 10px;
            }
        }

    .catalogue-listing-item__title {
        @include font(20px, 30px);

        width: calc(100% - 20px);
        margin: 0 10px 5px;
        color: $c-black;
        text-align: center;

        @include breakpoint(phablet) {
            @include font(14px, 30px);

            margin-bottom: 0;
        }

        a {
            color: inherit;
        }
    }

    .catalogue-listing-item__size {
        @include font(12px, 20px);

        color: $c-grey--mid-text;
        text-align: center;
    }

    .catalogue-listing-item__actions {
        display: none;
        padding-top: 10px;

        .button:not(:last-child) {
            margin-bottom: 5px;
        }

        @include breakpoint(tablet) {
            display: block;
        }
    }

.catalogue-print-banner {
    background-color: $c-grey--dark;
}

    .catalogue-print-banner__text {
        @include font(26px, 40px);
        color: $c-white;

        span {
            @include thinText;
        }

        @include breakpoint(phablet) {
            display: none;
        }

        &--short {
            display: none;
            @include font(14px, 17px);
            text-transform: uppercase;

            span {
                @include regularText;
            }

            @include breakpoint(phablet) {
                display: block;
            }
        }

    }

    .catalogue-print-banner__wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
            display: inline-flex;
            margin-left: 30px;

            @include breakpoint(phablet) {
                display: none;
            }
        }
    }

.catalogue-print-request {
    background-color: #f1f1f1;
    padding: 80px 0;

    @include breakpoint(phablet) {
        background-color: $c-white;
        padding: 0;
    }
}

.catalogue-download {
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    .text--right {
        padding-top: 30px;
        @include infinity-border(top, left);
        padding-right: 30px;
    }
    p, h1 {
        padding-right: 30px;
    }

    .richtext {
        padding-bottom: 30px;
    }

    @include infinity-border(bottom, left);
}

.catalogue-form__wrap {
    background-color: $c-white;
    padding: 0 50px;

    @include breakpoint(phablet) {
        padding: 0;
    }
}

.catalogue-form {

    fieldset {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    fieldset:nth-child(2) {
       border-top: 1px solid $c-section-border;
    }
}

    .catalogue-form__title {
        @include font(26px, 34px);
        text-align: center;
        margin-bottom: 30px;
    }

// Catalogue image

.catalogue-image {
    @include aspect(1);

    img {
        width: 100%;
        height: auto;
    }
}

// Very similar to product-dl-list
.catalogue-view-list {
}

.catalogue-view-list__detail {
    padding: 0px 0 25px 0;
}

    .catalogue-view-list__detail--preview-file {
        padding-bottom: 0;
    }

.catalogue-view-list__link {
    padding: 16px 0;
    color: $c-grey--mid;
    display: inline-block;
    position: relative;
    line-height: 1.3;

    span {
        text-transform: uppercase;
        text-decoration: underline;
        @include rem(14px);
        padding-left: 30px;
        display: inline-block;
        text-align: left;
    }
}

    .catalogue-view-list__link--preview-file {
        padding-bottom: 0;
    }

.catalogue-view-list__title {
    min-width: 200px;
    font-weight: bold;
    color: $c-grey--mid;
    line-height: 1.4;
    text-align: left;
    @include rem(16px);

    span {
        text-transform: none;
        display: block;
        font-weight: normal;
        @include copy;
        @include rem(16px);
    }

    img {
        float: left;
        width: 62px;
        margin-right: 20px;
        margin-top: -7px;
        margin-bottom: -2px;
    }

    &--uppercase {
        text-transform: uppercase;
    }
}

    .catalogue-view-list__title--preview-file {
        padding-top: 15px;
        font-weight: normal;

        @include breakpoint(tablet) {
            padding-top: 0;
        }
    }
