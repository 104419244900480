.product-showcase--form {
    position: relative;

    @include product_showcase_padding(55px, 0px, 0px);

    @include breakpoint(tablet) {
        min-height: 0;
    }
}

    .product-form {
        overflow: hidden;
    }

        .product-form__container {

            @include breakpoint(tablet) {
                width: 100%;
                max-width: 100%;
            }
        }

        .product-form__intro {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 50%;
            padding-top: 36px;
            color: $c-product-showcase-form-heading;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @include product_showcase_margin(55px, 53px, 23px);

            @include breakpoint(tablet) {
                position: relative;
                width: auto;
                height: 100vw;
            }
        }

        .product-form__details {
            float: right;
            width: 50%;
            min-height: 100vh;
            text-align: left;
            color: $c-product-showcase-form-details;
            background: $c-product-showcase-form-bg;

            @include product_showcase_padding(36px, 0px, 0px);

            @include breakpoint(tablet) {
                float: none;
                width: auto;
                min-height: 0;
                overflow: hidden;
            }

            fieldset {
                max-width: 376px;
                padding: 0 75px 63px;

                @include rem(16px);
                line-height: 1*19/16;

                @include breakpoint(tablet) {
                    margin: 0 auto;
                    padding: 38px 0 45px;
                }
            }

            label {
                display: block;
                margin-bottom: 18px;

                &.error {
                    color: $c-product-showcase-form-details;
                }
            }
        }

            .product-form__field {
                margin-bottom: 36px;
                float: none;
                margin-right: 0;

                .input {
                    width: 100%;
                    border: 1px solid $c-product-showcase-form-details;
                    color: inherit;
                    background: none;
                }

                .select-replace select {
                    color: $c-black;
                }

                &.field-error {
                    .input {
                        border: 2px solid $c-product-showcase-form-details;
                    }
                }

                p.error {
                    color: $c-product-showcase-form-details;
                }
            }

            .product-form__options {
                display: flex;

                @include breakpoint(mobile) {
                    justify-content: space-between;
                }
            }

                 .product-form__option {

                    &:nth-child(2):nth-last-child(2) {
                        margin: 0 40px;
                        text-align: center;

                        @include breakpoint(mobile) {
                            margin: 0;
                        }
                    }

                    &:nth-child(2):nth-last-child(2) .product-form__fake_radio {
                        margin: 0 auto 18px;
                    }

                    &:last-child {

                        @include breakpoint(mobile) {
                            direction: rtl;
                        }
                    }

                    .no-flexbox & {
                        float: left;
                    }
                }

                    .product-form__fake_radio {
                        display: block;
                        width: 48px;
                        height: 48px;
                        margin-bottom: 18px;
                        cursor: pointer;
                        position: relative;
                        border: 1px solid $c-product-showcase-form-details;

                        img {
                            pointer-events: none;
                        }
                    }

                    .product-form__label {
                        text-transform: uppercase;
                        cursor: pointer;
                    }

                    .product-form__radio {
                        display: none;

                        &:checked + label {
                            .product-form__fake_radio {
                                border-width: 2px;
                                top: -1px;
                                left: -1px;
                                margin-bottom: 16px;
                            }

                            .product-form__fake_radio__tick {
                                display: block;
                            }
                        }
                    }

                    // Silly specificity
                    .product-form__option:nth-child(2):nth-last-child(2) .product-form__radio:checked + label .product-form__fake_radio {
                         margin-bottom: 16px;
                     }

                    .product-form__submit {
                        padding: 12px 35px 11px;
                        text-transform: uppercase;
                        background: $c-white;
                        border: none;
                        border-radius: 0;
                        cursor: pointer;
                        font-family: $f-default-stack;

                        @include rem(14px);
                        line-height: 1*17/14;
                    }

                        .product-form__fake_radio__tick {
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: url(/images/tick.png) center center no-repeat;

                            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                background-image: url(/images/tick-2x.png);
                                background-size: 32px 30px;
                            }
                        }
