.import-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
	border-bottom: 1px solid $c-border;

	@include breakpoint(tablet) {
		display: block;
		padding: 20px 0;
	}
}

.import-item--removed {

	p {
		text-decoration: line-through;
	}
}

.import-item--all {

	@include breakpoint(tablet) {
		text-align: center;
	}
}

	.import-item__content {
		flex: 1 1 auto;
		margin-right: 20px;

		@include breakpoint(tablet) {
			margin: 0 0 10px 0;
		}
	}

		.import-item__title {
			@include apply-map($f-title);
			@include font(18px, 30px);

			margin-bottom: 3px;
			color: $c-black;

			.import-item--all & {
				@include lightText;

				@include breakpoint(tablet) {
					@include font(16px, 22px);
				}
			}
		}

		.import-item__text {
			@include font(14px, 17px);

			margin-bottom: 10px;
			color: $c-black;

			span {
				color: $c-import-action-required;

				.import-group--available & {
					color: $c-import-available;
				}

				.import-group--not-available & {
					color: $c-import-not-available;
				}
			}
		}

		.import-item__note {
			@include font(12px, 15px);
			@include lightText;

			display: flex;
			align-items: center;
			color: $c-grey--mid;

			svg {
				flex: 0 0 auto;
				width: 20px;
				height: 20px;
				margin-right: 10px;
				fill: $c-import-action-required;

				.import-group--not-available & {
					fill: $c-import-not-available;
				}
			}
		}

	.import-item__actions {
		flex: 0 0 210px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		text-align: left;

		@include breakpoint(tablet) {
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;

			> * {
				flex: 1 1 33.33%;
			}
		}

		a {
			@include font(12px, 15px);
			@include lightText;

			margin: 7px;
			text-decoration: underline;
			color: $c-grey--mid;
			transition: color .3s ease;
			white-space: nowrap;

			@include breakpoint(tablet) {
				@include font(13px, 16px);
				@include boldText;
			}

			&:hover {
				color: $c-brand;
			}
		}

		.import-item--all & {

			@include breakpoint(tablet) {
				display: block;
			}
		}

		.button {
			width: 100%;

			@include breakpoint(tablet) {
				width: auto;
				max-width: 300px;
				order: 1;
				flex-basis: 40%;
			}
		}
	}

	.import-item__actions--single-link {
		text-align: center;
		justify-content: center;

		@include breakpoint(tablet) {
			text-align: left;
		}
	}

	.import-item__actions--accept-all {

		@include breakpoint(tablet) {
			text-align: center;
			justify-content: center;
		}
	}
