$steps: 4;

.downlight-selector {
    max-width: 1095px;
    margin: 0 auto;

    @include breakpoint(tablet) {
        max-width: 730px;
    }
}

.downlight-heading {
    @include font(26px, 32px);

    margin-bottom: 30px;
    text-align: center;
    color: $c-black;

    @include breakpoint(mobile) {
        @include font(24px, 28px);
    }

    //span {
    //    display: none;
    //    @include breakpoint(phablet) {
    //        display: inline;
    //    }
    //}
}

.downlight-breadcrumb {
    display: flex;
    align-items: top;
    justify-content: space-between;
    margin: 40px 0;

    @include breakpoint(phablet) {
        display: none;
    }
}

.downlight-crumb {
    position: relative;
    text-align: center;
    opacity: 0.5;
    color: $c-grey--mid-text;
    @include font(20px, 24px);
    font-weight: 200;

    @include breakpoint(phablet) {
        @include font(14px, 16px);
    }

    span {
        display: table;
        text-transform: uppercase;
        font-weight: normal;
        color: $c-grey--mid-text;
        @include font(12px, 15px);
        margin: 5px auto 0 auto;
        letter-spacing: 1px;
        padding-bottom: 2px;
        border-bottom: 1px solid $c-grey--mid-text;
    }

    @for $i from 1 through $steps {
        .downlight-selector[data-current-step="#{$i}"] &:nth-child(#{$i*2-1}) {
            color: $c-black;;
            font-weight: 400;
        }

        .downlight-selector[data-current-step="#{$i}"] &:nth-child(-n+#{$i*2}) {
            opacity: 1;
        }
    }

    &__link {
        &:hover {
            color: $c-brand;
        }
    }
}

.downlight-arrow {
    opacity: 0.3;
    padding: 0 10px;

    @for $i from 1 through $steps {
        .downlight-selector[data-current-step="#{$i}"] &:nth-child(-n+#{$i*2-1}) {
            opacity: 1;
        }
    }

    .icon-chevron {
        display: none;
    }

    @include breakpoint(mobile) {
        .progress-arrow {
            display: none;
        }

        .icon-chevron {
            display: block;
        }
    }
}

.downlight-steps {
    position: relative;
    margin: 40px 0;
}

.downlight-step {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;

    &--active {
        position: static;
        z-index: 1;
        opacity: 1;
    }
}

.downlight-choice {
    text-align: center;
    background: #F4F4F4;
    transition: background 0.5s ease-in;

    &--disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    &:hover {
        background: #E4E4E4;
    }

    figure {
        @include aspect(1);

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.downlight-choice-text {
    @include font(20px, 30px);
    @include regularText;
    padding: 20px 0;

    @include breakpoint(mobile) {
        @include font(16px, 30px);
    }
}

.downlight-any {
    text-align: center;
}

.downlight-description {
    text-align: center;
    max-width: 500px;
    margin: 35px auto 0 auto;

    a.downlight-download {
        @include font(12px, 15px);
        margin-left: 0.3em;
        text-decoration: underline;

        &:hover {
            color: black;
        }
    }
}

.downlight-previous-step {
    text-align: center;
    margin-top: 35px;
}

.downlight-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: black;
    opacity: 0.8;
    z-index: 1;
}

.downlight-popup {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 40px;
    border-radius: 5px;
    z-index: 2;
    text-align: center;
    max-width: 700px;

    @include breakpoint(phablet) {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: none;
        border-radius: 0;
    }

    .downlight-choice {
        padding: 50px 0;
        border-radius: 3px;
    }

    p {
        margin-bottom: 0.6em;
    }

    a.downlight-download {
        @include font(12px, 15px);
        margin-left: 0.3em;
        text-decoration: underline;

        &:hover {
            color: black;
        }
    }
}

.downlight-navigation {
    padding: 20px 0;
}

.downlight-backlink {
    @include font(12px, 22px);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $c-grey--mid-text;

    svg {
        margin-right: 5px;
        fill: $c-brand;
    }

    &:hover {
        color: $c-black;

        svg {
            fill: $c-black;
        }
    }
}


// PDP style level info
.downlight-style {

    &__image {
        @include aspect(5 / 3);

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    &__content {
        padding-left: 80px;

        @include breakpoint(tablet) {
            padding: 20px 0 0 0;
        }
    }

    &__title {
        @include large-title;
        color: $c-black;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
            @include font(24px, 30px);
        }
    }

    &__finish {
        @include font(20px, 46px);
        @include lightText;
    }

    &__variants {
        padding-top: 10px;
    }
}

// Variants
.downlight-variants {
    background: $c-downlight-variants-background;

    &__filtered-by {
        @include font(20px, 26px);
        @include lightText;
        text-align: center;
        padding-bottom: 50px;
        color: $c-black;

        span {
            @include regularText;
        }
    }
}

.downlight-variant {
    background-color: $c-white;

    &__content {
        padding: 30px;

        @include breakpoint(tablet) {
            padding: 30px 15px;
        }
    }

    &__image {
        @include aspect(1);

        img {
            width: 100%;
            height: auto;
        }
    }

    &__title {
        @include small-title;
        color: $c-black;
        margin-bottom: 10px;
    }

    &__price {
        @include font(18px, 22px);
        margin-bottom: 10px;

        span {
            @include font(14px, 17px);
            @include lightText;
        }
    }

    &__more {
        border-top: 1px solid $c-downlight-variants-background;
        padding: 20px 30px;
        text-align: right;

        @include breakpoint(mobile) {
            text-align: left;
        }
    }
}

.downlight-other-variants {
    background: $c-downlight-variants-background;
    padding-top: 0;
    padding-bottom: 0;

    .accordion__title {
        color: $c-black;
        @include font(26px, 46px);
        text-align: center;
        position: relative;
        @include lightText;

        span {
            @include regularText;
        }
    }

    .downlight-other-variants__variants {
        padding: 60px 0;
    }
}

    .downlight-other-variants__accordion {
        background: $c-white;
    }

.downlight-accessories {
    background: #F4F4F4;
    padding-top: 0;

    &--divider {
        border-top: 1px solid $c-grey--light;
    }

    .compatible-item {
        background: $c-white;
        padding: 30px;
    }
        .compatible-item__title {
            @include font(20px, 24px);
            color: $c-black
        }

        .compatible-item__image {
            flex: unset;
            flex-shrink: 0;
            max-width: 40%;
            align-self: start;
        }
        .compatible-item__content {
            align-self: start;
            display: flex;
            flex-flow: column;
            align-items: start;
        }

            .compatible-item__price {
                @include font(18px, 22px);

                span {
                    @include font(14px, 17px);
                    @include lightText;
                }
            }

            .compatible-item__more {
                margin-top: 25px;
            }
}

    .downlight-accessories__heading {
        background: $c-white;
        padding: 50px 0;
        margin-bottom: 60px;
    }

        .downlight-accessories__title {
            color: $c-black;
            @include font(26px, 46px);
            text-align: center;
            position: relative;
            @include lightText;

            span {
                @include regularText;
            }
        }


// No filter results
.downlight-no-results {
    background: #F4F4F4;
    padding: 90px 0;
    margin: 0 5px;
    width: 100%;
    text-align: center;

    p {
        color: $c-detail-subtitle;
        @include font(20px, 24px);
        font-weight: 300;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }

    a {
        color: $c-black;
        text-decoration: underline;
    }
}

[data-visible-count="0"] {
    display: none;
}
