.block--image-text {
    padding: 0;
    color: $c-inspiration-text;

    &.block--reverse {

        .block__content {
            padding-right: 50px;
            padding-left: 0;

            @include breakpoint(tablet) {
                padding-right: 0;
            }
        }
    }

    .wrap {
        @include breakpoint(tablet) {
            padding: 0;
        }

        .wrap & {
            padding: 0;
        }
    }

        .grid {
            overflow: visible;
        }

    .block__image {
        @include aspect(1);

        margin: 0 0 10px;
        overflow: visible;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            @include objectFitImage;

            width: 100%;
            height: 100%;
        }
    }

    .block__caption {
        @include font(12px, 22px);
        @include lightText;

        color: $c-block-caption;
        text-align: right;
    }

    .block__content {
        padding-left: 50px;

        @include breakpoint(tablet) {
            padding: 0 15px;
        }

        .wrap & {

            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }

        .block__title {
            @include font(20px, 28px);

            margin-bottom: 25px;
            color: $c-black;

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }
        }

        .block__title--large {
            @include font(32px, 40px);

            @include breakpoint(tablet) {
                @include font(20px, 28px);
            }
        }

        .block__text {
            color: inherit;
        }
}

.block--image-text-large {

    &.block--reverse {

        .block__content {
            padding-right: 50px;
            padding-left: 0;

            @include breakpoint(tablet) {
                padding-right: 0;
            }
        }
    }

    .block__content {
        padding-left: 60px;

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

    .block__title {
        @include font(32px, 40px);

        @include breakpoint(tablet) {
            @include font(20px, 28px);
        }
    }
}
