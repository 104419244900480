.journal-articles {
    padding-top: 15px;
    border-top: 1px solid $c-border-light;
}

    .journal-articles__title {
        @include font(18px, 22px);
        @include lightText;

        margin-bottom: 35px;
        text-transform: uppercase;
        color: $c-black;
        letter-spacing: 4px;

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            margin-bottom: 25px;
            letter-spacing: 3px;
        }
    }
