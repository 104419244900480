.quality-entry-form {
    background-color: #F8F8F8;
    padding: 30px 40px;
    text-align: center;

    p {
        &.quality-entry-form__title {
            @include rem(18px);
            font-weight: 700;
        }

        &.quality-entry-form__subtitle {
            @include rem(14px);
            font-weight: 700;
            margin-bottom: 5px;
        }
    }

    @include breakpoint(mobile) {
        padding: 20px;
    }
}

.quality-form {

    .quality-form__section--confirm {
        background-color: #F8F8F8;

        p {
            @include f-light;
        }
    }

    p {
        &.quality-form__text--light {
            @include f-light;
        }
    }

    .file-list {
        margin-bottom: 10px;

        li .success {
            color: #2EA200;
        }

        li .remove {
            color: #ff0000;
            padding-left: 10px;
        }
    }
}

dt {
    @include rem(14px);
    display: block;
    margin-bottom: 10px;
}

dd {
    @include copy;
    @include rem(15px);
    @include f-regular;
}
