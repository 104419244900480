.loading {}

.loading--active {
    @include allowForBars((
        default: $header-top-height + $track-configurator-nav-height
    ));

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: $c-track-configurator-background;
    z-index: 2;

    @include breakpoint(tablet) {
        display: none;
    }
}

    .loading__content {
        display: none;

        .loading--active & {
            position: relative;
            display: block;
            width: 100%;
            max-width: 600px;
            margin: auto;
            padding: 25px;
        }
    }

    .loading__title {
        @include font(20px, 24px);

        margin-bottom: 25px;
    }

    .loading__intro {}
