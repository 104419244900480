.pdp-unavailable {}

    .pdp-unavailable__overlay {

        .pdp-unavailable & {
            position: relative;

            &:before {
                @include objectFitImage;

                content: '';
                background: $c-white;
                opacity: 0.5;
                z-index: 5;
            }
        }
    }

    .pdp-unavailable__notice {
        display: none;

        .pdp-unavailable & {
            display: flex;
            max-width: $site-max-width / 2;
            padding: 30px 0 80px;
            overflow: hidden;

            @include breakpoint(pdp-large)  {
                padding: 0px 80px 80px;

                @media (max-height: 600px) {
                    max-width: none;
                }
            }

            @include breakpoint(pdp-medium)  {
                padding: 0px 50px 80px;
            }

            @include breakpoint(tablet)  {
                max-width: none;
                padding: 0px 20px 80px;
            }
        }
    }
        .pdp-unavailable__svg {
            margin-right: 15px;
        }

        .pdp-unavailable__content {
            padding-top: 3px

        }

            .pdp-unavailable__copy {
                @include copy;
                @include rem(14px);

                color: $c-pdp-grey;
                padding-bottom: 15px;

                span {
                    @include boldText;
                }

                a {
                    text-decoration: underline;
                    transition: color .3s ease;

                    &:hover {
                        color: $c-brand;

                        @include breakpoint(tablet) {
                            color: inherit;
                        }
                    }
                }
            }
