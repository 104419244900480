.combo-listing-item {
    text-align: center;

    .button {
        margin-top: 10px;
    }
}

    .combo-listing-item__image {
        @include aspect(1);

        margin-bottom: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .combo-listing-item__title {
        @include font(20px, 30px);

        color: $c-black;

        span {
            @include lightText;
        }
    }

    .combo-listing-item__price {
        @include font(16px, 30px);
        @include lightText;

        color: $c-label--light;

        span {
            @include regularText;

            color: $c-black;
        }
    }
