.swatch {
    position: relative;
    width: 22px;
    height: 22px;
    padding: 0;
    background: $c-grey--button;
    border: 1px solid $c-swatch-border;
    border-radius: 3px;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        border-radius: 3px;
    }
}

.swatch--clickable {

    &:hover {
        border-color: $c-black;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid $c-white;
            border-radius: inherit;
        }
    }
}
