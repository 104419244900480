@import 'magnific-popup/src/css/main.scss';

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .mfp--#{$name}-false {
            display: none;
        }
        .mfp--#{$name}-true {
            display: inline-block;
        }
        .mfp--#{$name}-block {
            display: block;
        }
    }
}

.mfp-bg {
    height: 100vh !important;

    &.mfp--key {

        @include breakpoint(mobile) {
            background: $c-white;
            opacity: 1;
        }
    }

    &.mfp--track-configurator-start {
        @include allowForBars((
            default: $header-top-height + $track-configurator-nav-height + $track-configurator-toggle-height
        ));

        background: $c-track-configurator-background;
        opacity: 1;
    }

    &.mfp--track-configurator-popup {
        @include allowForBars();
    }

    &.mfp--pdp-configure-popup {
        @include allowForBars();
    }

    &.mfp--track-configurator-popup--remove.mfp-fade.mfp-ready {
        opacity: 0.3;
    }

    &.mfp--pdp-configure-popup-remove.mfp-fade.mfp-ready {
        opacity: 0.3;
    }

    &.mfp--popup-page {
        background: $c-white;
        opacity: 1;
    }
}

.mfp-wrap {

    &.mfp--track-configurator-start {
        @include allowForBars((
            default: $header-top-height + $track-configurator-nav-height + $track-configurator-toggle-height
        ));

        height: calc(100% - #{$header-top-height + $track-configurator-nav-height + $track-configurator-toggle-height});
    }

    &.mfp--track-configurator-popup {
        @include allowForBars();

        bottom: 0;
        height: auto;
    }

    &.mfp--pdp-configure-popup {
        @include allowForBars();

        bottom: 0;
        height: auto;
    }
}

.mfp-container {
    padding: 0;

    .mfp--track-configurator-popup & {
        text-align: left;
    }

    .mfp--pdp-configure-popup & {
        text-align: right;
    }

    .mfp--track-configurator-popup--secondary & {
        text-align: center;
    }
}

    .mfp-content {
        padding: 0 44px;

        .mfp--outside & {
            padding-top: 44px;
            padding-bottom: 44px;
        }

        .mfp--filters &,
        .mfp--confirm & {

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

        .mfp--s--flush & {
            padding: 0 35px;

            @include breakpoint(mobile) {
                padding: 15px;
            }
        }

        .mfp--scenes & {
            max-width: 800px + 44px + 44px;
            padding: 60px 44px;

            @include breakpoint(mobile) {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .mfp--key & {
            max-width: 375px + 44px + 44px;

            @include breakpoint(tablet) {
                padding: 15px 0;
            }

            @include breakpoint(mobile) {
                max-width: none;
                vertical-align: top;
            }
        }

        .mfp--stock-levels & {
            max-width: 640px + 44px + 44px;
            padding: 60px 44px;

            @include breakpoint(mobile) {
                padding: 15px;
            }
        }

        .mfp--country-selection & {
            max-width: 1000px + 44px + 44px;
            padding: 60px 44px;

            @include breakpoint(mobile) {
                padding: 15px;
            }
        }

        .mfp--notice & {
            max-width: 700px + 44px + 44px;
            padding: 60px 44px;
        }

        .mfp--downloads & {
            max-width: 800px;
        }

        .mfp--track-configurator-popup & {
            max-width: 700px;
            min-height: 100%;
            margin: 0;
            padding: 0;
            background: $background-grey;
        }

        .mfp--pdp-configure-popup & {
            max-width: 700px;
            min-height: 100%;
            margin: 0;
            padding: 0;
            background: $background-grey;
        }

        .mfp--filters & {
            @include breakpoint(phablet) {
                max-width: 700px;
                min-height: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .mfp--track-configurator-popup--remove & {
            width: 33.3333%;
            max-width: 700px;
        }

        .mfp--track-configurator-popup--secondary & {
            max-width: 300px;
            min-height: 0;
            padding: 30px;
        }

        .mfp--popup-page & {
            background: $c-white;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

        .mfp--pdp-overlay &,
        .mfp--plp-overlay & {
            max-width: 700px + 44px + 44px;
            padding: 60px 44px;

            @include breakpoint(mobile) {
                padding: 0px;
                height: 100%;
            }
        }

        .mfp--pdp-overlay & {

            @include shortViewport {
                padding: 20px;

                @include breakpoint(tablet) {
                    padding: 60px 44px;
                }

                @include breakpoint(mobile) {
                    padding: 0;
                }
            }
        }

        .mfp--site-switch & {
            max-width: 930px + 40px;
            padding: 20px;

            @include breakpoint(tablet) {
                max-width: 465px + 40px;
            }
        }

        .mfp--import-quantity & {
            max-width: 560px + 44px + 44px;
            padding: 44px;

            @include breakpoint(tablet) {
                max-width: none;
                min-height: 100%;
                margin: 0;
                padding: 0;
                background: $c-white;
            }
        }

        .mfp--import-overlay & {
            background: $c-import-background;
            max-width: 700px;
            padding: 100px 40px;

            @include breakpoint(phablet) {
                padding: 40px 20px;
                height: 100%;
            }
        }
    }

.mfp-fade {

    &.mfp-bg {
        opacity: 0;
        transition: all 0.15s ease-out;

        &.mfp-ready {
            opacity: 0.8;
        }

        &.mfp-removing {
            opacity: 0;
        }
    }

    &.mfp-wrap {

        .mfp-content {
            opacity: 0;
            transition: all 0.15s ease-out;
        }

        &.mfp-ready .mfp-content {
            opacity: 1;
        }
        &.mfp-removing .mfp-content {
            opacity: 0;
        }
    }
}

.mfp--popup-page {
    @extend .mfp-fade;

    &.mfp-bg {

        &.mfp-ready {
            opacity: 1;
        }
    }
}

.mfp-close {
    opacity: 1;

    &:active {
        top: 0;
    }

    .mfp--outside & {
        top: -44px;
        right: -44px;

        &:active {
            top: -44px;
        }

        svg {
            fill: $c-white;
            pointer-events: none;
        }
    }

    .mfp--filters &,
    .mfp--confirm & {

        @include breakpoint(tablet) {
            top: 7px;
            right: 13px;
        }

        &:active {

            @include breakpoint(tablet) {
                top: 7px;
            }
        }

        svg {

            @include breakpoint(tablet) {
                fill: $c-brand;
            }
        }
    }

    .mfp--scenes & {
        width: 60px;
        height: 60px;
        transform: translateY(-100%);

        @include breakpoint(mobile) {
            right: 0;
        }

        svg {
            fill: $c-white;
        }
    }

    .mfp--notice & {
        width: 60px;
        height: 60px;
        transform: translate(100%, -100%);

        @include breakpoint(tablet) {
            transform: translateY(-100%);
        }

        @include breakpoint(mobile) {
            right: 0;
        }

        svg {
            fill: $c-white;
        }
    }

    .mfp--key & {
        width: 68px;
        height: 68px;

        svg {
            fill: $c-brand;
        }
    }

    .mfp--popup-page & {
        position: fixed;
        top: 30px;
        right: 30px;
        width: 40px;
        height: 40px;
        line-height: 40px;

        @include breakpoint(tablet) {
            top: 20px;
            right: 10px;
        }

        &:hover {

            svg {
                fill: $c-brand;
            }
        }

        svg {
            fill: $c-black;
        }
    }

    svg {
        pointer-events: none;
    }

    .mfp--pdp-overlay &,
    .mfp--plp-overlay & {
        top: 16px;

        svg {
            fill: $c-white;
        }

        @include breakpoint(tablet) {
            top: 20px;
            right: 20px;
            width: auto;
        }

        @include breakpoint(mobile) {

            svg {
                fill: $c-black;
            }
        }
    }

    .mfp--pdp-overlay & {

        @include shortViewport {
            top: 20px;
            right: 20px;
        }

        svg {

            @include shortViewport {
                fill: $c-black;

                @include breakpoint(tablet) {
                    fill: $c-white;
                }

                @include breakpoint(mobile) {
                    fill: $c-black;
                }
            }
        }
    }

    .mfp--import-quantity & {

        @include breakpoint(tablet) {
            top: 10px;
        }

        svg {
            fill: $c-white;

            @include breakpoint(tablet) {
                fill: $c-brand;
            }
        }
    }

    .mfp--import-overlay & {

        svg {
            fill: $c-black;
        }
    }

    .mfp--pdp-configure-popup & {
        position: fixed;
        right: 715px;
        top: 15px;

        @include breakpoint(tablet) {
            right: 5px;
            top: 0px;
        }

        svg {
            fill: $c-white;

            @include breakpoint(tablet) {
                fill: $c-grey--dark;
            }
        }
    }
}

.mfp-arrow {
    position: fixed;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1046;

    @include breakpoint(tablet) {
        display: none;
    }

    &:active {
        margin: 0;
    }

    svg {
        fill: $c-black;
    }

    .mfp--popup-page & {
        &:hover {
            svg {
                fill: $c-brand;
            }
        }
    }
}

.mfp-arrow--next {
    right: 30px;
}

.mfp-arrow--previous {
    left: 30px;
}

.mfp--country-selection {
    // z-index battles with cookiepro
    z-index: 5000000000;
}

.mfp-sidebar {
    z-index: 1000000;
    top: 0 !important;

    .mfp-container {
        width: 100%;
        max-width: 720px;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
    }

    .mfp-content {
        height: 100%;
        padding: 0;
        background: #f3f3f3;
    }

    .mfp-close {
        border-radius: 50%;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 7px;
        right: 728px;
        left: auto;
        background: transparent;

        svg {
            width: 16px;
            height: 16px;
            fill: $c-white;
        }

        &:hover {
            background: $c-black;
        }
    }

    .pdp-configure-popup__action {
        max-width: 720px;
        width: 100%;

        button {
            background: $c-white;
        }
    }
}

.mfp-content {
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .modal-popup {
        background: #F4F4F4;
        max-width: 630px;
        padding: 45px 55px;
        margin: 0 auto;
        position: relative;
        border-radius: 3px;
        animation: .3s ease-out fadeIn;

        .field {
            margin-bottom: 10px;
        }

        .field--button {
            margin-top: 20px;

            span {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            em {
                font-style: normal;
            }

            svg {
                fill: $c-white;
            }

            button:after {
                display: none;
            }

            button:hover {
                svg {
                    fill: $c-black;
                }
            }
        }

        .field--disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        }

        .mfp-close {
            border-radius: 50%;
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -40px;
            right: -40px;
            left: auto;
            background: transparent;

            svg {
                width: 16px;
                height: 16px;
                fill: $c-white;
            }

            &:hover {
                background: $c-black;
            }
        }

        @include breakpoint(mobile) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 20px 15px;

            .mfp-close {
                top: 20px;
                right: 10px;

                svg {
                    filter: #{"invert()"};
                }
            }
        }
    }

    .modal-popup--busy {
        opacity: 0.5;
        pointer-events: none;
        cursor: wait;
    }

    .modal-popup__title {
        @include font(26px, 32px);
        margin-bottom: 21px;
    }
}
