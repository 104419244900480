.blocks .block.block--sus-commitments {
    margin: 20px 0;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-commitments {
    background: $c-block-background-linen;
    padding: 95px calc((1 / 12) * 100%) 110px;
    margin: 0 auto;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: calc(40% + 100px);
        background: #fff4ec;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include breakpoint(tablet) {
        padding: 55px 0;
        max-width: none;
    }

    .block__header {
        flex-grow: 0;
        position: sticky;
        top: 150px;
        padding-right: calc((1 / 6) * 100%);

        @include breakpoint(wrap) {
            padding-right: calc(((1 / 6) * 100%) + 40px);
        }

        @include breakpoint(tablet) {
            max-width: 700px;
            text-align: center;
            position: relative;
            padding: 0 10px;
            margin: 0 auto 40px;
            top: initial;
        }

        .body--admin & {
            top: 150px + 44px;

            @include breakpoint(tablet) {
                top: initial;
            }
        }
    }

        .block__title {
            @include font(54px, 62px);

            color: $c-black;
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                @include font(32px, 40px);
            }
        }

        .block__intro {
            @include font(24px, 34px);

            color: $c-black;

            @include breakpoint(tablet) {
                @include font(16px, 26px);
            }
        }

    .block__accordion {
        margin-bottom: 55px;
        z-index: 2;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }

        .block__item {

            &:not(:last-child) {
                margin-bottom: 25px;

                @include breakpoint(tablet) {
                    margin-bottom: 35px;
                }
            }
        }

            .block__subtitle {
                @include lightText;
                @include font(32px, 32px);

                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid $c-border-light;

                @include breakpoint(tablet) {
                    @include font(24px, 32px);
                }
            }

                .block__button {
                    @include regularText;

                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 100%;
                    background: none;
                    padding: 0;
                    color: $c-black;
                    cursor: default;

                    @include breakpoint(tablet) {
                        cursor: pointer;
                        align-items: center;
                    }

                    svg {
                        display: none;

                        @include breakpoint(tablet) {
                            display: block;
                            transition: transform 100ms linear;

                            
                        }
                    }
                }

                .block__button[aria-expanded="true"] {

                    svg {
                        transform: rotate(180deg);
                    }
                }

                    .block__illustration {
                        max-width: 80px;

                        img {
                            width: 100%;
                            height: auto;
                        }

                        @include breakpoint(tablet) {
                            display: none;
                        }
                    }

            .block__text {
                @include lightText;
                @include font(16px, 25px);

                color: $c-black;
            }

    .block__footer {
        z-index: 2;
    }

        .block__logos {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                justify-content: center;
            }

            @include breakpoint(mobile) {
                justify-content: space-between;
            }
        }

            .block__image {
                max-width: 90px;

                @include breakpoint(tablet) {
                    max-width: 75px;
                }
                
                &:not(:last-child) {
                    margin-right: 10px;
                }

                &::before,
                &::after {
                    display: none;
                }

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }

        .block__text--small {
            @include font(14px, 19px);

            @include breakpoint(tablet) {
                text-align: center;
            }

            a {
                @include regularText;

                color: $c-black;
                text-decoration: underline;
            }
        }
}
