.notification {
    background-color: $c-brand;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 3px;

    p {
        color: $c-white;
        @include font(16px, 22px);
    }
}
