.journal-listing {
    color: $c-black;

    @include breakpoint(tablet) {
        padding-bottom: 10px;
    }
}

    .journal-listing__link {
        display: block;
        text-decoration: none;
        color: inherit;

        .journal-listing--small & {

            @include breakpoint(tablet) {
                display: flex;
            }
        }
    }

        .journal-listing__image {
            @include aspect(4 / 3);

            margin-bottom: 36px;
            background: $c-border;

            @include breakpoint(tablet) {
                margin-bottom: 28px;
            }

            .journal-listing--large & {
                @include aspect(3 / 4);
            }

            .journal-listing--small & {

                @include breakpoint(tablet) {
                    flex: 0 0 auto;
                    width: 120px;
                    margin-right: 20px;
                    margin-bottom: 0;
                }
            }

            img {
                width: 100%;
                height: auto;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .journal-listing__link:hover & {
                    transform: scale(1.2);
                }
            }
        }

        .journal-listing__content {

            .journal-listing--small & {

                @include breakpoint(tablet) {
                    flex: 1 1 auto;
                }
            }
        }

            .journal-listing__subtitle {
                @include font(12px, 15px);
                @include lightText;

                margin-bottom: 10px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }

            .journal-listing__title {
                @include font(20px, 28px);

                transition: color .3s ease;

                .journal-listing--small & {

                    @include breakpoint(tablet) {
                        @include font(16px, 24px);
                    }
                }

                .journal-listing__link:hover & {
                    color: $c-brand;

                    @include breakpoint(tablet) {
                        color: inherit;
                    }
                }
            }
