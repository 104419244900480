.product-apps {
    padding: 50px;
    border-top: 1px solid $c-grey--button;

    @include breakpoint(tablet) {
        padding: 40px 20px;
    }
}

    .product-apps__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
    }

        .product-apps__image {
            margin-right: 20px;
        }

        .product-apps__title {
            @include font(20px, 30px);

            color: $c-black;

            @include breakpoint(tablet) {
                @include font(16px, 24px);
            }

            span {
                @include lightText;
            }
        }

    .product-apps__items {
        display: flex;
        justify-content: center;
        margin: -10px auto;
    }

        .product-apps__link {
            margin: 10px;
        }
