.blocks .block.block--sus-lifecycle {
    margin: 0 auto;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-lifecycle {
    padding: 105px 0 85px;
    max-width: calc((5 / 6) * 100%);

    @include breakpoint(tablet) {
        padding: 50px 0 40px;
        max-width: none;
    }

    .block__title {
        @include font(54px, 62px);

        color: $c-black;
        margin-bottom: 30px;
        text-align: center;

        @include breakpoint(tablet) {
            @include font(32px, 40px);
        }
    }

    .block__banner {
        @include aspect(3 / 1);

        margin-bottom: 55px;

        @include breakpoint(tablet) {
            @include aspect(2 / 1);

            margin-bottom: 20px;
        }

        img {
            position: relative;
            width: 100%;
            height: auto;
        }
    }

    .block__text {
        @include lightText;
        @include font(16px, 24px);

        color: $c-black;
    }

    .block__text--intro {
        max-width: 60%;
        text-align: center;
        margin: 0 auto 45px;

        @include breakpoint(phablet) {
            max-width: none;
            text-align: left;
            margin-bottom: 80px;
        }
    }

    .block__mobile-carousel {
        display: flex;
        justify-content: space-between;

        @include breakpoint(phablet) {
            margin-right: -15px;
        }
    }

        .block__item {
            display: flex !important; //override inline slick styles
            flex-direction: column;
            flex: 1 1 200px;

            &:not(:last-child) {
                margin-right: 30px;
            }

            @include breakpoint(tablet) {
                flex-basis: 0;
            }

            @include breakpoint(phablet) {
                margin: 0;
            }
        }

            .block__title--number {
                margin-bottom: 10px;
                text-align: left;
            }

            .block__image {
                @include aspect(1);

                margin-bottom: 20px;

                @include breakpoint(phablet) {
                    order: -1;
                }

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }
}
