.page-intro {
    padding: 45px 0;
    color: $c-black;
    background: $c-white;
    text-align: center;
    overflow: hidden;

    @include breakpoint(tablet) {
        padding: 30px 0;
    }

    &--flush-bottom {
        padding-bottom: 0;
    }
}

.page-intro--border {
    border-bottom: 1px solid $c-grey--button;
}

.page-intro--tall {
    padding: 65px 0;

    @include breakpoint(tablet) {
        padding: 30px 0;
    }
}

.page-intro--tight {
    padding: 25px 0;
}

.page-intro--has-blocks {
    padding-bottom: 60px;

    @include breakpoint(tablet) {
        padding-top: 60px;
    }
}

.page-intro--about {
    .wrap {
        padding-top: 50px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    @include breakpoint(tablet) {
        padding: 30px 0;
    }
}

.page-intro--content-before {
    padding-top: 15px;

    @include breakpoint(tablet) {
        padding-top: 30px;
    }
}

.page-intro--relative {
    .wrap {
        position: relative;
    }
}

    .page-intro__button {
        position: absolute;
        top: 50%;
        right: 80px;
        transform: translateY(-50%);

        @include breakpoint(tablet) {
            margin: 25px 0 0;
            position: relative;
            transform: translate(0, 0);
            right: initial;
            top: initial;
        }
    }

    .page-intro__title {
        @include font(40px, 62px);

        margin-bottom: 10px;

        @include breakpoint(tablet) {
            @include font(32px, 40px);
        }

        &:last-child {
            margin-bottom: 0;
        }

        .page-intro--media-library &,
        .page-intro--journal-article & {
            @include font(40px, 62px);

            @include breakpoint(tablet) {
                @include font(32px, 40px);
            }
        }
    }

    .page-intro__subtitle {
        @include lightText;
        @include font(16px, 19px);

        color: $c-black;
        margin-bottom: 13px;
        letter-spacing: 3px;
        text-transform: uppercase;

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            letter-spacing: 3px;
        }

        .page-intro--journal-article & {
            @include font(16px, 19px);

            margin-bottom: 13px;
            letter-spacing: 3px;
        }
    }

    .page-intro__text {
        max-width: 870px;
        margin: 0 auto;

        .page-intro--search & {
            @include font(24px, 46px);
            @include lightText;

            color: $c-detail-subtitle;

            span {
                @include regularText;
                color: $c-black;
            }

            @include breakpoint(tablet) {
                @include font(18px, 30px)
            }
        }

        .page-intro--docs & {
            color: $c-detail-subtitle;
        }
    }

    .page-intro__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
    }

        .page-intro__journal-subheader {
            @include font(12px, 15px);

            margin-left: 15px;
            color: $c-black;
            letter-spacing: 2px;
            text-transform: uppercase;

            span {
                @include lightText;
            }

            @include breakpoint(tablet) {
                @include font(12px, 20px);

                text-align: left;
            }
        }

        .page-intro__aviwrap {
            @include aspect(1);

            flex: 0 0 auto;
            border-radius: 50%;
            border: solid 1px $c-swatch-border;

            img {
                border-radius: 50%;
                border: 2px solid $c-white;
                padding: 1px;
                height: 50px;
            }
        }
