.journal-features {
    margin-bottom: 30px;

    @include breakpoint(tablet) {
        margin-bottom: 0;
    }
}

    .journal-features__feature {
        padding-right: 60px;

        @include breakpoint(tablet) {
            padding-right: 0;
        }
    }

    .journal-features__content {

        @include breakpoint(tablet) {
            padding: 40px 20px 0;
        }
    }

        .journal-features__title {
            @include font(18px, 22px);
            @include lightText;

            margin-bottom: 35px;
            padding-top: 16px;
            color: $c-black;
            border-top: 1px solid $c-border-light;
            text-transform: uppercase;
            letter-spacing: 4px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                margin-bottom: 25px;
                letter-spacing: 3px;
            }
        }
