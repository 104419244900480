$quantity-control-width: 30px;
$quantity-control-height: 50px;

.quantity-control {
    position: relative;
    display: flex;
    justify-content: flex-start;

    &:not(.quantity-control--label):not(.quantity-control--label-inline) {

        label {
            @include hideSafely;
        }
    }

    label {
        flex: 0 0 100%;
    }

    input {
        flex: 0 0 100px;
        min-width: 0;
        height: $quantity-control-height;
        padding: 0;
        text-align: center;
        border: 1px solid $c-border;
        border-radius: 0;

        &:focus {
            border-color: $c-border;
        }
    }
}

.quantity-control--label {
    flex-wrap: wrap;
}

.quantity-control--label-inline {
    justify-content: flex-start;
    align-items: center;

    label {
        flex: 0 1 auto;
        margin-right: 20px;
    }
}

.quantity-control--flex {

    input {
        flex: 1 1 auto;
    }
}

.quantity-control--shrink {

    input {
        flex-shrink: 1;
    }
}

.quantity-control--grow {

    input {
        flex-grow: 1;
    }
}

.quantity-control--fixed {

    input {
        max-width: 40px;
    }

    button {
        flex: 0 0 auto;
    }
}

.quantity-control--center {
    justify-content: center;
}

    .quantity-control__button {
        position: relative;
        width: $quantity-control-width;
        height: $quantity-control-height;
        background: $c-white;
        border: 1px solid $c-border;
        cursor: pointer;
        user-select: none;
        transition: color .3s;

        &:hover {
            color: $c-black;
        }

        &:focus {
            outline: none;
        }

        &:active {
            background: $c-brand;

            svg {
                fill: $c-white;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            fill: $c-black;
            transform: translate(-50%, -50%);
        }
    }

    .quantity-control__button--up {
        border-left: none;
        border-radius: 0 3px 3px 0;
    }

    .quantity-control__button--down {
        border-right: none;
        border-radius: 3px 0 0 3px;
    }
