.nav-feature {
    @include lightondark;

    display: block;
}

.nav-feature--tight {
    padding: 0;
}

    .nav-feature__image {
        @include aspect(4 / 3);

        img {
            width: 100%;
            height: auto;
            transition: transform .5s cubic-bezier(.36,1.05,.54,1);

            .nav-feature:hover & {
                transform: scale(1.075);
            }
        }  
    }
