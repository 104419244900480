.category-preview {}

    .category-preview__link {
        display: block;
        text-decoration: none;
        color: inherit;
    }

        .category-preview__image {
            @include aspect(315 / 375);

            margin-bottom: 15px;

            img {
                width: 100%;
                height: auto;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .category-preview__link:hover & {
                    transform: scale(1.2);

                    @include breakpoint(tablet) {
                        transform: none;
                    }
                }
            }
        }

        .category-preview__title {
            @include font(18px, 32px);
            @include border-line-grow(var(--block-text-color));

            display: inline-block;
            color: var(--block-text-color, $c-black);
            transition: color .3s ease;
            padding-bottom: 5px;

            @include breakpoint(tablet) {
                @include font(16px, 30px);
            }

            .category-preview__link:hover & {
                @include border-line-trigger;
            }
        }
