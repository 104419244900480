.project-callout {
    @include lightondark;

    position: relative;
    overflow: hidden;
}

    .project-callout__link {
        display: block;
        text-decoration: none;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.3;
        }
    }

        .project-callout__image {
            @include aspect(2 / 1);

            img {
                @include cover;

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .project-callout__link:hover & {
                    transform: scale(1.2);
                }
            }
        }

        .project-callout__content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 25px 40px;
            z-index: 1;

            @include breakpoint(tablet) {
                padding: 15px;
            }
        }

        .project-callout__title {
            @include font(26px, 34px);

            color: $c-white;

            @include breakpoint(tablet) {
                @include font(18px, 24px);
            }

            span {
                @include thinText;
            }
        }
