.bg-icon {
    position: relative;
    overflow: hidden;
}

    .bg-icon--hidden {
        .bg-icon__icon svg {
            display: none;
        }
    }

    .bg-icon__icon {
        position: absolute;
        top: 30px;
        left: 50%;
        margin-left: calc(600px - 390px);

        @media (max-width: 1333px) {
            margin-left: calc(45vw - 390px);
        }

        svg {
            width: 779px;
            height: 779px;
            fill: $c-footer-bg;
            opacity: 0.4;
            transition: opacity 300ms;

            @include breakpoint(tablet) {
                display: none;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }
