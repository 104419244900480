.cta-banner {
    padding: 80px 0;
    text-align: center;

    @include breakpoint(tablet) {
        padding: 40px 0;
    }
}

    .cta-banner__title {
        @include font(32px, 46px);
        @include thinText;

        margin-bottom: 20px;
        color: $c-cta-banner-title;

        @include breakpoint(tablet) {
            @include font(24px, 32px);
            @include lightText;
        }
    }

    .cta-banner__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -13px;

        @include breakpoint(phablet) {
            margin: -5px 0;
        }

        .button {
            margin: 13px;

            @include breakpoint(phablet) {
                flex: 0 0 100%;
                margin: 5px 0;
            }
        }
    }
