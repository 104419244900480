.import-group {
	margin-bottom: 40px;

	&:last-child {
		margin-bottom: 0;
	}

	@include breakpoint(tablet) {
		margin-bottom: 18px;
	}
}
	
	.import-group__header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			width: 35px;
			height: 35px;

			.import-group--available & {
				fill: $c-import-available;
			}

			.import-group--not-available & {
				fill: $c-import-not-available;
			}

			.import-group--action-required & {
				fill: $c-import-action-required;
			}
		}
	}

		.import-group__title {
			@include apply-map($f-title);
			@include font(32px, 62px);

			color: $c-black;

			@include breakpoint(tablet) {
				@include font(26px, 62px);
			}

			span {
				@include lightText;
			}
		}
