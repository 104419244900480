// Accessory listings

.accessory-listing .grid {
    margin-bottom: -30px;
}

.accessory-listing__group__title {
    @include rem(25px);
    padding-top: 20px;
    margin-bottom: 30px;
}

.accessory-listing__group__more {
    margin-top: 30px;
}

.accessory-listing__group__more--null {
    color: $grey2;
}
