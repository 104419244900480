.block--products {
    --richtext-color: var(--block-text-color, $c-black);
    background: var(--block-background, transparent);
    overflow: hidden;

    .block__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        color: var(--block-text-color, $c-black);

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }
    }

        .block__title {
            @include font(26px, 38px);
            color: inherit;
            font-family: var(--block-title-font-family, #{$f-primary-stack});

            @include breakpoint(tablet) {
                @include font(22px, 28px);
            }
        }

    .block__carousel {
        margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);
        margin-right: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);

        @include breakpoint(wrap) {
            margin-left: calc(#{$site-gutter} * -1);
            margin-right: calc(#{$site-gutter} * -1);
        }

        @include breakpoint(tablet) {
            margin-left: calc(#{$site-gutter--mobile} * -1);
            margin-right: calc(#{$site-gutter--mobile} * -1);
            overflow: hidden;
        }
    }

        .block__carousel-item { // To be removed when hooked up with products
            @include aspect(1);
            @include font(16px, 20px);

            // need to override the grid width on product card which has no specific class
            > div {
                width: 100%;
            }
            .product-listing-item {
                margin-bottom: 0;
            }
        }

}

.block--products.block--padded:not(.block--background-white) {
    padding: 100px 0;

    @include breakpoint(tablet) {
        padding: 40px 0;
    }
}
