.docs-search {
    input.input {
        border-right: 0;
        border-radius: 3px 0 0 3px;
    }

    .button {
        border-radius: 0 3px 3px 0;
    }
}

.docs-search__results-summary {
    @include f-light;
    @include font(14px, 17px);
    padding: 30px 0 20px;
}

.docs-search__results {
    margin-bottom: 30px;
}

    .docs-search__results-item {
        @include font(16px, 28px);
        color: $c-black;
        border-bottom: 1px solid $c-border;
        padding: 10px 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
