// Annual events

.annual-events .grid {
    margin: 0 -15px;
}

.annual-events__event {
    box-sizing: border-box;
    border-right: 1px solid $c-section-border;
    padding: 15px;
    margin: 0;

    &:nth-child(4) {
        border-right: none;
    }

    @include breakpoint(tablet) {
        &:nth-child(2) {
            border-right: none;
        }
    }

    @include breakpoint(mobile) {
        border-right: none;
    }
}

// Exhibitions

.exhibitions {}

.exhibitions__exhibition {

    .grid {

        > * {
            border-right: 1px solid $c-section-border;
            padding: 30px;
            margin: 0;

            &:nth-child(3) {
                border-right: none;
            }
        }
    }

    img {
        width: auto;
        max-width: 100%;
        height: auto;
    }

    .exhibitions__exhibition__title {
        @include copy;
        @include f-regular;
        text-transform: uppercase;
    }

    .exhibitions__exhibition__text {
        @include copy;

        .link {
            @include f-regular;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include breakpoint(tablet) {
        .grid {
            padding: 15px 0;

            .grid__col {
                border-right: none;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }
}
