.field.adaptive-placeholder {
    position: relative;

    label {
        @include font(14px, 17px);
        @include lightText;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 0;
        padding: 14px 15px 0;
        color: $c-label--light;
        transition: all .3s;
        z-index: 1;
    }

    &.field-error {

        label {
            color: $c-error;
        }
    }

    label ~ input.input {
        padding-top: 20px;
    }

    label ~ textarea.input {
        padding-top: 20px;
    }

    .select-replace__value,
    .multi-select__value {
        padding-top: 20px;
    }

    &[disabled] {

        label {
            opacity: 0.3;
        }
    }
}

.field.adaptive-placeholder--active {

    label {
        @include font(11px, 20px);

        top: 3px;
        right: auto;
        bottom: auto;
        left: 15px;
        padding: 0;
        transform: none;
    }

    &.field-error {

        label {
            color: $c-grey--mid-text;
        }
    }
}
