.block--table {

    .wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .block__content {
        text-align: center;
        color: $c-black;
        max-width: 900px;

        @include breakpoint(tablet) {
            text-align: left;
        }
    }

        .block__title {
            @include font(54px, 62px);

            margin-bottom: 15px;

            @include breakpoint(tablet) {
                @include font(32px, 62px);

                margin-bottom: 5px;
            }
        }

    .block__table-container {
        width: 100%;
        overflow: auto;

        &--show {
            .block__table {
                position: relative;
                opacity: 1;
                bottom: unset;
            }
        }
    }

        .block__table {
            overflow-x: auto;
            min-width: 1100px;
            position: absolute;
            bottom: 0;
            opacity: 0;
            transition: opacity 1s ease-in-out;

            th,
            td {
                @include font(16px, 24px);
                padding: 20px 18px;
                border: 1px solid $c-border;
                color: $c-black;
                width: 13%;

                &.product-name {
                    width: 35%;
                }

                &.block__table--no-border {
                    border: none;
                }
            }

            th {
                text-transform: none;
                font-weight: 400;

                &.block__table--header-group {
                    text-align: center;
                    text-transform: uppercase;
                }

                &.block__table--header-highlight {
                    background-color: $c-block-background-table-heading;
                }
            }

            td {
                font-weight: 300;

                a {
                    font-weight: 400;
                    text-decoration: underline;

                    &:hover {
                        color: $c-brand;
                    }
                }
            }
        }
}
