.header-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 $site-gutter;
    max-width: $site-max-width;
    margin: 0 auto;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0;
    }

    svg {
        pointer-events: none;
    }
}

    .header-nav__list {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 45px;

        @include breakpoint(product-gallery) {
            gap: 28px;
        }

        @include breakpoint(tablet) {
            display: block;
            background: $c-white;
            border-radius: 3px;
        }
    }

        .header-nav__lower {
            @include breakpoint(tablet) {
                padding: 16px $site-gutter--mobile;
                flex-wrap: wrap;
                border-bottom: 1px solid $c-border-light;

                .header[active-item] & {
                    display: none !important;
                }
            }
        }

        .header-nav__item {
            @include breakpoint(tablet) {
                margin: 0;
                border-bottom: 1px solid $c-border-light;

                .header[active-item] &,
                .header-nav__lower-item & {
                    border-bottom: none;
                }
            }
        }

        .header-nav__item--active {
            @include breakpoint(tablet) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                background-color: $c-white;
                z-index: 5;
            }
        }

            .header-nav__link {
                @include font(16px, 16px);

                position: relative;
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 8px 0;
                color: inherit;
                background: transparent;
                transition: color 200ms ease;
                @include border-line-grow($c-black, false);

                .header-nav__item--active & {
                    @include border-line-trigger;
                }

                @include breakpoint(tablet) {
                    @include font(18px, 18px);
                    @include regularText;

                    display: flex;
                    padding: 20px $site-gutter--mobile;
                    justify-content: space-between;
                    align-items: center;
                    transition: none;

                    &::before,
                    &::after {
                        content: none;
                    }

                    .header-nav__item--active & {
                        position: fixed;
                        top: $header-top-height--mobile;
                        left: 0;
                        right: 0;
                        background-color: $c-white;
                        justify-content: center;
                        z-index: 1;
                        border-bottom: 1px solid $c-border-light;
                    }
                }

                &:hover {
                    @include border-line-trigger;

                    @include breakpoint(tablet) {
                        color: inherit;
                    }
                }

                svg {
                    fill: currentColor;
                    width: 11px;
                    margin-top: 2px;

                    @media (hover: none) and (pointer: fine) {
                        transition: transform 150ms ease-in;
                    }

                    .header-nav__item--active & {
                        transform: rotate(180deg);
                    }

                    @include breakpoint(tablet) {
                        transform: rotate(270deg);

                        .header-nav__item--active & {
                            @include breakpoint(tablet) {
                                position: absolute;
                                top: calc(50% - 1px);
                                left: $site-gutter--mobile;
                                transform: translateY(-50%) rotate(90deg);
                            }
                        }
                    }
                }
            }

            .header-nav__link--has-icon {
                svg {
                    display: block;
                    margin-left: 8px;
                    fill: currentColor;

                    &.icon-search {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            .header-nav__content {
                display: none;

                .header-nav__item--activating & {
                    display: block;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    left: 0;
                    background: $c-white;
                    border-top: 1px solid $c-border-light;
                    overflow: hidden;
                    z-index: 5;
                    opacity: 0;
                    transition: opacity 150ms ease;
                    padding: 0 $site-gutter;
                    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2);

                    @include breakpoint(tablet) {
                        border: none;
                        position: static;
                        top: auto;
                        border-bottom: none;
                        transition: none;
                        padding: 0;
                        box-shadow: none;
                        padding-top: 83px;
                    }
                }

                .header-nav__item--active & {
                    opacity: 1;
                }
            }

                .header-nav__container {
                    height: 100%;
                    padding: 30px 0 20px;
                    display: grid;
                    column-gap: 20px;
                    grid-template-columns: repeat(12, minmax(0, 1fr));

                    @include breakpoint(tablet) {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        max-width: 100%;
                        gap: 0;
                    }
                }

                .header-nav__container--product {
                    padding-bottom: 40px;

                    @include breakpoint(tablet) {
                        padding: 0;
                    }
                }

                    .header-nav__title {
                        @include font(16px, 16px);

                        margin-bottom: 16px;
                        margin-right: 40px;
                        opacity: 0;
                        transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 1250ms;

                        @include breakpoint(tablet) {
                            transition: opacity .3s ease;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin: 0;
                            padding: 0 $site-gutter--mobile 10px;
                            cursor: pointer;
                            transition: none;
                        }

                        a {
                            color: $c-black;
                        }

                        .header-nav__item--active & {
                            opacity: 1;
                        }
                    }

                        .header-nav__sub-container {
                            grid-column: span 2;
                            grid-row: 1 / 2;

                            @include breakpoint(tablet) {
                                grid-column: span 1;
                                position: relative;
                                padding: 20px 0;

                                &:first-child {
                                    padding-top: 0;
                                }

                                &:not(:last-child) {
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        height: 1px;
                                        background-color: $c-border-light;
                                        left: $site-gutter--mobile;
                                        right: $site-gutter--mobile;
                                    }
                                }

                                &:has(.nav-callout) {
                                    padding: 0;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        @for $i from 1 through 12 {
                            .header-nav__sub-container--span-#{$i} {
                                grid-column: span #{$i};
                            }
                        }

                        .header-nav__sub-container--callouts {
                            display: flex;
                            flex-wrap: wrap;
                            column-gap: 20px;

                            @include breakpoint(tablet) {
                                display: block;
                            }

                            .header-nav__title {
                                flex-basis: 100%;
                            }

                            .nav-callout {
                                flex: 1;
                            }
                        }

                        .header-nav__sub-container--collections {
                            display: grid;
                            grid-template-columns: repeat(2, minmax(0, 1fr));
                            gap: 20px;
                            grid-row: 1 / 4;
                            align-content: flex-start;

                            @include breakpoint(tablet) {
                                display: block;
                            }

                            .nav-collection-header {
                                grid-column: 1 / -1;
                                margin-bottom: -8px;

                                @include breakpoint(tablet) {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        .header-nav__sub-container--second-row {
                            grid-row: 2 / 3;
                        }

                        .header-nav__sub-container--hide-on-mobile {
                            @include breakpoint(tablet) {
                                display: none;
                            }
                        }

                        .header-nav__sub-container--2-rows {
                            grid-row: 1 / 3;
                        }

                        .header-nav__sub-view-all {
                            text-align: center;
                            padding-top: 20px;
                            grid-column: 1 / -1;
                            border-top: 1px solid $c-border-light;
                            margin-top: 20px;

                            @include breakpoint(tablet) {
                                text-align: left;
                                margin: 0;
                                border: none;
                                padding-top: 0;
                            }

                            a {
                                @include font(14px, 22px);
                                @include border-line-grow($c-black, false);

                                display: inline-flex;
                                gap: 8px;
                                align-items: center;

                                @include breakpoint(tablet) {
                                    @include font(16px, 24px);
                                
                                    padding: 6px $site-gutter--mobile;
                                }

                                &:hover {
                                    @include border-line-trigger;
                                }
                            }

                            svg {
                                margin-top: 2px;
                            }
                        }

                        .header-nav__sub-list {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            opacity: 0;
                            transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 1250ms;

                            @include breakpoint(tablet) {
                                display: block;
                                transition: none;
                            }

                            .header-nav__item--active & {
                                opacity: 1;
                            }
                        }

                        .header-nav__sub-list--split {
                            display: block;
                            column-count: 2;
                            column-gap: 20px;

                            .header-nav__sub-item:not(:last-child) {
                                margin-bottom: 10px;
                            }

                            @include breakpoint(tablet) {
                                column-count: 1;
                            }
                        }

                            .header-nav__sub-item {
                                display: flex;
                            }

                                .header-nav__sub-link {
                                    @include font(14px, 20px);
                                    @include lightText;

                                    display: block;
                                    transition: font-weight 200ms ease-in;
                                    color: $c-black;

                                    @include breakpoint(tablet) {
                                        @include font(16px, 22px);

                                        padding: 6px $site-gutter--mobile;
                                        transition: none;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                .header-nav__sub-link--all {
                                    @include regularText;
                                    @include border-line-grow($c-black, false);

                                    color: $c-black;
                                    display: inline-flex;
                                    align-items: center;
                                    gap: 8px;

                                    @include breakpoint(tablet) {
                                        padding-left: $site-gutter--mobile;
                                    }

                                    &:hover {
                                        @include border-line-trigger;

                                        text-decoration: none;
                                    }
                                }

                                .header-nav__sub-header {
                                    @include regularText;
                                    @include font(18px, 22px);

                                    margin-bottom: 5px;
                                    transition: color .3s ease;

                                    @include breakpoint(tablet) {
                                        @include font(15px, 26px);

                                        margin: 0;
                                    }

                                    .header-nav__sub-link:hover & {
                                        color: $c-brand;
                                    }
                                }

                                .header-nav__sub-text {
                                    color: $c-grey--mid;
                                }
