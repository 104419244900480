.bundle-items {
    display: none;
}

.bundle-items--visible {
    display: block;
    max-width: 645px;
    margin: 50px auto;

    @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 30px);
        margin: 0;
    }
}

    .bundle-items__head {
        @include lightondark;

        position: relative;
        padding: 15px 30px;
        color: $c-white;
        background: $c-brand;
    }

        .bundle-items__title {
            @include rem(32px);

            line-height: calc(53 / 32);

            @include breakpoint(mobile) {
                @include rem(24px);

                line-height: calc(29 / 24);
            }
        }

        .bundle-items__close {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 25px;
            height: 25px;
            transform: translate(100%, -100%);
            cursor: pointer;

            @include breakpoint(mobile) {
                top: 50%;
                right: 12px;
                transform: translate(0, -50%);
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                fill: $c-white;
                transform: translate(-50%, -50%);
            }
        }

    .bundle-items__body {
        flex: 1 1 auto;
        background: $c-white;
    }

        .bundle-items__folder {
            padding: 25px 30px;
            background: $background-grey;
            font-size: 0;

            @include breakpoint(mobile) {
                padding: 15px;
            }
        }

        .bundle-items__item {
            padding: 20px 30px;
            font-size: 0;
            border-bottom: 1px solid $background-grey;

            @include breakpoint(mobile) {
                padding: 15px;
            }

            &:last-child {
                border-bottom: none;
            }
        }

            .bundle-items__thumbnail {
                @include aspect(1);

                display: inline-block;
                vertical-align: middle;
                background: red;
                width: 67px;

                @include breakpoint(mobile) {
                    width: 60px;
                }

                @include breakpoint(tablet) {
                    margin-bottom: 15px;
                }
            }

            .bundle-items__content {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 200px - 15px);
                margin-right: 15px;

                @include breakpoint(tablet) {
                    width: 100%;
                    margin: 0 0 15px;
                }

                &.bundle-items__content--has-image {
                    width: calc(100% - 67px - 200px - 30px);
                    margin-left: 15px;

                    @include breakpoint(tablet) {
                        width: calc(100% - 67px - 15px);
                        margin: 0 0 15px 15px;
                    }

                    @include breakpoint(mobile) {
                        width: calc(100% - 60px - 15px);
                    }
                }

                .bundle-items__folder & {
                    width: calc(100% - 240px - 15px);

                    @include breakpoint(tablet) {
                        width: 100%;
                    }

                    &.bundle-items__content--added {
                        width: calc(100% - 200px - 15px);

                        @include breakpoint(tablet) {
                            width: 100%;
                        }
                    }

                    &.bundle-items__content--has-image {
                        width: calc(100% - 67px - 240px - 30px);

                        @include breakpoint(tablet) {
                            width: calc(100% - 67px - 15px);
                        }

                        @include breakpoint(mobile) {
                            width: calc(100% - 60px - 15px);
                        }

                        &.bundle-items__content--added {
                            width: calc(100% - 67px - 200px - 30px);

                            @include breakpoint(tablet) {
                                width: calc(100% - 67px - 15px);
                            }

                            @include breakpoint(mobile) {
                                width: calc(100% - 60px - 15px);
                            }
                        }
                    }
                }
            }

                .bundle-items__name {
                    @include rem(16px);

                    line-height: calc(19 / 16);

                    @include breakpoint(mobile) {
                        @include rem(14px);

                        line-height: calc(17 / 14);
                    }

                    .bundle-items__folder & {
                        @include rem(26px);

                        line-height: calc(34 / 26);

                        @include breakpoint(mobile) {
                            @include rem(20px);

                            line-height: calc(34 / 20);
                        }
                    }
                }

                .bundle-items__type {
                    @include f-light;
                    @include rem(14px);

                    line-height: calc(24 / 14);
                    text-transform: capitalize;
                    color: $footer-grey;

                    @include breakpoint(mobile) {
                        @include rem(12px);

                        line-height: calc(24 / 12);
                    }
                }

            .bundle-items__action {
                display: inline-block;
                vertical-align: middle;
                width: 200px;

                @include breakpoint(tablet) {
                    width: 100%;
                }

                .bundle-items__folder & {
                    width: 240px;

                    &.bundle-items__action--added {
                        width: 200px;

                        @include breakpoint(tablet) {
                            width: 100%;
                        }
                    }

                    @include breakpoint(tablet) {
                        width: 100%;
                    }
                }
            }
