.section-nav--showcase {
    @include breakpoint(mobile) {
        padding-top: 15px;
        padding-bottom: 15px;

        .section-nav__link {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.showcase-product {
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    background-size: cover;

    &.section--highlight .copy {
        color: $c-white;
    }
}

    .showcase-product--sketch {
        background: url(/images/sketch.jpg) center center;
    }

    .showcase-product--borderless {
        border-top: none;
        border-bottom: none;
    }

    .showcase-product__image {

        @include breakpoint(tablet) {
            margin: 0 auto;
            padding: 0;
            margin-bottom: 20px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    .showcase-product__description {
        max-width: 550px;
        line-height: 28px;
    }

    @include breakpoint(tablet) {
        .showcase-product__details {
            padding-top: 20px;
            text-align: center;
        }

        .showcase-product__description {
            max-width: none;
        }
    }

.showcase-title {
    font-weight: 500;
    @include rem(52px);
    margin-bottom: 40px;
    line-height: 1*64/52;

    @include breakpoint(tablet) {
        @include rem(40px);
        line-height: 1*64/40;
        margin-bottom: 20px;
    }

    @include breakpoint(mobile) {
        @include rem(30px);
        margin-bottom: 10px;
    }
}

.showcase-body {
    font-weight: normal;
    line-height: 28px;
}

.showcase-gallery {
    text-align: center;
}

    .showcase-gallery__container {
        display: table;
    }

    .showcase-gallery__content {
        display: table-header-group;

        @include breakpoint(mobile) {
            display: table-footer-group;
        }
    }

    .showcase-gallery__gallery {
        display: table-footer-group;

        @include breakpoint(mobile) {
            display: table-header-group;
            .gallery {
                margin-bottom: 30px;
            }
            .slider__nav {
                margin-top: 30px;
            }
        }
    }

    .showcase-gallery__body {
        max-width: 700px;
        margin: 0 auto 50px;

        @include breakpoint(mobile) {
            margin-bottom: 0;
        }
    }

    .showcase-gallery__slider-nav__item {
        margin-right: 20px;
        cursor: pointer;
        text-decoration: underline;
        text-transform: none;

        &:hover {
            color: $c-black;
        }

        @include breakpoint(tablet) {
            line-height: 30px;
        }
    }

    .showcase-gallery__slider-nav__item.slider__nav__item--active {
        text-decoration: none;
    }

.showcase-rotator {
    width: 100%;
    height: auto;
    max-width: 600px;

    @include breakpoint(tablet) {
        margin: 0 auto;
    }

    img {
        width: 100%;
        height: auto;
    }
}

    .showcase-rotator__spin-controls {
        display: block;
        margin: 10px 0;

        a {
            padding: 10px;
            @include rem(25px);
        }
    }

.showcase-video {
    padding-top: 125px;
    padding-bottom: 125px;

    @include breakpoint(tablet) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

    .showcase-video__video {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .showcase-video__title {
        @include rem(40px);
        line-height: 50px;
        padding-left: 30px;

        @include breakpoint(tablet) {
            padding-left: 0;
            text-align: center;
            @include rem(30px);
            line-height: 40px;
            margin-bottom: 20px;
        }
    }

    .showcase-video__quote {
        @include rem(40px);
        line-height: 50px;
        padding-left: 30px;

        &:before,
        &:after {
            content: '"';
        }

        @include breakpoint(tablet) {
            padding-left: 0;
            text-align: center;
            @include rem(30px);
            line-height: 40px;
        }
    }

    .showcase-video__quote--small {
        @include rem(18px);
        line-height: 26px;

        @include breakpoint(tablet) {
            @include rem(16px);
            line-height: 22px;
        }

        .grid--reverse & {
            padding-right: 20px;

            @include breakpoint(tablet) {
                padding-right: 0;
            }
        }
    }

    .showcase-video__cite {
        font-weight: bold;
        @include rem(14px);
        display: block;
        line-height: 20px;
        padding-top: 20px;
        padding-left: 30px;

        @include breakpoint(tablet) {
            padding-left: 0;
            text-align: center;
            padding-top: 0;
        }
    }

    .showcase-video__cite-position {
        font-weight: normal;
        display: block;
    }

    .detail__key-points {
        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }

    .showcase-interested__or-divider-wrap {
        position: relative;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }

    .showcase-interested__or-divider {
        @include rem(16px);
        position: absolute;
        right: -20px;
        top: 50%;
        margin-top: -8px;
        margin-right: 10px;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .showcase-interested__section {
    }


        .showcase-interested__section--left {
            padding-right: 100px;
        }

        .showcase-interested__section--right {
            padding-left: 100px;
        }

        .showcase-interested__section--right,
        .showcase-interested__section--left {
            @include breakpoint(tablet) {
                padding: 0;
            }
        }

.showcase-technical {
}

.showcase-technical__view-button {
    margin: 30px 0 0;

    @include breakpoint(tablet) {
        margin: 30px 0 60px;
    }
}

.showcase-also-available {}

    .showcase-also-available__title {
        @include rem(28px);
        line-height: calc(40 / 28);
    }


.showcase-gallery {
    .slider--fade {
        .slider__inner {
            &:before {
                padding-top: (600 / 1200) * 100%;
            }
        }
    }
}

.hero__bannergroup--csr {
    bottom: 100px;
}
