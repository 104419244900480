.media-filter__field {
    padding: 18px 30px 25px;
    background: none;
    margin: 0 0 0 8px;
    border: 1px solid $c-swatch-border;
    border-radius: 3px;

    @include breakpoint(tablet) {
        margin: 0 0 20px 0;
    }

    @include breakpoint(mobile) {
        padding: 0;
        margin: 0;
        border: none;
    }
}

    .media-filter__form {}

        .media-filter__fieldset {
            display: flex;
            flex-flow: column nowrap;

            @include breakpoint(phablet) {
                justify-content: center;
            }

            @include breakpoint(mobile) {
                margin-bottom: 10px;
            }
        }

            .media-filter__title {
                @include font(18px, 30px);

                color: $c-black;
            }

            .media-filter__search {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                margin-top: 15px;

                @include breakpoint(tablet) {
                    flex-wrap: wrap;
                }

                @include breakpoint(phablet) {
                    margin-top: 10px;
                }
            }

                .field {
                    margin: 0;
                }

                .media-filter__options {
                    flex-basis: calc(50% - 30px - 30px - 20px);
                    flex-grow: 1;
                    margin: 5px 0px 5px 0;

                    &:first-child {
                        margin-right: 20px;

                        @include breakpoint(phablet) {
                            margin-right: 0;
                            margin-bottom: 15px;
                            flex-basis: 100%;
                        }
                    }

                    option {
                        color: $c-grey--mid;
                    }

                    @include breakpoint(phablet) {
                        width: 100%;
                    }
                }

                .media-filter__lower {
                    position: relative;
                    display: flex;
                    align-items: center;
                    min-height: 26px;
                    justify-content: space-between;
                    margin-top: 15px;
                }

                    .media-filter__text {
                        @include lightText;
                        @include font(14px, 22px);

                        color: $c-grey--mid;
                    }

                    .media-filter__text--absolute {
                        @include breakpoint(phablet) {
                            position: absolute;
                            right: 0%;
                            top: -50px;
                        }
                    }
