// Lists

.list {
    margin-bottom: 30px;

    li {
        line-height: 1*26/16;
        margin-bottom: 5px;
    }
}

.list--icons li i {
    margin-right: 10px;
}

.list--top-offset {
    margin-top: 30px;
}
