.callout {
    text-align: left;
}

    .callout__title {
        @include subtitle;
        // @include rem(45px);

        @include breakpoint(tablet) {
            @include rem(35px);
        }

        @include breakpoint(tablet) {
            @include rem(28px);
        }
    }

    .callout__link {
        display: block;
        position: relative;

        &:hover {
            .callout__button {
                color: $c-black;
            }
        }
    }

        .callout__link--half {
            @include aspect(620/320);
        }

    .callout__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .callout__button {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: $c-white;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: $c-grey--mid;
        padding: 15px 30px;
        z-index: 2;

        @include breakpoint(mobile) {
            line-height: 1*25/15;
        }
    }
