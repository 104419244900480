.inspiration-article {
    padding-top: 100px;

    @include breakpoint(tablet) {
        padding-top: 60px 0;
    }

    .block--richtext {
        .wrap {
            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }

    .block--image-text {
        .block__content {
            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }
}

    .inspiration-article__wrap {
        width: 100%;
        max-width: 1095px + 40px;
        margin: 0 auto;
        padding: 0 $site-gutter;

        @include breakpoint(tablet) {
            padding: 0 $site-gutter--mobile;
        }
    }

        .inspiration-article__header {
            margin-bottom: 25px;
            text-align: center;

            @include breakpoint(tablet) {
                margin-bottom: 15px;
            }
        }

            .inspiration-article__subtitle {
                @include font(18px, 22px);
                @include lightText;

                margin-bottom: 5px;
                color: $c-black;

                @include breakpoint(tablet) {
                    @include font(14px, 17px);

                    margin-bottom: 10px;
                }
            }

            .inspiration-article__title {
                @include font(40px, 62px);

                color: $c-black;

                @include breakpoint(tablet) {
                    @include font(32px, 40px);
                }

                .inspiration-article__products & {
                    @include font(40px, 46px);

                    margin-bottom: 30px;

                    @include breakpoint(tablet) {
                        @include font(24px, 62px);

                        margin-bottom: 5px;
                        text-align: left;
                    }
                }
            }

        .inspiration-article__meta {
            margin-bottom: 30px;
        }

        .inspiration-article__content {
            margin-bottom: 110px;

            @include breakpoint(tablet) {
                margin-bottom: 30px;
            }

            .block {
                margin-bottom: 20px;
            }
        }

        .inspiration-article__products {
            margin-bottom: 80px;

            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }

            .mfp-content & {
                margin-right: -44px;
                margin-left: -44px;

                @include breakpoint(tablet) {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
