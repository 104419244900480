.radio-replace {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    background: $c-white;
    border: 1px solid $c-border;
    border-radius: 100%;

    input[type="radio"]:checked + & {
        border-color: $c-grey--mid-text;

        &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: $c-brand;
            border-radius: 100%;
        }
    }
}

.radio-replace--button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 47px;
    height: 32px;
    margin-right: 0;
    letter-spacing: 1px;
    color: $f-field-color;
    border-radius: 3px;

    @include breakpoint(phablet) {
        min-width: 60px;
    }

    input[type="radio"]:checked + & {
        color: $c-white;
        background: $c-grey--dark;
        border: none;

        &:after {
            display: none;
        }
    }
}
