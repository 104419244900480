.contact-form {
    padding: 45px 55px;
    background-color: $c-import-background;

    @include breakpoint(phablet) {
        padding: 35px 15px;
    }

    .field {
        margin-bottom: 10px;
    }

    textarea.input {
        vertical-align: top;
        height: 100px;
    }

    .field.adaptive-placeholder {
        label {
            padding: 14px 45px 0;
        }
    }

    .field.adaptive-placeholder--active {
        label {
            padding: 0;
            left: 45px;
        }
    }

    .field--required {
        .field__optional {
            display: none;
        }
    }

    .field--country {
        &.field:has(select.input:not(.multi-select))::after {
            content: none;
        }

        select.input {
            padding: 0;
            padding-right: 40px;
            background-color: transparent;
            border: none;
            height: auto;
            max-width: 200px;
        }

        .field__label {
            display: none;
        }
    }
}

    .contact-form__key {
        @include font(14px, 22px);
        @include lightText;
        margin: 40px 0 20px 0;

        span {
            color: #DF6764;
        }
    }

    .contact-form__footer {
        margin-top: 5px;

        .form-privacy {
            @include font(12px, 20px);
            margin-bottom: 0;
        }
    }

        .contact-form__submit {
            margin-bottom: 15px;
        }

        .contact-form__optin {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $light-grey;

            .field {
                margin-bottom: 0;

                label {
                    margin-bottom: 0;
                }
            }
        }

.contact-options {
    margin-top: 40px;
}

    .contact-option address {
        margin-bottom: 15px;
    }

    .contact-option:not(:last-child) {
        margin-bottom: 20px;
        border-bottom: 1px solid $c-border;
    }

        .contact-option__title {
            @include font(20px, 30px);
            margin-bottom: 5px;
        }

.antispam p {
    @include rem(14px);
}

.js .antispam-hide {
    display: none;
}

.contact-content {
    padding: 60px 0 80px;

    @include breakpoint(phablet) {
        padding: 30px 15px;
    }
}

    .contact-content__left {
        background-color: $c-grey--light;

        @include breakpoint(phablet) {
            padding-top: 20px;

            .button {
                margin-bottom: 40px;
            }
        }
    }

    .contact-content__title {
        @include font(26px, 32px);
        padding-bottom: 20px;

        @include breakpoint(phablet) {
            @include font(24px, 34px);
        }
    }
