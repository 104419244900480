.button-arrow {
    width: 7px;
    height: 10px;
}

.external {
    width: 13px;
    height: 14px;
}

.icon-account {
    width: 19px;
    height: 19px;
}

.icon-action-required {
    width: 40px;
    height: 40px;
}

.icon-add-downloads {
    width: 15px;
    height: 18px;
}

.icon-add-piece {
    width: 8px;
    height: 17px;
}

.icon-add-to-basket {
    width: 25px;
    height: 20px;
}

.icon-adjustable {
    width: 8px;
    height: 27px;
}

.icon-approved {
    width: 30px;
    height: 40px;
}

.icon-arrow-carousel-left {
    width: 14px;
    height: 25px;
}

.icon-arrow-carousel-right {
    width: 14px;
    height: 25px;
}

.icon-arrow-cycle {
    width: 230px;
    height: 220px;
}

.icon-arrow-down {
    width: 17px;
    height: 24px;
}

.icon-arrow-large-down {
    width: 33px;
    height: 18px;
}

.icon-arrow-large-left {
    width: 18px;
    height: 33px;
}

.icon-arrow-large-right {
    width: 18px;
    height: 33px;
}

.icon-arrow-large-up {
    width: 33px;
    height: 18px;
}

.icon-arrow-mid-down {
    width: 13px;
    height: 8px;
}

.icon-arrow-mid-left {
    width: 8px;
    height: 13px;
}

.icon-arrow-mid-right {
    width: 8px;
    height: 13px;
}

.icon-arrow-mid-up {
    width: 13px;
    height: 8px;
}

.icon-arrow-small-down {
    width: 10px;
    height: 7px;
}

.icon-arrow-small-left {
    width: 7px;
    height: 10px;
}

.icon-arrow-small-right {
    width: 7px;
    height: 10px;
}

.icon-arrow-small-up {
    width: 10px;
    height: 7px;
}

.icon-arrow-straight-down {
    width: 13px;
    height: 23px;
}

.icon-arrow-thin {
    width: 6px;
    height: 10px;
}

.icon-arrow-up {
    width: 17px;
    height: 24px;
}

.icon-arrow {
    width: 15px;
    height: 37px;
}

.icon-astro {
    width: 125px;
    height: 33px;
}

.icon-available {
    width: 40px;
    height: 40px;
}

.icon-back-arrow-large {
    width: 14px;
    height: 24px;
}

.icon-back-arrow {
    width: 6px;
    height: 10px;
}

.icon-basket {
    width: 18px;
    height: 15px;
}

.icon-bathroom {
    width: 30px;
    height: 40px;
}

.icon-beam-angle {
    width: 24px;
    height: 24px;
}

.icon-casambi-colourtemp {
    width: 70px;
    height: 70px;
}

.icon-casambi-grouplights {
    width: 70px;
    height: 70px;
}

.icon-casambi-groups {
    width: 70px;
    height: 70px;
}

.icon-casambi-scene {
    width: 70px;
    height: 70px;
}

.icon-casambi-sensors {
    width: 70px;
    height: 70px;
}

.icon-casambi-switch {
    width: 70px;
    height: 70px;
}

.icon-center {
    width: 27px;
    height: 27px;
}

.icon-charging-port {
    width: 23px;
    height: 22px;
}

.icon-chevron {
    width: 7px;
    height: 10px;
}

.icon-choose-direction {
    width: 18px;
    height: 17px;
}

.icon-close-small {
    width: 10px;
    height: 10px;
}

.icon-close {
    width: 20px;
    height: 20px;
}

.icon-cross {
    width: 18px;
    height: 15px;
}

.icon-cuts {
    width: 15px;
    height: 14px;
}

.icon-decorative-toggle {
    width: 20px;
    height: 20px;
}

.icon-dimensions {
    width: 26px;
    height: 27px;
}

.icon-dimmable {
    width: 8px;
    height: 8px;
}

.icon-direct-download {
    width: 15px;
    height: 19px;
}

.icon-doc-download {
    width: 15px;
    height: 18px;
}

.icon-doc-pdf {
    width: 15px;
    height: 19px;
}

.icon-doc {
    width: 15px;
    height: 19px;
}

.icon-driver {
    width: 318px;
    height: 60px;
}
.st0{fill:#f0a200}
.st1{fill:#1d1d1b}

.icon-edit {
    width: 13px;
    height: 13px;
}

.icon-end-caps {
    width: 16px;
    height: 12px;
}

.icon-exit {
    width: 17px;
    height: 17px;
}

.icon-expand-white {
    width: 17px;
    height: 17px;
}

.icon-expand {
    width: 17px;
    height: 17px;
}

.icon-external {
    width: 11px;
    height: 11px;
}

.icon-eye {
    width: 20px;
    height: 14px;
}

.icon-facebook {
    width: 10px;
    height: 20px;
}

.icon-fire-rated {
    width: 7px;
    height: 8px;
}

.icon-folder-add {
    width: 22px;
    height: 18px;
}

.icon-folder-added {
    width: 23px;
    height: 18px;
}

.icon-folder {
    width: 20px;
    height: 16px;
}

.icon-glass {
    width: 30px;
    height: 30px;
}

.icon-gold {
    width: 30px;
    height: 40px;
}

.icon-help {
    width: 8px;
    height: 15px;
}

.icon-helper-add {
    width: 14px;
    height: 14px;
}

.icon-helper-delete {
    width: 14px;
    height: 14px;
}

.icon-info {
    width: 16px;
    height: 16px;
}

.icon-instagram {
    width: 20px;
    height: 20px;
}

.icon-john-lewis {
    width: 30px;
    height: 40px;
}

.icon-led {
    width: 23px;
    height: 23px;
}

.icon-linkedin {
    width: 21px;
    height: 20px;
}

.icon-magnify {
    width: 20px;
    height: 20px;
}

.icon-minus-circled {
    width: 27px;
    height: 27px;
}

.icon-minus-light {
    width: 10px;
    height: 9px;
    fill: none;
}

.icon-minus-small {
    width: 10px;
    height: 2px;
}

.icon-minus {
    width: 20px;
    height: 2px;
}

.icon-mto {
    width: 27px;
    height: 23px;
}

.icon-no {
    width: 24px;
    height: 24px;
}

.icon-not-available {
    width: 40px;
    height: 40px;
}

.icon-open-nav {
    width: 20px;
    height: 18px;
}

.icon-oval-cycle {
    width: 230px;
    height: 220px;
}

.icon-pause {
    width: 16px;
    height: 21px;
}

.icon-pdf {
    width: 12px;
    height: 14px;
}

.icon-pencil {
    width: 9px;
    height: 10px;
    fill: none;
}

.icon-pin {
    width: 22px;
    height: 28px;
}

.icon-pinterest {
    width: 16px;
    height: 20px;
}

.icon-platinum {
    width: 30px;
    height: 40px;
}

.icon-play-roundel {
    width: 60px;
    height: 60px;
}

.icon-play {
    width: 19px;
    height: 22px;
}

.icon-plus-circled {
    width: 27px;
    height: 27px;
}

.icon-plus-light {
    width: 10px;
    height: 9px;
    fill: none;
}

.icon-plus-small {
    width: 10px;
    height: 10px;
}

.icon-plus {
    width: 20px;
    height: 20px;
}

.icon-premier {
    width: 30px;
    height: 40px;
}

.icon-quote-1 {
    width: 90px;
    height: 59px;
}

.icon-quote-2 {
    width: 90px;
    height: 58px;
}

.icon-redo {
    width: 20px;
    height: 14px;
}

.icon-refresh {
    width: 42px;
    height: 48px;
}

.icon-remove-piece {
    width: 12px;
    height: 12px;
}

.icon-rotate-left {
    width: 18px;
    height: 18px;
}

.icon-rotate-right {
    width: 18px;
    height: 18px;
}

.icon-save {
    width: 18px;
    height: 18px;
}

.icon-search {
    width: 20px;
    height: 20px;
}

.icon-share {
    width: 20px;
    height: 18px;
}

.icon-show-all {
    width: 27px;
    height: 27px;
}

.icon-showroom {
    width: 30px;
    height: 40px;
}

.icon-silver {
    width: 30px;
    height: 40px;
}

.icon-small-screen {
    width: 202px;
    height: 137px;
}

.icon-switched {
    width: 8px;
    height: 8px;
}

.icon-technical-toggle {
    width: 20px;
    height: 20px;
}

.icon-tick {
    width: 12px;
    height: 10px;
}

.icon-trash {
    width: 10px;
    height: 10px;
}

.icon-twenty-years-logo-small {
    width: 61px;
    height: 45px;
}

.icon-twitter {
    width: 20px;
    height: 16px;
}

.icon-undo {
    width: 20px;
    height: 14px;
}

.icon-upload {
    width: 18px;
    height: 21px;
}

.icon-video {
    width: 60px;
    height: 60px;
}

.icon-warning-alt {
    width: 23px;
    height: 23px;
}

.icon-warning-black {
    width: 17px;
    height: 15px;
}

.icon-warning {
    width: 24px;
    height: 22px;
}

.icon-x {
    width: 33px;
    height: 33px;
}

.icon-yes {
    width: 12px;
    height: 10px;
}

.icon-youtube {
    width: 20px;
    height: 15px;
}

.icon-zoom-in {
    width: 14px;
    height: 14px;
}

.icon-zoom-minus {
    width: 24px;
    height: 24px;
}

.icon-zoom-out {
    width: 14px;
    height: 2px;
}

.icon-zoom-plus {
    width: 24px;
    height: 24px;
}

.progress-arrow {
    width: 40px;
    height: 10px;
}

