.inspiration-listing {
    color: $c-black;

    @include breakpoint(tablet) {
        padding-bottom: 10px;
    }
}

    .inspiration-listing__link {
        display: block;
        text-decoration: none;
        color: inherit;
    }

        .inspiration-listing__image {
            @include aspect(1);

            margin-bottom: 15px;
            background: $c-border;

            .inspiration-listing--large & {
                @include aspect(650 / 487);
            }

            img {
                width: 100%;
                height: auto;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .inspiration-listing__link:hover & {
                    transform: scale(1.2);

                    @include breakpoint(tablet) {
                        transform: none;
                    }
                }
            }
        }

        .inspiration-listing__subtitle {
            @include font(12px, 15px);
            @include lightText;

            margin-bottom: 5px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .inspiration-listing__title {
            @include font(24px, 40px);

            @include breakpoint(tablet) {
                @include font(20px, 28px);
            }
        }
