.block--get-the-look {
    @include lightondark;

    overflow: hidden;
    padding: 0;
    display: grid;
    /* For alignment with the feature grid block */
    grid-template-columns: minmax(0 , 1fr) calc(((100vw - 1440px) / 2) + (1400px * (2 / 3) + 40px));

    @include breakpoint(wrap) {
        grid-template-columns: minmax(0, 1fr) calc(80px + ((100vw - 200px) * (2 / 3) + 40px));
    }

    @include breakpoint(tablet) {
        grid-template-columns: minmax(0, 1fr);
    }

    .block__content {
        position: relative;
        width: 100%;
        padding: 60px 40px 100px calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / 2);
        color: $c-white;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background: var(--block-background);

        @include breakpoint(wrap) {
           padding-left: $site-gutter;
        }

        @include breakpoint(tablet) {
            max-width: none;
            margin-left: 0;
            padding: 60px 15px 30px;
        }

        .button {
            margin-right: auto;
        }
    }

    .block__content--top {
        @include breakpoint(tablet) {
            display: none;
        }
    }

    .block__content--bottom {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            padding-top: 35px;
            padding-bottom: 70px;
        }
    }

    &.block--width-full-bleed {
        max-width: none;
    }

    &.block--width-main-content {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }


    &.block--text-dark .block__content {
        color: $c-black;
    }

        .block__subtitle {
            @include font(18px, 22px);
            @include lightText;

            margin-bottom: 5px;
            text-transform: uppercase;
            letter-spacing: 4px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                margin-bottom: 10px;
                letter-spacing: 3px;
            }
        }

        .block__title {
            @include font(38px, 44px);

            margin-right: -100%;
            margin-bottom: 20px;
            max-width: 100%;
            font-family: var(--block-title-font-family, $f-primary-stack);

            @include breakpoint(tablet) {
                @include font(26px, 32px);

                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        .block__text {
            max-width: 100%;
            margin-bottom: 30px;

            @include breakpoint(tablet) {
                margin-bottom: 15px;
            }
        }

    &.block--text-light .block__text {
        @extend .richtext--dark;
    }

    .block__image {
        @include aspect(5 / 3);

        flex: 1 1 auto;
        margin: 0;

        @include breakpoint(tablet) {
            @include aspect(5 / 3);
        }
    }

    .block__gradient {
        position: absolute;
    }

    .block__gradient--1 {
        top: 0;
        left: 0;
        width: 167px;
        height: 100%;
        background: linear-gradient(270deg, rgba($c-black, 0) 0%, $c-black 100%);
        opacity: 0.35;

        @include breakpoint(tablet) {
            width: 68px;
        }
    }

    .block__gradient--2 {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 285px;
        background: linear-gradient(180deg, rgba($c-black, 0) 0%, $c-black 100%);
        opacity: 0.35;

        @include breakpoint(tablet) {
            height: 117px;
        }
    }

    .block__carousel-container {
        grid-column: span 2;

        @include breakpoint(tablet) {
            grid-column: span 1;
        }
    }

    .block__carousel {
        position: relative;
        z-index: 10;
        margin-top: -80px;
        margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);
        margin-right: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);

        @include breakpoint(wrap) {
            margin-left: calc(#{$site-gutter} * -1);
            margin-right: calc(#{$site-gutter} * -1);
        }

        @include breakpoint(tablet) {
            margin-top: -40px;
            margin-left: calc(#{$site-gutter--mobile} * -1);
            margin-right: calc(#{$site-gutter--mobile} * -1);
            overflow: hidden;
        }

        &.hide-desktop-carousel {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            margin-right: 0;
            margin-left: 0;

            @include breakpoint(phablet) {
                margin-left: calc(#{$site-gutter--mobile} * -1);
                margin-right: calc(#{$site-gutter--mobile} * -1);
                display: block;
            }
        }
    }

        .block__product { // To be removed when hooked up with products
            @include aspect(1);
            @include font(16px, 20px);

            // need to override the grid width on product card which has no specific class
            > div {
                width: 100%;
            }

            .product-listing-item {
                margin-bottom: 0;
            }

            .product-listing-item__title {
                color: $c-black;
            }
        }
}
