// Quick add form

.account-quick-add-form {
    margin-bottom: -20px;

    .field {
        margin-left: 10px;
        margin-right: 10px;
    }

    .field--submit {
        width: auto;
        margin-right: 0;

        @include breakpoint(phablet) {
            width: 100%;
            margin: 0;
        }
    }
}

.account-quick-add-form__inner {
    display: flex;
    flex-wrap: wrap;

    .field:first-child {
        margin-left: 0;
        flex: 1;
    }

    @include breakpoint(phablet) {
        margin-bottom: 20px;
    }
}

.account-quick-add-form__title {
    @include font(14px, 22px);
    @include f-light;
}
