.pdp-grid {
    padding: 15px 0 0 0;
    border-top: solid 1px $c-border-light;
}

    .pdp-grid__header {
        margin-bottom: 35px;

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }
    }

        .pdp-grid__title {
            @include lightText;
            @include font(18px, 22px);

            letter-spacing: 4px;
            color: $c-black;
            text-transform: uppercase;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                text-align: left;
            }
        }
