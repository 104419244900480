.account-address-form {}

    .account-address-form__address {
        display: none;

        .account-address-form--manual & {
            display: block;
        }
    }

    .account-address-form__toggle_wrap {
        .account-address-form--manual & {
            display: none;
        }
    }

    .account-address-form__lookup {
        .account-address-form--manual & {
            display: none;
        }
    }

    .account-address-reset-buttons {
        margin-bottom: 30px;
    }
