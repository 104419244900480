.pdp-tabs {
    scroll-margin-top: $header-height + 20px;

    @include breakpoint(tablet) {
        scroll-margin-top: $header-top-height--mobile + 20px;
    }
}

.pdp-tabs__nav {
    display: flex;
    justify-content: center;
    gap: 40px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @include breakpoint(tablet) {
        margin: 0 calc($site-gutter--mobile * -1);
        gap: 20px;
        padding: 0 $site-gutter--mobile;
        justify-content: flex-start;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
}

.pdp-tabs__nav--scrolling {
    justify-content: flex-start;
}

.pdp-tabs__button {
    @include font(20px, 28px);

    background: none;
    padding: 0;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px solid $c-swatch-border;

    @include breakpoint(tablet) {
        @include font(14px, 26px);

        padding-bottom: 5px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        background-color: $c-black;
        transition: 200ms ease-in width;
    }

    &:hover::after,
    &[aria-selected="true"]::after {
        width: 100%;
    }
}

.pdp-tabs__item {
    margin-top: 35px;

    &.is-hidden {
        display: none;
    }
}

.pdp-tabs__item--technical {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 80px;
    align-items: flex-start;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

.pdp-tabs__item--information-image {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }
}

.pdp-tabs__item--shade {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint(tablet) {
        justify-content: flex-start;
    }

    & > * {
        flex-basis: calc(25% - 15px);

        @include breakpoint(tablet) {
            flex-basis: calc(50% - 10px);
        }
    }
}

.pdp-tabs__item--item {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    & > * {
        flex-basis: calc(33.33% - (40px / 3));

        @include breakpoint(tablet) {
            flex-basis: 100%;
        }
    }
}

.pdp-tabs__item--finishes {
    margin: 35px calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2) 0;

    @include breakpoint(wrap) {
        margin: 35px calc(#{$site-gutter} * -1) 0;
    }

    @include breakpoint(tablet) {
        margin: 35px calc(#{$site-gutter--mobile} * -1) 0;
        overflow: hidden;
    }
}

.pdp-tabs__content {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.pdp-tabs__content--bg {
    background-color: $c-white;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: none;
    text-align: left;
}

.pdp-tabs__title {
    @include font(20px, 38px);

    margin-bottom: 5px;
}

.pdp-tabs__technical {
    max-width: 450px;
    margin-left: auto;

    @include breakpoint(phablet) {
        max-width: none;
        margin: 0;
    }

    img {
        width: 100%;
        height: auto;
        mix-blend-mode: multiply;
    }
}

.pdp-tabs__info-image {
    height: 100%;

    img {
        border-radius: 5px 0 0 5px;
        width: 100%;
        height: 100%;

        @include breakpoint(tablet) {
            aspect-ratio: 7 / 5;
            border-radius: 5px 5px 0 0;
        }
    }
}

.pdp-tabs__info-image-track {
    aspect-ratio: 1;
    background-image: url('/images/track-configurator/background/callout--square.jpg');
    background-size: cover;
    border-radius: 5px 0 0 5px;

    @include breakpoint(tablet) {
        aspect-ratio: 7 / 5;
        border-radius: 5px 5px 0 0;
    }
}

.pdp-tabs__cta {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    grid-column: span 2;

    @include breakpoint(tablet) {
        grid-column: span 1;
        gap: 10px;
        flex-direction: column;
    }
}

.pdp-tabs__cta-title {
    @include font(20px, 38px);

    @include breakpoint(tablet) {
        @include font(16px, 28px);
    }
}
