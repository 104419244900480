.product-table {
    width: 100%;
    text-align: left;

    th,
    td {
        @include font(14px, 20px);

        padding-bottom: 10px;

        @include breakpoint(tablet) {
            @include font(12px, 18px);
        }
    }

    th {
        white-space: nowrap;
        width: 33%;
        padding-right: 20px;
    }

    td {
        @include lightText;
    }

        svg {
            margin-bottom: -3px;
            margin-left: 5px;
            fill: $c-brand;
        }
}

.product-table--center {
    width: fit-content;
    margin: 0 auto;
    grid-column: span 2;

    @include breakpoint(phablet) {
        margin: 0;
        grid-column: span 1;
    }
}
