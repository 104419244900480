.image-text {
    @include aspect(1600 / 730);

    position: relative;
    background: $background-grey;
    overflow: hidden;

    max-height: 730px;

    @include breakpoint(tablet) {
        max-height: none;
    }
}

.image-text--track-configurator-callout {
    @include aspect(1600 / 365);

    min-height: 365px;
    max-height: 365px;

    @include breakpoint(tablet) {
        min-height: none;
        max-height: none;
    }
}

.image-text--blue {
    @include lightondark;

    color: $c-white;
    background: $c-brand;
}

    .image-text__grid {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include breakpoint(tablet) {
            position: relative;
        }
    }

    .image-text__grid-col {
        position: relative;
    }

    .image-text__grid-col--push {
        margin-left: 10%;

        @include breakpoint(track-configurator-small) {
            margin-left: 5%;
        }

        @include breakpoint(nav) {
            margin-left: 0;
        }
    }

        .image-text__image-wrapper {
            position: relative;
            width: 100%;
            min-height: 100%;

            @include breakpoint(tablet) {
                @include aspect(18 / 7);

                min-height: 0;
            }

            @include breakpoint(mobile) {
                @include aspect(1);
            }

            .image-text--layout-2 &,
            .image-text--layout-3 &,
            .image-text--track-configurator-callout & {
                position: absolute;
                top: 0;
                left: 0;

                @include breakpoint(tablet) {
                    position: relative;
                    top: auto;
                    left: auto;
                }
            }
        }

        .image-text__image-wrapper--2 {
            position: absolute;
        }

            .image-text__image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                .image-text--track-configurator-callout & {
                    background-image: url(/images/track-configurator/background/callout--desktop.jpg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    @include breakpoint(mobile) {
                        background-image: url(/images/track-configurator/background/callout--mobile.jpg);
                    }
                }

                img {
                    @include objectFitImage;
                }
            }

            .image-text__background {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: url(/images/track-configurator/background/track.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

        .image-text__content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            max-width: 840px;
            justify-content: center;
            min-height: 100%;
            margin: 0 auto;
            padding: 50px 100px;
            box-sizing: border-box;

            @include breakpoint(tablet) {
                max-width: 670px;
                height: auto;
                padding: 30px 15px;
            }

            .image-text--track-configurator-callout & {
                padding: 0 40px;
                max-width: 720px;
                margin-right: 0;

                @include breakpoint(tablet) {
                    max-width: 670px;
                    margin-right: auto;
                    padding: 30px 15px;
                }
            }

            .image-text--layout-2 & {
                padding-right: 0;
                margin-right: 0;

                @include breakpoint(tablet) {
                    margin-right: auto;
                    padding-right: 15px;
                }
            }

            .image-text--layout-3 & {
                justify-content: flex-end;
            }
        }

            .image-text__title {
                @include rem(52px);

                position: relative;
                line-height: calc(63 / 52);
                margin-bottom: 15px;
                z-index: 1;

                @include breakpoint(tablet) {
                    @include rem(30px);

                    line-height: calc(37 / 30);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .image-text--track-configurator-callout & {
                    @include f-light;
                    @include rem(42px);

                    line-height: calc(62 / 42);

                    @include breakpoint(tablet) {
                        @include rem(30px);

                        line-height: calc(37 / 30);
                    }

                    strong {
                        @include f-regular;
                    }
                }
            }

            .image-text__intro {
                position: relative;
                margin-bottom: 30px;
                z-index: 1;

                &:last-child {
                    margin-bottom: 0;
                }

                p {
                    @include f-light;
                    @include rem(18px);

                    line-height: calc(30 / 18);

                    @include breakpoint(tablet) {
                        @include rem(16px);

                        line-height: calc(26 / 16);
                    }

                    .image-text--track-configurator-callout & {
                        @include rem(16px);

                        line-height: calc(26 / 16);
                    }
                }
            }

            .image-text__button {
                position: relative;
                z-index: 1;
            }
