.detail-boxes {
    display: grid;
    grid-template-columns: minmax(0,1fr) auto;
}

    .detail-boxes__item {
        padding: 20px;
        border: 1px solid $c-swatch-border;
        border-bottom: none;
        display: grid;
        grid-column: span 2;
        grid-template-columns: subgrid;
        align-items: center;

        @include breakpoint(phablet) {
            padding: 15px;
        }

        &:first-child {
            border-radius: 3px 3px 0 0;
        }

        &:last-child {
            border-radius: 0 0 3px 3px;
            border-bottom: 1px solid $c-swatch-border;
        }

        &:only-child {
            border-radius: 3px;
        }

        .button--border:not(.button--small-text) {
            flex-shrink: 0;

            span {
                padding-bottom: 5px;
            }
        }
    }

        .detail-boxes__content {
            margin-right: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            row-gap: 2px;
        }

            .detail-boxes__title {
                @include font(12px, 20px);

                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .detail-boxes__fields {
                @include lightText;
                @include font(14px, 22px);

                position: relative;
                margin-right: 10px;
                display: flex;

                @include breakpoint(tablet) {
                    @include font(12px, 15px);
                }

                &:not(:last-child)::after {
                    content: '|';
                    color: $c-detail-subtitle-separator;
                    margin-left: 10px;
                }
            }
