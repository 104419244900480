.block--call-to-action {
    background-color: var(--block-background);

    display: flex;
    align-items: center;

    &.block--align-centre {
        justify-content: center;
    }

    &.block--align-left {
        // Force left centre align on small devices
        @include breakpoint(mobile) {
            justify-content: center;
        }
    }

    padding-left: 80px;
    padding-right: 80px;

    &[class^="block--background-"]:not(.block--background-white),
    &[class*=" block--background-"]:not(.block--background-white) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &.block--background-white {
        .button--white {
            border: 1px solid $c-border;
        }
    }
}
