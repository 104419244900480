.dimensions {
    display: flex;
    align-items: baseline;
    gap: 5px;

    dt,
    dd {
        @include font(14px, 22px);
        @include lightText;

        margin: 0;
    }
}
