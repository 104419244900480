select {
    display: block;
    width: 100%;
}

// Wraps the select in a div.multi-select
.multi-select {
    display: block;
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    padding: 0;
    background: $c-white;
    border: 1px solid $c-border;
    border-radius: 3px;

    &.multi-select--focused {
        border-radius: 3px 3px 0 0;

        &::after {
            transform: rotate(-180deg);
        }
    }

    .field-error & {
        border-color: $c-error;
    }

    // Use native for functionality but hide visually
    select {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -1000px;
        width: 0;
        height: 0;
        margin: 0;
        font-size: 1rem;
        line-height: 1;
        background: #fff;
        border: 0;
        border-radius: 0;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

// The current value of the native select is used to create a p.multi-select__value inside the select replace
// Double class name required to increase specificity

.multi-select__value {
    @include apply-map($f-field);
    @include font(14px, 20px);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: left;
    margin: 0;
    padding: 20px 45px 0 15px;
    color: $f-field-color;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
}

.multi-select__value--disabled {
    color: lighten(#999, 35%);
}

.multi-select__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $c-border;
    pointer-events: none;

    .field-error & {
        border-left-color: $c-error;
    }

    svg {
        fill: $c-black;
    }

    &--unfocused {
        .multi-select--focused & {
            display: none;
        }
    }

    &--focused {
        display: none;

        .multi-select--focused & {
            display: flex;
        }
    }
}

.multi-select__search {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0 calc(2em + 13px) 0 1em;
    line-height: calc(47 / 11);
    border: none;
    width: 100%;
    outline: none;
}

.multi-select__drop {
    position: absolute;
    z-index: 20;
    top: 44px;
    left: -1px;
    min-width: 100%;
    display: none;
    background: #fff;
    border: 1px solid #d2d2d2;
    border-top: 0;
    box-sizing: content-box;
}

.multi-select__list {
    max-height: 300px;
    overflow: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.multi-select__list-item {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: flex-start;

    a {
        display: block;
        flex-grow: 1;
        @include font(13px, 22px);
        text-align: left;
        font-weight: 400;
        color: $c-body;
    }

    input[type="checkbox"] {
        appearance: none;
        border: 1px solid $c-border;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background-color: $c-white;
        transition: outline .2s ease;
        outline-offset: -1px;
        outline-style: solid;
        outline-width: 2px;
        outline-color: transparent;
    }
}

.multi-select__list-item--selected {
    input[type="checkbox"] {
        border-color: $c-black;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }   
}

.multi-select__list-item--current {
    a {
        color: #fff;
        background: #666;
    }
}

.multi-select__list-item--disabled {
    a {
        pointer-events: none;
        color: #ccc;
    }
}

.multi-select__list-item--hidden {
    display: none;
}

.multi-select--multiple {
    .multi-select__list-item--selected {

        .no-touch & {
            a:hover {
                &:before {
                    color: $c-brand;
                    content: "✘";
                    background: none;
                    top: 5px;
                    left: 8px;
                }
            }
        }
    }
}

.multi-select__list-item {
    &.multi-select__list-item--image {
        img {
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: 1px solid $c-border-light;
        }

        &.multi-select__list-item--selected {
            img {
                border-color: $c-grey--dark;
            }
        }
    }
}

.multi-select__elevator-button {
    content: "";
    width: 25px;
    height: 25px;
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    display: none !important;
    position: absolute;
    right: 5px;
    border: 1px solid #000;
    border-radius: 100%;
    opacity: 0.3;

    .touchevents & {
        display: block;
    }
}

.multi-select__elevator-button-click {
    opacity: 1;
    transition: opacity;
}

.multi-select__elevator-button--up {
    top: 5px;
}

.multi-select__elevator-button--down {
    bottom: 5px;

}

// When the native select is focused .multi-select--focused class is appended
.multi-select--focused {
    &.multi-select--search {
        .multi-select__value {
            display: none;
        }

        .multi-select__search {
            display: block;
        }
    }

    &.multi-select--multiple {
        .multi-select__value {
            background: $c-white;
        }
    }

    .multi-select__drop {
        display: block;
    }
}

// When the native select is disabled .multi-select--disabled class is appended
.multi-select--disabled {
    opacity: 0.3;

    .multi-select__value {
        color: lighten(#999, 35%);
    }

    &::after {
        opacity: 0.2;
    }

    .multi-select__value {
        color: #999;
    }
}

.select-inline {
    display: inline-block;
    vertical-align: top;

    .multi-select {
        height: auto;
        text-transform: none;
        text-indent: 0;
        font-size: inherit;
        box-shadow: none;
        background: none;
    }

    .multi-select__drop {
        top: 25px;
    }

    .multi-select__value {
        position: static;
        top: auto;
        left: auto;
        width: auto;
        line-height: 1;
        padding: 0 20px 0 0;
        text-transform: none;
        font-size: inherit;
        border: none !important;
    }

    .multi-select__list-item {
        display: block;
        white-space: nowrap;
        word-break: keep-all;

        a {
            border: none;
            padding: 5px 10px 5px 30px;
        }
    }
}

.alt-multi-select {

    .multi-select:after {
        top: 50%;
        transform: translateY(-50%);
    }

    .multi-select,
    .multi-select__drop {
        box-shadow: none;
        border: 1px solid $c-lines;
    }

    .multi-select__value {
        padding-left: 15px;
        line-height: calc(43 / 14);
    }

    .multi-select__drop {
        top: 43px;
        right: -1px;
        left: -1px;
    }

    .multi-select__value {
        text-transform: none;
    }

    select {
        display: none;
    }
}

.alt-multi-select--small {

    .multi-select {
        height: 33px;
    }

    .multi-select__value {
        line-height: calc(33 / 14);
    }

    .multi-select__drop {
        top: 33px;
    }
}
