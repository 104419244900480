.block--popup-split {
    color: $c-black;

    @include breakpoint(tablet) {
        padding: 0;
    }

    .block__image {
        @include aspect(4 / 3);

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }

        img {
            @include cover;

            width: 100%;
            height: 100%;
        }
    }

    .block__title {
        @include font(18px, 24px);
        margin-bottom: 10px;
        font-weight: 400;
    }
}
