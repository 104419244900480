.load-more {
    position: relative;
    margin-top: 30px;
    padding-bottom: 130px;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 50%;
        background: linear-gradient(0deg, $c-white 0%, rgba($c-white, 0) 100%);
    }
}

.load-more--disabled {
    padding-bottom: 120px;

    &:after {
        display: none;
    }
}

    .load-more__wrap {
        @extend .wrap;

        .load-more--disabled & {
            display: none;
        }
    }

        .load-more__placeholders {
            @extend .grid;
            @extend .grid--semi-tight;
            @extend .grid--vflush;

            opacity: 0.2;
        }

            .load-more__placeholder {
                height: 130px;
                background: $c-brand;
            }

        .load-more__button {
            @include font(18px, 22px);
            @include lightText;

            position: absolute;
            top: 50%;
            left: 50%;
            padding: 0;
            background: transparent;
            letter-spacing: 4px;
            text-transform: uppercase;
            transform: translate(-50%, -50%);
            z-index: 1;
            outline: none;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
            }

                img {
                    margin-right: 25px;
                }
        }
