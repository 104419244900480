.block {
    .gallery__crosshair {
        z-index: 4;
    }

    .hotspot {
        border-radius: 100%;
        border: 1px solid $c-white;
        width: 26px;
        height: 26px;
        display: block;
        text-align: center;
        position: relative;
        background-color: transparentize($c-black, 0.9);

        &:after {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background: $c-white;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        svg {
            width: 10px;
            height: 10px;
            margin-top: -8px;
            vertical-align: middle;
        }
    }

    .hotspot--minus {
        display: none;
    }

    .gallery__crosshair--active {
        .hotspot--plus {
            display: none;
        }

        .hotspot--minus {
            display: block;
        }
    }
}
