.tag {
    display: inline-block;
    @include rem(12px);
    background-color: $c-brand;
    color: $c-white;
    padding: 2px 10px;
}

.tag--round {
    border-radius: 999px;
}
