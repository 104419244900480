.confirm-popup {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    background: $c-white;
    border-radius: 3px;

    @include breakpoint(tablet) {
      max-width: none;
      border-radius: 0;
    }
}

    .confirm-popup__content {
        padding: 30px;
        text-align: right;
    }

        .confirm-popup__title {
            @include lightondark;
            @include font(22px, 27px);

            padding: 15px 30px;
            color: $c-white;
            background: $c-brand;
            text-align: center;

            @include breakpoint(tablet) {
                color: $c-black;
                background: transparent;
                border-bottom: 1px solid $c-grey--button;
            }
        }
