.band {
    padding: 50px 0;
}

.band--margin {
    margin: 50px 0;
}

.band--responsive {
    @include breakpoint(tablet) {
        padding: 30px 0;
    }
}

.band--flush {
    padding: 0;
}

.band--m-flush {
    @include breakpoint(tablet) {
        padding: 0;
    }
}

.band--tall {
    padding: 70px 0;

    @include breakpoint(tablet) {
        padding: 50px 0;
    }

    @include breakpoint(phablet) {
        padding: 40px 0;
    }
}

.band--tall-margin {
    margin: 70px 0;

    @include breakpoint(tablet) {
        margin: 50px 0;
    }

    @include breakpoint(phablet) {
        margin: 30px 0;
    }
}

.band--extra-tall {
    padding: 100px 0;

    @include breakpoint(tablet) {
        padding: 50px 0;
    }

    @include breakpoint(phablet) {
        padding: 40px 0;
    }
}

.band--short {
    padding: 30px 0;

    @include breakpoint(phablet) {
        padding: 15px 0;
    }
}

.band--flush-top {
    padding-top: 0;
}

.band--short-top {
    padding-top: 30px;

    @include breakpoint(phablet) {
        padding-top: 15px;
    }
}

.band--flush-bottom {
    padding-bottom: 0;
}

.band--border-light {
    border-top: 1px solid $c-grey--light;
    border-bottom: 1px solid $c-grey--light;
}

.band--border-light-top {
    border-top: 1px solid $c-grey--light;
}

.band--border-light-bottom {
    border-bottom: 1px solid $c-grey--light;
}

.band--grey {
    background: $c-grey--button;
}

.band--light-grey {
    background: $c-grey--light;
}

    .band__title {
        @include font(32px, 46px);

        margin: 0 0 30px;
        text-align: center;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(24px, 32px);
        }

        span {
            @include lightText;
        }
    }

.band--no-overflow {
    overflow: hidden;
}

.band--about {
    padding-top: 45px;
    padding-bottom: 0;
    @include breakpoint(tablet) {
       padding-top: 0;
    }
}

.band--about-video {
    padding: 0 18%;
    margin-bottom: 120px;
    @include breakpoint(tablet) {
        padding: 0 40px;
        margin-bottom: 60px;
    }
    @include breakpoint(phablet) {
        padding: 0 20px;
    }
}

.band--medium {
    padding: 40px 0;

    &:nth-of-type(2) {
        padding-top: 80px;
    }

    &:nth-last-of-type(2) {
        padding-bottom: 60px;
    }

    @include breakpoint(tablet) {
        padding: 20px 0;

        &:nth-of-type(2) {
            padding-top: 40px;
        }

        & + .band--project {
            margin-top: -40px;
        }
    }
}

.band--project {
    @include breakpoint(tablet) {
        padding-top: 0;
    }
}
