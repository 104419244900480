// Checkout summary

@import 'ecom/components/checkout-address-form';

.checkout-summary {
    text-align: left;
}

.checkout-summary__header {
    text-transform: uppercase;
    @include rem(16px);
    margin-bottom: 30px;
}

.checkout-summary__products-container {
    &:after {
        content: '';
        display: block;
        height: 1px;
        width: calc(100% - 20px);
        background: $c-border;
    }
}

.checkout-summary__products-wrap {
    max-height: 425px;
    overflow: auto;
    padding: 5px 20px 0 0;

    @include breakpoint(mobile) {
        max-height: 300px
    }
}

.checkout-summary__products {
    width: 100%;

    td {
        vertical-align: top;
        @include rem(16px);
        @include f-regular;
        line-height: 1.5;
    }
}

.checkout-summary__products__product {
    width: 85px;
    min-height: 70px;
    padding-right: 15px;

    img {
        max-width: 70px;
        height: auto;
    }
}

.checkout-summary__products__product__qty {
    width: 60px;
    text-align: right;
}

.checkout-summary__products__product__x {
    width: 25px;
}

.checkout-summary__products__product__details {
    .product-stock {
        line-height: 25px;
    }
}

    .checkout-summary__details__title {
        padding-bottom: 5px;
    }

    .checkout-summary__details__meta {
        @include f-light;
        @include font(14px, 17px);
        padding-bottom: 5px;
    }

    .checkout-summary__details__dispatch {
        @include font(14px, 17px);
        @include f-regular;
    }

.checkout-summary__products__product__buffer {
    padding:10px;
}

.checkout-summary__products__product__price {
    text-align: right;
    width: 100px;
}

.checkout-summary__products__product__warning {
    padding: 30px 0;
}

.checkout-summary__footer {
    font-size: 0;
    padding-top: 30px;
    margin: 0 20px 0 0;
}

.checkout-summary__footer__label {
    display: inline-block;
    width: 50%;
    text-align: left;
    @include rem(16px);
    @include f-regular;
    line-height: 1.5;
}

.checkout-summary__footer__data {
    display: inline-block;
    width: 50%;
    text-align: right;
    @include rem(16px);
    @include f-regular;
    line-height: 1.5;
}

.checkout-summary__footer__label--grand,
.checkout-summary__footer__data--grand {
    @include rem(20px);
}

.checkout-summary__footer__data--vat {
    color: $c-brand;
}

.checkout-summary__message {
    margin-top: 20px;
}

// Checkout form

.checkout-form__details {
    @include rem(15px);
    line-height: 1.4;
    margin-bottom: 30px;
}

.checkout-form__reference {
    text-align: left;

    label {
        display: inline-block;
        width: 170px;
        @include rem(15px);
        text-align: left;
        line-height: 1.4;
        margin-right: 10px;
        margin-bottom: 20px;

        span {
            display: block;
            color: $grey2;
            font-style: italic;
        }
    }
}

.checkout-form__delivery-info {
    @include copy;
    @include f-regular;
    @include rem(15px);
    line-height: 1.4;
    margin-bottom: 15px !important;
}

.checkout-form__address-add-link {
    display: inline-block;
    @include rem(14px);
    text-transform: uppercase;
    margin: 20px 0;
}

.checkout-form__address-add-region {
    display: none;
}

.checkout-form__address-add-region--show {
    display: block;
}

.checkout-form #order_notes_wrap {
    float: none;
    margin-right: 0;
    width: 100%;
}

.checkout-form__footer {
    @include clearfix;

    p {
        @include copy;
        @include rem(14px);
        margin: 15px 0 0;
        line-height: 1.5;

        input {
            margin-right: 15px;
        }

        @include breakpoint(tablet) {
            margin-top: 0;
        }
    }
}

.checkout-form__hint {
    display: block;
    color: $grey2;
    font-style: italic;
    margin: 10px 0 10px;
}

.checkout-form label[for="address"] .fa-spin,
.checkout-form__residential .fa-spin {
    margin-left: 10px;
}

.checkout-form__comments {

    span {
        display: block;
        color: $grey2;
        font-style: italic;
        margin-top: 5px;
    }
}

.checkout-form__branch {
    width: 100%;
}

.checkout-new-address {
    margin-bottom: 50px;
}

.checkout-address-form__toggle_wrap {
    margin-bottom: 30px;

    .checkout-address-form--manual & {
        display: none;
    }
}

.checkout-reset-buttons {
    margin-bottom: 30px;
}
