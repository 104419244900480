.downlight-variant-table {
    width: 100%;
    max-width: 500px;

    tr {}

        th,
        td {
            padding-bottom: 5px;
            width: 50%;
        }

        th {
            @include font(11px, 16px);

            padding-right: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $c-grey--mid-text;
        }

        td {
            @include font(14px, 20px);
            @include lightText;

            color: $c-grey--mid;
        }
}
