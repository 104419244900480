.block--feature-grid {
    padding: 0;

    &.block--reverse .block__text {
        grid-column: 3 / 4;
        padding-right: 0;
        padding-left: 20px;

        @include breakpoint(phablet) {
            padding: 0;
            grid-column: 1 / 3;
        }
    }

    .block__text {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding-right: 20px;

        @include breakpoint(phablet) {
            order: 1;
            padding: 0;
            grid-column: 1 / 3;
            grid-row: 3 / 4;
            margin-bottom: 30px;
        }
    }

        .block__feature-title {
            @include font(38px, 44px);

            margin-bottom: 15px;

            @include breakpoint(tablet) {
                @include font(26px, 32px);

                margin-top: 10px;
            }

            @include breakpoint(phablet) {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }

        .block__buttons {
            display: flex;
            align-items: center;
            gap: 30px;
            flex-wrap: wrap;
        }
}
