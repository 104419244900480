
.where-buy {
    padding: 40px 60px;

    @include breakpoint(tablet) {
        padding: 0 5px;
    }
}

    .where-buy__left {
        background-color: $light-grey;

        @include breakpoint(tablet) {
            padding-top: 20px;
            background-color: $c-white;
        }
    }

    .where-buy__right {

        @include breakpoint(tablet) {
            padding-bottom: 20px;
        }
    }

    .where-buy__heading {
        @include font(26px, 32px);
        color: $c-black;
        margin-bottom: 30px;

        @include breakpoint(tablet) {

        }
    }

.where-buy__grid {
    display: flex;
    gap: 20px;
    justify-content: center;

    @include breakpoint(tablet) {
        flex-direction: column;
        gap: 30px;
    }

    > * {
        flex-basis: 50%;

        @include breakpoint(tablet) {
            flex-basis: 100%;
        }
    }
}

    .where-buy-partners,
    .where-buy-international__user {
        padding: 50px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .where-buy-partners {
        background-color: #F8F6EF;
        padding: 50px;
        position: sticky;
        @include allowForBars($header-height, top);

        @include breakpoint(tablet) {
            position: static;
            padding: 0;
            background-color: $c-white;
        }
    }

        .where-buy-partner {
            padding: 30px 0;
            border-top: 1px solid $c-border;

            &:last-child {
                border-bottom: 1px solid $c-border;
            }
        }

            .where-buy-partner__title {
                @include font(18px, 26px);
                color: $c-black;
                margin-bottom: 10px;
            }

            .where-buy-partner__logo {
                margin-bottom: 10px;
            }

            .where-buy-partner__content {
                @include font(16px, 26px);
                @include lightText;

                &.richtext {

                    a {
                        @include lightText;
                    }

                    p {
                        margin-bottom: 5px;
                    }
                }
            }

.where-buy-retailers {
    background-color: #F8F6EF;
    padding: 50px;
    border-radius: 3px;
    flex-basis: 50%;

    @include breakpoint(tablet) {
        background-color: $c-white;
        padding: 0 15px;
        border-radius: 0;
    }

    li {
        border-top: 1px solid $c-border;
        border-left: 1px solid $c-border;
        border-right: 1px solid $c-border;

        &:last-child {
            border-bottom: 1px solid $c-border;
            border-radius: 0 0 3px 3px;
        }

        &:first-child {
            border-radius: 3px 3px 0 0;
        }

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            @include font(18px, 21px);

            @include breakpoint(tablet) {
                @include font(16px, 21px);
                padding: 15px 20px;
            }

            &:hover {
                background-color: $c-black;
                color: $c-white;

                svg {
                    fill: $c-white;
                }
            }

            svg {
                width: 13px;
                height: 13px;
                display: block;
            }
        }
    }
}
