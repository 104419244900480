@mixin shadow($angle) {
    content: "";
    position: absolute;
    z-index: 1;
    opacity: 0.15;
    background: linear-gradient($angle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none;
}

.select-bar {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
}

.select-bar--selected {
    opacity: 1;
    pointer-events: auto;
}

.compare {
    overflow: auto;
    max-height: 100vh;
    max-height: 100dvh;
    border: 1px solid $light-grey;

    &.compare--scroll-right:after {
        @include shadow(90deg);
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        z-index: 4;
    }


    @include breakpoint(mobile) {
        border: none;
    }
}

.compare__wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
}

.compare__table {
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.compare__head {
    position: sticky;
    z-index: 4;
    top: 0;

    .compare--scroll-top &:after {
        @include shadow(0deg);
        bottom: -40px;
        left: 0;
        right: 0;
        height: 40px;
    }
}

.compare__foot {
    position: sticky;
    z-index: 4;
    bottom: 0;

    .compare--scroll-bottom &:after {
        @include shadow(180deg);
        top: -40px;
        left: 0;
        right: 0;
        height: 40px;
    }
}

.compare__cell {
    min-width: 175px;
    max-width: 175px;
    padding: 8px 10px 10px 10px;
    white-space: normal;
    border-right: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    background: $c-white;
    box-sizing: border-box;
    @include font(12px, 16px);
    font-weight: 300;
    transition: opacity 250ms, min-width 250ms linear 250ms;
    vertical-align: middle;

    svg {
        width: 17px;
        height: 14px;
    }

    @include breakpoint(mobile) {
        &:first-child {
            min-width: 0;
            max-width: 90px;
        }
    }
}

.compare__cell--deleting {
    opacity: 0.5;
    min-width: 0;
}

.compare__cell--frozen {
    z-index: 2;
    position: sticky;
    left: 0;
    background: #f4f4f4;
    @include font(13px, 16px);
    font-weight: 400;

    .compare--scroll-left &:after {
        @include shadow(270deg);
        right: -40px;
        top: 0;
        bottom: 0;
        width: 40px;
    }

    &:last-child:after {
        display: none !important;
    }

    @include breakpoint(mobile) {
        @include font(12px, 16px);
    }
}
th.compare__cell--frozen {
    z-index: 3;
}
td.compare__cell--frozen {
    left: auto;
}

.compare__cell--blank {
    border-bottom: none;
    padding: 0;
}

.compare__finish {
    display: flex;
    align-items: center;
    gap: 7px;

    .swatch {
        border-radius: 1px;
        border: none;
        outline: 1px solid $c-black;
        outline-offset: 1px;
        width: 16px;
        height: 16px;
    }
}

.compare__cta {
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;

    &:last-child {
        margin-bottom: 0;
    }
}
.compare__cta--button {
    display: flex;
    padding: 9px 20px 10px 20px;
    border-radius: 3px;
    border: 1px solid var(--grey-divider, #EAEAEA);
    background: var(--White, #FFF);
}
.compare__cta--link {
    background: none;
    text-decoration: underline;
}

.compare__image {
    padding: 0;

    img {
        display: block;
        width: 175px;
        height: 175px;
    }
}

.compare__clear {
    display: flex;
    justify-content: space-between;
    @include font(12px, 16px);
    font-weight: 300;
    text-decoration: underline;

    @include breakpoint(mobile) {
        @include font(11px, 16px);
    }

    svg {
        width: 9px;
        height: 10px;
        fill: $c-black;
        transform: translateY(3px);
    }

    &:hover {
        svg {
            fill: #5A5A5A;
        }
    }
}

.compare__product {
    display: flex;
    justify-content: space-between;
}

.compare__actions {
    display: flex;
    margin-right: -3px;
    height: 16px;
    align-self: center;
}

.compare__action {
    svg {
        width: 9px;
        height: 10px;
        margin: 3px;
        pointer-events: none;
        fill: $c-black;
    }

    &:hover {
        svg {
            fill: #5A5A5A;
        }
    }
}
