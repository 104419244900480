.pdp-overlay {
    padding: 50px 60px 60px;
    background: $c-white;
    border-radius: 3px;

    @include breakpoint(tablet) {
        padding: 20px;
        border-radius: 0;
    }

    @include shortViewport {
        padding: 20px 30px;

        @include breakpoint(tablet) {
            padding: 20px;
        }
    }
}

    .pdp-overlay__header {
        margin-bottom: 35px;

        @include shortViewport {
            margin-bottom: 15px;

            @include breakpoint(tablet) {
                margin-bottom: 35px;
            }
        }
    }

        //extend classes from components/_detail.scss

        .pdp-overlay__title {
            @extend .detail__title;

            margin-bottom: 0;

            @include shortViewport {
                @include font(30px, 46px);
            }
        }

        .pdp-overlay__secondary-title {
            @extend .detail__secondary-title;
            @include font(24px, 46px);

            margin-bottom: 0;
        }

    .pdp-overlay__jlp {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        img {
            max-width: 120px;
            height: auto;
            margin-left: 30px;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }
    }

    .pdp-overlay__subtitle {
        //extend class from components/_pdp-grid.scss
        @extend .pdp-grid__title;

        padding: 15px 0 35px 0;

        @include shortViewport {
            padding-bottom: 15px;

            @include breakpoint(tablet) {
                padding-bottom: 35px;
            }
        }
    }

        .pdp-overlay__subtitle--jlp {
            text-transform: none;
            letter-spacing: 1px;

            span {
                @include boldText;

                margin-top: 5px;
                display: block;
            }
        }

    .pdp-overlay__lower {
        text-align: center;
    }

        .pdp-overlay__text {
            @include font(18px, 30px);
            @include lightText;

            color: $c-black;
            padding-bottom: 15px;
        }

    .pdp-overlay__partners {
        border: 1px solid $c-border-light;
        padding: 40px;
        margin-bottom: 40px;

        @include breakpoint(tablet) {
            padding: 20px;
        }

        @include shortViewport {
            margin-bottom: 15px;

            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
        }
    }

    .pdp-overlay__partner-link {
        @include aspect(5 / 3);

        img {
            width: 100%;
            height: auto;
        }
    }
