.media-library {
    position: relative;

    @include breakpoint(tablet) {
        padding-top: 20px;
    }
}

    .media-library__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        padding: 60px 80px 80px;
        border-top: 1px solid $c-border-light;

        @include breakpoint(tablet) {
            padding: 20px 15px;
            border-top: none;
        }
    }

    .media-library__content--lower {

        @include breakpoint(phablet) {
            align-items: center;
        }
    }

    .media-library__content--border-right {
        border-right: 1px solid $c-border-light;

        @include breakpoint(tablet) {
            border-right: none
        }
    }

    .media-library__content--border-top {
        border-top: 1px solid $c-border-light;
    }

        .media-library__title {
            @include font(32px, 40px);

            margin-bottom: 15px;
            color: $c-black;

            @include breakpoint(phablet) {
                @include font(24px, 46px);
            }

            @include breakpoint(tablet) {
                margin-top: 20px;
            }
        }

        .media-library__intro {

            p {
                @include font(14px, 22px);
                @include lightText;

                flex: 1 1 auto;
                color: $c-pdp-grey;
                margin-bottom: 35px;
            }

            .media-library__content--lower & {

                p {
                    @include breakpoint(phablet) {
                        text-align: center;
                    }
                }
            }
        }

        .media-library__actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            a:first-child {
                margin-right: 120px;

                @include breakpoint(phablet) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            @include breakpoint(phablet) {
                margin-right: 0;
                flex-direction: column;
                padding: 0 38px;
            }
        }

        .media-library__image {
            @include aspect(12 / 7);

            position: relative;
            width: 100%;

            @include breakpoint(tablet) {
                width: calc(100% + 30px);
                margin: 0 -15px;
            }

            img {
                @include objectFitImage;
            }
        }

        .media-library__nav {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            border: 1px solid $c-border-light;
            border-radius: 3px;
            padding: 20px 30px;
            margin-bottom: 35px;

            p {
                @include font(18px, 24px);

                color: $c-black;
            }

            a {
                @include font(16px, 24px);

                color: $c-brand;
                text-decoration: underline;
            }

            @include breakpoint(phablet) {
                flex-wrap: wrap;

                p {
                    flex-basis: 100%;

                    @include breakpoint(phablet) {
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }

                a {
                    flex-basis: 50%;
                    text-align: center;
                }
            }
        }

        .media-library__button {
            max-width: 200px;

            @include breakpoint(tablet) {
                align-self: center;
                margin-bottom: 20px;
            }
        }
