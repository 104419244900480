
.page-title {
    @include page-title;
}

.large-title {
    @include large-title;
}

.medium-title {
    @include medium-title;
}

.small-title {
    @include small-title;
}

.label,
label {
    @include label;
}

////////

a {
    text-decoration: none;
    color: $c-black;
}

.link {
    @include link;
}

.link--coloured {
    color: $c-link-coloured;
    border-color: $c-link-coloured;
}

.link--blue {
    color: $c-brand;
    text-decoration: underline;
}

.link--underlined {
    text-decoration: none;

    span {
        @include underlined-link;
    }
}

.link--disabled {
    cursor: none;
    color: $grey3;
    cursor: default;
}

.link--svg {
    fill: $c-grey--mid;

    svg {
        max-width: 8px;
        margin-right: 10px;
        margin-bottom: -8px;
    }
}

.icon-link {
    @include font(12px, 22px);
    color: $c-grey--mid-text;

    span {
        display: inline-block;
        margin-right: 10px;
        text-transform: uppercase;
    }
}

.banner-title {
    font-weight: 500;
    @include rem(62px);
    line-height: calc(1 * 74 / 62);
    margin-bottom: 15px;
}

.banner-subtitle {
    font-weight: 100;
    @include rem(62px);
    line-height: calc(1 * 74 / 62);
    margin-bottom: 15px;
}

@mixin title {
    font-weight: 500;
    @include rem(52px);
    line-height: calc(1 * 64 / 52);
    margin-bottom: 15px;

    @include breakpoint(tablet) {
        @include rem(40px);
        line-height: calc(1 * 64 / 40);
    }

    @include breakpoint(mobile) {
        @include rem(30px);
    }
}

.section-title,
.page-title {
    @include title;
}

@mixin subtitle {
    @include rem(32px);
    font-weight: 500;
    line-height: calc(1 * 44 / 32);
    margin-bottom: 28px;

    @include breakpoint(tablet) {
        @include rem(28px);
        line-height: 30px;
        margin-bottom: 20px;
    }

    @include breakpoint(mobile) {
        @include rem(24px);
        line-height: 26px;
        margin-bottom: 18px;
    }
}

.section-subtitle,
.page-subtitle {
    @include subtitle;
}

.section-subtitle--half,
.page-subtitle--half {
    margin-bottom: 15px;
}

@mixin product_showcase_title {
    font-weight: 500;
    @include rem(52px);
    line-height: calc(1 * 64 / 52);
    margin-bottom: 0;

    @include breakpoint(tablet) {
        @include rem(52px);
    }

    @include breakpoint(mobile) {
        @include rem(30px);
    }
}

.product-showcase-title {
    @include product_showcase_title;
}

.heading3 {
    font-weight: 500;
    @include rem(42px);
    line-height: calc(1 * 54 / 42);
    margin-bottom: 15px;
}

.heading4,
.callout-title {
    font-weight: 500;
    @include rem(32px);
    line-height: calc(1 * 44 / 32);
    margin-bottom: 25px;
}
.heading4--small {
    @include rem(26px);
}

.heading4--smaller {
    @include rem(18px);
}

.heading4__link-underline {
    text-decoration: underline;
    color: $c-black;
}

.heading4__link-underline--neutral {
    color: inherit;
}

.heading4--closer,
.callout-title--closer {
    margin-bottom: 16px;
}

.heading4--snug,
.callout-title--snug {
    margin-bottom: 0;
}

.heading4--offset {
    margin-bottom: 30px;
    @include breakpoint(mobile) {
        margin-bottom: 16px;
    }
}

.heading4--context {
    color: $grey2;

    span {
        color: $c-copy;
    }
}

    .heading4__action {
        float: right;
        margin-top: 15px;
    }

.heading5 {
    @include rem(16px);
    font-weight: 400;
    line-height: 1.5;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.heading5--snug {
    margin-bottom: 0;
}

.copy {
    @include copy;

    a {
        color: $c-link;
        border-color: $c-link;
        @include link;
        font-weight: 400;

        &:hover {
            color: $c-link--hover;
            border-color: $c-link--hover;
        }
    }
}

.intro {
    @include intro;
    margin-bottom: 30px;
}

.intro--light {
    color: $c-white;
}

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-text--left {
            text-align: left;
        }

        .#{$name}-text--right {
            text-align: right;
        }
    }
}

.text--thin {
    @include f-thin;
}

.text--light {
    @include f-light;
}

.text--regular {
    @include f-regular;
}

.text--bold {
    @include f-bold;
}

.text--upper {
    text-transform: uppercase;
}

.text--meta {
    color: $grey2;
}

.text--notice {
    color: $c-success;
}

.text--space-right {
    padding-right: 20px;
}

.p--offset {
    margin-bottom: 30px;
}

.divider--copy {
    @include copy;
    padding-top: 12px;
    padding-bottom: 40px;
}
