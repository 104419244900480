.product-intro {
    padding: 80px 0;
    color: $c-black;

    @include breakpoint(tablet) {
        padding: 40px 0;
    }
}

    .product-intro__images {
        @include aspect(4 / 3);

        position: relative;

        @include breakpoint(tablet) {
            margin-bottom: 25px;
        }
    }

        .product-intro__image {
            position: absolute;
            max-width: 60%;

            &:first-child {
                top: 0;
                left: 0;
            }

            &:last-child {
                right: 0;
                bottom: 0;
            }

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

    .product-intro__content {
        padding-left: 80px;

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

        .product-intro__title {
            @include font(50px, 46px);
            @include thinText;

            margin-bottom: 10px;

            @include breakpoint(tablet) {
                @include font(30px, 37px);
            }
        }

        .product-intro__sub-title {
            @include font(16px, 24px);

            margin-bottom: 15px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);
            }
        }

        .product-intro__text {}
