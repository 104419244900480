
.page-not-found {
    background-image: url(/images/404.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #cfcecb;
    height: 662px;
    color: $c-white;
    position: relative;

    @include breakpoint(tablet) {
        background-position: top;
        background-size: auto 85%;
        max-height: 662px;
        height: calc(100vw - 100px);
    }

    @include breakpoint(phablet) {
        background-position: top;
        background-size: auto 90%;
        max-height: 662px;
        height: calc(100vw - 100px);
    }

    @include breakpoint(mobile) {
        background-position: top;
        background-size: auto 70%;
        max-height: 662px;
        height: calc(100vw - 100px);
    }

    .page-not-found__content {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 75%;
        padding-bottom: 50px;
        @include breakpoint(phablet) {
            width: 90%;
            padding-bottom: 45px;
        }
        @include breakpoint(mobile) {
            width: 95%;
            padding-bottom: 25px;
        }

        h1, p {
            @include rem(40px);
            line-height: 1.25;
            @include breakpoint(phablet) {
                @include rem(30px);
            }
            @include breakpoint(mobile) {
                @include rem(24px);
            }
        }

        p {
            a {
                color: $c-black;
                text-decoration: underline;
            }
        }
    }
}
