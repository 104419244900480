.bio {
    padding: 80px;

    @include breakpoint(tablet) {
        padding: 40px 20px;
    }
}

    .bio__image {
        @include aspect(4 / 3);

        margin-bottom: 15px;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .bio__title {
        @include font(26px, 46px);

        color: $c-bio-title;

        @include breakpoint(tablet) {
            @include font(20px, 46px);
        }
    }

    .bio__text {}
