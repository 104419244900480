// Galleries

.gallery__inner {
    position: relative;
    @include clearfix;
}

.gallery .slider__nav {
    padding-top: 20px;
    text-align: center;
    text-transform: uppercase;
}

// Outer aligned gallery

.gallery--outer {
    position: relative;

    .slider__nav {
        text-align: center;
        font-size: 0;
        padding-top: 35px;
    }

    .slider__nav__item {
        display: inline-block;
        width: 40px;
        height: 5px;
        margin: 0 3px;
        background: white;
    }

    .slider__nav__item--active {
        background: rgba(255,255,255,0.25);
    }

    .slider__next,
    .slider__prev {
        // Normal margin + half new nav padding bit
        margin-top: -37px + -17px;
    }
}

.gallery__captions__container {
    padding-top: 15px;
}

.gallery__captions {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    bottom: -35px;
    overflow: hidden;
    width: 400px;
    height: 50px;
}

.gallery__captions--no-offset {
    bottom: auto;
}

.gallery__caption {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: transform .3s ease;
    transform: translateY(50px);
    font-style: italic;

    a {
        font-style: normal;
        color: white;
        text-decoration: underline;
    }

    &.gallery__caption--show {
        transform: translateY(0);
    }
}

.gallery__caption--default {
    line-height: 1.25em;

    a {
        color: inherit;
    }
}

.gallery--outer__link {
    display: block;
    position: absolute;
    right: 0;
    bottom: -5px;
    color: white;
    text-decoration: underline;
}
.gallery--outer__link--default {
    color: inherit;
    bottom: auto;
}

@include breakpoint(tablet) {
    .gallery--outer__link {
        position: static;
        text-align: center;
        margin-top: 30px;
    }

    .gallery__captions {
        position: relative;
        text-align: center;
        margin-top: 0px;
        width: 100%;
    }
}

// Gallery type selector

.gallery-type-selector {
    position: absolute;
    right: 0;
    bottom: 0;
}

@include breakpoint(tablet) {
    .gallery-type-selector {
        position: static;
    }
}

    // Hotspot gallery
    .gallery__crosshair {
        $size: 26px;
        $centre-translate: calc($size / 2);
        position: absolute;
        transform: translateX(-($centre-translate)) translateY(-($centre-translate));
        height: $size;
        width: $size;
        cursor: pointer;
        z-index: 2;

        &:before,
        &:after {
            content: '';
            height: $size ;
            width: $size;
            display: block;
            position: absolute;
        }

        .icon-plus-circled {
            display: block;
        }

        .icon-minus-circled {
            display: none;
        }
    }

        .gallery__crosshair--active {
            .icon-plus-circled {
                display: none;
            }

            .icon-minus-circled {
                display: block;
            }
        }

    .gallery__product {
        width: 250px;
        padding: 15px;
        background: $c-white;
        position: absolute;
        z-index: 3;
    }

        .gallery__product--hidden {
            display: none;
        }

        .gallery__product__image {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
        }

        .gallery__product__meta {
            @include copy;
            margin-bottom: 0;

            &:last-of-type {
                margin-bottom: 10px;
            }
        }
