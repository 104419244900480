.popup-content {
    position: relative;
}

    .popup-content__container {
        padding: 30px 70px;
        background: $c-white;
        text-align: center;
        overflow: hidden;

        .notice-popup & {
            padding: 30px 40px 6px;
        }

        @include breakpoint(tablet) {
            padding: 20px;
        }
    }
        .popup-content--left-align {
            text-align: left;
        }
        .popup-content--right-align {
            text-align: right;
        }

        .popup-content__copy {
            margin: 0 0 30px;
        }

            .popup-content__field {
                margin: 0;
                text-align: left;

                & input:disabled {
                    color: $grey3;
                }
            }

        .popup-content__links {
            font-size: 0;

            p {
                @include rem(14px);

                line-height: 1*25/15;
                letter-spacing: 1px;
                margin: 0 20px;

                @include breakpoint(tablet) {
                    margin: 20px 0;
                }
            }

            * {
                display: inline-block;
                vertical-align: middle;

                @include breakpoint(tablet) {
                    display: block;
                }
            }
        }

            a.popup-content__link {

                @include breakpoint(mobile) {
                    display: block;
                }
            }

        .popup-content__split-half {
            width: 50%;
            position: relative;
            display: inline-block;
            font-size: 0;
            text-align: center;
            vertical-align: top;

            @include breakpoint(tablet) {
                width: 100%;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    .popup-content .mfp-close {
        @include breakpoint(mobile) {
            right: 16px;
        }
    }

    .popup-content__container--padded {
        padding: 80px 40px 60px;
    }


.geo-popup {}

    .geo-popup__choice {
        .popup-content__link {
            margin-bottom: 20px;
            display: inline-block;
            width: 70%;
        }

        p {
            @include breakpoint(tablet) {
                margin: 0 0 20px;
            }
        }
    }

        .geo-popup__choice--or {
            &:before {
                content: 'or';
                position: absolute;
                right: -10px;
                top: 25px;
                transform: translateY(-50%);
                font-size: 20px;

                @include breakpoint(tablet) {
                    @include rem(25px);
                    right: 50%;
                    transform: none;
                    top: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    bottom: -20px;
                }
            }
        }

.add-to-basket-modal {}

    .product__price,
    .product__name {
        @include rem(18px);
    }

    .product__finish {
        @include rem(16px);
    }

    .product__name {
        margin-bottom: 5px;
    }

    .product__stock {
        color: $c-brand;
        // min-height: 60px;
    }
