// Select replace
//select.input {
//    @include rem(14px);
//    color: $c-grey--mid;
//    width: 100%;
//    text-indent: 12px;
//    margin: 0;
//    font-family: Brown, arial, sans-serif;
//    padding: 5px 3px 4px;
//    height: 45px;
//    -webkit-appearance: menulist-button;
//    border-color: #E3E3E3;
//    margin: 0;
//    line-height: 1;
//    background: #fff;
//    border: 0;
//    border-radius: 0;
//    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
//    -moz-appearance: none;
//    box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
//
//    &:hover {
//        cursor: pointer;
//    }
//
//    option {
//        text-transform: none;
//    }
//}
//.select-replace {
//    display: block;
//    position: relative;
//    width: auto;
//    margin: 0;
//    padding: 0;
//    background: white;
//    overflow: hidden;
//    box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
//
//    select.input {
//        opacity: 0;
//        position: relative;
//        z-index: 2;
//        width: 100%;
//    }
//
//    &:after {
//        content: "\f107";
//        display: block;
//        position: absolute;
//        right: 15px;
//        top: 15px;
//        @extend .fa;
//        @include rem(13px);
//        pointer-events: none;
//    }
//}
//
//.select-replace--disabled {
//    &:after {
//        opacity: 0.3;
//        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
//    }
//
//    p.select-replace__value, .select-replace__value {
//        color: #999;
//    }
//
//    select {
//        &:hover {
//            cursor: default;
//        }
//    }
//}
//
//    .select-replace__value {
//        @include rem(14px);
//        position: absolute;
//        top: 0;
//        left: 0;
//        height: 100%;
//        width: 100%;
//        z-index: 1;
//        vertical-align: text-top;
//        display: block;
//        margin-bottom: 0;
//        padding: 0 40px 0 20px;
//        box-sizing: border-box;
//        text-overflow: ellipsis;
//        overflow: hidden;
//        white-space: nowrap;
//        word-break: break-all;
//        color: $c-grey--mid;
//        text-decoration: none;
//        text-transform: uppercase;
//        line-height: 1*45/14;
//        text-align: left;
//    }
//
//// Inline fancy selects
//
//.select-inline {
//    display: inline-block;
//    vertical-align: top;
//
//    select.input,
//    .select-replace {
//        height: auto;
//        text-transform: none;
//        text-indent: 0;
//        font-size: inherit;
//        box-shadow: none;
//        background: none;
//    }
//
//    .select-replace {
//        &:after {
//            top: 1px;
//            right: 0;
//            color: $c-grey--mid;
//        }
//    }
//
//    .select-replace__value {
//        line-height: 1;
//        padding: 0 20px 0 0;
//        text-transform: none;
//        font-size: inherit;
//        border: none !important;
//    }
//}

.select--margin-bottom {
    margin-bottom: 10px;
}

// Standard form elements


// Standard forms
.form {
    @include clearfix;
}

.form__section--padded{
    padding: 22px;
}
.form__section--padded--alt{
    padding: 15px 22px;
}

.product:last-child .form__section--padded--alt {
    padding-bottom: 30px;
}

.form__fieldset {
    & > *:last-child, & > .richtext > *:last-child {
        margin-bottom: 0;
    }
}

.form__fieldset--padded {
    padding: 40px 0 !important;
}

.form__fieldset--grid {
    padding-bottom: 10px !important;
}

.form__fieldset--divider {
    border-bottom: 1px solid $c-section-border;
}

.form__feildset--divider--dark {
    border-color: $c-lines;
}

.form__fieldset--top-divider {
    border-top: 1px solid $c-section-border;
}

.form__fieldset--right-divider {
    border-right: 1px solid $c-section-border;
    padding-right: 30px !important;
}

.form__fieldset--left-divider {
    border-left: 1px solid $c-section-border;
    padding-left: 30px !important;
    margin-left: -1px;
}

.form__fieldset-label {
    text-transform: uppercase;
    @include rem(16px);
    margin-bottom: 30px;

    a {
        display: inline-block;
        text-transform: none;
        @include rem(15px);
        margin-left: 20px;

        span {
            text-decoration: underline;
        }

        i {
            margin-left: 15px;
        }
    }
}

.form p.select-replace__value {
    border: 1px solid $c-lines;
}

.form__footer {
    text-align: left;
    clear: left;
    @include clearfix;

    .link {
        display: block;
        float: left;
        margin-top: 10px;
    }

    label {
        color: $c-link;
    }

    .wrap {
        float:none;
    }
}

.form__footer__left {
    float: left;
}

.form__footer__right {
    float: right;
}

.field--submit {
    margin-right: 0;
}

// Inline forms

.form--inline {
    text-align: center;
    font-size: 0;

    &.form--labels {
        .field--submit {
            margin-top: 24px;
        }
    }
}

.form--right {
    text-align: right;
}

.form--inline {
    .field--file {
        label, .file-name {
            display: none;
        }
        .file-input {
            overflow: hidden;
            position: relative;
            input[type=file] {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                padding: 50px;
                cursor: pointer;
            }
        }
    }
}

.form--search {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    background: $c-border-light;

    @include breakpoint(tablet) {
        padding-left: $site-gutter--mobile;
        padding-right: $site-gutter--mobile;
    }

    .field {
        margin-bottom: 0;
    }

    .field--input,
    .field--input-flush {
        flex: 0 1 470px;
        max-width: 470px;
        margin-right: 15px;

        @include breakpoint(tablet) {
            margin-right: 10px;
        }
    }

    .field--input-flush {
        margin-right: 0;
    }

    input {
        @include font(14px, 20px);
        @include lightText;
    }
}


// Cleverlabels support styling

div.password .cleverpassword {
    position: relative;
}

div.password input.password {
    position: relative;
}

div.password input.cleverlabel {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
}

.field.field--file .import-file-label {
    position: relative;
    top: unset;
    left: unset;

    span {
        width: 100%;
        color: inherit;
    }

    .import-file-field {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.form__title {
    padding: 15px 30px;
    background: $c-brand;
    color: $c-white;
    @include rem(25px);
}

.form__section-title{
    padding: 10px 30px;
    background: $background-grey;
    @include f-regular;
    @include rem(14px);
    margin-top: 15px;

    display: flex;
    box-sizing: border-box;
    width: 100%;
}
    .form__section-title__title {
        flex: 1 1 auto;
        flex-wrap: nowrap;
        padding-right: 15px;
    }

    .form__section-title__close {
        @include rem(25px);
        position: relative;
        cursor: pointer;

        &:before {
            content: "×";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -3px;
        }
    }

.select-wrap {
    font-size: 0;

    .wrap {
        display:inline-block;
        width: 50%;
        padding: 0;

        &:first-child {
            padding-right: 7px;
        }

        &:last-child {
            padding-left: 8px;
        }
    }
}

.form-privacy {
    margin-bottom: 20px;
    @include font(14px, 20px);
    @include lightText;
    text-align: left;

    a {
        text-decoration: underline;
        text-underline-position: under;

        &:hover {
            color: $c-brand;
        }
    }
}
