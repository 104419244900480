.notice-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $c-grey--dark;
    z-index: map-get($z-indexes, primary-header);
    height: $notice-height;
    display: none;
    box-sizing: border-box;

    @include breakpoint(tablet) {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;

        padding: 10px 20px 11px;
        height: auto;

        p {
            padding: 0;
        }
    }

    @media all and (-ms-high-contrast: none) and (max-width: 1023px), (-ms-high-contrast: active) and (max-width: 1023px) {
        position: fixed !important;
        height: 80px;
    }

    @media all and (-ms-high-contrast: none) and (max-width: 700px), (-ms-high-contrast: active) and (max-width: 700px) {
        height: 103px;
    }

    @media all and (-ms-high-contrast: none) and (max-width: 500px), (-ms-high-contrast: active) and (max-width: 500px) {
        height: 128px;
    }

    .has-notice-bar &:not(.notice-bar--hide) {
        display: block;
    }

    .has-geo-bar & {
        border-top: 1px solid #666;
    }

    .body--admin & {
        top: 44px;
    }

    .body--debug & {
        top: 20px;
    }

    .body--admin.body--debug & {
        top: 64px;
    }

    .body--admin.has-geo-bar & {
        top: 44px + 49px;
    }

    .body--debug.has-geo-bar & {
        top: 20px + 49px;
    }

    .body--admin.body--debug.has-geo-bar & {
        top: 64px + 49px;
    }

    .wrap {
        height: 100%;
        display: flex;
        justify-content: center;

        .notice-bar__text {
            @include font(16px, 26px);
            @include regularText;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            color: $c-white;

            p {
                margin-right: 10px;
            }
        }

        .notice-bar__link {
            text-decoration: underline;
            transition: color .3s ease;
            color: $c-white;

            &:hover {
                color: $c-brand;

                @include breakpoint(tablet) {
                    color: inherit;
                }
            }
        }

        p {

            span {
                @include regularText;

                padding: 0 0.3em;
                color: $c-white;
            }
        }

        & > a {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            padding: 10px 20px;

            @include breakpoint(tablet) {
                align-items: start;
            }

            svg {
                fill: $c-white;
            }
        }
    }
}
