.account-sidebar {

    @include breakpoint(tablet) {
        margin: 0 -15px;
        width: calc(100% + 30px)
    }
}

    .account-sidebar__inner {
        padding: 30px 30px 20px;
        background: $light-grey;
        border-radius: 3px;

        @include breakpoint(tablet) {
            padding: 20px;
        }
    }

    .account-sidebar__details {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba($c-account-border, 0.3);
    }

        .account-sidebar__welcome {
            @include font(18px, 24px);
            @include lightText;
            margin-bottom: 10px;

            span {
                @include regularText;
            }
        }

    .account-sidebar__nav {
        display: inline-block;
        padding-top: 15px;

        @include breakpoint(tablet) {
            display: block;
            padding-top: 25px;
        }
    }

        .account-sidebar__nav-item {
            display: block;
            @include font(16px, 22px);
            @include lightText;
            padding: 10px 0;

            &:hover,
            &--active {
                color: $c-brand;
            }

            &--active {
                @include regularText;
            }
        }
