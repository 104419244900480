.sustainability-intro {
    padding: 70px 0;
    background: #F0EFEB;
    overflow: hidden;

    @include breakpoint(tablet) {
        padding: 40px 5px;

        & .grid--flex {
            flex-direction: column;
        }
    }

    &__left {
        border-right: 1px solid #D2D2D2;
        text-align: right;
        padding-right: 45px;
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            border-right: none;
            padding-right: 0;
            text-align: center;
        }
    }

    &__right {
        padding-left: 45px;

        @include breakpoint(tablet) {
            padding-left: 0;
            text-align: center;
        }
    }
}

    .sustainability-intro__title {
        font-family: $f-alternate-stack;
        @include font(50px, 46px);

        @include breakpoint(tablet) {
            @include font(40px, 34px);

            &:after {
                content: '';
                display: block;
                width: 70px;
                height: 1px;
                margin: 22px auto;
                background: #D2D2D2;
            }
        }
    }

    .sustainability-intro__text {
        max-width: 400px;
        font-weight: normal;
    }


.sustainability-pillars {
    &__title {
        @include font(40px, 48px);
        font-weight: normal;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            @include font(30px, 34px);
        }
    }
}
.sustainability-pillar {
    text-align: center;
    position: relative;
    padding-bottom: 45px;

    @include breakpoint(tablet) {
        padding: 0 10px 45px;
        margin-bottom: 20px;
    }

    &--flush {
        @include breakpoint(tablet) {
            margin-bottom: 0;
        }
    }

    &__title {
        font-family: $f-alternate-stack;
        @include font(40px, 46px);
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            @include font(30px, 34px);
        }
    }

    &__text {
        font-weight: normal;
        margin-bottom: 30px;
    }

    &__button {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
    }

    &__image {
        margin-bottom: 30px;
    }
}

.sustainability-design {
    background-color: #F0EFEB;

    .grid--flex {
        @include breakpoint(tablet) {
            flex-direction: column-reverse;
        }
    }
}

    .sustainability-design__images {
        @include aspect(10 / 9);

        position: relative;

        @include breakpoint(tablet) {
            margin-left: 30px;
            margin-bottom: 30px;
        }
    }

        .sustainability-design__image {
            position: absolute;
            max-width: 70%;

            &:first-child {
                top: 0;
                left: 5%;
                z-index: 1;
            }

            &:nth-child(2) {
                right: 0;
                bottom: 0;
            }

            &:last-child {
                left: 0;
                bottom: 0;
                max-width: 30%;
            }

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

.sustainability-culture {}

    .sustainability-culture__image {
        @include aspect(3 / 2);

        position: relative;

        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }

        img {
            @include cover;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

.sustainability-content {
    max-width: 580px;

    &--right {
        padding-left: 80px;
    }

    @include breakpoint(tablet) {
        padding-left: 0;
    }
}

    .sustainability-content__title {
        font-family: $f-alternate-stack;
        @include font(40px, 46px);
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            @include font(30px, 34px);
        }
    }

    .sustainability-content__text {
        font-weight: normal;
        margin-bottom: 30px;
    }