.order-info {
    padding-top: 12px;
}

    .order-info__title {
        color: $c-black;
        @include rem(12px);
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .order-info__content {
        background-color: #F4F4F4;
        padding: 10px 15px;
        border-left: #333333 1px solid;

        .richtext {
            color: $c-black;
        }
    }
