.global-callout {
    @include lightondark;

    position: relative;
    overflow: hidden;
}

    .global-callout__link {
        display: block;
        text-decoration: none;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.3;
        }
    }

        .global-callout__image {
            @include aspect(2 / 1);

            @include breakpoint(tablet) {
                @include aspect(3 / 2);
            }

            img {
                @include cover;

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

                .global-callout__link:hover & {
                    transform: scale(1.2);
                }
            }

            .global-callout--three-col & {
                @include aspect(7 / 5);

                @include breakpoint(tablet) {
                    @include aspect(5 / 4)
                }
            }
        }

        .global-callout__content {
            position: absolute;
            top: 40px;
            right: 25px;
            bottom: 40px;
            left: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            z-index: 1;

            @include breakpoint(tablet) {
                top: 20px;
                bottom: 20px;
            }
        }

        .global-callout__title {
            @include font(34px, 46px);

            position: absolute;
            top: 50%;
            right: 20px;
            left: 20px;
            color: $c-white;
            transform: translateY(-50%);
            text-align: center;

            @include breakpoint(tablet) {
                @include font(30px, 34px);
            }

            .global-callout--dark & {
                color: $c-dark-callout-title;
            }

            span {
                @include thinText;
            }
        }
