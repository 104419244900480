.social {}

    .social__list {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
    }

        .social__item {
            display: flex;
            margin: 8px;
        }

            .social__link {
                display: flex;
                align-items: center;

                svg {
                    display: block;
                    fill: $c-white;
                }
            }
