.number-nav {
    background: $c-white;
    border-bottom: 1px solid $c-architectural-border;

    @include breakpoint(tablet) {
        text-align: center;
    }
}

    .number-nav--sticky {
        position: sticky;
        z-index: map-get($z-indexes, sticky-nav);
    }

    .number-nav--scrolling {
        .number-nav__link__number {
            font-size: 0;
        }
    }

    .number-nav__link {
        display: inline-block;
        padding: 10px 20px 20px 0;

        &:hover {
            .number-nav__link__title {
                color: $c-architectural-red;
            }
        }

        @include breakpoint(tablet) {
            padding: 10px;
        }

        .number-nav__link + & {
            margin-left: 30px;

            @include breakpoint(tablet) {
                margin-left: 0;
            }
        }
    }

        .number-nav__link--active {
            .number-nav__link__title {
                color: $c-architectural-red;
            }
        }

        .number-nav__link__number {
            @include rem(50px);
            transition: all 0.2s ease-out;

            line-height: calc(61 / 50);
            color: $c-architectural-number;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .number-nav__link__title {
            @include rem(16px);

            line-height: calc(19 / 16);
            font-weight: 300;
            display: block;
            margin-top: -9px;
            color: $c-black;

            @include breakpoint(tablet) {
                margin-top: 0;
            }
        }
