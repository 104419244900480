.search {
    display: none;
}

.search--activating {
    @include allowForBars((
        default: $header-height,
        tablet: $header-top-height--mobile,
    ), top);

    position: fixed;
    right: 0;
    left: 0;
    display: grid;
    border-top: 1px solid $c-border-light;
    grid-template-columns: repeat(12, minmax(0, 1fr)); // Mimic the header
    background: $c-white;
    opacity: 0;
    transition: opacity .3s ease;
    padding: 0 40px;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile;
        grid-template-columns: 1fr;
        border-top: none;
    }
}

.search--active {
    opacity: 1;
}

    .search__fieldset {
        position: relative;
        display: flex;
        width: 100%;
        margin: 28px 0;
        grid-column: 3 / 11;

        @include breakpoint(tablet) {
            margin: 20px 0;
            padding: 0;
            grid-column: span 1;
        }
    }

        .search__label {
            @include hideSafely;
        }

        input.search__input {
            @include font(26px, 26px);
            @include lightText;

            min-width: 0;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding: 0 0 18px 0;
            color: $c-black;
            background: transparent;

            @include breakpoint(tablet) {
                @include font(24px, 29px);
            }

            @include placeholderStyles {
                color: $c-detail-subtitle;
            }

            &:focus {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
        }

        .search__submit {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            padding: 0 0 18px 0;

            svg {
                fill: $c-black;
            }
        }
