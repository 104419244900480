.import-footer {
	@include font(16px, 26px);
	@include lightText;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	color: $c-black;
	background: $c-import-background;

	@include breakpoint(tablet) {
		@include font(14px, 26px);

		flex-direction: column;
		margin: 30px #{-$site-gutter--mobile} 0;
		padding: 20px 15px;
	}

	svg {
		margin-right: 15px;
		fill: $c-import-action-required;

		@include breakpoint(tablet) {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}

	p {

		span {
			@include regularText;
		}
	}

	&--complete {
		svg {
			fill: $c-import-available;
		}
	}
}

	.import-footer__text {
		display: flex;
		align-items: center;
		margin-right: 15px;

		@include breakpoint(tablet) {
			margin-right: 0;
			margin-bottom: 12px;
		}
	}
