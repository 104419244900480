.import-overlay {
    text-align: center;

    @include breakpoint(phablet) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

    .import-overlay__figure {
        margin-bottom: 20px;

        svg {
            fill: $c-black;
        }
    }

    .import-overlay__text {
        @include font(14px, 17px);

        margin-bottom: 20px;
        color: $c-black;

        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }
