.stockists-world-map {
    height: 670px;
    width: 100%;

    @include breakpoint(tablet) {
        display: none;
    }

    .gm-style {

        > div:not(.gmnoprint) div:not(.stockists-world-map__close) {
            font-family: $f-default-stack !important;
        }
    }
}

    .stockists-world-map__inner {
        font-family: Brown, arial, sans-serif;
        @include rem(16px);
        line-height: 30px;
        position: absolute;

        background: white;
        padding: 14px;
        border: 1px solid $light-grey;
        border-radius: 3px;
        box-shadow: 0 8px 15px 0 rgba(0,0,0,0.08);

        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            bottom: -28px;
            left: 50%;
            box-sizing: border-box;
            margin-left: -7px;
            border: 14px solid $light-grey;
            border-color: transparent transparent #ffffff #ffffff;
            transform-origin: 0 0;
            transform: translateX(-50%) rotate(-45deg);
            box-shadow: -8px 8px 8px 0 rgba(0,0,0,0.08);
        }
    }

    .stockists-world-map__close {
        position: absolute;
        top: 8px;
        right: 16px;
        font-family: FontAwesome;
        opacity: 0.25;
        &:hover {
            opacity: 1;
        }
    }

    .stockists-world-map__close::before {
        content:"\f00d";
    }

    .stockists-world-map__inner--single {
        width: 360px;
        white-space: nowrap;
    }

    .stockists-world-map__inner--cluster {
        min-width: 300px;
        padding: 10px;
    }

    .stockists-world-map__message {
        max-width: 420px;
        white-space: normal;
    }

    .stockists-world-map__detail {
        @include rem(16px);

        display: flex;
        width: 100%;
        margin-bottom: 10px;
        color: $c-copy;
        padding: 0;

        strong {
            text-transform: uppercase;
        }

        @include breakpoint(tablet) {
            padding: 0 25px;
        }
    }

        .stockists-world-map__detail__header {
            @include font(16px,19px);
            color: $c-black;
            width: 32%;
            margin-bottom: 0;

            @include breakpoint(smallest) {
                width: 100%;
            }
        }

        .stockists-world-map__detail__value {
            @include f-light;
            @include font(14px,22px);

            max-width: 68%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .stockists-world-map__detail__value--website {
            text-decoration: underline;
        }

        .stockists-world-map__detail__value--address {
            white-space: pre-line;
        }

    .stockists-world-map__logo {
        max-width: 125px !important;
        margin-bottom: 10px;
    }

    .stockists-world-map__title {
        @include font(18px,22px);
        color: $c-black;
        white-space: normal;
    }

    .stockists-world-map__category {
        @include f-light;
        @include font(14px,28px);

        margin-bottom: 15px;

        @include breakpoint(tablet) {
            margin-bottom: 10px;
        }
    }

    .stockists-world-map__links {
        margin: 0 -14px -14px;
        padding: 20px;
        border-top: 1px solid $grey3;

        @include breakpoint(tablet) {
            padding: 5px 20px;
        }
    }

    .stockists-world-map__links--extra-space {
        margin: 0 -16px -28px;

        @include breakpoint(tablet) {
            margin: 0;
            padding: 0;
        }
    }

    .stockists-world-map__links--short {
        padding: 0 20px;
    }

        .stockists-world-map__map-link {
            display: block;
            font-weight: bold;
            text-decoration: underline;
            text-decoration-skip-ink: auto; // future proofing
            text-decoration-skip: ink; // deprecated

            &.button span {
                padding: 10px 20px;
            }
        }
