.guiding-principle-intro {
    text-align: center;
    max-width: 655px;
    padding: 50px 0;
    margin: 0 auto;
}

.guiding-principle-row {

    padding-bottom: 90px;

    &:last-child {
        padding-bottom: 104px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 25px;

        &:last-child {
            padding-bottom: 35px;
        }
    }

    > .guiding-principle {
        margin-left: auto;
        margin-right: auto;
    }

    &--left {
        > .guiding-principle {
            margin-right: auto;
            margin-left: unset;
        }
    }

    &--right {
        > .guiding-principle {
            margin-left: auto;
            margin-right: unset;
        }
    }
}


.guiding-principle {
    padding-left: 55px;
    max-width: 655px;
    position: relative;

    @include breakpoint(tablet) {
        padding-left: 28px;
    }

    &--light-green {
        .guiding-principle__text,
        .guiding-principle__stage {
            background-color: #BEC1B6;
        }
    }

    &--copper {
        .guiding-principle__text,
        .guiding-principle__stage {
            background-color: #D8BEAB;
        }
    }

    &--cream {
        .guiding-principle__text,
        .guiding-principle__stage {
            background-color: #FDF1E7;
        }
    }

    &--green {
        .guiding-principle__text,
        .guiding-principle__stage {
            background-color: #A6A48E;
        }
    }

    &--grey {
        .guiding-principle__text,
        .guiding-principle__stage {
            background-color: #F0EFEB;
        }
    }
}

    .guiding-principle__image {
        @include aspect(2 / 1);

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .guiding-principle__text {
        @include font(18px, 30px);
        padding: 20px;
        text-align: center;
        border-radius: 0 0 3px 3px;

        @include breakpoint(tablet) {
            @include font(16px, 24px);
            @include lightText;
            padding: 5px 10px;
        }
    }

    .guiding-principle__stage {
        position: absolute;
        top: 0;
        left: 0;
        font-family: $f-alternate-stack;
        width: 55px;
        text-align: center;
        border-radius: 3px 0 0 3px;

        span {
            display: block;
            @include font(26px, 40px);
            padding: 12px;

            @include breakpoint(tablet) {
                @include font(16px, 40px);
                padding: 0 7px;
            }
        }

        @include breakpoint(tablet) {
            width: 28px;
        }
    }

.guiding-principle-line {}

    .guiding-principle-line--center,
    .guiding-principle-line--left,
    .guiding-principle-line--right, {
        position: relative;
    }

    .guiding-principle-line__piece {
        position: absolute;
        background: $c-guiding-principle-line;
    }

        .guiding-principle-line__piece--l-only {

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .guiding-principle-line__piece--horizontal {
            width: 0;
            height: 1px;
        }

        .guiding-principle-line__piece--vertical {
            width: 1px;
            height: 0;
        }

        // Center lines
        .guiding-principle-line__piece--center_1 {
            bottom: 45px;
            right: calc(50% - 27.5px);
            height: 45px;

            @include breakpoint(tablet) {
                bottom: 0;
                right: calc(50% - 14px);
                height: 25px;
            }
        }

        .guiding-principle-line__piece--center_2 {
            bottom: 45px;
            right: calc(50% - 27.5px);
            width: 300px;
        }

        .guiding-principle-line__piece--center_3 {
            bottom: 0;
            right: calc(50% - 27.5px + 300px);
            height: 46px;
        }

        // Left lines
        .guiding-principle-line__piece--left_1 {
            bottom: 45px;
            left: 355px;
            height: 45px;

            @include breakpoint(tablet) {
                bottom: 0;
                left: calc(50% + 14px);
                height: 25px;
            }
        }

        .guiding-principle-line__piece--left_2 {
            bottom: 45px;
            left: 355px;
            width: calc(100% - 355px - 300px);
        }

        .guiding-principle-line__piece--left_3 {
            bottom: 0;
            right: 300px;
            height: 46px;
        }

        // Right line
        .guiding-principle-line__piece--right_1 {
            bottom: 45px;
            right: 300px;
            height: 45px;

            @include breakpoint(tablet) {
                bottom: 0;
                right: calc(50% - 14px);
                height: 25px;
            }
        }

        .guiding-principle-line__piece--right_2 {
            bottom: 45px;
            right: 300px;
            width: calc(100% - 355px - 300px);
        }

        .guiding-principle-line__piece--right_3 {
            bottom: 0;
            left: 355px;
            height: 46px;
        }

        // Last line
        .guiding-principle-line__piece--last_1 {
            bottom: 59px;
            right: 300px;
            height: 45px;

            @include breakpoint(tablet) {
                bottom: 10px;
                right: calc(50% - 14px);
                height: 25px;
            }
        }

        .guiding-principle-line__piece--last_2 {
            bottom: 59px;
            right: 300px;
            width: calc(50% - 300px);
        }

        .guiding-principle-line__piece--last_3 {
            bottom: 14px;
            right: 50%;
            height: 45px;
        }

        .guiding-principle-line__piece--last_4 {
            width: 14px;
            height: 14px;
            bottom: 0;
            right: 50%;
            border-radius: 50%;
            transform: translateX(50%);

            @include breakpoint(tablet) {
                width: 10px;
                height: 10px;
                bottom: 0;
                right: calc(50% - 14px);
            }
        }
