@use 'sass:math';

.nav-callout-header {
    @include font(16px, 16px);

    color: $c-black;
    margin-bottom: 20px;
    grid-column: 1  / -1;

    @include breakpoint(tablet) {
        display: none;
    }
}

.nav-callout {
    display: block;
}

    .nav-callout__image {
        margin-bottom: 7px;

        @include breakpoint(tablet) {
            display: none;
        }

        img {
            width: 100%;
            height: auto;
            transition: transform 350ms ease-in;

            .nav-callout:hover & {
                transform: scale(1.075);
            }
        }

        .nav-callout--portrait & {
            @include aspect(math.div(4, 5));
        }

        .nav-callout--landscape & {
            @include aspect(math.div(5, 3));
        }

        .nav-callout--boxy & {
            @include aspect(math.div(5, 4));
        }
    }

    .nav-callout__subtitle {
        @include lightText;
        @include font(12px, 22px);

        letter-spacing: 2px;
        text-transform: uppercase;
        color: $c-black;
        margin-top: 15px;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .nav-callout__title {
        @include font(14px, 22px);
        @include border-line-grow($c-black, false);

        color: $c-black;
        display: inline-block;

        @include breakpoint(tablet) {
            @include font(16px, 22px);
            @include lightText;

            padding: 6px $site-gutter--mobile;
            color: $c-black;
        }
        
        .nav-callout:hover & {
            @include border-line-trigger;
        }
    }
