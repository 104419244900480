.blocks .block.block--sus-stats {
    margin: 0 auto;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-stats {
    padding: 80px 0 90px;
    max-width: calc((5 / 6) * 100%);
    margin: 0 auto;

    @include breakpoint(tablet) {
        padding: 50px 0;
        max-width: none;
    }

    .block__title {
        @include font(54px, 62px);

        color: $c-black;
        margin-bottom: 35px;
        text-align: center;
        letter-spacing: 1.5px;

        @include breakpoint(tablet) {
            @include font(32px, 40px);

            margin-bottom: 30px;
        }
    }

    .block__banner {
        @include aspect(3 / 1);

        margin-bottom: 90px;

        @include breakpoint(tablet) {
            @include aspect(2 / 1);

            margin-bottom: 35px;
        }

        img {
            position: relative;
            width: 100%;
            height: auto;
        }
    }

    .block__content {
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            flex-direction: column;
            align-items: center;
        }
    }

        .block__item {
            max-width: 380px;
            margin: 0 40px;
            text-align: center;

            @include breakpoint(tablet) {
                margin: 0 20px;

                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }

            .block__stats {
                @include font(32px, 48px);

                color: $c-black;
                letter-spacing: 1px;
                margin-bottom: 10px;

                @include breakpoint(tablet) {
                    @include font(24px, 30px);

                    margin-bottom: 7px;
                }

                span {
                    @include font(54px, 62px);

                    letter-spacing: 1.5px;

                    @include breakpoint(tablet) {
                        @include font(32px, 48px);
                    }
                }
            }

            .block__text {
                @include lightText;
                @include font(16px, 24px);

                color: $c-black;

                @include breakpoint(tablet) {
                    @include font(14px, 22px);
                }
            }
}
