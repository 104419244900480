.block--social {
    --richtext-color: var(--block-text-color, $c-black);
    color: var(--block-text-color, $c-black);
    background: var(--block-background, transparent);

    @include breakpoint(tablet) {
        padding: 0;
        padding-top: 35px;
    }

    @include breakpoint(phablet) {
        padding-top: 0;
    }

    .wrap {
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
            flex-direction: column-reverse;
            margin: 0;
        }

        @include breakpoint(phablet) {
            padding: 0;
        }
    }

    .blocks &:last-child {

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }
    }

    .block__content {
        width: 413px;
        padding: 30px 60px 30px 0;

        @include breakpoint(tablet) {
            width: 100%;
            padding: 35px 15px;
        }
    }

        .block__subtitle {
            @include font(16px, 24px);
            @include lightText;

            display: flex;
            align-items: center;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 20px;

            svg {
                margin-right: 5px;
                fill: currentColor;
            }

            @include breakpoint(tablet) {
                @include font(14px, 24px);
                margin-bottom: 10px;
            }
        }

        .block__title {
            @include font(38px, 44px);

            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(26px, 32px);

                margin-bottom: 10px;
            }
        }

        .block__text {
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }
        }

    .block__gallery {
        flex: 1 1 auto;
        padding: 40px 0;

        @include breakpoint(tablet) {
            padding: 0;
            width: 100%;
        }
    }

    .block__gallery-content {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: 20px;

        & :first-child {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
        }

        @include breakpoint(phablet) {
            display: block;
        }
    }

        .block__image {
            @include aspect(1);
            height: 100%;
        }
}
