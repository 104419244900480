.faq-view-all {
    display: inline-block;
    width: 100%;
    background: none;
    text-align: center;
    margin-top: 20px;
    padding: 0;

    span {
        @include lightText;
        @include font(14px, 17px);

        text-decoration: underline;
        transition: color .3s ease;
    }

    &:hover span {
        color: $c-brand;
    }
}

.faqs {
    padding-bottom: 100px;
}

    .faqs__section-title {
        @include font(18px, 22px);
        @include lightText;

        color: $c-black;
        text-transform: uppercase;
        letter-spacing: 4px;

        &:not(:first-of-type) {
            margin-top: 60px;

            @include breakpoint(tablet) {
                margin-top: 35px;
            }
        }

        @include breakpoint(tablet) {
            @include font(14px, 17px);

            letter-spacing: 3px;
        }
    }

    .faqs__item {
        border-bottom: 1px solid $c-border;
    }

        .faqs__item-title {
            @include font(20px, 30px);

            padding: 20px 0;
            color: $c-black;

            @include breakpoint(tablet) {
                @include font(16px, 24px);
                padding: 15px 0;
            }
        }

            .faqs__item-button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: none;
                width: 100%;
                white-space: normal;
                text-align: left;
                padding: 0;

                svg {
                    flex-shrink: 0;
                    margin-left: 15px;

                    @include breakpoint(tablet) {
                        width: 10px;
                        height: 10px;
                    }
                }
                
                .icon-minus {
                    display: none;
                }
            }
            
            .faqs__item-button[aria-expanded='true'] {

                .icon-plus {
                    display: none;
                }

                .icon-minus {
                    display: block;
                }
            }
