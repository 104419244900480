.product-showcase--cta {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 300px;
    z-index: 1;

    @include product_showcase_padding(0px, 0px, 0px);

    @include breakpoint(tablet) {
        padding-bottom: 0;
    }
}

.product-showcase-cta {
    padding: 50px 0 (50px + (475/2));
    background: #f8f8f8;
    position: relative;

    @include breakpoint(tablet) {
        padding-bottom: 0;
    }
}

    .product-showcase-cta__title {
        @include rem(30px);
    }

    .product-showcase-cta__copy {
        @include rem(16px);
        @include f-thin;
        line-height: 1*19/16;
        max-width: 580px;
        margin: 0 auto 25px;
    }

    a.product-showcase-cta__button {
        padding-left: 50px;
        padding-right: 50px;
    }

    .product-showcase-cta__image {
        max-width: 875px;
        position: absolute;
        bottom: -50%;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(tablet) {
            position: static;
            transform: none;
            max-width: 100%;
            height: auto;
            margin-top: 30px;
        }
    }
