.block--banner {
    --richtext-color: var(--block-text-color, $c-black);
    @include lightondark;
    position: relative;
    color: var(--block-text-color, $c-black);

    @include breakpoint(tablet) {
        margin: 0;
    }

    &.block--tinted {
        background: $c-block-banner-bg;

        .block__image {
            opacity: 0.2;
        }
    }

    &.block--no-image .block__content {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
        margin: 0 auto;
    }

    .block__image {
        aspect-ratio: 10 / 3;

        @include breakpoint(tablet) {
            aspect-ratio: 7 / 4;
            margin: 0;
        }

        img {
            @include coverImage;

            width: 100%;
            height: 100%;
        }

        &::after,
        &::before {
            display: none;
        }
    }

    .block__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 60px;
        width: 850px;
        text-align: center;
        background-color: var(--block-background, $c-white);

        @include breakpoint(tablet) {
            text-align: left;
            position: relative;
            top: unset;
            left: unset;
            transform: none;
            width: auto;
            padding: 40px $site-gutter--mobile;
        }
    }

        .block__subtitle {
            @include font(18px, 18px);
            @include lightText;

            text-transform: uppercase;
            letter-spacing: 4px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                letter-spacing: 3px;
            }
        }

        .block__title {
            @include font(38px, 44px);

            margin-bottom: 15px;
            font-family: var(--block-title-font-family, $f-primary-stack);

            @include breakpoint(tablet) {
                @include font(26px, 32px);

                margin-bottom: 10px;
            }
        }

        .block__text {
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                margin-bottom: 15px;
            }
        }
}
