.media-search__field {
    padding: 18px 30px 25px;
    background: $c-block-background-light-grey;
    margin: 0 0 8px 0;
    border: 1px solid $c-swatch-border;
    border-radius: 3px;

    @include breakpoint(tablet) {
        padding: 18px 30px 20px;
        margin: 0 0 20px 0;
    }
}

.media-search__field--transparent {
    background: none;
}

    .media-search__form {}

        .media-search__fieldset {
            display: flex;
            flex-flow: column nowrap;

            @include breakpoint(phablet) {
                justify-content: center;
            }
        }

            .media-search__title {
                @include font(18px, 30px);

                color: $c-black;

                @include breakpoint(phablet) {
                    text-align: center;
                }
            }

            .media-search__search {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                margin-top: 15px;

                @include breakpoint(tablet) {
                    flex-wrap: wrap;
                }

                @include breakpoint(phablet) {
                    flex-basis: 100%;
                    margin-bottom: 10px;
                    justify-content: center;
                }

                .field {
                    flex-basis: 65%;
                    margin: 5px 20px 5px 0;
                    flex-grow: 1;

                    @include breakpoint(phablet) {
                        flex-basis: 100%;
                        margin-right: 0;
                    }
                }

                    label {
                        @include font(14px, 17px);
                    }

                    button {
                        margin: 5px 0;

                        @include breakpoint(phablet) {
                            flex-basis: 60%;
                        }
                    }

                    .input {
                        @include font(14px, 17px);
                        @include inputPlaceholderColor($grey2);

                        padding: 13px 15px;
                        -webkit-appearance: none;
                        border-radius: 3px;
                        margin-right: 20px;
                    }
            }

            .media-search__search--checkbox {
                justify-content: flex-start;
            }

                .media-search__options {
                    width: 200px;

                    &:first-child {
                        margin-right: 20px;

                        @include breakpoint(phablet) {
                            margin-right: 0;
                            margin-bottom: 5px;
                        }
                    }

                    option {
                        color: $c-grey--mid;
                    }

                    @include breakpoint(phablet) {
                        width: 100%;
                    }
                }

                .media-search__lower {
                    position: relative;
                    display: flex;
                    min-height: 26px;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 15px;
                }

                    .media-search__text {
                        padding-right: 23px;

                        @include breakpoint(phablet) {
                            padding-right: 0;
                        }

                        a {
                            @include font(14px, 22px);

                            color: $c-black;
                        }

                    }

                    .media-search__text--absolute {
                        @include breakpoint(phablet) {
                            position: absolute;
                            right: 0%;
                            top: -65px;
                        }
                    }
