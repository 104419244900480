.blog-event-form {
    fieldset {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 0;
    }

    fieldset:nth-child(2) {
        padding-top: 0;
    }

    .heading5 {
        margin-bottom: 30px;
    }
}
