// Grouped content transitions

[data-aos="content__title"],
[data-aos="content__subtitle"],
[data-aos="content__logo"],
[data-aos="content__text"],
[data-aos="content__buttons"] {
    transform: translateY(25px);
    opacity: 0;
    transition-property: transform, opacity;

    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    body[data-aos-duration] & {
        transition-duration: 2250ms;
    }

    &.aos-animate {
        transform: none;
        opacity: 1;
    }
}

[data-aos="content__subtitle"] {
    transition-delay: 150ms, 100ms;
}

[data-aos="content__title"],
[data-aos="content__logo"] {
    transition-delay: 250ms, 200ms;
}

[data-aos="content__text"] {
    transition-delay: 350ms, 250ms;
}

[data-aos="content__buttons"] {
    transition-delay: 450ms, 300ms;
}

// Stack transitions

[data-aos^="stack"] {
    transform: translateY(25px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-delay: 250ms, 200ms;

    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    body[data-aos-duration] & {
        transition-duration: 1250ms;
    }

    &.aos-animate {
        transform: none;
        opacity: 1;
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--#{$i}"] {
        transition-delay: $i * 200ms;
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--pdp-m-#{$i}"] {

        @include breakpoint(pdp-medium) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--m-#{$i}"] {

        @include breakpoint(tablet) {
            transition-delay: $i * 200ms;
        }
    }
}

// Stack transitions

[data-aos="fade"] {
    opacity: 0;

    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    body[data-aos-duration] & {
        transition-duration: 1250ms;
    }

    &.aos-animate {
        opacity: 1;
    }
}

// Slide content transitions

[data-aos^="sliding"] {
    transform: translateX(-50px);
    opacity: 0;
    transition-property: transform, opacity;

    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    body[data-aos-duration] & {
        transition-duration: 2250ms;
    }

    &.aos-animate {
        transform: none;
        opacity: 1;
    }
}

[data-aos*="sliding--reverse"] {
    transform: translateX(50px);
}
