// Order page

.order-detail__data {
    @include copy;
    @include rem(16px);
    color: $c-body;
    margin-bottom: 0;
}

// Account locale switcher

.account-locale-switcher {
    margin-left: 15px;
}

// Details headers

.account-details-header__name {
    text-align: left;
}

.account-details-header__number {
    text-align: right;
    position: relative;

    .heading4 {
        @include f-light;
    }

    @include breakpoint(tablet) {
        text-align: left;
    }
}

.account-details-header__discount {
    text-align: left;

    .heading4 {
        @include f-light;
        padding-top: 55px;

        @include breakpoint(tablet) {
            padding-top: 0;
        }
    }
}

// Details data

.account-details-data {
    @include copy;
    @include f-light;
    margin-bottom: 0;
    padding-bottom: 20px;
}

.account-details-default {
    @include copy;
    @include f-light;
    @include rem(14px);

    display: flex;

    .default-address,
    .account-details-billing {
        color: $grey2;
        padding: 15px 20px;
    }
}

.account-details-default--address {
    flex-direction: column;
}

.address {
    position:relative;

    .account-details-data,
    .account-details-default {
        border: 1px solid $light-grey;
        @include copySmall;
    }

    label {
        display: flex;
    }

    label,
    .account-details-link {
        @include copySmall;
        font-weight: 400;
    }

    .account-details-data {
        border-radius: 3px 3px 0 0;
        padding: 15px 20px;
        height: 100%;
    }

    .account-details-default {
        border-top: 0;
        border-radius: 0 0 3px 3px;
    }

    .account-details-default-address {
        padding: 15px 20px;
    }

    .account-details-link {
        border-top: 1px solid $light-grey;
        padding: 15px 20px;

        &:hover {
            color: $c-brand;
        }

        span {
            text-decoration: underline;
            margin-left: 20px;
        }
    }

    .account-details-billing {
        border-top: 1px solid $light-grey;
    }
}

// Details actions

.account-details-action {
    @include copy;
    @include f-regular;
    @include rem(14px);
    text-transform: uppercase;
    margin-bottom: 0;

    i {
        @include rem(12px);
    }
}

.account-details-status-badge {
    float: right;
    margin-top: 10px;
}

.account-details-repeat {
    margin-top: 30px;
}

// Quick add / Basket

.account-quick-add {
    border: 1px solid $c-border;
    border-radius: 3px;
    padding: 30px;

    @include breakpoint(tablet) {
        padding: 20px;
    }
}

    .account-quick-add-basket__details {
        @include font(16px, 22px);
        @include lightText;

        > * {
            display: inline-block;
        }
    }

        .account-quick-add-basket__items {
            padding-right: 30px;
        }

        .account-quick-add-basket__price {
            @include regularText;
        }

        .account-quick-add__import {
            text-align: right;
            margin-top: 10px;

            @include breakpoint(phablet) {
                text-align: left;
                margin-top: 20px;
            }
        }

.account-section {
    padding: 30px 0;

    &--border {
        border-bottom: 1px solid rgba($c-account-border, 0.3);

        @include breakpoint(tablet) {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    &--flush {
        padding: 0;
    }

    &--flush-top {
        padding-top: 0;
    }

    &--flush-bottom {
        padding-bottom: 0;
    }
}

    .account-section__title {
        @include font(32px, 46px);
        margin-bottom: 20px;
        color: $c-black;

        &--flush {
            margin: 0;
            @include font(32px, 32px);
        }

        &--inner {
            margin-bottom: 10px;
        }
    }

    .account-section__title--responsive {
        
        @include breakpoint(tablet) {
            @include font(26px, 46px);
        }
    }

    .account-section__text {
        @include font(14px, 24px);
        @include lightText;

        margin-top: 12px;
        margin-bottom: 12px;
    }

    .account-section__intro {
        margin-bottom: 10px;
    }

    .account-section__detail-link {
        @include font(12px, 22px);
        color: $c-grey--mid-text;

        &:hover {
            color: $c-brand;
        }

        span {
            display: inline-block;
            margin-right: 10px;
            text-transform: uppercase;
        }
    }

    .account-section__content {
        padding-bottom: 30px;
        flex-direction: column;
        display: flex;

        &--border {
            border-bottom: 1px solid rgba($c-account-border, 0.3);
            margin-bottom: 30px;
        }

        &--m-border {
            @include breakpoint(tablet) {
                border-bottom: 1px solid rgba($c-account-border, 0.3);
            }
        }

        &--flush {
            padding: 0;
        }

        .account-section--border & {
            padding-bottom: 0;

            &--border {
                border-bottom: none;
                margin-bottom: 0;
            }

            @include breakpoint(tablet) {
                border-bottom: 1px solid rgba($c-account-border, 0.3);
                padding-bottom: 30px;
            }

        }
    }

        .account-section__content-actions {
            margin-top: auto;
        }

        .account-section__sub-title {
            @include font(24px, 34px);
            margin-bottom: 10px;
            color: $c-black;
        }

    .account-bordered-content {
        &__left {
            padding-right: 15px;

            @include breakpoint(tablet) {
                padding-right: 0;
            }
        }

        &__right {
            padding-left: 15px;

            @include breakpoint(tablet) {
                padding-left: 0;
            }
        }
    }

.account-back-link {

    .richtext & {
        text-decoration: none;
    }

    svg {
        margin-right: 5px;
        fill: $c-brand;
    }

    &:hover {
        svg {
            fill: $c-black;
        }
    }
}

// edit email
.email-change__content {
    label,
    .email-change__message {
        text-align: left;
    }
}

.password-complexity {
    @extend .richtext;
    @extend .richtext--flush;

    ul,
    ol {
        margin-left: 20px;
    }

    li {
        margin: 0 0 0.5em;
    }
}
