@import 'tools/mixins';

/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */

/* This should only apply to the wysiwyg */
body.richtext {
    font-size: 100%;
    background: #FFF;
}

.richtext {
    @include apply-map($f-primary);
    @include font(16px, 24px);
    @include lightText;

    color: $c-body;
    color: var(--richtext-color, $c-body);

    h1 {
        @include page-title;
    }

    h2 {
        @include large-title;
    }

    h3 {
        @include medium-title;
    }

    h4 {
        @include small-title;
    }

    h5 {
        @include tiny-title;
    }

    h6 {
        @include tiny-title;
    }

    h1, h2, h3, h4,  h5, h6 {
        margin-bottom: 0.5em;
    }

    p {
        margin-bottom: calc(19em / 16);
    }

    strong {
        @include regularText;
    }

    blockquote {
        border: 1px solid #BBB;
        background-color: #EEE;
        padding: 1em 2em;
        margin: 0.5em 0 1em 0;
        font-style: italic;
        font-family: Georgia, Times, serif;
        line-height: 1.4;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        margin-bottom: calc(30em / 16);
    }

    ul ul,
    ol ol {
        margin-top: .5em;
    }

    ul {
        list-style: none;
        padding-left: 20px;
        position: relative;

        li:before {
            content: "\2022";
            position: absolute;
            left: 0;
            color: $c-brand;
        }
    }

    ol {
        list-style: decimal outside;
        padding-left: 20px;
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    li {
        margin: 0 0 0.75em;
    }

    ul li ,
    ol li {
        padding-left: 3px;
    }

    dl {
        margin: 0 2em 1em 2.5em;
    }

    dt {
        font-weight: bold;
        margin: 1em 0 0.4em 0;

    }

    dd {
        line-height: 1.3em;
        margin-bottom: 0.5em;
    }

    a:not(.button) {
        @include regularText;

        color: $c-body;
        text-decoration: underline;

        &:hover {
            color: $c-brand;
        }
    }

    hr {
        border: 0;
        height: 1px;
        background-color: $c-border;
        margin: 1.875em 0;
    }

    blockquote {
        margin: 0 0 1.875em;

        p:last-child {
            margin-bottom: 0;
        }
    }

    table {
        width: 100%;
        text-align: left;

        td, th {
            @include copy;
            vertical-align: middle;
            padding: 10px;
        }

        th {
            @include rem(14px);
            @include f-light;
            text-transform: uppercase;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    table, th, td {
        border: 1px solid $c-grey--light;
    }
}

.richtext--dark {
    @include lightondark;

    color: $c-white;

    a {

        &:hover {
            color: $c-white;
        }
    }
}

.richtext--light {
    color: $c-richtext--light;
}

.richtext--dark-text {
    color: $c-black;
}

.richtext--dark-link {
    a {
        color: $c-black;
    }
}

.richtext--large {
    @include font(18px, 22px);
}

.richtext--small {
    @include font(14px, 22px);
}

.richtext--small-responsive {
    @include font(14px, 22px);

    @include breakpoint(tablet) {
        @include font(12px, 20px);
    }
}

.richtext--m-small {
    @include breakpoint(tablet) {
        @include font(14px, 22px);
    }
}

.richtext--responsive {
    h1, h2, h3, h4,  h5, h6 {

        @include breakpoint(tablet) {
            @include font(20px, 28px);
        }
    }
}

.richtext--large-adapt {
    @include font(18px, 26px);

    @include breakpoint(tablet) {
        @include font(16px, 24px);
    }
}

.richtext--flush {
    >:last-child {
        margin-bottom: 0;
    }
}

.richtext--smaller-margin {
    p {
        margin-bottom: 8px;
    }
}
