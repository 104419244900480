.news-preview {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

    .news-preview__link {
        display: block;
        flex: 1 1 auto;
        background: $background-grey;

        .section--alt & {
            background: $c-white;
        }
    }

        .news-preview__image {
            @include aspect(2 / 1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        .news-preview__top {
            @include aspect(2 / 1);

            position: relative;
        }

            .news-preview__content {
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                width: 100%;
                padding: 20px 25px;
                box-sizing: border-box;

                @include breakpoint(tablet) {
                    padding: 10px 15px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    background: linear-gradient(180deg, rgba($c-black, 0) 0%, rgba($c-black, 0.5) 100%);
                    z-index: -1;
                }
            }

            .news-preview__title {
                @include lightondark;
                @include rem(28px);

                line-height: calc(30 / 28);
                flex: 1 1 auto;
                margin-right: 20px;
                color: $c-white;

                @include breakpoint(tablet) {
                    @include rem(24px);

                    line-height: calc(30 / 24);
                }
            }

            .news-preview__cta {
                @include rem(11px);
                @include f-bold;

                line-height: calc(14 / 11);
                letter-spacing: .5px;
                text-transform: uppercase;
                padding: 10px 15px;
                color: $c-grey--mid;
                background: $c-white;
                white-space: nowrap;
                transition: color 0.2s;

                .news-preview__link:hover & {
                    color: $c-black;
                }
            }

        .news-preview__bottom {
            padding: 15px 25px;

            @include breakpoint(tablet) {
                padding: 15px;
            }
        }

            .news-preview__intro {
                @include f-light;
                @include rem(14px);

                line-height: calc(22 / 14);

                @include breakpoint(tablet) {
                    margin-bottom: 10px;
                }

                @include breakpoint(mobile) {
                    @include rem(12px);

                    line-height: calc(22 / 12);
                }
            }
