.block--split-callout {
    padding: 0;
    color: $c-black;

    &.block--reverse {

        .block__content {
            padding-right: 50px;
            padding-left: 0;

            @include breakpoint(tablet) {
                padding-right: 0;
            }
        }
    }

        .grid {
            overflow: visible;
        }

    .block__image {
        @include aspect(1);

        margin: 0 0 10px;
        overflow: visible;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            @include objectFitImage;

            width: 100%;
            height: 100%;
        }
    }

    .block__caption {
        @include font(12px, 22px);
        @include lightText;

        color: $c-block-caption;
        text-align: right;
    }

    .block__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        padding-left: 50px;

        @include breakpoint(tablet) {
            padding: 0 15px;
        }

        .wrap & {

            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }

    .block__item {

        .block__title {
            @include font(40px, 46px);
            margin-bottom: 15px;
            font-weight: 400;

            @include breakpoint(tablet) {
                @include font(30px, 36px);
            }
        }
    }
}
