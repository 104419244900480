.page-filter {
    @extend .wrap;
    overflow: hidden;

    &[loading="true"] {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($c-white, 0.8);
        }
    }
}

    .page-filter__section {
        display: flex;
        justify-content: center;
        gap: 40px;

        @include breakpoint(mobile) {
            gap: 20px;

            border-top: 1px solid $c-border;
            padding-top: 20px;
        }

        .page-filter--left & {
            justify-content: flex-start;
        }

        .select-replace {
            border-radius: 0;

            svg {
                right: 0;
                width: 13px;
                height: 9px;
                transform: translateY(-50%);
            }
        }

            .select-replace__value {
                padding: 0 23px 0 0;
                border: none;
                border-bottom: 1px solid $c-border;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $c-black;

                &:after {
                    display: none;
                }
            }
    }

        .page-filter__link {
            @include font(20px, 38px);
            padding-bottom: 5px;
            color: $c-black;

            @include border-line-grow;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover,
            &--active {
                @include border-line-trigger;
            }

            @include breakpoint(mobile) {
                @include font(14px, 26px);
            }
        }
