.product-features {
    max-width: $site-max-width / 2;
    padding: 100px 80px;

    @include breakpoint(tablet) {
        max-width: none;
        padding: 35px 20px;
    }
}

    .product-features__title {
        @include font(32px, 46px);

        margin-bottom: 15px;
        text-align: center;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(24px, 34px);
        }

        span {
            @include lightText;
        }
    }

    .product-features__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;

        > * {
            flex: 1 1 100%;
        }
    }

        .product-features__image {
            flex: 0 0 70px;
            width: 70px;
            height: 70px;
            margin-right: 20px;
            overflow: hidden;

            @include breakpoint(tablet) {
                flex-basis: 50px;
                width: 50px;
                height: 50px;
                margin-right: 15px;
            }

            img {
                max-width: 100%;
                height: auto;
            }

            svg {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .product-features__text {
            @include font(16px, 24px);
            @include lightText;

            max-width: calc(100% - 90px);

            @include breakpoint(tablet) {
                @include font(14px, 22px);

                max-width: calc(100% - 65px);
            }
        }

        .product-features__extra {
            margin-top: 20px;
            margin-left: 90px;

            @include breakpoint(tablet) {
                margin-left: 65px;
            }
        }

            .product-features__list {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                dt {
                    @include regularText;

                    margin-bottom: 5px;
                    text-transform: uppercase;
                }

                dd {
                    @include font(14px, 22px);
                    @include lightText;
                }
            }
