.product-showcase-navigation {
    position: fixed;
    top: 50%;
    left: 25px;
    z-index: 2;

    @include breakpoint(tablet) {
        display: none;
    }
}

    .product-showcase-navigation__item {
        width: 14px;
        height: 14px;
        margin-bottom: 17px;
        border-radius: 100%;
        color: transparent;
        background: $c-product-showcase-navigation;
        transition: background 100ms;

        &:last-child {
            margin: 0;
        }

        a {
            display: block;
            height: 100%;
            line-height: 1;

            @include rem(14px);
        }

        span {
            margin-left: 27px;
            color: $c-product-showcase-navigation--active;
            transition: opacity 100ms;
            white-space: nowrap;
        }
    }

    .product-showcase-navigation__item:hover,
    .product-showcase-navigation__item--active {
        background: $c-product-showcase-navigation--active;
    }
