.tippy-popper {
    transition: opacity .3s !important;
}

    .tippy-tooltip {
        &.astro-theme {
            border-radius: 4px;
            background: $c-section-bg--highlight;

            @include f-regular;
            @include rem(14px);
            text-transform: uppercase;
            line-height: 1.4;
            text-align: center;

            @include breakpoint(tablet) {
                @include rem(12px);
            }

            @include breakpoint(mobile) {
                @include rem(10px);
            }

            a {
                color: $c-white;
                text-decoration: underline;
            }

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-section-bg--highlight;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-section-bg--highlight;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-section-bg--highlight;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-section-bg--highlight;
            }
        }
        // Abstract to use the above
        &.astro--white-theme {
            border-radius: 4px;
            background: $c-white;
            color: $c-black;
            border-radius: 0;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

            @include f-light;
            @include rem(12px);
            text-transform: uppercase;
            line-height: 1.4;
            text-align: center;

            a {
                text-decoration: underline;
            }

            @include breakpoint(mobile) {
                @include rem(10px);
            }

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-white;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-white;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-white;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-white;
            }
        }
        &.astro--white-large-theme {
            padding: 25px 15px;
            color: $c-black;
            background: $c-white;
            border-radius: 0;
            border-color: $grey3;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

            @include f-regular;
            @include rem(16px);

            line-height: calc(19 / 16);
            font-weight: 100;

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-white;
                border-width: 17px;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-white;
                border-width: 17px;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-white;
                border-width: 17px;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-white;
                border-width: 17px;
            }
        }

        &.astro--white--box-arrow-theme {
            padding: 25px 15px;
            color: $c-black;
            background: $c-white;
            border-radius: 0;
            border-color: $grey3;
            // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
            filter: drop-shadow(0 0 2px rgba(0, 0, 0, .3));

            @include f-regular;
            @include rem(16px);

            line-height: calc(19 / 16);
            font-weight: 100;

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-white;
                border-width: 17px;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-white;
                border-width: 17px;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-white;
                border-width: 17px;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-white;
                border-width: 17px;
            }
        }

        &.astro--grey-theme {
            @include font(14px, 17px);
            @include lightText;

            color: $c-tooltip-color;
            background: $c-grey--button;
            border-radius: 3px;
            text-align: center;

            a {
                text-decoration: underline;
            }

            @include breakpoint(mobile) {
                @include rem(10px);
            }

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-grey--button;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-grey--button;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-grey--button;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-grey--button;
            }
        }

        &.astro--brand-theme {
            @include font(12px, 18px);

            color: $c-white;
            background: $c-brand;
            border-radius: 3px;
            text-align: center;

            a {
                text-decoration: underline;
            }

            @include breakpoint(mobile) {
                @include rem(10px);
            }

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-brand;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-brand;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-brand;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-brand;
            }
        }

        &.astro--track-configurator-helper-theme,
        &.astro--track-configurator-error-them {
            @include font(12px, 15px);
            @include boldText;

            padding: 8px 10px;
            color: $c-white;
            border-radius: 3px;
            box-shadow: 0 3px 5px 0 rgba($c-black, 0.15);
            text-align: center;
            white-space: nowrap;

            a {
                text-decoration: underline;
            }
        }

        &.astro--track-configurator-helper-theme {
            background: $c-track-configurator-helper;

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-track-configurator-helper;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-track-configurator-helper;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-track-configurator-helper;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-track-configurator-helper;
            }
        }

        &.astro--track-configurator-error-theme {
            background: $c-track-configurator-error;

            .tippy-popper[x-placement^=top] &.tippy-tooltip .tippy-arrow {
                border-top-color: $c-track-configurator-error;
            }

            .tippy-popper[x-placement^=right] &.tippy-tooltip .tippy-arrow {
                border-right-color: $c-track-configurator-error;
            }

            .tippy-popper[x-placement^=bottom] &.tippy-tooltip .tippy-arrow {
                border-bottom-color: $c-track-configurator-error;
            }

            .tippy-popper[x-placement^=left] &.tippy-tooltip .tippy-arrow {
                border-left-color: $c-track-configurator-error;
            }
        }
    }
