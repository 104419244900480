@import 'swiper/swiper';

.swiper-container--career-quotes {
    width: 100%;

    @include breakpoint(tablet) {
        width: 75%;
    }

    @include breakpoint(mobile) {
        background: $blue1;
        width: 100%;
    }

    .swiper-slide {
        opacity: .5;
        transition: opacity .7s ease-out;

        @include breakpoint(mobile) {
            opacity: 1;
        }
    }

    .swiper-slide.swiper-slide-active {
        opacity: 1;

        .career-quote {
            display: block;
        }
    }
}

.career-quote {
    display: none;
    position: absolute;
    text-align: center;
    left: 15px;
    right: 15px;
    bottom: 15px;
    position: absolute;
    line-height: 18px;
    @include rem(15px);
    @include copy;

    @include breakpoint(mobile) {
        display: block;
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 10%;
        margin-left: -7px;
        margin-top: 0;
        width: 0;
        height: 0;
        opacity: 0.8;
        border-bottom: 10px solid $blue1;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        @include breakpoint(mobile) {
            opacity: 1;
        }
    }

    &:before {
        content: '';
        top: -1px;
        left: 0;
        bottom: 0;
        right: 0;
        background: $blue1;
        opacity: 0.8;
        position: absolute;
        z-index: 1;

        @include breakpoint(mobile) {
            opacity: 1;
        }
    }
}

    .career-quote__quote {
        position: relative;
        z-index: 2;
        color: $c-white;
        padding: 30px 45px 10px;

        &:before,
        &:after {
            content: '"';
            @include copy;
            @include rem(70px);
            font-style: italic;
            position: absolute;
            color: $c-white;

            @include breakpoint(tablet) {
                @include rem(50px);
            }
        }

        &:before {
            top: -10px;
            left: 10px;
        }

        &:after {
            bottom: -50px;
            right: 20px;
        }
    }

    .career-quote__cite {
        position: relative;
        z-index: 2;
        color: $c-white;
        padding: 0 40px 30px;
        font-style: italic;

        @include breakpoint(tablet) {
            padding: 0 30px 20px 0;
        }

        @include breakpoint(tablet) {
            padding-left: 20px;
            padding-top: 20px;
        }
    }

.swiper-button-prev,
.swiper-button-next {
    width: 70px;
    height: 150px;
    margin-top: -75px;

    @include breakpoint(mobile) {
        top: 180px;
    }
}

.swiper-button-prev {
    @include breakpoint(mobile) {
        left: 10px;
    }
}

.swiper-button-next {
    @include breakpoint(mobile) {
        right: 10px;
    }
}

.swiper-button-prev {
    background: url(/images/arrow-left--thin.png) center center no-repeat;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(/images/arrow-left--thin-2x.png);
        background-size: 70px 150px;
    }
}

.swiper-button-next {
    background: url(/images/arrow-right--thin.png) center center no-repeat;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(/images/arrow-right--thin-2x.png);
        background-size: 70px 150px;
    }
}

.slider--gallery {

    .swiper-wrapper {
        flex-direction: column;

        @include breakpoint(tablet) {
            flex-direction: row;
        }
    }
    
    .swiper-slide {
        height: auto;
    }
}

