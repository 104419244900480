.share {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;

    @include breakpoint(tablet) {
        position: static;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 50px 0;
        transform: none;
    }
}

    .share__title {
        @include font(12px, 15px);
        @include thinText;

        margin-bottom: 15px;
        letter-spacing: 2px;
        color: $c-black;
        text-transform: uppercase;
        text-align: center;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    .share__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        margin-bottom: 1px;
        background: $c-grey--mid;
        font-size: 0;

        @include breakpoint(tablet) {
            margin-right: 1px;
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;

            @include breakpoint(tablet) {
                margin-right: 0;
            }
        }

        svg {
            fill: $c-white;
        }
    }
