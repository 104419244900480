.product-dl {
    position: relative;
    padding-bottom: 40px;

    @include breakpoint(tablet) {
        padding: 20px 0 40px;
    }
}

    // .product-dl__button {
    //     position: absolute;
    //     top: 50px;
    //     right: 80px;
    //     text-align: center;

    //     @include breakpoint(tablet) {
    //         top: 100px;
    //         position:absolute;
    //         left:50%;
    //         transform:translate(-50%, -50%);
    //         width: 60%;
    //     }
    // }

    .product-dl__search {
        margin-top: 5px;
        padding: 40px 130px 40px;

        @include breakpoint(tablet) {
            padding: 0 20px;
            border-top: none;
        }
    }

    .product-dl__download-all {
        padding: 0 130px 20px;

        @include breakpoint(tablet) {
            padding: 20px;
        }

        @include breakpoint(mobile) {
            .button {
                width: 100%;
            }
        }
    }

        .product-dl__list {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 10px;

            &::after {
                content: '';
                position: absolute;
                right: 50%;
                width: 1px;
                height: 100%;
                background: $c-border-light;
            }

            @include breakpoint(mobile) {
                flex-flow: column nowrap;
            }

            @include breakpoint(tablet) {
                margin-bottom: 25px;
                padding-right: 5px;
                padding-left: 5px;

                &::after {
                    display: none;
                }
            }
        }

        .product-dl__list--no-results {
            @include font(18px, 30px);
            @include regularText();

            color: $c-black;
            text-align: center;
            justify-content: center;

            strong {
                @include boldText();
            }

            &::after {
                display: none;
            }
        }

    .product-dl__view {
        padding: 40px 0;
        text-align: center;

        .wrap {
            position: relative;
        }

        &-back {
            position: absolute;
            top: 50%;
            left: 70px;
            border-bottom: none !important;
            transform: translateY(-50%);

            @include breakpoint(tablet) {
                left: 20px;
            }
        }
    }

    .product-dl__view-title {
        @include font(20px, 30px);

        padding: 0 155px;
        text-align: left;

        @include breakpoint(tablet) {
            padding: 0;
            text-align: center;
        }
    }
