.number-section {
    padding: 100px 0 0;
    overflow: visible;

    @include breakpoint(tablet) {
        padding-top: 40px;
    }

    .grid--flex {

        .flexbox & {
            margin: -15px;

            @include breakpoint(mobile) {
                margin: -8px;
            }
        }
    }
}

    .number-section--last {
        padding-bottom: 85px;

        @include breakpoint(tablet) {
            padding-bottom: 40px;
        }
    }

    .number-section--overflow-hidden {
        overflow: hidden;
    }

    .number-section__header {}

        .number-section__header__title {}

            .number-section__header__title__number {
                @include rem(100px);
                line-height: (121 / 100);
                color: $c-architectural-number;

                @include breakpoint(tablet) {
                    @include rem(70px);
                }

                @include breakpoint(mobile) {
                    @include rem(60px);
                }
            }

            .number-section__header__title__title {
                @include rem(38px);
                line-height: calc(46 / 38);
                font-weight: 300;
                display: block;
                margin-top: -16px;
                color: $c-black;

                @include breakpoint(tablet) {
                    @include rem(30px);
                }
            }

    .number-section__body {
        position: relative;

        * + & {
            margin-top: 45px;
        }

        a:not(.button) {
            text-decoration: underline;
        }
    }

        .number-section__intro-copy {
            text-align: left;

            p, a {
                line-height: calc(19 / 16);
                font-weight: 100;
            }
        }

            .number-section__intro-copy--padding-top {
                padding-top: 50px;

                @include breakpoint(tablet) {
                    padding-top: 0;
                }
            }

            .number-section__intro-copy--padding-right {
                padding-right: 50px;

                @include breakpoint(tablet) {
                    padding-right: 0;
                }
            }

        .number-section__catalogue {
            margin-top: 36px;
            margin-left: 50px;
            text-align: left;

            @include breakpoint(tablet) {
                margin-top: 0;
                margin-left: 0;
            }

            .number-section__catalogue + & {
                margin-top: 50px;

                @include breakpoint(tablet) {
                    margin-top: 40px;
                }
            }
        }

            .number-section__catalogue__title {
                @include rem(24px);

                line-height: calc(29 / 24);
                font-weight: 300;
                color: $c-black;
            }

            .number-section__catalogue__copy {
                @include rem(18px);

                line-height: calc(22 / 18);
                font-weight: 100;

                * + & {
                    margin-top: 20px;
                }
            }

            .number-section__catalogue__actions {
                font-size: 0;

                * + & {
                    margin-top: 20px;
                }

                a {
                    @include rem(18px);

                    line-height: calc(22 / 18);
                    font-weight: 100;
                }
            }

            .number-section__catalogue__actions--has-icon {

                a {
                    font-weight: 400;
                    text-transform: uppercase;
                }

                a + a {
                    margin-left: 100px;
                }
            }

        .number-section__quote-background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            overflow: hidden;

            @include breakpoint(tablet) {
                position: static;
            }
        }

            .number-section__quote-background__image img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                max-width: 100%;
                height: auto;
                margin: 0 auto;

                @include breakpoint(tablet) {
                    position: static;
                }

                @include breakpoint(mobile) {
                    display: none;
                }
            }

        .number-section__quote-container {
            position: relative;
            z-index: 2;
        }

        .number-section__quote-background-colour {
            background-color: rgba($c-white ,0.7);
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

        .number-section__quote {
            max-width: 420px;
            padding: 80px 90px 90px;
            position: relative;
            box-sizing: border-box;

            @include breakpoint(tablet) {
                margin: 0 auto;
            }

            &:before,
            &:after {
                @include rem(120px);
                @include f-regular;
                position: absolute;
                color: $grey2;
                z-index: 1;
                opacity: 0;

                @include breakpoint(mobile) {
                    @include rem(100px);
                }
            }

            &:before {
                content: '“';
                top: 45px;
                left: 40px;

                @include breakpoint(mobile) {
                    top: 0;
                    left: 0;
                }
            }

            &:after {
                content: '”';
                right: 20px;
                bottom: 45px;

                @include breakpoint(mobile) {
                    bottom: -30px;
                }
            }

            @include breakpoint(mobile) {
                max-width: 300px;
                margin: 0 auto;
                padding: 30px 20px 30px;
            }

            &.transition {
                &:before,
                &:after {
                    opacity: 1;
                }

                .number-section__quote__quote {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .number-section__quote__quote {
            @include rem(52px);
            @include f-bold;

            line-height: 1;
            z-index: 2;
            position: relative;
            opacity: 0;
            transform: translateX(-50px);
            transition: all 1s ease-out;

            @include breakpoint(mobile) {
                @include rem(40px);
            }
        }

        .number-section__quote__cite {
            @include rem(14px);
            @include f-regular;
            color: $grey2;
            position: absolute;
            right: -20px;
            bottom: -45px;

            @include breakpoint(mobile) {
                @include rem(12px);
                bottom: -25px;
                right: 30px;
            }
        }

    .number-section__body--tight-top {
        * + & {
            margin-top: 15px;
        }
    }

    .number-section__body--overflow-hidden {
        overflow: hidden;
    }


    .number-section__image-1 {
        width: 100%;
        height: auto;
        transition: all 1s ease-out;
        opacity: 0;
        transform: translateY(50px);

        &.transition {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .number-section__image-split {
        width: 100%;
        height: auto;
        transition: all 1s ease-out;
        opacity: 0;
        transform: translateY(50px);

        &.transition {
            opacity: 1;
            transform: translateY(0);
        }
    }
