// Call prompts

.call-prompt {
    background-color: $c-brand--dark;

    p {
        @include copy;
        @include font(20px, 38px);
        @include f-regular;
        color: white;
        text-align: center;

        a {
            color: white;
            text-decoration: underline;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint(tablet) {
            padding: 0 $site-gutter--mobile;
            @include font(18px, 26px);
        }
    }
}
