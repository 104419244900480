.pdp-callout {
    background: $c-grey--button;
    overflow: hidden;
}

    .pdp-callout__image {
        @include aspect(2 / 1);

        position: relative;

        img {
            @include cover;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .pdp-callout__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 580px;
        padding-left: 80px;

        @include breakpoint(tablet) {
            margin: 0 auto;
            padding: 30px 15px;
        }

        .pdp-callout--text-only & {
            padding: 80px;
            max-width: none;
            align-items: center;

            @include breakpoint(tablet) {
                padding: 30px 15px;
            }
        }
    }

        .pdp-callout__title {
            @include font(40px, 46px);

            margin-bottom: 15px;
            color: $c-black;

            @include breakpoint(tablet) {
                @include font(30px, 34px);
            }

            span {
                @include lightText;
            }
        }
