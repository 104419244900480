.article-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 720px;
    margin: 0 auto;

    @include breakpoint(tablet) {
        display: block;
    }
}

    .article-meta__list {
        flex: 0 0 25%;
        padding: 0 10px;
        text-align: center;

        @include breakpoint(tablet) {
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
            padding: 0;
        }
    }

        .article-meta__key,
        .article-meta__value {
            @include font(12px, 20px);

            text-transform: uppercase;
            color: $c-black;
            letter-spacing: 2px;

            @include breakpoint(tablet) {
                @include font(11px, 20px);

                letter-spacing: 1.8px;
            }
        }

        .article-meta__key {
            margin: 0;

            @include breakpoint(tablet) {
                margin-right: 5px;
            }
        }

        .article-meta__value {
            @include lightText;

            @include breakpoint(tablet) {
                text-align: left;
            }
        }
