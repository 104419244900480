html {
    font-size: 100%;
    overflow-y: scroll;
    height: 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;

    &[data-track-configurator-nav="0"],
    &[data-track-configurator-nav="1"] {
        overflow: hidden;
    }

    &.lock-scroll {
        overflow: clip;
    }

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

body {
    @include rem($base-px);
    @include apply-map($f-primary);

    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $c-background;
    color: $f-primary-color;
    min-width: 320px;
}

html #debug-bar {
    width: 100%;
    z-index: 30;
    height: 20px;
    position: fixed;
    top: 0 !important;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

html #debug-holder {
    display: none !important;
}

.body--debug.body--admin {
    #debug-bar {
        top: 44px !important;
    }
}

html #admin-bar {
    position: fixed;
    top: 0;

    @include breakpoint(phablet) {
        display: none;
    }
}

#admin-bar a {
    line-height: 1;
}
