// Downloads listing

.downloads-listing {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 0;

        .button {
            border-radius: 0;

            &:not(:last-child) {
                border-bottom: none;
            }

            &:last-child {
                border-radius: 0 0 3px 3px;
            }

            &:first-child {
                border-radius: 3px 3px 0 0;
            }
        }


    }
}

.downloads-all {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    align-items: center;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }
}

    .downloads-all__content {}

        .downloads-all__title {
            @include font(26px, 32px);
            margin-bottom: 15px;

            @include breakpoint(tablet) {
                @include font(22px, 28px);
            }
        }

        .downloads-all__text {
            @include font(16px, 24px);
            font-weight: 300;

            @include breakpoint(tablet) {
                @include font(14px, 22px);
            }
        }

        .downloads-all__form {
            background-color: #F8F6EF;
            border-radius: 3px;
            padding: 20px;

            form {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 20px;

                @include breakpoint(phablet) {
                    gap: 15px;
                }
            }

                .downloads-all__form-label {
                    @include font(18px, 21px);

                    @include breakpoint(phablet) {
                        flex-basis: 100%;
                    }
                }

                .downloads-all__form-select {
                    flex-grow: 1;
                }
        }

.downloads-all__popup-form {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .field {
        flex-grow: 1;
    }
}

// Downloads prompt

.downloads-prompt {
    @extend .copy;

    p {
        display: inline-block;
        margin-right: 10px;
    }
}
