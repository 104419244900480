.account-notice {
    border: 1px solid $c-account-notice;
    border-radius: 3px;
    background-color: rgba($c-account-notice, 0.04);
    padding: 10px 20px;
    margin-bottom: 30px;
}

    .account-notice__title {
        @include font(26px, 34px);
        padding-bottom: 10px;
    }

    .account-notice___text { }