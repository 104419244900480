.download-mini-basket {
    display: flex;
    background: $c-white;
    border-radius: 5px;
    overflow: hidden;
}

    .download-mini-basket__label {
        @include rem(14px);

        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        flex: 1 0 auto;
        padding: 10px 15px 10px 20px;
        color: $c-grey--mid;
        transition: color 0.2s;

        &:hover {
            color: $c-black;
        }
    }

        .download-mini-basket__icon {
            margin-left: 12px;
            color: $c-brand;
        }

    .download-mini-basket__count {
        @include rem(16px);

        line-height: calc(20 / 16);
        min-width: 40px;
        color: $c-white;
        padding: 10px 12px;
        background: $c-grey--dark;
        box-sizing: border-box;
    }
