// Energy calculator

.energy-calculator-form {
    margin: 0 auto;
    max-width: 870px;
}

.energy-calculator-results {
    display: none;
}

.energy-calculator-results--show {
    display: block;
}

.energy-calculator-results {
    border-top: 1px solid #e3e3e3;
}

.energy-calculator-results__bulb {
    @extend .section;
    @extend .section--alt;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 20px;

    .container {
        position: relative;
        padding-left: 160px;
        min-height: 135px + 2;
    }
}

.energy-calculator-results__bulb__thumb {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 135px;
    height: 135px;
    border: 1px solid #e3e3e3;
}

.energy-calculator-results__bulb__type {
    @include copy;
    @include rem(25px);
    @include f-regular;
    line-height: 1;
}

.energy-calculator-results__bulb__title {
    @include copy;
    @include f-regular;
}

.energy-calculator-results__bulb__price {
    @include copy;
    @include f-light;
    margin-top: -15px;
}

.energy-calculator-results__bulb__stock {
    @include copy;
    @include f-light;
}

.energy-calculator-results__bulb__lifetime {
    position: absolute;
    top: 0;
    right: 0;
    @include copy;
    @include rem(25px);
    @include f-regular;
    line-height: 1;
}

.energy-calculator-results__bulb__form {
    position: absolute;
    right: 0;
    bottom: 0;
}

.energy-calculator-results__bulb__form__qty {
    width: 100px;
    margin-right: 20px;
}

.energy-calculator-results__bulb__disclaimer {
    position: absolute;
    left: 0;
    bottom: 0;
    @include copy;
    @include rem(14px);
    @include f-regular;
    color: $grey2;
}

.energy-calculator-results__bulb--disclaimer {
    .container {
        padding-bottom: 35px;
    }

    .energy-calculator-results__bulb__form {
        bottom: 35px;
    }
}

.energy-calculator-results__bulb--empty {
    padding-bottom: 30px;

    .container {
        padding-left: 0;
        min-height: 0;
    }

    p {
        @include copy;
        text-align: center;
        margin-bottom: 0;
    }
}

.energy-calculator-results__summary {
    background: $c-section-bg--highlight;
    padding: 20px 0;
}

.energy-calculator-results__summary__row {
    @include clearfix;
}

.energy-calculator-results__summary__row__label,
.energy-calculator-results__summary__row__data {
    display: block;
    float: left;
    @include copy;
    @include rem(25px);
    @include f-regular;
    color: white;
}

.energy-calculator-results__summary__row__data {
    float: right;
}
