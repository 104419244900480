.product-showcase--intro {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 674px + 65px + 70px;
    z-index: 1;

    .admin & {
        min-height: 674px + 65px + 44px + 70px;
    }

    .debug & {
        min-height: 674px + 65px + 20px + 70px;
    }

    .debug.admin & {
        min-height: 674px + 65px + 44px + 20px + 70px;
    }

    @include product_showcase_padding(0px, 0px, 0px);

    @include breakpoint(tablet) {
        min-height: 0;

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            min-height: 335px + 20px;
        }
    }

    @include breakpoint(mobile) {

        &,
        .admin &,
        .debug &,
        .debug.admin & {
            min-height: 335px + 20px;
        }
    }
}


    .product-hero {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        background-position: 0 65px + 70px;
        background-repeat: no-repeat;
        background-size: auto 674px;
        opacity: 0;
        transition: opacity 300ms 300ms;

        @media (max-width : 1340px) {
            background-position: calc(100% - 700px) 65px + 70px;
        }

        .admin & {
            background-position: 0 65px + 44px + 70px;

            @media (max-width : 1340px) {
                background-position: calc(100% - 700px) 65px + 44px + 70px;
            }
        }

        .debug & {
            background-position: 0 65px + 20px + 70px;

            @media (max-width : 1340px) {
                background-position: calc(100% - 700px) 65px + 20px + 70px;
            }
        }

        .debug.admin & {
            background-position: 0 65px + 44px + 20px + 70px;

            @media (max-width : 1340px) {
                background-position: calc(100% - 700px) 65px + 44px + 20px + 70px;
            }
        }

        @include breakpoint(tablet) {
            background-size: auto 335px;

            &,
            .admin &,
            .debug &,
            .debug.admin & {
                background-position: 0 20px;
            }
        }

        @media (max-width : 690px) {
            &,
            .admin &,
            .debug &,
            .debug.admin & {
                background-position: calc(100% - 400px) 20px;
            }
        }

        @include breakpoint(mobile) {
            background-size: auto 335px;

            &,
            .admin &,
            .debug &,
            .debug.admin & {
                background-position: 0 20px;
            }
        }
    }

    .product-hero--1 {
        background-image: url(/images/atelier-20/ast_atelier_lamp_bronze.jpg);
    }

    .product-hero--2 {
        background-image: url(/images/atelier-20/ast_atelier_lamp_copper.jpg);
    }

    .product-hero--3 {
        background-image: url(/images/atelier-20/ast_atelier_lamp_gold.jpg);
    }

    .product-hero__active {
        opacity: 1;
        transition: opacity 1000ms;
    }

    .product-showcase__flex-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    .product-showcase__title,
    .product-showcase__copy {
        margin-right: 100% * 1 / 8;
        text-align: left;

        @include breakpoint(tablet) {
            margin-right: 0;
        }
    }

    .product-showcase__title {
        margin-top: 200px;
        margin-left: 620px;
        padding: 15px 80px;

        @media (max-width : 1340px) {
            width: 346px;
            margin-left: auto;
        }

        @include breakpoint(tablet) {
            width: auto;
            margin-top: 100px;
            margin-right: 62px;
            margin-left: 250px;
            padding: 15px 20px;
        }

        @media (max-width : 690px) {
            width: 338px;
            margin-left: auto;
        }

        @include breakpoint(mobile) {
            width: auto;
            margin: 356px 0 0 40px;
        }
    }

        .product-title {
            margin: 0;
        }

            .product-title span {
                display: block;
            }

    .product-showcase__copy {
        min-height: calc(674px - 130px - 173px);
        margin-left: 620px;
        padding: 40px 80px;

        @media (max-width : 1340px) {
            width: 346px;
            margin-left: auto;
        }

        @include breakpoint(tablet) {
            width: auto;
            min-height: 0;
            margin-right: 62px;
            margin-left: 250px;
            padding: 30px 20px;
        }

        @media (max-width : 690px) {
            width: 338px;
            margin-left: auto;
        }

        @include breakpoint(mobile) {
            width: auto;
            margin: 0 $grid-gutter-h--tight 0 40px;
        }
    }

    .product-showcase__copy p,
    .product-showcase__copy a {
        @include rem(16px);
        @include f-thin;
        line-height: 1*19/16;
    }
