.blocks .block.block--sus-image-sandwich {
    margin: 20px 0;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-image-sandwich {
    position: relative;
    padding: 65px 0 85px;

    @include breakpoint(tablet) {
        padding: 180px 0 40px; 
    }

    .block__content {
        max-width: 50%;
        text-align: center;
        margin: 0 auto;

        @include breakpoint(tablet) {
            max-width: 700px;
        }
    }

        .block__title {
            @include font(54px, 62px);

            color: $c-black;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(32px, 40px);

                margin-bottom: 15px;
            }
        }

        .block__subtitle {
            @include lightText;
            @include font(18px, 22px);
        
            color: $c-black;
            margin-bottom: 30px;
            text-transform: uppercase;
            letter-spacing: 4px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                letter-spacing: 3px;
                margin-bottom: 20px;
            }
        }

        .block__intro {
            @include font(24px, 32px);

            color: $c-black;
            padding-bottom: 25px;
            border-bottom: 1px solid $c-border-light;
            margin-bottom: 25px;
            
            @include breakpoint(tablet) {
                @include font(16px, 26px);
            }
        }

        .block__text {
            @include font(12px, 15px);

            color: $c-black;
            margin-bottom: 10px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .block__text--light {
            @include lightText;

            margin: 0;
        }

        .block__spinner-wrap {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 520px;
            height: 520px;

            @include breakpoint(pdp-medium) {
                width: 360px;
                height: 360px;
            }

            @include breakpoint(tablet) {
                width: 270px;
                height: 270px;
            }
        }
    
        .block__spinner-wrap--left {
            left: -5%;

            @include breakpoint(wrap) {
                left: -15%;
            }

            @include breakpoint(tablet) {
                left: 50%;
                top: -110px;
                transform: translate(-50%, 0);
            }
        }
    
        .block__spinner-wrap--right {
            right: -5%;

            @include breakpoint(wrap) {
                right: -15%;
            }

            @include breakpoint(tablet) {
                display: none;
            }
        }
      
            .block__spinner-bulb,
            .block__spinner-stems {
                position: absolute;
                width: 100%;
                height: 100%;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            .block__spinner-bulb {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 240px;
                    height: 240px;

                    @include breakpoint(pdp-medium) {
                        width: 170px;
                        height: 170px;
                    }

                    @include breakpoint(tablet) {
                        width: 125px;
                        height: 125px;
                    }
                }
            }
      
            .block__spinner-bulb--left {
                z-index: 1;
                animation: rotation 60s linear infinite reverse;
            }
            
            .block__spinner-bulb--right {
                z-index: 1;
                animation: rotation 60s linear infinite;
            }
            
            .block__spinner-stems--left {
                animation: rotation 60s linear infinite;
            }
            
            .block__spinner-stems--right {
                animation: rotation 60s linear infinite reverse;
            }

            .block__spinner-bulb,
            .block__spinner-stems {

                @media (prefers-reduced-motion) {
                    animation: none;
                }
            }
            
            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(359deg);
                }
            }
}
