.block--image {
    padding: 0;

    .wrap & .wrap {
        padding: 0;
    }

    .block__image {
        @include aspect(5 / 3);

        margin: 0;
        overflow: visible;

        img {
            width: 100%;
            height: auto;
        }

        &.block__image--square {
            @include aspect(1);
        }

        &.block__image--portrait {
            @include aspect(4 / 5);
        }

        @include breakpoint(tablet) {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .block__caption {
        @include font(12px, 22px);
        @include lightText;

        color: $c-block-caption;
        text-align: right;
    }
}
