.guide-info {
    background-color: #eaeaea;
}

    .guide-info__image {
        & img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .guide-info__container {
        max-width: $site-max-width / 2;
        padding: 70px 80px;
        overflow: hidden;

        @include breakpoint(pdp-large)  {

            @media (max-height: 600px) {
                max-width: none;
                padding-top: 40px;
            }
        }

        @include breakpoint(pdp-medium)  {
            padding: 40px 50px;

            @media (max-height: 600px) {
                padding-top: 10px;
            }
        }

        @include breakpoint(tablet)  {
             max-width: none;
             padding: 30px 20px;
        }
    }

        .guide-info__item {
            &:not(:last-child) {
                margin-bottom: 40px;
            }

            @include breakpoint(tablet) {
                &:not(:last-child) {
                    margin-bottom: 26px;
                }
            }
        }

        .guide-info__title {
            @include medium-title;

            color: $c-black;
            margin-bottom: 15px;

            span {
                @include lightText;
            }

            @include breakpoint(mobile) {
                @include font(24px, 46px);
            }
        }

        .guide-info__text {
            p.has-class-icon {
                display: inline-flex;

                svg {
                    margin-right: 5px;
                }
            }
        }

.advanced-guide {
    background-color: $c-brand;
    color: $c-white;
    @include font(21px, 34px);

    & .button {
        margin-left: 30px;

        @include breakpoint(mobile) {
            margin: 0;
        }
    }
}

.driver-section {
    display: none;

    &--active {
        display: block;
    }
}

    .driver-section__title {
        @include medium-title;
        color: $c-black;
        margin-bottom: 30px;

        span {
            @include thinText;
        }

        @include breakpoint(mobile) {
            @include font(24px, 34px);
            margin-bottom: 20px;
        }
    }

.driver-steps {}

    .driver-steps__divider {
        display: none;
        padding: 40px 0;
        line-height: 1;

        svg {
            fill: $c-brand;
        }
    }

    .driver-step {
        display: none;

        &--active,
        &--fixed {
            display: block;
        }

       &--view-drivers {
           padding: 40px 0;
       }
    }

    .driver-step--divider + .driver-steps__divider,
    .driver-step--divider-fixed + .driver-steps__divider {
        display: block;
    }

        .driver-step__title {
            @include font(16px, 24px);
            @include f-regular;
            letter-spacing: 1px;
            color: $c-black;
            margin-bottom: 15px;
            text-transform: uppercase;

            @media screen and (max-width: 630px) {
                .driver-step & {
                    text-align: center;
                }
            }

            @include breakpoint(mobile) {
                text-align: center;
            }
        }

        .driver-step__answers {
            display: flex;
            justify-content: center;
            align-items: center;

            .button {
                margin: 0 0 0 20px;

                &:first-child {
                    margin: 0;
                }

                &.has-info-icon {
                    margin: 0 5px 0 20px;
                    &:first-child {
                        margin: 0 5px 0 0;
                    }
                }

                @include breakpoint(mobile) {
                    width: calc(100% - 21px);

                    &:first-child:nth-last-child(2),
                    &:first-child:nth-last-child(2) ~ .button {
                        width: 50%;
                    }
                }
            }

            svg {
                fill: $c-brand;
            }
        }

            .driver-step__answers--column {
                @include breakpoint(mobile) {
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .button {
                        margin: 0 5px 20px 0;

                        &.has-info-icon {
                            margin: 0 5px 20px 0;
                            &:first-child {
                                margin: 0 5px 20px 0;
                            }
                        }
                    }

                    svg {
                        margin-bottom: 20px;
                    }
                }
            }

        .driver-step__button {}


.driver-criteria {
    margin-bottom: 40px;

    .accordion & {
        margin-bottom: 0;
    }
}

.driver-results-wrap {
    position: relative;
    text-align: center;
}

.driver-results__message {
    &--error {
        display: none;
    }
}

.driver-results__refine {
    margin: 30px 0;

    &--error {
        color: $c-error;
    }
}

.driver-reset {
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    @include font(14px, 40px);
    color: #9A9A9A;
    text-transform: uppercase;
    z-index: 1;

    svg {
        fill: #9A9A9A;
        margin-right: 10px;
    }

    @include breakpoint(tablet) {
        position: unset;
        top: unset;
        left: 0;
        text-align: left;
        margin-bottom: 20px;
    }

    &:hover {
        color: $c-link-coloured;

        svg {
            fill: $c-link-coloured;
        }
    }
}

//.info-section {
//    display: block;
//    text-align: center;
//    padding-bottom: 50px;
//    &:not(:last-of-type) {
//        margin-bottom: 50px;
//        border-bottom: 1px solid #D8D8D8;
//    }
//}
//    .info-section .icon-driver-icon {
//        fill: #EFA200;
//    }
//    .info-section__container {
//        max-width: 570px;
//        margin: 0 auto;
//    }
//    .info-section__pretitle {
//        color: $blue1;
//        font-size: 24px;
//        font-style: italic;
//        font-weight: 300;
//    }
//    .info-section__title {
//        @include rem (30px);
//        font-style: normal;
//        margin: 20px;
//        color: $blue1;
//    }
//    .info-section__subtitle {
//        font-weight: 300;
//    }
//    .info-section__subtitle--has-icon {
//        line-height: 23px;
//        display: inline-flex;
//    }
//    .info-section__subtitle--has-icon .icon-led-icon {
//        margin-right: 8px
//    }
//    .info-section__intro {
//        @include rem (24px);
//        color: $blue1;
//        margin: 20px 0;
//    }
//
//.calculator-section {}
//.calculator-section__container {
//    max-width: 870px;
//    margin: 0 auto;
//    text-align: center;
//}
//.calculator-section__subtitle {
//    @include rem (24px);
//    font-weight: 300;
//    font-style: italic;
//    color: $blue1;
//}
//.calculator-section__title {
//    @include rem (30px);
//    color: $blue1;
//    padding: 16px 0;
//}
//
//.recommend-list {}
//.recommend-list__image {
//    width: 100%;
//    height: auto;
//}
//.recommend-list__container {
//    max-width: 960px;
//    margin: 0 auto;
//    text-align: center;
//}
//.recommend-list__title {
//    @include rem (32px);
//    padding: 45px 25px 25px 25px;
//}
//.recommend-list__item {
//    @include rem (16px);
//    @include f-thin;
//    display: inline-flex;
//    float: left;
//    width: 50%;
//    padding: 16px 0;
//    text-align: left;
//    .svg-container {
//        line-height: 27px;
//        display: flex;
//        align-items: center;
//    }
//}
//.recommend-list__item .text {
//    display: flex;
//    align-items: center;
//    margin-left: 10px;
//}
//.recommend-list__dos {
//    width: 100%;
//    overflow: hidden;
//    padding-bottom: 32px;
//}
//.recommend-list__donts {
//    width: 100%;
//    overflow: hidden;
//}
//.advanced-guide {
//    color: white;
//    p {
//        color: white;
//    }
//    a {
//        @include underlined-link;
//        color: inherit;
//    }
//    a::before {
//        background-color: white;
//        bottom: -4px;
//    }
//}

.calculator {
    width: 100%;
}
.calculator__container {
    max-width: 870px;
    margin: 0 auto;
}
.calculator__inputs {
    display: flex;
    max-width: 910px;
    margin: 12px auto 0;

    @media screen and (max-width: 630px) {
        .driver-step & {
            display: block;
            margin-bottom: 12px;
        }
    }

    .driver-step & {
        max-width: 700px;
    }

    @include breakpoint(mobile) {
        padding: 0;
    }

    .accordion & {
        margin-top: 0;
    }

    > * {
        margin-right: 20px;

        @media screen and (max-width: 630px) {
            .driver-step & {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        @include breakpoint(mobile) {
            margin-right: 0;
            margin-bottom: 20px;
        }

        &:last-child {
            margin-right: 0;

            @media screen and (max-width: 630px) {
                .driver-step & {
                    margin-bottom: 0;
                }
            }

            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }
    }
}

    .calculator__input--dimmer {
        min-width: 150px;
    }

    .calculator__input {
        &--wide {
            flex: 1 1 100%;
        }

        &--qty{
            flex: 1 1 450px;
        }

        .quantity-control {
            @include breakpoint(mobile) {
                justify-content: center;
            }
        }

        .button {
            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        .field {
            p.error {
                display: none;
            }

            &.field-error {
                p.error {
                    display: block;
                }
            }
        }
    }

//@mixin segment () {
//    position: absolute;
//    width: 100%;
//    top: 100%;
//    left: 0;
//    height: 100%;
//    overflow: hidden;
//    opacity: 0;
//
//    @include breakpoint(mobile) {
//        max-width: 346px;
//    }
//}
//
//@mixin line ($height, $mb: 56px, $hasSubtitle: false) {
//    display: block;
//    position: relative;
//    max-width: 570px;
//    margin: 0 auto;
//    transition: all ease 400ms;
//    &.is-active {
//        padding: 5px;
//        min-height: $height;
//        @if $hasSubtitle == true {
//            margin-bottom: ($mb + 98);
//        } @else {
//            margin-bottom: $mb;
//        }
//        &::after {
//            height: $height;
//        }
//        .calculator__segment {
//            opacity: 1;
//            transition: opacity 400ms ease-in;
//            left: 50%;
//            transform: translateX(-50%);
//        }
//    }
//    .calculator__segment--title {
//        @include rem (30px);
//        @include f-regular;
//        color: $blue1;
//
//        line-height: 1;
//        margin-top: 10px;
//    }
//    .calculator__segment--subtitle {
//        @include rem (16px);
//        // @include f-regular;
//        color: $blue1;
//        margin-top: 20px;
//        line-height: 24px;
//        font-weight: 300;
//    }
//}
//
//.calculator__specialist {
//    height: 0;
//    max-width: 570px;
//    line-height: 22px;
//    color: #8499A5;
//    font-size: 14px;
//    font-style: italic;
//    font-weight: 300;
//    line-height: 22px;
//    text-align: center;
//    opacity: 0;
//    &.is-active {
//        opacity: 1;
//        margin: 45px auto 0;
//        height: 100%;
//    }
//}
//
//.calculator__segment--down {
//    @include line(56px, 72px);
//
//    .calculator__segment {
//        @include segment();
//    }
//
//    &::after {
//        position: absolute;
//        height: 0;
//        content: '';
//        border-left: 1px solid #E6A42D;
//        top: 0;
//        left: 50%;
//        transform: translateX(-50%);
//        transition: height 400ms;
//    }
//}
//
//.calculator__segment--splitter {
//    @include line(20px, 40px);
//    transition: all 0ms;
//    .calculator__segment {
//        @include segment();
//        max-width: 540px;
//        left: 50%;
//        transform: translateX(-50%);
//        display: flex;
//
//        margin-top: 10px;
//    }
//
//    &.is-active {
//        &.calculator__segment--splitter--three {
//            .calculator__segment {
//                transition: all ease-in 100ms;
//                max-width: 500px;
//            }
//            &::before {
//                height: 45px;
//            }
//            &::after {
//                width: 330px;
//            }
//        }
//        &::before {
//            height: 24px;
//        }
//        &::after {
//            width: 270px;
//            height: 20px;
//        }
//    }
//    &::after {
//        position: absolute;
//        content: '';
//        height: 0;
//        top: 0;
//        left: 50%;
//        width: 0;
//        transform: translateX(-50%);
//        border: 1px solid #E6A42D;
//        border-bottom: none;
//    }
//    &::before {
//        position: absolute;
//        content: '';
//        height: 0;
//        top: -24px;
//        left: 50%;
//        border-left: 1px solid #E6A42D;
//        transform: translateX(-50%);
//    }
//
//    .calculator__segment--left, .calculator__segment--middle, .calculator__segment--right {
//        @include rem(24px);
//        @include feature;
//
//        line-height: 1;
//
//        text-decoration: underline;
//
//        @include breakpoint(mobile) {
//            &.is-selected {
//                background: $c-brand;
//            }
//        }
//
//        &.is-selected, &:hover {
//            font-weight: 400;
//            text-decoration: none;
//            cursor: pointer;
//            .icon-info-icon {
//                fill: $blue1;
//            }
//        }
//        flex: 1;
//
//        .icon-info-icon {
//            transition: fill ease 400ms;
//            fill: $c-brand;
//        }
//    }
//}
//
//.calculator__segment--dual {
//    @include line(130px, 56px, true);
//
//    &.is-active {
//        .calculator__segment--left::before, .calculator__segment--right::before {
//            width: 1px;
//        }
//    }
//
//    .calculator__segment--left {
//        width: 50%;
//        .calculator__segment {
//            @include segment();
//            left: 50%;
//            transform: translateX(-50%);
//        }
//        &.is-active {
//            &::after {
//                height: 130px;
//            }
//            .calculator__segment {
//                height: 100%;
//                opacity: 1;
//                transition: opacity 400ms ease 400ms;
//            }
//        }
//        &::before {
//            width: 0;
//            position: absolute;
//            content: '';
//            height: 130px;
//            top: 0px;
//            left: calc(50% - 136px);
//            background: linear-gradient(#E6A42D, transparent 50%);
//        }
//        &::after {
//            transition: height ease 400ms;
//            position: absolute;
//            content: '';
//            height: 0;
//            top: 0px;
//            left: calc(50% - 136px);
//            border-left: 1px solid #E6A42D;
//        }
//    }
//    .calculator__segment--right {
//        width: 50%;
//        .calculator__segment {
//            @include segment();
//        }
//        &.is-active {
//            &::after {
//                height: 130px;
//            }
//            .calculator__segment {
//                height: 100%;
//                opacity: 1;
//                transition: opacity 400ms ease-in;
//            }
//        }
//        &::before {
//            width: 0;
//            position: absolute;
//            content: '';
//            height: 130px;
//            top: 0px;
//            right: calc(50% - 136px);
//            background: linear-gradient(#E6A42D, transparent 50%);
//        }
//        &::after {
//            transition: height ease 400ms;
//            position: absolute;
//            content: '';
//            height: 0;
//            top: 0px;
//            right: calc(50% - 136px);
//            border-left: 1px solid #E6A42D;
//        }
//    }
//}
//
//.calculator__segment--triple {
//    @include line(80px);
//
//    &.is-active {
//        .calculator__segment--left::before, .calculator__segment--middle::before, .calculator__segment--right::before {
//            width: 1px;
//        }
//    }
//
//    .calculator__segment--left {
//        width: percentage(100/3);
//        .calculator__segment {
//            @include segment();
//        }
//        &.is-active {
//            &::after {
//                height: 80px;
//            }
//        }
//
//        &::before {
//            width: 0;
//            position: absolute;
//            content: '';
//            height: 130px;
//            top: 0px;
//            left: calc(50% - 166px);
//            background: linear-gradient(#E6A42D, transparent 50%);
//        }
//        &::after {
//            transition: height ease 400ms;
//            position: absolute;
//            content: '';
//            height: 0;
//            top: 0px;
//            left: calc(50% - 166px);
//            border-left: 1px solid #E6A42D;
//        }
//    }
//    .calculator__segment--middle {
//        width: calc(100%/3);
//        .calculator__segment {
//            @include segment();
//        }
//        &.is-active {
//            &::after {
//                height: 80px;
//            }
//            .calculator__segment {
//                height: 100%;
//            }
//        }
//
//        &::before {
//            transition: all ease 400ms;
//            width: 0;
//            position: absolute;
//            content: '';
//            height: 130px;
//            top: 0px;
//            left: 50%;
//            transform: translateX(-50%);
//            background: linear-gradient(#E6A42D, transparent 50%);
//        }
//        &::after {
//            transition: height ease 400ms;
//            position: absolute;
//            content: '';
//            height: 0;
//            top: 0px;
//            left: 50%;
//            transform: translateX(-50%);
//            border-left: 1px solid #E6A42D;
//        }
//    }
//    .calculator__segment--right {
//        width: percentage(100/3);
//        .calculator__segment {
//            @include segment();
//        }
//        &.is-active {
//            &::after {
//                height: 80px;
//            }
//            .calculator__segment {
//                height: 100%;
//            }
//        }
//
//        &::before {
//            transition: all ease 400ms;
//            width: 0;
//            position: absolute;
//            content: '';
//            height: 130px;
//            top: 0px;
//            right: calc(50% - 166px);
//            background: linear-gradient(#E6A42D, transparent 50%);
//        }
//        &::after {
//            transition: height ease 400ms;
//            position: absolute;
//            content: '';
//            height: 0;
//            top: 0px;
//            right: calc(50% - 166px);
//            border-left: 1px solid #E6A42D;
//        }
//    }
//    .calculator__segment--left, .calculator__segment--middle, .calculator__segment--right {
//        margin-left: -16px;
//    }
//}

//.calculator-product {
//    display: flex;
//    background-color: #F8F8F8;
//    margin-bottom: 25px;
//}
//.calculator-product.is-recommended {
//    border: 2px solid #DDDDDD;
//}
//.calculator-product__image {
//    display: flex;
//    width: 147px;
//    height: 147px;
//    position: relative;
//}
//.calculator-product__text {
//    padding: 12px;
//    text-align: left;
//    flex: 2;
//}
//.calculator-product__buy {
//    padding: 12px;
//    flex: 1.5;
//    display: flex;
//    justify-content: space-between;
//    &__form {
//        display: flex;
//        flex: 1;
//        flex-direction: column;
//    }
//    &__top {
//        flex: 1;
//        display: flex;
//        align-items: flex-end;
//        padding-bottom: 12px;
//    }
//    &__price {
//        @include rem(22px);
//    }
//    &__pricing {
//        flex: 1;
//    }
//    &__quickbuy {
//        flex: 1;
//    }
//    &__required {
//        flex: 2;
//        text-align: right;
//        padding-right: 8px;
//    }
//    &__qty {
//        flex: 1;
//    }
//    &__bottom {
//        flex: 1;
//        display: flex;
//        align-items: center;
//    }
//    &__stockinfo {
//        @include rem(14px);
//        font-style: italic;
//    }
//}
//.calculator-product__title {
//    @include rem(16px);
//    margin-bottom: 9px;
//}
//.calculator-product__subtitle {
//    @include f-thin;
//    @include rem(16px);
//}
//.calculator-product--recommended {
//    @include rem(12px);
//    @include f-light;
//
//    text-transform: uppercase;
//    background: $c-success;
//    position: absolute;
//    left: 50%;
//    transform: translateX(-50%);
//    margin-top: 8px;
//    padding: 4px 20px;
//    color: $c-white;
//}

.calculator-results-others {
    margin-bottom: 24px;
}
.calculator-results-others.is-active {
    .calculator-results-others__container {
        .driver-calculator-results__driver:not(:first-of-type) {
            opacity: 1;
            display: block;
        }
    }
    .calculator-results-others__title::before {
        content: "\f148";
    }
}
.calculator-results-others__container {
    padding: 0 20px;
    transition: all 400ms ease;
    .driver-calculator-results__driver:not(:first-of-type) {
        opacity: 0;
        display: none;
    }
}

.calculator-results-others__type {
    @include rem(18px);
    margin-bottom: 20px;
}
.calculator-results-others__subtitle {
    @include rem(18px);
    font-weight: 300;
    display: none;
    max-width: 570px;
    margin: 0 auto 20px;
    &.show {
        display: block;
    }
}
.calculator-results-others__title {
    @include rem(14px);
    @include link;

    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-skip: ink;

    &:hover {
        cursor: pointer;
    }

    &::before {
        @include f-thin;
        font-family: FontAwesome;
        content:"\f149";
        padding-right: 4px;
    }

    display: none;
    &.show {
        display: block;
        margin-top: 24px;
    }
}

.calculator__more-info {
    display: none;
    @include breakpoint (mobile) {
        display: block;
        padding: 16px 0;
        text-decoration: underline;
        .icon-info-icon {
            transform: translateY(25%);
            fill: $c-brand;
            &::hover {
                fill: $blue1;
            }
        }
    }
}

.results-recommend {
    max-width: 570px;
    margin: -30px auto 45px;
    color: $blue1;
}
.results-recommend__title {
    @include rem(30px);
    margin-bottom: 4px;
}
.results-recommend__copy {
    @include rem(16px);
    font-weight: 300;
    line-height: 24px;
}

.separator {
    height: 72px;
    position: relative;
    &::after {
        position: absolute;
        display: block;
        content: '';
        border-bottom: 1px solid black;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
}

[data-dimming="false"] {
    .calculator__segment--dual.is-active {
        margin-bottom: 56px;
    }
}

@include breakpoint(mobile) {

    .calculator__inputs {
        display: block;
        margin-bottom: 12px;
    }
    .calculator__segment--splitter {
        &::before {
            &.is-active {
                height: 36px;
            }
            top: 0;
        }
        &::after {
            border: none;
        }
        &.is-active {
            margin-bottom: 124px;
            min-height: 36px;
            .calculator__segment {
                max-width: 346px !important;
            }
        }
        .calculator__segment {
            overflow: unset;
            flex-direction: column;
        }
        .calculator__segment--left, .calculator__segment--middle, .calculator__segment--right {
            display: block;
            float: none;
            padding: 12px;
            text-transform: uppercase;
            color: $c-white;
            @include rem(14px);
        }

        .calculator__segment--left, .calculator__segment--middle, .calculator__segment--right {
            margin-top: 10px;
            background: $c-grey--mid;
        }
        &.calculator__segment--splitter--three {
            .calculator__segment--left, .calculator__segment--middle, .calculator__segment--right {
                margin-left: 0;
                margin-right: 0;
                position: unset;
                left: unset;
                top: unset;
                right: unset;
                transform: unset;
                display: block;
                svg {
                    display: none;
                }
            }
        }
    }
    .calculator__segment--down.is-active {
        margin-bottom: 80px;
        min-height: 30px;

        .calculator__segment--title {
            max-width: 250px;
            margin: 0 auto;
        }
        .calculator__segment {
            overflow: unset;
        }
        &.is-active {
            &::after {
                height: 36px;
            }
        }
    }
    .calculator__segment--down.is-active::after {
        height: 36px !important;
    }
    .calculator__segment--splitter.is-active::before {
        height: 36px !important;
    }
    .calculator__segment--dual.is-active {
        min-height: 36px;
        margin-bottom: 182px;
        .calculator__segment {
            overflow: unset;
        }
    }
    .calculator__segment--dual .calculator__segment--left {
        &::before {
            opacity: 0;
            width: 0 !important;
        }
        &.is-active {
            &::after {
                height: 36px !important;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .calculator__segment--dual .calculator__segment--right {
        &::before {
            opacity: 0;
            width: 0 !important;
        }
        &.is-active {
            &::after {
                height: 36px !important;
                left: 50%;
                // transform: translateX(-50%);
            }
        }
    }
    .calculator__segment--splitter.calculator__segment--splitter--three.is-active {
        margin-bottom: 310px;
    }
    .calculator__segment--triple.is-active {
        min-height: 36px;
        margin-bottom: 0;
        .calculator__segment--left,
        .calculator__segment--middle,
        .calculator__segment--right {
            &::before {
                width: 0 !important;
            }
            &::after {
                top: 0;
                left: 50%;
            }
            &.is-active {
                &::after {
                    height: 36px;
                }
            }
        }
    }
    .calculator__specialist {
        display: none;
    }
    .recommend-list__title {
        padding: 45px 0;
    }
    .recommend-list__container {
        margin: 0 21px;
    }
    .calculator-product {
        flex-direction: column;
        margin: 8px;
    }
    .calculator-product__subtitle {
        margin-top: 8px;
    }
    .calculator-product__image {
        width: 100%;
        height: 100%;
        justify-content: center;
        img {
            width: 100%;
            height: 100% !important;
        }
    }
    .calculator-product--recommended {
        @include rem (22px);
        padding: 8px 20%;
    }
    .calculator-product__required {
        @include rem(14px);
        @include f-thin;
        display: block;
        margin-top: 21px;
        font-style: italic;
    }
    .recommend-list__item {
        width: 100%;
    }
    .results-recommend {
        margin: 0 auto 60px;
    }
    .calculator__results {
        padding: 0 8px;
    }
    .selectize-dropdown .selectize-dropdown-content .option[data-selectable] {
        padding: 12px;
    }
}

.calculator-back-to-top {
    position: fixed;
    bottom: -36px;
    left: 12px;
    @include breakpoint(mobile) {
        i {
            font-size: 28px;
        }
        background: $c-white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3), 1px 0 0 0 #EBEBEB;
        transform: scale(0);
        transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s;
        z-index: 99999;
        &:hover {
            cursor: pointer;
        }
        &.show {
            bottom: 12px;
            transform: scale(1);
        }
    }
}

.calculator-section {
    background: $background-grey;
    padding: 52px 0;
}

.selectize-input {
    text-align: left;
}

.driver-calculator-results__driver-inner {
    min-height: unset;
}

.is-recommended {
    border: 2px solid #DDD;
}
