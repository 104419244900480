.block--richtext {
    padding: 0;

    .wrap & .wrap {
        padding: 0;
    }

    .block__text {
        @extend .richtext;
        @extend .richtext--flush;
        @extend .richtext--dark-link;
        color: $c-inspiration-text;
    }

    .block__link {
        margin-top: 20px;
    }

    &.block--align-centre {
        .block__text {
            @include font(18px, 28px);
            max-width: 870px;
            margin: 0 auto;
            text-align: center;

            @include breakpoint(tablet) {
                @include font(14px, 22px);
                text-align: left;
            }
        }

        .block__link {
            margin: 0;
            text-align: center;
        }
    }
}
