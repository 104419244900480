.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

    .pagination__prev,
    .pagination__next,
    .pagination__link {
        @include font(18px, 18px);

        width: 40px;
        height: 40px;
        display: grid;
        place-items: center;
        border-bottom: 1px solid $c-border;
        transition: border-color 150ms ease-in;

        &:hover {
            border-color: $c-black;
        }
    }

    .pagination__link--active {
        border-color: $c-black;
    }

    .pagination__link--mobile
    .pagination__gap--mobile {
        display: none;

        @include breakpoint(phablet) {
            display: grid;
        }
    }

    .pagination__links {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
    }

    .pagination__link--mobile,
    .pagination__gap--mobile {
        display: none;

        @include breakpoint(phablet) {
            display: grid;
        }
    }

    .pagination__gap {
        width: 40px;
        text-align: center;
    }
