.filtered-results {
    padding-top: 40px;

    @include breakpoint(phablet) {
        padding-top: 30px;
    }
}

.filtered-show {
    border: 1px solid $c-border;
    display: block;
    position: relative;

    span {
        color: $c-black;
        @include font(20px, 24px);
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 15px 25px;
        margin-right: 55px;
        border-right: 1px solid $c-border;
        display: block;
    }

    .icon-chevron {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
    }
}

.filtered-title {
    @include font(22px, 27px);

    color: $c-black;
}

.filtered-reset {
    @include font(12px, 15px);
    letter-spacing: 0.5px;
    text-decoration: underline;
    font-weight: 200;
    transition: color .3s ease;
    color: $c-black;

    &:hover {
        color: $c-brand;
    }
}

.filtered-start-over {
    padding-bottom: 20px;

    @include breakpoint(phablet) {
        padding-left: 15px;
    }

    a {
        @include font(12px, 15px);
        letter-spacing: 0.5px;
        text-decoration: none;
        font-weight: 200;
        transition: color .3s ease;
        color: $c-black;

        &:hover {
            color: $c-brand;
        }

        span {
            text-decoration: underline;
        }

        svg {
            margin-right: 5px;
            fill: currentColor;
        }
    }
}

.filtered-filters {
    @include breakpoint(phablet) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100%;
        right: auto;
        transition: left 300ms;
        background: white;
        width: 100%;
        height: 100%;
        padding: 15px;
        z-index: 999;
    }
}

.filtered-filters--show {
    left: 0;
}

.filtered-filters__heading {
    margin-bottom: 15px;
    text-align: center;
    @include font(22px, 27px);
    padding: 0 15px 15px;
    color: $c-black;
    background: transparent;
    border-bottom: 1px solid $c-grey--button;
}

.filtered-filters__close {
    position: absolute;
    top: 7px;
    right: 13px;

    svg {

        @include breakpoint(tablet) {
            fill: $c-brand;
        }
    }
}

.filtered-filters__button-group {
    display: flex;

    @include breakpoint(tablet) {
        position: sticky;
        bottom: 0;
        background: $c-white;
        z-index: 1;
    }

    > * {
        width: 100%;
        margin: 10px 0;
    }
}

.filtered-results-heading {
    margin-bottom: 15px;

    &--divide {
        padding-top: 14px;
        border-top: 1px solid $c-border;
    }

    &__title {
        @include font(22px, 46px);
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(18px, 28px);
        }

        span {
            @include lightText;
        }
    }
}
