// Quick add form

.quick-add-form {
    margin-bottom: -20px;

    .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .field {
        width: 135px;
        margin-left: 10px;
        margin-right: 10px;

        .section--altdark & input {
            border: none;
        }
    }

    .field--submit {
        width: auto;
    }
}

.quick-add-form__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &--left {
        justify-content: left;
    }
}

.quick-add-form__title {
    display: inline-block;
    @include rem(22px);
    vertical-align: top;
    margin: 0 10px 20px;
    line-height: 45px;

    .section--altdark & {
        color: white;
    }

    &--h-flush {
        margin: 0 0 20px;
    }
}
