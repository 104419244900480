@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-thin.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-thinitalic.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-thinitalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-light.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-light.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-lightitalic.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-lightitalic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-regular.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-italic.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-bold.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Brown";
    src: url("/fonts/brownPro/lineto-brown-pro-bolditalic.woff2") format("woff2"),
         url("/fonts/brownPro/lineto-brown-pro-bolditalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Charter';
    src: url('/fonts/charter/bold.woff2') format('woff2'),
         url('/fonts/charter/bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Minion";
    src: url("/fonts/MinionPro-Regular.woff2") format("woff2"),
         url("/fonts/MinionPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
