.pdp-configure-popup {
    position: relative;
}

    .pdp-configure-popup__title {
        @include font(22px, 46px);

        position: sticky;
        top: 0;
        padding: 7px;
        color: $c-black;
        background: $c-white;
        text-align: center;
        z-index: 1;

        @include breakpoint(tablet) {
            @include font(18px, 28px);
            
            padding-left: 45px;
            padding-right: 45px;
        }
    }

        .pdp-configure-popup__container {
            padding: 40px 60px 120px;
            background: $background-grey;
            position: relative;

            @include breakpoint(phablet) {
                padding: 20px 20px 100px;
            }
        }

            .pdp-configure-popup__content {
                display: flex;
                flex-direction: column;
            }

                .pdp-configure-popup__item {
                    margin-bottom: 15px;

                    @include breakpoint(tablet) {
                        margin-bottom: 5px;
                    }
                }

                    .pdp-configure-popup__subtitle {
                        @include font(18px, 22px);

                        color: $c-black;
                        margin-bottom: 10px;

                        @include breakpoint(tablet) {
                            @include font(14px, 22px);
                        }

                        span {
                            @include lightText;
                        }
                    }

                    .pdp-configure-popup__subtitle--large-margin {
                        margin-bottom: 15px;
                    }

                    .pdp-configure-popup__text {
                        @include lightText;
                        @include font(14px, 22px);
                    }

                    .pdp-configure-popup__buttons {
                        margin-top: 15px;

                        & > * {
                            min-width: 105px;
                            margin-bottom: 25px;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                        }

                        small {
                            display: none;

                            &.pdp-configure-popup__mto {
                                display: block;
                            }
                        }
                    }

                    .pdp-configure-popup__option {
                        &--disabled {
                            opacity: 0.5;
                        }
                    }

            .pdp-configure-popup__action {
                position: fixed;
                bottom: 0;
                right: 0;
                width: 700px;
                background: $c-white;
                padding: 18px 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include breakpoint(phablet) {
                    width: 100%;
                    padding: 10px;
                    padding-left: 20px;
                }

                .overlay & {
                    width: auto;

                }

                .button {
                    flex-shrink: 0;
                }

                .mfp--has-scroll & {
                    right: var(--scrollbar-width);
                }
            }

                .pdp-configure-popup__action-selection {
                    @include font(14px, 20px);

                    padding-right: 15px;
                    display: none;
                }

                .pdp-configure-popup__action-content {}

                    .pdp-configure-popup__action-price {
                        @include font(24px, 24px);

                        color: $c-black;
                    }

                    .pdp-configure-popup__action-tax {
                        @include lightText;
                        @include font(14px, 18px);

                        color: $c-pdp-grey;

                        @include breakpoint(tablet) {
                            @include font(12px, 18px);
                        }
                    }

                    .pdp-configure-popup__action-lower {
                        @include font(14px, 17px);

                        display: flex;
                        color: $c-black;
                        margin-top: 3px;

                        @include breakpoint(tablet) {
                            @include font(12px, 15px);
                        }
                        
                        span {
                            margin-left: 8px;
                        }

                        svg {
                            fill: $c-brand;
                        }
                    }
