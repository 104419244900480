// This is to be looked at / removed another time
// Joe

@mixin infinity-border($position, $extension) {
    // position: relative;

    // @if $extension == left {
    //     &:before {
    //         content: "";
    //         position: absolute;
    //         top: 0;
    //         bottom: auto;
    //         width: 10000px;
    //         height: 1px;
    //         background: $c-section-border;
    //         left: auto;
    //         right: 0;

    //         @if $position == top {
    //             top: 0;
    //             bottom: auto;
    //         } @else {
    //             top: auto;
    //             bottom: 0;
    //         }
    //     }
    // } @else {
    //     &:after {
    //         content: "";
    //         position: absolute;
    //         bottom: 0;
    //         top: auto;
    //         width: 10000px;
    //         height: 1px;
    //         background: $c-section-border;
    //         left: 0;
    //         right: auto;

    //         @if $position == top {
    //             top: 0;
    //             bottom: auto;
    //         } @else {
    //             top: auto;
    //             bottom: 0;
    //         }
    //     }
    // }
}

@mixin simple-infinity-border($position) {
    // position: relative;

    // &:after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     top: auto;
    //     width: 100vw;
    //     height: 1px;
    //     background: $c-section-border;
    //     left: calc((-100vw - -100%) / 2);
    //     right: auto;

    //     @if $position == top {
    //         top: 0;
    //         bottom: auto;
    //     } @else {
    //         top: auto;
    //         bottom: 0;
    //     }
    // }
}

@mixin clear-infinity-border($extension: null) {
    // @if $extension == left {
    //     &:before {
    //         display: none;
    //     }
    // } @else if $extension == right {
    //     &:after {
    //         display: none;
    //     }
    // } @else {
    //     &:before, &:after {
    //         display: none;
    //     }
    // }
}

.border--top {
    border-top: 1px solid $c-section-border;
}

.border--l-top {
    border-top: 1px solid $c-section-border;

    @include breakpoint(mobile) {
        border-top: none;
    }
}

.border--right {
    border-right: 1px solid $c-section-border;

    @include breakpoint(mobile) {
        border-right: none;
    }
}

.border--bottom {
    border-bottom: 1px solid $c-section-border;
}

// .border--left {
//     margin-left: -1px;
//     border-left: 1px solid $c-section-border;
// }

// .border--top {
//     @include infinity-border(top, left);
//     @include infinity-border(top, right);
// }

// @include breakpoint(tablet) {
//     .m-border--top {
//         @include infinity-border(top, left);
//     }
// }

// .border--bottom {
//     @include infinity-border(bottom, left);
//     @include infinity-border(bottom, right);
// }

// .border--top-left {
//     @include infinity-border(top, left);
// }

// .border--top-right {
//     @include infinity-border(top, right);
// }

// .border--bottom-left {
//     @include infinity-border(bottom, left);
// }

// .border--bottom-right {
//     @include infinity-border(bottom, right);
// }

// @each $name in map-keys($sizes) {
//     @include breakpoint(map-get($sizes, $name)) {
//         .#{$name}-border--right {
//             border-right: 1px solid $c-section-border;
//         }

//         .#{$name}-border--left {
//             margin-left: -1px;
//             border-left: 1px solid $c-section-border;
//         }

//         .#{$name}-border--top {
//             @include infinity-border(top, left);
//             @include infinity-border(top, right);
//         }

//         .#{$name}-border--bottom {
//             @include infinity-border(bottom, left);
//             @include infinity-border(bottom, right);
//         }

//         .#{$name}-border--top-left {
//             @include infinity-border(top, left);
//         }

//         .#{$name}-border--top-right {
//             @include infinity-border(top, right);
//         }

//         .#{$name}-border--bottom-left {
//             @include infinity-border(bottom, left);
//         }

//         .#{$name}-border--bottom-right {
//             @include infinity-border(bottom, right);
//         }

//         .#{$name}-no-border {
//             @include clear-infinity-border;
//         }

//         .#{$name}-no-border--left {
//             border-left: none;
//         }

//         .#{$name}-no-border--right {
//             border-right: none;
//         }

//         .#{$name}-no-border--top-left {
//             @include clear-infinity-border(left);
//         }

//         .#{$name}-no-border--top-right {
//             @include clear-infinity-border(right);
//         }
//     }
// }

// @each $name in map-keys($sizes) {
//     @include breakpoint(map-get($sizes, $name)) {
//         .#{$name}-border-padding--left {
//             padding-left: $grid-gutter-h;
//             margin-left: -1px;
//         }

//         .#{$name}-border-padding--right {
//             padding-right: $grid-gutter-h;
//         }

//         .#{$name}-border-padding--top {
//             padding-top: $grid-gutter-v;
//         }

//         .#{$name}-border-padding--bottom {
//             padding-bottom: $grid-gutter-v;
//         }

//         .#{$name}-border-half-padding--bottom {
//             padding-bottom: $grid-gutter-v / 2;
//         }

//         .#{$name}-no-border-padding {
//             padding: 0;
//             margin: 0;
//         }

//         .#{$name}-no-border-padding--left {
//             padding-left: 0;
//             margin-left: 0;
//         }

//         .#{$name}-no-border-padding--right {
//             padding-right: 0;
//         }

//         .#{$name}-no-border-padding--top {
//             padding-top: 0;
//         }

//         .#{$name}-no-border-padding--bottom {
//             padding-bottom: 0;
//         }
//     }
// }
