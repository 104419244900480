.page-meta {
    padding: 30px 0;
    border-top: 1px solid $c-grey--button;
    border-bottom: 1px solid $c-grey--button;

    @include breakpoint(tablet) {
        padding: 20px 0;
    }
}

    .page-meta__wrap {
        display: flex;
        flex-wrap: wrap;
    }

        .page-meta__list {
            margin-right: 100px;

            @include breakpoint(tablet) {
                margin-right: 30px;
            }
        }

            .page-meta__key {
                @include font(12px, 20px);

                margin-bottom: 5px;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $c-page-meta-key;

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                }
            }

            .page-meta__value {
                @include font(22px, 46px);

                color: $c-black;
            }
