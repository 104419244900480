.track-configurator-progress {
    @include lightondark;

    display: flex;
    position: relative;
    justify-content: center;
    padding: 20px;
    background: $c-brand;
}

    .track-configurator-progress__item {
        min-width: 180px;
        color: $c-white;
        text-align: center;
        transition: opacity .3s;
    }

    .track-configurator-progress__item--inactive,
    .track-configurator-progress__item--current {
        pointer-events: none;
    }

    .track-configurator-progress__item:not(.track-configurator-progress__item--current) {
        opacity: 0.4;
    }

    .track-configurator-progress__item:not(.track-configurator-progress__item--current):not(.track-configurator-progress__item--inactive) {

        &:hover {
            opacity: 0.8;
        }
    }

        .track-configurator-progress__number {
            position: relative;
            margin-bottom: 10px;

            p {
                @include f-bold;
                @include rem(40px);

                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 80px;
                margin: 0 auto;
                color: $c-white;
                background: $c-brand;
                border: 5px solid $c-white;
                border-radius: 100%;
                box-sizing: border-box;
                overflow: hidden;
                z-index: 1;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba($c-white, 0.25) 0%, rgba($c-white, 0) 100%);
                    z-index: -1;
                }
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 41px;
                height: 7px;
                margin-top: 30px;
                box-shadow: 0 10px 20px 0 rgba($c-black, 0.5);
                transform: translate(-50%, -50%);
            }
        }

        .track-configurator-progress__label {
            @include rem(14px);

            line-height: calc(17 / 14);
        }
