.product-listing-item {
    @include rem(16px);

    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    transition: opacity .3s $transition-function;
}

    .product-listing-item__overlay-block {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $c-white;
        opacity: 0;
        display: none;
    }

    .product-listing-item__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .product-listing-item__image-wrap {
        align-self: stretch;
        position: relative;
        margin-bottom: 15px;
        overflow: hidden;

        @include breakpoint(tablet) {
            margin-bottom: 5px;
        }
    }

        .product-listing-item__image {
            aspect-ratio: 1;
            background: $background-grey;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1), opacity .5s;
                z-index: 1;

                // When there is no hover image apply the zoom the the main image on hover
                .product-listing-item__image-wrap:hover &:last-of-type {
                    transform: scale(1.2);

                    @include breakpoint(tablet) {
                        transform: none;
                    }
                }

                // When there is a hover image zoom and reveal it on hover
                .product-listing-item__image-wrap:hover &:nth-of-type(2) {
                    transform: scale(1.2);
                    opacity: 1;

                    @include breakpoint(tablet) {
                        transform: none;
                    }
                }

                &:nth-of-type(2) {
                    opacity: 0;
                }
            }
        }

    .product-listing-item__badge {
        @include font(12px, 22px);
        @include lightondark;

        position: absolute;
        top: 0;
        left: 0;
        color: $c-white;
        background: $c-grey--dark;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 4px 10px;
        z-index: 10;

        .product-listing-item__image-wrap:hover:not(.product-listing-item__badge--no-hover) & {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }

    .product-listing-item__badge--large {
        width: 120px;
    }

    .product-listing-item__overlay {
        @include lightondark;

        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($c-black, .5);
        z-index: 2;
        opacity: 0;
        transition: opacity .3s ease;

        &:empty {
            background: transparent;
        }

        .product-listing-item__image-wrap:hover & {
            opacity: 1;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

        .product-listing-item__overlay-contents {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            text-align: center;
        }

            .product-listing-item__overlay-title {
                @include font(24px, 30px);

                margin-bottom: 10px;
                color: $c-white;
            }

            .product-listing-item__overlay-text {
                @include font(16px, 19px);
                @include lightText;

                margin-bottom: 10px;
                color: $c-white;
            }

            .product-listing-item__overlay-stock {
                @include font(16px, 19px);

                color: $c-white;
            }

        .product-listing-item__overlay-actions {
            padding: 20px;
            background: $c-grey--dark;

            .product-listing-item__image-wrap:hover & {

                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }

            .product-listing-item__form {
                display: flex;
                margin: -10px;

                .button {
                    margin: 10px;
                }
            }

            .product-listing-item__form--hidden {
                display: none;
            }

    .product-listing-item__title {
        @include font(18px, 26px);

        width: calc(100% - 20px);
        margin: 0px 10px 10px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: var(--block-text-color, $c-black);

        @include breakpoint(phablet) {
            @include font(16px, 30px);

            margin-bottom: 5px;
        }

        a {
            color: inherit;
        }
    }

    .product-listing-item__text {
        @include font(14px, 24px);
        @include lightText;

        margin-bottom: 6px;
        color: var(--block-text-color, $c-black);
    }

    .product-listing-item__stock {
        @include font(14px, 24px);
    }

    .product-listing-item__price {
        @include font(16px, 19px);

        margin-bottom: 5px;
        color: $c-brand;
    }

    .product-listing-item__mto {
        @include lightText;
        @include font(12px, 18px);
        padding-top: 8px;
    }

    .product-listing-item__view-more {
        @include lightText;
        @include font(12px, 18px);

        color: $c-grey--dark;
        transition: color 150ms ease;
        display: block;
        padding-top: 8px;

        &:hover {
            color: $c-brand;
        }

        @include breakpoint(phablet) {
            @include font(12px, 18px);
        }

        svg {
            margin-left: 10px;
            fill: currentColor;
            transition: fill 150ms ease;
        }
    }

.product-listing-item__checkbox {
    position: relative;
    align-self: stretch;
    min-width: 105px;
    margin-top: 7px;
    text-align: center;

    @include breakpoint(tablet) {
        padding: 0 10px;
        min-width: 45px;
    }

    label {
        position: relative;
        font-family: $f-default-stack;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        color: $c-label;
        padding-left: 39px;
    }

    input {
        @include hideSafely(true);

        &:checked ~ .product-listing-item__pseudo-checkbox::before {
            background-image: url("/astro/project-specification/image/tick.svg");
            background-repeat: no-repeat;
            background-position: center;
            border-color: #505050;
        }

        &:disabled ~ .product-listing-item__pseudo-checkbox::before {
            opacity: 0.4;
        }
    }

    .listing-item__table-head--show-project & {
        min-width: 99px;
    }
}

.product-listing-item__pseudo-checkbox {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 3px;
    }

    .listing-item__table-head--show-project & {
        left: 16px;
    }
}
