// Section tabs

.tabs {
    @include breakpoint(tablet) {
        height: 50px;
        overflow: hidden;
        position: relative;
    }

    @include breakpoint(mobile) {
        &:not(.detail__tabs) {
            margin-bottom: 0;
        }
    }
}


.tabs--overflow-left:before,
.tabs--highlighted--overflow-left:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* using rgba($COLOUR, 0) because ios safari doesn't like transparent */
    background: linear-gradient(90deg, $background-grey 0%, rgba($background-grey, 0) 10%);
    z-index: 1;
    pointer-events: none;
}

.tabs--overflow-right:after,
.tabs--highlighted--overflow-right:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* using rgba($COLOUR, 0) because ios safari doesn't like transparent */
    background: linear-gradient(90deg, rgba($background-grey, 0) 90%, $background-grey 100%);
    z-index: 1;
    pointer-events: none;
}

.tabs--highlighted--overflow-left:before {
    background: linear-gradient(90deg, $c-brand 0%, rgba($c-brand, 0) 10%);
}
.tabs--highlighted--overflow-right:after {
    background: linear-gradient(90deg, rgba($c-brand, 0) 90%, $c-brand 100%);
}

.tabs__wrap {
    display: flex;
    justify-content: center;

    @include breakpoint(tablet) {
        overflow: hidden;
        overflow-x: auto;
        height: 60px; /* something larger than parent + scrollbar gutter (16px?) */
    }
}

.tabs--hightlight {
    .tabs__link {
        color: #DDDDDD;

        &:before {
            background-color: #DDDDDD;
        }

        &.tabs__link--active {
            color: $c-white;
        }
    }
}

.tabs--hightlight .tabs__link span::before {
    background-color: #DDDDDD;
}

.tabs--hightlight .tabs__link--active span::before {
    background-color: $c-white;
}


.tabs__link {
    @include rem(16px);
    @include f-light;

    min-width: 170px;

    &.tabs__link--active {
        color: $c-black;
        @include f-regular;

        &:hover {
            cursor: default;
        }
    }
}

.tabs__link:not(:last-of-type) {
    margin-right: 6px;
}

.tabs__link span {
    display: block;
    position: relative;
    width: 100%;
}

.tabs__link > span::before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #d8d8d8;
    background: linear-gradient(to left, #d8d8d8 50%, $c-link 50%);
    background-size: 200% 100%;
    background-position:right bottom;
}
.tabs__link--active > span::before {
    background-color: $c-link;
    background-position:left bottom;
    transition:all 1s ease;
}

@-webkit-keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.tabs__content {
    visibility: hidden;
    opacity: 0;
    height: 0;
}

.tabs__content--active {
    visibility: visible;
    opacity: 1;
    height: auto;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

.tabs__content__split {
    border-right: 1px solid $grey4;
    padding-right: 30px;
    height: 100%;
    box-sizing: border-box;

    @include breakpoint(tablet) {
        border-right: none;
        padding-right: 0;
        height: auto;
    }
}

@include breakpoint(mobile) {
    .tabs__wrap {
        justify-content: flex-start;
    }
}


/*
    Styles for the tabs on the PLP Filters page
 */
.tabs--filters {
    height: 35px;
    margin: 40px 0 0;

    @include breakpoint(tablet) {
        height: auto;
    }

    @include breakpoint(mobile) {
        margin: 40px -16px 0;
    }

    .tabs__wrap {

        @include breakpoint(tablet) {
            height: auto;
        }
    }

    .tabs__link {
        padding: 10px;
        border: 1px solid $grey3;
        border-width: 1px 1px 0 1px;
        text-transform: uppercase;
        font-weight: 400;

        @include breakpoint(tablet) {
            @include rem(12px);

            line-height: calc(25 / 12);
            width: 33.3333%;
            min-width: 0;
            padding: 5px 10px;
        }

        @include breakpoint(mobile) {
            padding: 5px;
        }

        &:not(:last-of-type) {
            margin-right: 0;
        }

        &:not(:first-of-type) {
            border-width: 1px 1px 0 0;
        }

        &:first-child {

            @include breakpoint(mobile) {
                border-left: none;
            }
        }

        &:last-child {

            @include breakpoint(mobile) {
                border-right: none;
            }
        }

        span {
            &:before {
                content: none;
            }
        }

        &.tabs__link--active {
            background-color: $grey3;
        }
    }
}

.tabs__content--filters {
    padding: 0 20px;
    background-color: $grey3;

    @include breakpoint(mobile) {
        margin: 0 -16px;
        padding: 0 16px;
    }

    &.tabs__content--active {
        animation: none;

        .filters {
            padding-top: 45px;

            @include breakpoint(tablet) {
                padding-top: 15px;
            }
        }

        .filters--alt {
            border-top: 2px solid $c-white;

            @include breakpoint(tablet) {
                border-top: none;
            }
        }
    }
}
