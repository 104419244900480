.mini-basket {
    width: 420px;
    z-index: 10000;
    position: absolute;
    top: 49px;
    right: 0;
    padding: 30px;
    background-color: $c-white;
    display: none;
    box-shadow: 0 4px 10px 0 rgba($c-black, 0.1);

    &.mini-basket--show {
        display: block;
    }

    @include breakpoint(nav) {
        width: 100%;
        top: 0;
        left: 0;
        position: fixed;
        padding: 0px;
        //max-height: none;
        //overflow: hidden;
    }

    img {
        width: 100px;
        height: auto;
    }
}

    .mini-basket__contents {
        display: flex;
        flex-wrap: wrap;
    }

        .mini-basket__title {
            @include font(18px, 30px);

            flex: 0 0 100%;
            color: $c-black;
        }

        .mini-basket__finish {
            @include font(14px, 17px);
            @include lightText;

            flex: 0 0 100%;
            margin-bottom: 10px;
            color: $c-mini-basket-finish;
        }

        .mini-basket__price {
            @include font(14px, 17px);

            flex: 0 0 50%;
            margin-bottom: 10px;
            color: $c-black;
        }

        .mini-basket__quantity {
            @include font(14px, 17px);

            flex: 0 0 50%;
            margin-bottom: 10px;
            text-align: right;
            color: $c-black;

            span {
                @include lightText;

                color: $c-grey--mid;
            }
        }

        .mini-basket__accessories {
            @include font(12px, 15px);
            @include lightText;

            transition: color .3s ease;

            &:hover {
                color: $c-brand;
            }
        }

.mini-basket__dispatch {
    .product-dispatch-message {
        @include font(14px, 17px);
        font-weight: 400;
        text-align: center;
        border-bottom: 1px solid $c-section-border;
        padding-bottom: 20px;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.mini-basket--order-total {
    @include font(16px, 24px);
    @include lightText;

    color: $c-black;
}

.mini-basket--order-value {
    @include font(22px, 24px);

    text-align: right;
    color: $c-black;
}

.mini-basket--ex-vat {
    @include font(14px, 17px);

    text-align: right;
    color: $c-brand;
}

.mini-basket--marker {
    z-index: 10001;
    position: absolute;
    top: 65px;
    left: -217px;
    padding-top: 20px;
    display: none;

    &:after {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid rgb(255, 255, 255);
        clear: both;
        content: '';
        position: absolute;
        transform: translate(-50%, -3%);
        width: 0;
        height: 0;
        left: 240px;
        top: -18px;
    }

    &.mini-basket-marker--show {
        display: block;

        @include breakpoint(nav) {
            display: none;
        }
    }
}

.mini-basket--divider {
    border-bottom: 1px solid $c-section-border;
    margin-right: 15px;
    margin-bottom: 20px;
    margin-left: 15px;
}

