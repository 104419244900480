.block--enquiry-form {
    color: var(--block-text-color, $c-black);

    .wrap {
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .block__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(tablet) {
            grid-template-columns: minmax(0, 1fr);
        }
    }

        .block__media {
            position: relative;

            @include breakpoint(tablet) {
                display: none;
            }
        }

            .block__image {
                @include aspect(1);
            }

            .block__media-content {
                position: absolute;
                top: 55px;
                left: 55px;
                color: $c-black;
                max-width: 400px;
            }

                .block__title {
                    @include font(38px, 44px);
                    margin-bottom: 15px;

                    @include breakpoint(tablet) {
                        @include font(26px, 32px);
                    }
                }

                .block__intro {
                    @include font(18px, 24px);

                    @include breakpoint(tablet) {
                        @include font(16px, 22px);
                    }
                }

        .block__content {
            background-color: var(--block-background, transparent);
            padding: 55px;

            @include breakpoint(tablet) {
                padding: 35px 15px;
            }
        }

            .block__content-header {
                display: none;

                @include breakpoint(tablet) {
                    display: block;
                    margin-bottom: 20px;
                }
            }

            .field {
                margin-bottom: 10px;

                &.field--required {
                    .field__optional {
                        display: none;
                    }
                }
            }

            .field--radio {
                label:first-child {
                    color: var(--block-text-color, $c-black);
                }
            }

            textarea.input {
                height: 100px;
            }

            .form-privacy {
                @include font(12px, 20px);
                margin-bottom: 0;

                a {
                    color: var(--block-text-color, $c-black);
                }
            }
}

.block--enquiry-form__sticky-bar {
    text-align: right;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;

    background: #F4F4F4;
    border-top: 1px solid $c-border-light;

    z-index: 10;

    @include breakpoint(tablet) {
        padding: 10px 15px;
    }
}
