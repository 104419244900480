.header-background {
    display: none;
    position: absolute;
    top: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    background: rgba($c-black, 0.75);

    @include breakpoint(tablet) {
        display: none;
    }
}

.header-background--activating {
    display: block;
    opacity: 0;
    transition: opacity .3s;

    @include breakpoint(tablet) {
        display: none;
    }
}

.header-background--active {
    opacity: 1;
}
