$selectize-border-radius: 0;
$selectize-shadow-input: none;
$selectize-shadow-input-focus: none;

@import 'selectize-scss/src/selectize';
@import 'selectize-scss/src/selectize.default';

/* OVERRIDE STYLES */
$selectize-font-size: 14px;
$selectize-line-height: 1;
$selectize-color-text: $c-grey--mid;
$selectize-padding-x: 3px;
$selectize-padding-y: 5px;
$selectize-shadow-input: 1px 1px 1px rgba(0,0,0,0.25);

.selectize-control {
    text-align: left;

    &.single {
        .selectize-input{
            height: 49px;
            box-shadow: $selectize-shadow-input;

            &:after {
                margin: 0;
                border: none;

                content: "\f107";
                font-family: FontAwesome;
                display:block;
                position:absolute;
                right: 20px;
                top:14px;
                @include rem(14px);
                pointer-events: none;
            }

            @include selectize-vertical-gradient(#fff,#fff);

            input,
            .item {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &.dropdown-active {
                &:after {
                    margin: 0;
                    border: none;
                }
            }
        }
    }
}

.product-selection {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 5;

    @include breakpoint(nav) {
        top: 0px;
    }

    &.product-selection--scrolling:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 10px;
        width: 100%;
        background: linear-gradient(180deg, #f3f3f3 0, transparent 100%);
    }
}

.product-dropdown {
    margin: 10px 0;
    display: flex;
    align-items: center;

    @include breakpoint(phablet) {
        display: block;
    }
}

    .product-dropdown__wrap.product-dropdown__wrap {
        flex: 1 1 auto;
        margin-right: 15px;

        &.field {
            margin-bottom: 0;

            @include breakpoint(phablet) {
                margin-bottom: 15px;
            }
        }

        .selectize-control {
            width: 100%;
        }

        @include breakpoint(phablet) {
            padding-right: 0;
        }
    }
