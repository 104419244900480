.checkout-address-form {

}
    .checkout-address-form__toggle {
        cursor: pointer;

        .checkout-address-form--manual & {
            display: none;
        }
    }

    .checkout-address-form__customer {

    }

    .checkout-address-form__address {
        display: none;

        .checkout-address-form--manual & {
            display: block;
        }
    }

    .checkout-address-form__address--visible {
        display: block;
    }
