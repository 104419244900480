.media-list__content {
    flex: 0 1 calc(50% - 15px);
    padding: 30px;
    height: 100%;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto auto;

    &:nth-child(odd) {
        margin-right: 15px;

        @include breakpoint(mobile) {
            margin-right: 0;
        }
    }

    &:nth-child(even) {
        margin-left: 15px;

        @include breakpoint(mobile) {
            margin-left: 0;
        }
    }

    @include breakpoint(tablet) {
        padding: 25px 0;
    }
}

.media-list__content--product {
    grid-template-columns: 1fr auto;
    padding: 18px 30px 22px;

    @include breakpoint(tablet) {
        grid-template-rows: auto auto auto;
        padding-right: 0;
        padding-left: 0;
    }
}

    .media-list__image {
        @include aspect( 1 );

        grid-column: 1/2;
        grid-row: 1/3;
        margin-right: 10px;

        img {
            width: 100%;
            height: auto;
        }

        @include breakpoint (tablet) {
            grid-row: 1/2;
        }
    }

    .media-list__image--no-image {
        align-self: flex-start;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $c-login-bg;
    }

    .media-list__link {
        grid-column: 2/3;
        grid-row: 1/2;
        margin-right: 20px;
        overflow-x: hidden;

        @include breakpoint(phablet) {
            overflow-x: auto;
        }

        svg {
            margin-right: 15px;
            flex-shrink: 0;
            fill: $c-grey--folder;
        }

        .media-list__content--product & {
            display: flex;
            align-items: flex-start;
            grid-column: 1/2;
            grid-row: 1/3;
            padding-top: 3px;

            svg {
                margin-top: 5px;
            }

            @include breakpoint(tablet) {
                margin-right: 0;
                grid-column: 1/3;
                grid-row: 1/2;
            }
        }
    }

        .media-list__header {
            flex: 1 1 auto;
        }

            .media-list__title {
                @include font(16px, 30px);

                color: $c-black;
            }

            .media-list__subtitle {
                @include font(11px, 22px);
                @include lightText;

                color: $c-black;

                .media-list__content--product & {
                    font-weight: normal;
                }
            }

    .media-list__button {
        grid-column: 2/3;
        grid-row: 2/3;
        display: flex;
        align-items: flex-end;
        flex-flow: row nowrap;

        @include breakpoint(tablet) {
            grid-column: 1/3;
            flex-basis: 100%;
            flex-flow: column nowrap;
            margin-top: 10px;
        }

        .media-list__content--product & {
            grid-column: 2/3;
            grid-row: 1/2;

            @include breakpoint(tablet) {
                grid-column: 1/3;
                grid-row: 3/4;
                margin-top: 0;
            }
        }
    }

    .media-list__button--multiple {

        a:first-of-type {
            margin-right: 25px;

            @include breakpoint(tablet) {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }

    .media-list__stock {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        margin-top: 8px;
        grid-column: 2/3;
        grid-row: 2/3;

        @include breakpoint(tablet) {
            grid-column: 1/3;
            justify-content: flex-start;
            margin: 15px 0;
        }
    }

        .media-list__count {
            @include font(14px, 22px);
            @include lightText;

            color: $c-pdp-grey;
        }

        .media-list__details {
                @include font(14px, 22px);

                color: $c-brand;
                text-decoration: underline;
                margin-left: 15px;
        }
