// Support

.support-block {
    @include lightondark;
    @include aspect(1);

    position: relative;
}

    .support-block__link {
        display: block;
        text-decoration: none;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            height: 50%;
            background: linear-gradient(0deg, rgba($c-black, 0) 0, $c-black 100%);
            opacity: 0.5;
        }
    }

    .support-block__image {
        @extend .image;

        position: relative;

        img {
            transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);

            .support-block__link:hover & {
                transform: scale(1.2);
            }
        }
    }

    .support-block__content {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        padding: 40px;
        z-index: 1;
        text-align: center;

        @include breakpoint(tablet) {
            padding: 15px;
        }
    }

        .support-block__title {
            @include font(26px, 34px);

            color: $c-white;
        }
