.overlay--default {
    .overlay__bg {
        background-color: $c-overlay-bg;
    }

    &.overlay-transition--fade.overlay--active {
        .overlay__bg {
            opacity: .8;
        }
    }

    .overlay__close {
        width: 44px;
        height: 44px;
        line-height: 44px;
        top: -44px;
        right: -44px;
        background: transparent;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            fill: $c-white;
            stroke: none;
        }

        @include breakpoint(tablet) {
            top: 7px;
            right: 0;
            z-index: 101;

            @include allowForBars((
                tablet: 7px
            ), 'top');

            svg {
                fill: $c-brand;
            }
        }
    }

    .overlay__container {
        max-width: 900px;

        @include breakpoint(tablet) {
            box-shadow: none;
            max-width: none;
            height: 100%;
        }
    }

        .overlay__content {
            overflow: auto;

            @include breakpoint(tablet) {
                height: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                right: 0;
                margin-left: 0;
                @include allowForBars($property: margin-top);
            }
        }

            .overlay__body {
                padding: 30px 30px 10px;
                overflow: auto;

                &--center {
                    text-align: center;
                }

                &--image-only {
                    @include aspect(7 / 6);

                    img {
                        @include cover;

                        width: 100%;
                        height: 100%;
                    }
                }

                @include breakpoint(tablet) {
                    padding: 30px 15px 0;
                }
            }

        .overlay__title {
            @include lightondark;
            @include font(22px, 27px);

            padding: 15px 30px;
            color: $c-white;
            background: $c-brand;
            text-align: center;

            @include breakpoint(tablet) {
                color: $c-black;
                background: transparent;
                border-bottom: 1px solid $c-grey--button;
            }
        }

        .overlay__footer {
            border-top: 1px solid $c-border-light;
            padding: 20px 0 30px;
            margin: 0 30px;

            @include breakpoint(tablet) {
                padding: 20px 0 30px;
                margin: 0 15px;
            }
        }
}

.overlay--medium {
    .overlay__container {
        max-width: 600px;
    }
}

.overlay--takeover.overlay--filters,
.overlay--takeover.overlay--filter-menu {
    @include breakpoint(tablet) {
        .overlay__bg {
            background-color: $c-white;
        }

        .overlay__content {
            top: 0;
            bottom: 0;
            transform: none;
            padding-bottom: 100px;
        }

        .overlay__close {
            width: 40px;
            height: 40px;
            padding: 0;
            background-color: transparent;
            display: grid;
            place-items: center;
            right: 5px;
            top: 15px;

            svg {
                width: 20px;
                height: 20px;
                stroke-width: 1px;
            }
        }
    }
}

.overlay.overlay--takeover {
    z-index: 100000;

    .overlay__container {
        transition: opacity .5s ease;
    }

    .overlay__container,
    .overlay__content {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: none;
        background: #F4F4F4;
    }

    .overlay__close {
        background: $c-white;
        border-radius: 50%;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            stroke-width: 0;
        }

        &:hover {
            background: $c-black;

            svg {
                fill: $c-white;
            }
        }

        @include breakpoint(tablet) {
            top: 15px;
            right: 15px;
        }
    }
}

.overlay.overlay--sidebar {
    .overlay__bg {
        background-color: $c-black;
    }

    .overlay__close {
        background: none;

        svg {
            fill: $c-white;
            stroke-width: 0;
        }

        &:hover {
            svg {
                fill: $c-black;
            }
        }
    }
}

.overlay--loading {
    opacity: 0.5;
    cursor: wait;
}
