.collection-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(phablet) {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

// Collections listing

.collection {
    position: relative;

    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin-right: -50%;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;

        .collection__title {
            display: block;
            @include rem(50px);
            margin-bottom: 25px;
        }

        .button {
        }
    }
}

    .collection__figure {

        img {
            width: 100%;
            height: auto;
        }
    }

// collection gallery

.collection-gallery {
    .slider__slide figure {
        img, &:before {
            margin-bottom: -0.5px !important;
        }
    }

    .slider__nav {
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        text-align: center;
        font-size: 0;
        z-index: 2;
    }

    .slider__nav__item {
        display: inline-block;
        width: 40px;
        height: 5px;
        margin: 6px 3px 0;
        background: $c-white;
    }

    .slider__nav__item--active {
        background: $c-grey--dark;
    }
}

// Needs refactor to be generic for filter meta too
.collection-meta {
    overflow: visible;
}

    .collection-meta__image-wrap {
        position: relative;
        height: 100%;

        @include breakpoint(tablet) {
            @include aspect(48/27);
        }
    }

    .collection-meta__video-wrap {
        position: relative;

        @include breakpoint(tablet) {
            @include aspect(16/9);
        }
    }

    .collection-meta__video {
        width: 100%;
        min-height: 338px;
        margin-bottom: -3px;

        @include breakpoint(tablet) {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
        }
    }

    .collection-meta__content {
        box-sizing: border-box;
        padding: 50px;
        text-align: left;
        display: table;
        height: 100%;
        width: 100%;

        @include breakpoint(tablet) {
            padding: 35px;
            text-align: center;
        }
    }

        .collection-meta__content--centered {
            text-align: center;
        }

    .collection-meta__content-inner {
        display: table-cell;
        vertical-align: middle;
    }

    .collection-meta__title {
        @include rem(40px);
    }

    .collection-meta__description {
        color: $c-white;
    }

