@import 'slick-carousel/slick/slick.scss';

.slick-disabled {
    opacity: 0.3;
}

.slick-arrow {
    outline: none;
}

.slick-track {
    display: flex;
    font-size: 0;
}

    .slick-slide {
        height: auto;
        outline: none;

        > div {
            height: 100%;
        }
    }
