.blocks .block.block--sus-image-third {
    margin: 0 auto;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-image-third {
    background: $c-block-background-linen;

    @include breakpoint(tablet) {
        padding: 60px 0;
    }

    .block__content {
        max-width: 75%;
        margin: 0 auto;
        padding: 30px 0;

        @include breakpoint(tablet) {
            max-width: 100%;
            padding: 0;
        }
    }

        .block__subtitle {
            @include lightText;
            @include font(18px, 22px);
        
            color: $c-black;
            margin-bottom: 13px;
            text-transform: uppercase;
            letter-spacing: 4px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);

                letter-spacing: 3px;
                margin-bottom: 18px;
            }
        }

        .block__title {
            @include font(54px, 62px);

            color: $c-black;
            margin-bottom: 25px;
            text-align: center;
            text-align: left;

            @include breakpoint(tablet) {
                @include font(32px, 40px);

                margin-bottom: 60px;
            }
        }

        .block__mobile-carousel {
            display: flex;
            justify-content: space-between;
        }

            .block__item {
                flex-basis: 200px;
                flex-grow: 1;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                @include breakpoint(tablet) {
                    flex-basis: 0;   
                }

                @include breakpoint(phablet) {
                    margin: 0;
                }
            }

                .block__image {
                    @include aspect(1);

                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                    }
                }

                .block__text {
                    @include lightText;
                    @include font(16px, 24px);

                    color: $c-black;

                    @include breakpoint(tablet) {
                        @include font(14px, 22px);
                    }
                }

    .block__full-height {
        @include aspect(3 / 5);

        position: relative;
        margin-right: -50px;

        img {
            @include cover;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
