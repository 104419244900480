$transparentItems: (light-blue, light-orange);

// Set background colours as CSS vars so the blocks can apply
// them to the appropriate element
@each $name, $value in $block-background-colours {
    .block--background-#{$name} {
        @if index($transparentItems, $name) {
            --block-background: rgba(#{$value}, 0.5);
        } @else {
            --block-background: #{$value};
        }
        --block-border-color-light: rgba(#{$value}, 0.8);
    }
}

.block--text-light {
    --block-text-color: #{$c-white};
    --block-arrow-border-color: #{$c-white};
    --block-arrow-color: #{$c-white};
}

.block--text-dark {
    --block-text-color: #{$c-black};
    --block-arrow-border-color: #{$c-black};
    --block-arrow-color: #{$c-black};
}

.block--title-font-minion {
    --block-title-font-family: #{$f-minion-stack};
}

.block {

    .gallery__product-title {
        @include font(14px, 17px);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .gallery__crosshair {
        .hotspot {
            transition: .2s transform;
        }

        &:hover .hotspot {
            transform: scale(1.2);
        }
    }

    .gallery__product:before {
        content: '';
        width: 0;
        height: 0;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent $c-white transparent;
        border-style: solid;
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
    }

    .gallery__product:not(.gallery__product--card) {
        width: auto;
    }

    .gallery__product--card {
        width: 340px;
        height: 433px;
        // need to override the grid width on product card which has no specific class
        > div {
            width: 100%;
        }

        .variants img {
            object-fit: none;
            font-family: normal;
            position: relative;
            top: unset;
            left: unset;
        }
    }
}

/**
 * Single Image
 */
.block__image {
    @include aspect(3 / 2);

    position: relative;

    @include breakpoint(tablet) {
        margin-bottom: 30px;
    }

    img {
        @include cover;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--square {
        @include aspect(1);

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }
    }

    &--5x3 {
        @include aspect(5 / 3);
    }

    &--project {
        @include aspect(1400 / 330);
        opacity: 0.29;

        @include breakpoint(tablet) {
            @include aspect(15 / 8);
        }

        &[data-aos^="stack"].aos-animate {
            opacity: 0.29;
        }
    }
}

.block-images {
    @include aspect(10 / 9);

    position: relative;

    @include breakpoint(tablet) {
        margin-bottom: 30px;
    }
}

.block-images__image {
    position: absolute;
    max-width: 70%;

    &:first-child {
        top: 0;
        left: 15%;
        z-index: 1;
    }

    &:nth-child(2) {
        right: 0;
        bottom: 0;
    }

    &:last-child {
        left: 0;
        bottom: 0;
        max-width: 30%;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.block__wrap {
    &--offset {
        margin-right: 80px;
        @include breakpoint(tablet) {
            margin-right: 0;
        }
    }

    &--split {
        margin-left: 80px;
        margin-right: 80px;

        @include breakpoint(tablet) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--project {
        position: relative;
        width: 100%;
        background: $c-black;
    }
}

.block-content {
    max-width: 580px;

    &--left {
        padding-right: 60px;
    }

    &--right {
        padding-left: 80px;
    }

    @include breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;
    }

    &--split {
        padding: 0 80px;

        @include breakpoint(tablet) {
            padding: 0 20px 40px;
        }
    }

    &--offset {
        padding: 0 80px;

        @include breakpoint(tablet) {
            padding: 0 20px 40px;

            .block-content__text {
                p:last-child {
                    margin-bottom: (19em / 16);
                }
            }
        }
    }

    &--project {
        max-width: unset;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        margin-top: 90px;
        padding-left: 80px;
        padding-right: 80px;
        color: white;

        @include breakpoint(phablet) {
            margin-top: 36px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &--cta {
        text-align: center;
        max-width: unset;
        margin: 0 80px;

        .block-content__title {
            display: inline-block;
            @include font(20px, 22px);
            margin: 0;
            vertical-align: middle;
        }

        .block-content__button {
            margin-left: 20px;
            display: inline-block;
        }

        @include breakpoint(phablet) {
            margin: 0 20px;
        }
    }
}

.block-content__title {
    @include font(40px, 46px);
    margin-bottom: 15px;

    &--alt {
        font-family: $f-alternate-stack;
    }

    &--offset {
        white-space: nowrap;
        float: right;
        position: relative;

        @include breakpoint(tablet) {
            white-space: inherit;
            float: inherit;
            position: inherit;
        }
    }

    &--dark {
        color: $c-black;
    }

    .block--text-light & {
        color: $c-white;
    }

    span {
        @include thinText;
    }

    @include breakpoint(tablet) {
        @include font(30px, 34px);
    }

    .block-content--project & {
        color: $c-white;
        @include font(18px, 22px);
        font-weight: lighter;
        letter-spacing: 4px;

        @include breakpoint(phablet) {
            @include font(16px, 19px);
            letter-spacing: 3.56px;
            text-align: left;
        }
    }
}

.block-content__text {
    font-weight: normal;
    margin-bottom: 30px;

    &--flush {
        margin-bottom: 0;
    }

    &--light-font {
        font-weight: 300;
    }

    &--light {
        font-weight: 300;
        color: $c-detail-subtitle;
    }

    .block--text-light & {
        @extend .richtext--dark;
    }

    .block-content--project & {
        color: $c-white;
        @include font(40px, 46px);
        font-weight: normal;

        @include breakpoint(tablet) {
            p:last-child {
                margin-bottom: (19em / 16);
            }
        }

        @include breakpoint(phablet) {
            @include font(24px, 28px);
            letter-spacing: 0;
            text-align: left;

            p:last-child {
                margin-bottom: 36px;
            }
        }
    }

    @include breakpoint(tablet) {
        margin-bottom: 0;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.block-content__button {
    @include breakpoint(tablet) {
        margin-top: 25px;
    }
}

.block-content_logo {
    text-align: left;

    img,
    svg {
        max-width: 160px;
        height: auto;

        @include breakpoint(tablet) {
            display: block;
            margin: 0 auto;
        }
    }
}

.block-featured {

    padding-top: 70px;

    @include breakpoint(tablet) {
        padding-top: 50px;
    }

    @include breakpoint(phablet) {
        padding-top: 30px;
    }

    & .product-preview:not(.product-preview--flush) {
        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }
}

.block-feature__content {
    position: relative;

    .product-preview--reverse & {
        text-align: left;
        @include breakpoint(tablet) {
            padding-right: 0;
        }
    }

    @include breakpoint(tablet) {
        margin: 30px 0 0;
        padding: 0;
    }
}

.block-feature__title {
    position: absolute;
    top: calc(50% - 80px);
}

@include breakpoint(desktop) {
    .block-feature__title {
        top: calc(50% - 125px);
    }
}

.block-split-large__left,
.block-split-large__right {
    height: 100%;
}

.block-split-large__left {
    @extend .l-one-half;
    @extend .m-full;

    @include breakpoint(pdp-large) {

        @media (max-height: 600px) {
            @include allowForBars($header-height, top);

            position: sticky;
            width: 33.3333%;
        }
    }

    @include breakpoint(tablet) {

        @media (max-height: 600px) {
            position: static;
            width: 100%;
        }
    }
}

.block-split-large__right {
    @extend .l-one-half;
    @extend .m-full;

    @include breakpoint(pdp-large) {

        @media (max-height: 600px) {
            width: 66.6666%;
        }
    }

    @include breakpoint(tablet) {
        @media (max-height: 600px) {
            width: 100%;
        }
    }
}

.block-split-large__content {
    position: relative;
    max-width: $site-max-width / 2;
    padding: 0;
    overflow: hidden;
    background-color: var(--block-background, transparent);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(pdp-large) {
        padding: 70px 80px;

        @media (max-height: 600px) {
            max-width: none;
            padding-top: 40px;
        }
    }

    @include breakpoint(pdp-medium) {
        padding: 40px 50px;

        @media (max-height: 600px) {
            padding-top: 10px;
        }
    }

    @include breakpoint(tablet) {
        max-width: none;
        padding: 30px 20px 0;
    }
}


.block__video {
    height: 100%;
    position: relative;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.block__video[data-type="vimeo"] {
    @include aspect(16 / 9);

    iframe {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.block__video-preview {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.block__play,
.block__pause {
    @include font(24px, 29px);
    @include lightText;

    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    letter-spacing: 6px;
    text-transform: uppercase;
    color: $c-white;
    transform: translate(-50%, -50%);

    @include breakpoint(tablet) {
        @include font(12px, 15px);

        letter-spacing: 3px;
    }

    svg {
        margin-right: 30px;
        fill: currentColor;

        @include breakpoint(tablet) {
            width: 40px;
            height: 40px;
            margin-right: 20px;
        }
    }
}

.block__pause {
    top: 20px;
    right: 20px;
    left: auto;
    transform: translate(0, 0);
    border-radius: 50px;
    background-color: transparentize($c-white, 0.35);
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
        margin-right: 0;
    }

    .icon-video {
        display: none;
    }
}

.block__pause--paused {
    .icon-video {
        display: block;
    }

    .icon-pause {
        display: none;
    }
}

.block__thumbnail-toggle {
    z-index: -1;
}

.block-projects {
    img {
        width: 100%;
    }

    @include breakpoint(phablet) {
        margin: 0;
    }

    .project-preview__content {
        padding-bottom: 25px;
        text-align: left;
    }
}

.blocks {

    .block {
        margin-top: 80px;
        margin-bottom: 80px;

        &:first-child {
            margin-top: 40px;
        }

        @include breakpoint(tablet) {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        &[class^="block--background-"]:not(.block--background-white):first-child,
        &[class*=" block--background-"]:not(.block--background-white):first-child {
            margin-top: 0;
        }
    }

    .block--flush {
        margin-top: 0;
        margin-bottom: 0;
    }

    .block--highlights {
        margin-top: 120px;

        @include breakpoint(tablet) {
            margin-top: 60px;
        }
    }

    .page-intro--has-blocks + & .block:first-child {
        margin-top: 0;
    }
}

.blocks--tight {

    .block {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .block--flush {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.blocks--tall {

    .block {
        margin-top: 120px;
        margin-bottom: 120px;

        @include breakpoint(tablet) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }

    .block--flush {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.blocks--flush-top {

    .block {

        &:first-child {
            margin-top: 0;
        }
    }
}

.blocks--flush-bottom {

    .block {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.blocks--m-tight {
    @include breakpoint(tablet) {
        .block {
            margin-top: 40px;
            margin-bottom: 40px;

            // Reset padding - this needs to be sorted out properly when the refresh is started
            padding: 0;
        }

        .block--banner {
            padding: 40px 0;
        }
    }
}

@import "blocks/block--awards";
@import 'blocks/block--banner';
@import 'blocks/block--image';
@import 'blocks/block--intro';
@import 'blocks/block--twin-image';
@import 'blocks/block--image-text';
@import 'blocks/block--richtext';
@import 'blocks/block--journal';
@import 'blocks/block--get-the-look';
@import 'blocks/block--social';
@import 'blocks/block--products';
@import 'blocks/block--video';
@import 'blocks/block--feature';
@import 'blocks/block--feature-grid';
@import 'blocks/hotspot';
@import 'blocks/block--overlap-image';
@import 'blocks/block--highlights';
@import 'blocks/block--categories';
@import 'blocks/block--sus-commitments';
@import 'blocks/block--sus-lifecycle';
@import 'blocks/block--sus-image-third';
@import 'blocks/block--sus-stats';
@import 'blocks/block--sus-image-two-thirds';
@import 'blocks/block--sus-image-sandwich';
@import 'blocks/block--sus-cards';
@import 'blocks/block--sus-circles';
@import 'blocks/block--twin-callout';
@import 'blocks/block--large-callout';
@import 'blocks/block--call-to-action';
@import "blocks/block--split-callout";
@import 'blocks/block--callout-bullets';
@import 'blocks/block--table';
@import 'blocks/popup/block--popup-split';
@import 'blocks/block--logos';
@import 'blocks/block--testimonials';
@import 'blocks/block--divider';
@import 'blocks/block--enquiry-form';
