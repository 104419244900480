// Callouts

.callouts {}

.callouts--product-grid {
    margin: 100px 0 0;

    @include breakpoint(tablet) {
        margin: 40px 0 0;
    }
}

    .callouts__grid {}

        .callouts__grid__col {

            &:last-child {
                position: relative;
                padding-bottom: calc(25% - 15px);

                @include breakpoint(tablet) {
                    padding-bottom: 15px;
                }

                > * {
                    position: absolute;
                    top: calc(-100% - 15px);
                    left: 30px;
                    width: calc(100% - 30px);

                    @include breakpoint(tablet) {
                        position: relative;
                        top: auto;
                        left: auto;
                        width: auto;
                    }
                }
            }
        }

            .callouts__callout {
                @include rem(15px);
                text-align: left;
                position: relative;

                &:hover {
                    cursor: pointer;

                    .callouts__callout__title {
                        color: $c-black;
                    }
                }
            }

                .callouts__callout--zoom {
                    overflow: hidden;

                    .callouts__callout__image {
                        transition: all 0.3s;
                    }

                    &:hover {
                        .callouts__callout__image {
                            transition: all 0.2s;
                            transform: scale(1.04, 1.04);
                        }
                    }
                }

            .callouts__callout__link {
                position: relative;
                display: block;

                img {
                    width: 100%;
                    height: auto;
                }
            }

                .callouts__gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($c-black, 0.2);
                }

            .callouts__callout__image {
                display: block;
                position: relative;
                height: 0;
                padding-bottom: 100%;
                background-size: cover;
                text-decoration: none;

                .callouts--partners & {

                    @include breakpoint(tablet) {
                        padding-bottom: 50%;
                    }
                }
            }

                .callouts__callout__image--short {
                    padding-bottom: calc(50% - 15px);
                    overflow: hidden;

                    @include breakpoint(tablet) {
                        padding-bottom: 100%;
                    }
                }

            .callouts__callout__content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                text-align: center;
                margin-right: -50%;
                max-width: 90%;
                box-sizing: border-box;
                z-index: 1;
            }

            .callouts__callout__heading {
                @include rem(28px);

                line-height: calc(53 / 28);
                color: $c-white;

                .callouts--partners & {

                    @include breakpoint(tablet) {
                        @include rem(24px);

                        line-height: calc(53 / 24);
                    }
                }
            }

            .callouts__callout__title {
                display: block;
                background: $c-white;
                font-weight: bold;
                text-transform: uppercase;
                color: $c-grey--mid;
                padding: 15px 30px;

                .callouts--partners & {

                    @include breakpoint(tablet) {
                        @include rem(11px);

                        line-height: calc(14 / 11);
                        display: inline-block;
                        padding: 10px 15px;
                    }
                }
            }

            .callouts__callout--video {
                i {
                    display: block;
                    position: absolute;
                    top: 25%;
                    left: 50%;
                    transform: translateX(-50%);
                    background: rgba(255,255,255,0.75);
                    width: 70px;
                    height: 70px;
                    border-radius: 70px;
                    line-height: 70px;
                    @include rem(18px);
                    color: #121212;
                    text-align: center;
                }

                &:hover i {
                    background: white;
                }

                span {
                    top: auto;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0;
                    transform: none;
                    text-decoration: underline;
                    padding: 20px 0 0;
                }
            }
