.scenes {
    padding: 100px 0;

    @include breakpoint(tablet) {
        padding: 35px 5px;
    }
}

    .scenes__title {
        @include font(40px, 46px);

        margin-bottom: 25px;
        color: $c-black;

        @include breakpoint(tablet) {
            @include font(24px, 34px);

            margin-bottom: 12px;
        }

        span {
            @include thinText;

            @include breakpoint(tablet) {
                @include lightText;
            }
        }
    }

    .scenes__text {
        margin-bottom: 20px;
    }

    .scenes__buttons {

        .button {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .scenes__image-wrapper {
        position: relative;
    }

        .scenes__image {
            @include aspect(4 / 3);

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        .scenes__image-2 {
            @include aspect(4 / 3);

            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity .3s ease;

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        .scenes__image-2--active {
            opacity: 1;
        }

        .scenes__caption {
            @include font(14px, 17px);

            padding: 15px 20px;
            background: $c-white;
            border-radius: 0 0 3px 3px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

            .scenes__caption-text {
                @include lightText;

                text-transform: none;
                letter-spacing: normal;
            }
