.product-links {
    padding: 50px;

    @include breakpoint(tablet) {
        padding: 40px 20px;
    }
}

    .product-links__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }

        img,
        svg {
            display: block;
        }
    }

    .product-links__items {
        max-width: 245px;
        margin: 0 auto;

        @include breakpoint(phablet) {
            max-width: none;
        }

        .button {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
