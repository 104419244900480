.blocks .block.block--sus-circles {
    margin: 0 auto;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

.block--sus-circles {
    padding: 120px 0 100px;

    @include breakpoint(tablet) {
        padding: 55px 0 0;
    }

    .block__header {
        max-width: calc((2 / 3) * 100%);
        margin: 0 auto 40px;
        text-align: center;

        @include breakpoint(tablet) {
            max-width: 700px;
            padding: 0 15px;
        }
    }

        .block__title {
            @include font(54px, 62px);

            color: $c-black;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(32px, 40px);

                margin-bottom: 10px;
            }
        }

        .block__intro {
            @include font(18px, 26px);

            color: $c-black;

            @include breakpoint(tablet) {
                @include font(16px, 26px);
            }
        }

    .block__content {
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }

        .block__item {
            position: relative;
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            border-radius: 50%;
            height: 650px;
            width: 650px;
            padding: 90px;

            @include breakpoint(tablet) {
                border-radius: 0;
                padding: 50px 15px;
                height: auto;
                width: auto;
            }
        }

        .block__item--seashell {
            background: $c-seashell;
            margin-right: -40px;
            z-index: 3;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }

        .block__item--springs {
            background: $c-block-background-desert-springs;
        }

            .block__subtitle {
                @include font(32px, 46px);

                color: $c-black;
                margin-bottom: 40px;
                flex-basis: 100%;
                text-align: center;

                @include breakpoint(tablet) {
                    @include font(24px, 32px);

                    margin-bottom: 20px;
                }
            }

                .block__details {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 40px;

                    @include breakpoint(tablet) {
                        width: 100%;
                    }

                    @include breakpoint(phablet) {
                        margin: 0;
                    }
                }

                    .block__stat {
                        text-align: center;
                        flex-basis: 40%;
                        margin-top: -40px;

                        @include breakpoint(phablet) {
                            margin: 0;
                        }
                    }

                        .block__number {
                            @include font(54px, 46px);

                            letter-spacing: 2px;
                            text-align: center;
                            color: $c-black;
                            z-index: 2;
                            position: relative;

                            @include breakpoint(tablet) {
                                @include font(22px, 25px);
                            }

                            span {
                                @include font(22px, 30px);
                                
                                letter-spacing: 0.5px;
                                display: block;

                                @include breakpoint(tablet) {
                                    @include font(14px, 24px);
                                }
                            }
                        }

                        .block__number--large {
                            @include font(70px, 60px);

                            margin-bottom: 40px;

                            @include breakpoint(tablet) {
                                @include font(44px, 44px);

                                margin-bottom: 30px;
                            }
                        }

                        .block__image {
                            max-width: 150px;
                            margin: 0 auto 20px;

                            img {
                                max-width: 100%;
                                height: auto;
                                position: relative;
                            }
                        }

                        .block__text {
                            @include lightText;
                            @include font(16px, 24px);

                            color: $c-black;
                            z-index: 2;
                            position: relative;
                        }

                        .block__bullets {
                            max-width: 300px;

                            @include breakpoint(tablet) {
                                max-width: 100%;
                                margin: 0 auto;
                            }
                        }

                    .block__stat--circle {
                        position: relative;
                        margin: 0;

                        @include breakpoint(phablet) {
                            margin-top: 40px;
                        }
   
                        &::before {
                            content: '';
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: -45px;
                            height: 160px;
                            width: 160px;
                            border-radius: 50%;
                            background: $c-block-background-desert-springs;

                            @include breakpoint(phablet) {
                                top: -17px;
                                height: 100px;
                                width: 100px;
                            }
                        }
                    }

                    .block__equal {
                        flex-basis: 20%;
                        text-align: center;
                        margin: 20px 20px 0;

                        @include breakpoint(phablet) {
                            margin-top: 60px;
                        }
                    }

            .block__image--absolute {
                max-width: none;
                position: absolute;
                right: -30px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                max-width: 250px;

                @include breakpoint(pdp-medium) {
                    right: -60px;

                    img {
                        max-width: 200px;
                    }
                }

                @include breakpoint(tablet) {
                    position: relative;
                    top: initial;
                    right: initial;
                    transform: none;
                    margin: 0 auto 20px;

                    img {
                        max-width: 300px;
                    }
                }
            }
   
}
