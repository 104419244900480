.footer-nav {}

    .footer-nav__list {}

        .footer-nav__item {}

            .footer-nav__link {
                @include font(14px, 26px);
                @include lightText;

                color: $c-white;
                -webkit-font-smoothing: antialiased;
                -moz-font-smoothing: antialiased;
                -o-font-smoothing: antialiased;
                transition: color .3s ease;

                &:hover {
                    text-underline-position: under;
                    text-decoration: underline;
                }
            }
