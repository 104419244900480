.awards {
    position: relative;
}

    .awards__container {
        margin: 0 65px;
        overflow: hidden;
    }

        .awards__logo {
            max-width: 160px;
            padding: 0 20px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

    .awards__nav {
        position: absolute;
        top: -60px;
        right: 105px;
        width: 66px;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);

        @include breakpoint(tablet) {
            top: -55px;
            right: 20px;
            width: 51px;
        }

        @include breakpoint(mobile) {
            top: -50px;
        }
    }

        .awards__control {
            cursor: pointer;

            &:hover {

                svg {
                    fill: $c-grey--dark;
                }
            }

            svg {
                display: block;
                fill: $c-border;
                transition: fill .3s ease;

                @include breakpoint(tablet) {
                    height: 25px;
                }
            }
        }
