.partners-import-field {
	padding: 20px;
	border: 1px dashed $c-import-border;
	border-radius: 3px;
	text-align: center;

    .field--file {

        .error {
            display: none;
        }

        @include breakpoint(mobile) {
            min-width: 0;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .import-file-field {
        visibility: hidden;
    }

    .field .import-file-label {
        @include breakpoint(mobile) {
            max-width: none;
        }
    }
}

	.partners-import-field__container {

		svg {
			margin-bottom: 13px;
		}

		.field--submit {
			display: none;
		}

		.field--submit--active {
			display: block;
		}
	}

		.partners-import-field__label {
			@include font(16px, 28px);
			@include lightText;

			margin-bottom: 10px;
			color: $c-black;
		}

		.partners-import-field__label--secondary {
			@include font(14px, 17px);
			@include regularText;

			margin-bottom: 15px;
			letter-spacing: 1px;
		}

		.partners-import-field__buttons {
			display: flex;
			justify-content: center;
			margin: -10px;

			> * {
				margin: 10px;
			}
		}
