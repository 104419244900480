.stockist-search {
    position: relative;
    max-height: 670px;
    overflow: hidden;
    overflow-y: scroll;
    padding-left: 20px;

    @include breakpoint(tablet) {
        max-height: none;
        border-right: none;
        padding-left: 0;
    }
}

    .stockist-search__entry {
        background-color: #F8F6EF;
        padding: 20px;
        border-radius: 3px;

        @include breakpoint(tablet) {
            padding: 30px 15px;
            border-radius: 0;
        }

    }

        .stockist-search__container {
            display: block;
            max-width: 600px;
            margin: 0 auto;
            background-color: $c-white;
        }

            .stockist-search__form {
                display: flex;
                border: 1px solid $c-border;
                border-radius: 3px;
            }

                .stockist-search__input-wrapper {
                    flex: 1 1 auto;
                }

                    input.input.stockist-search__input {
                        width: 100%;
                        border: none;

                        @include placeholderStyles() {
                            @include f-light;

                            color: $grey2;
                        }
                    }

                .stockist-search__reset {
                    @include f-light;
                    @include rem(10px);

                    line-height: normal;
                    align-self: center;
                    flex: 0 0 auto;
                    margin: 10px 15px;
                    color: $grey2;
                    text-transform: uppercase;
                    cursor: pointer;
                }

                .stockist-search__icon {
                    position: relative;
                    flex: 0 0 50px;
                    height: 50px;
                    border-left: 1px solid $c-border;

                    svg {
                        @include center;

                        fill: $c-black;
                    }
                }

        .stockist-search__options {
            padding: 20px 0 0;
            display: flex;
            justify-content: center;

            .field--inline {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;

                label {
                    position: relative;
                    color: $c-black;
                    @include font(12px, 18px);
                    top: unset;
                    left: unset;
                }

                select {
                    appearance: none;
                    background-color: transparent;
                    background-position: right 20px center;
                    background-size: 10px 7px;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10' height='7' viewBox='0 0 10 7'%3E%3Cpath d='M9.90008037,0.634364823 L9.39911346,0.105770677 C9.33235999,0.0351828234 9.25546364,0 9.1685297,0 C9.08180634,0 9.00494509,0.0351828234 8.93819162,0.105770677 L5.00005264,4.26115839 L1.06208915,0.105881781 C0.995300584,0.035293927 0.91843933,0.000111103653 0.831610682,0.000111103653 C0.744746937,0.000111103653 0.667885684,0.035293927 0.601132212,0.105881781 L0.100235497,0.634512961 C0.0333416395,0.704952677 0,0.786058343 0,0.877718857 C0,0.969305301 0.0334469289,1.05041097 0.100235497,1.12085068 L4.76957417,6.04814955 C4.83632765,6.1186263 4.913224,6.15384615 5.00005264,6.15384615 C5.08688129,6.15384615 5.16363726,6.1186263 5.23035563,6.04814955 L9.90008037,1.12085068 C9.96683384,1.05037393 10,0.969268267 10,0.877718857 C10,0.786058343 9.96683384,0.704952677 9.90008037,0.634364823 Z'/%3E%3C/svg%3E%0A");
                    text-overflow: ellipsis;
                    border: none;
                    padding: 0 35px 0 0;
                    @include font(12px, 18px);
                    text-decoration: underline;
                    @include f-regular;
                }
            }
        }

        .stockist-filter__content {
            padding: 25px 25px 0;

            @include breakpoint(tablet) {
                padding: 15px 15px 0;
            }
        }

            .stockist-filter__check {
                &.check_box {
                    .field & {
                        align-items: flex-start;
                    }
                }
            }

        .stockist-filter__option {
            position: relative;
            padding-left: 20px;
        }

            .stockist-filter__icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 20px;
            }

            .stockist-filter__link {
                display: inline-block;
                @include font(14px, 17px);
                @include f-regular;
                text-decoration: underline;
            }

    @-webkit-keyframes slideIn {
        0% {
            transform: translateY(-50%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slideIn {
        0% {
            transform: translateY(-50%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .stockist-search__overview {
        display: none;
        margin-top: -35px;
        padding-bottom: 15px;
        text-align: center;
        font-size: 12px;
    }
        .stockist-search__overview.show {
            display: block;
        }

    .stockist-search__noresults,
    .stockist-search__refine {
        visibility: hidden;
        opacity: 0;
        height: 0;
        border-top: 1px solid $grey3;
        border-bottom: 1px solid $grey3;
    }

        .stockist-search__noresults-content,
        .stockist-search__refine-content {
            @include rem(18px);
            padding: 30px;
            text-align: center;
            line-height: 1*26/18;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

    .stockist-search__noresults--active,
    .stockist-search__refine--active {
        visibility: visible;
        opacity: 1;
        height: auto;
        animation-name: slideIn;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;
    }

    .stockist-search__pagination {
        margin: 30px 0;

        @include breakpoint(tablet) {
            margin: 0 20px 30px;
        }
    }

    .stockist-search__map-results-wrap {
        position: relative;

        @include breakpoint(tablet) {
            margin: 0 15px 30px;
        }
    }

        .stockist-search__results {
            display: none;
            background: $c-white;

            @include breakpoint(tablet) {

            }
        }

        .stockist-search__results--active {
            display: block;
            transition: display 300ms;
        }

            .stockist-search__results__header {
                @include rem(16px);
                @include f-light;

                display: none;
                padding: 20px 16px;
                color: $c-copy;

                @include breakpoint(tablet) {
                    display: block;
                }

                strong {
                    font-style: italic;
                }
            }

            .stockist-search__results__item {
                @include rem(16px);

                position: relative;
                font-family: $f-default-stack;
                color: $c-copy;
                cursor: pointer;
                @include border-line-grow;

                &:hover {
                    @include border-line-trigger;
                }

                @include breakpoint(tablet) {
                }

                span {
                    @include f-light;

                    text-align: right;
                    text-decoration: none;
                    width: 30%;
                    display: inline-block;
                    position: absolute;
                    right: 40px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

                .stockist-search__results__item__inner {
                    padding: 15px 0;
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    &:after {
                        content: ' ';
                        position: absolute;
                        transform-origin: center;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        bottom: 0;
                        background: url('/images/stockists/icon-arrow-large-right.svg') no-repeat;
                        background-size: 10px 18px;
                        height: 18px;
                        width: 10px;

                        @include breakpoint(tablet) {
                            border: none;
                            background: url('/images/stockists/icon-plus-thin.svg') no-repeat;
                            background-size: 10px 9px;
                            height: 10px;
                            width: 9px;
                            right: 0;
                        }
                    }

                    .stockist-search__results__item--active & {
                        &:after {
                            @include breakpoint(tablet) {
                                background: url('/images/stockists/icon-minus-thin.svg') no-repeat;
                                background-size: 10px 9px;
                            }
                        }
                    }
                }

            .stockist-search__results__item--more {
                @include rem(16px);

                padding: 28px 0 0;
                font-family: $f-default-stack;
                color: $c-copy;
                margin-bottom: 20px;

                border-left: 1px solid $c-stockist-result-active;
                border-right: 1px solid $c-stockist-result-active;
                border-bottom: 1px solid $c-stockist-result-active;
                display: none;
            }

                .stockist-search__results__item__header {
                    @include font(16px, 19px);
                    margin: 0;
                    margin-bottom: 4px;

                    + .stockist-search__results__item__icon {
                        top: 20px;

                        @include breakpoint(tablet) {
                            top: 2px;
                        }
                    }
                }

                .stockist-search__results__item__category {
                    @include f-light;
                    @include font(12px, 20px);
                    margin: 0;
                }

                .stockist-search__results__item__distance {
                    @include f-light;
                    @include font(11px, 20px);
                    position: relative;
                    margin: 0;
                }

                .stockist-search__results__item__icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 15px;
                    height: 20px;
                }

                $stockistCategories: approved, bathroom, gold, international, john-lewis, national-retailer, platinum, premier, showroom, silver;

                @for $i from 1 through length($stockistCategories) {
                    .stockist-search__results__item__icon--#{nth($stockistCategories, $i)},
                    .stockist-filter__icon--#{nth($stockistCategories, $i)}
                    {
                        background: url('/images/stockists/#{nth($stockistCategories, $i)}.svg');
                        background-size: 15px 20px;
                    }
                }
