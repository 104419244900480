// Slidey sliders

.slider {
    position: relative;
}

    .slider__inner {
        position: relative;
        overflow: hidden;
    }

    .slider__slide {
        position: relative;
        z-index: 1;
        float: left;
        width: 100%;
        margin: 0 -100% 0 0;
        visibility: hidden;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .slider__slide:first-child {
        visibility: visible;
    }

    .slider--enabled .slider__slide {
        visibility: hidden;
        display: block;
        margin-right: -100%;
    }

    .slider .slider__slide--active {
        visibility: visible;
        z-index: 2;
    }

    .slider__slide--enter-left,
    .slider__slide--enter-right,
    .slider__slide--exit-left,
    .slider__slide--exit-right {

        .slider & {
            visibility: visible;
        }
    }

    .slider__slide--enter-left,
    .slider__slide--exit-left {
        transform: translateX(-100%);
    }

    .slider__slide--enter-right,
    .slider__slide--exit-right {
        transform: translateX(100%);
    }

    .slider__slide--exit-left,
    .slider__slide--exit-right {
        transition: transform 0.3s ease-in-out;
    }

    .slider__slide--active {
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .slider--enabled .slider__prev,
    .slider--enabled .slider__next {
        display: none;

        @include breakpoint(mobile) {
            display: block;
            background: none;
            &:before,
            &:after {
                content: none;
            }
            width: 35px;
            height: 75px;
            margin: -37px 20px 0;
            opacity: 0.8;
        }

        &:hover {
            opacity: 1;
        }
    }

    .slider--show-arrows .slider__prev,
    .slider--show-arrows .slider__next {
        display: block;
    }

    .slider--show-arrows-desktop .slider__prev,
    .slider--show-arrows-desktop .slider__next {
        display: block;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .slider__prev,
    .slider__next {
        display: none;
        position: absolute;
        top: 50%;
        width: 35px;
        height: 75px;
        margin: -37px 20px 0;
        opacity: 0.19;
        z-index: 3;
        transition: opacity .3s ease;

        @include breakpoint(mobile) {
            display: block;
        }

        &:hover {
            opacity: 1;
        }

        .slider--account & {
            margin-top: 46px;
            transform: translateY(-50%);
        }

        svg {
            display: block;
            fill: $c-white;
        }
    }

    .slider__prev {
        left: 0;
    }

    .slider__next {
        right: 0;
    }

    .slider__info {
        @include rem(15px);
        clear: both;
        text-align: right;
        padding-top: $grid-gutter-v;
        text-transform: uppercase;
    }

    .slider__info--center {
        text-align: center;
    }

    .slider--gallery {
        padding-left: calc(55px + 15px);

        @include breakpoint(tablet) {
            padding-left: 0;
        }

        .slider__nav {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            height: 55px * 11 + 15px * 10; // 11 items, plus margin of 10 items
            z-index: 3;

            @media (max-height: 600px) {
                height: 40px * 7 + 15px * 6; // 7 items, plus margin of 6 items
            }

            @include breakpoint(wrap) {
                height: 55px * 7 + 15px * 6; // 7 items, plus margin of 6 items

                @media (max-height: 600px) {
                    height: 40px * 4 + 15px * 3; // 4 items, plus margin of 3 items
                }
            }

            @include breakpoint(laptop) {
                height: 55px * 6 + 15px * 5; // 6 items, plus margin of 5 items

                @media (max-height: 600px) {
                    height: 40px * 4 + 15px * 3; // 4 items, plus margin of 3 items
                }
            }

            @include breakpoint(pdp-large)  {

                @media (max-height: 600px) {
                    bottom: 20px;
                    left: 20px;
                }
            }

            @include breakpoint(tablet)  {
                top: initial;
                bottom: calc((55px + 20px) * -1); // slider height plus padding
                left: 20px;
                right: 0;
                height: auto;

                @media (max-height: 600px) {
                    bottom: 40px;
                    left: 40px;
                }
            }
        }

            .slider__nav__item {
                display: block;
                margin-bottom: 15px;
                border-radius: 3px;
                overflow: hidden;

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                    margin-right: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                    margin-right: 0;
                }

                img {
                    width: 55px;
                    height: 55px;

                    @include breakpoint(pdp-large)  {

                        @media (max-height: 600px) {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    @include breakpoint(tablet)  {

                        @media (max-height: 600px) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }

            .slider__nav__item--active {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border: 1px solid $c-grey--dark;
                    border-radius: 3px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                    left: 1px;
                    border: 1px solid $c-white;
                    border-radius: 3px;
                }
        }
    }

    .slider[data-count="1"] {

        .slider__nav,
        .slider__next,
        .slider__prev {
            display: none;
        }
    }

    // allow fade in to work with gallery
    .slider--gallery.slider--fade {
        .slider__inner {
            &:before {
                padding-top: 100%;
            }
        }
    }

    // Fade sliders

    .slider--fade {
        .slider__inner {
            &:before {
                content: '';
                display: block;
                width: 100%;
                padding-top: (700 / 700) * 100%;
            }
        }

        .slider__slide {
            position: absolute;
            float: none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0;
            z-index: 1;
        }

        .slider__slide--enter-left,
        .slider__slide--exit-left {
            transform: translateX(0);
        }

        .slider__slide--enter-right,
        .slider__slide--exit-right {
            transform: translateX(0);
        }

        .slider__slide--enter-left,
        .slider__slide--enter-right {
            opacity: 0;
            z-index: 3;
        }

        .slider__slide--exit-left,
        .slider__slide--exit-right {
            opacity: 1;
            z-index: 2;
        }

        .slider__slide--active {
            opacity: 1;
            z-index: 3;
            transition: opacity 0.5s ease-in-out;
        }
    }

    .slider--fade--edge {
        .slider__inner {
            &:before {
                content: '';
                display: block;
                width: 100%;
                padding-top: (700 / 700) * 100%;
            }
        }
    }

    .slider--account {
        .slider__inner {
            &:before {
                padding-top: (2 / 3) * 100%;
            }
        }
    }

    @media screen and (min-width: 701px) {
        .slider--fade {
            .slider__inner {
                &:before {
                    padding-top: (700 / 1800) * 100%;
                }
            }
        }
        .slider--account {
            .slider__inner {
                &:before {
                    padding-top: (460 / 1400) * 100%;
                }
            }
        }
    }

    .slider--arrows {
        .slider__prev,
        .slider__next {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

// .slider__nav {
//     .slider--fit & {
//         right: 40px;
//     }
// }
