.fauxdal {
    @include allowForBars($property: margin-top);

    position: relative;
}

.fauxdal__close {
    position: absolute;
    top: 54px;
    right: 54px;
    width: 20px;
    height: 20px;
    transform: translate(50%, -50%);

    @include breakpoint(tablet) {
        top: 46px;
        right: 46px;
    }

    @include breakpoint(phablet) {
        right: 25px;
    }

    svg {
        display: block;
        fill: $c-black;
    }
}

    .fauxdal__primary-title {
        @include font(32px, 46px);

        padding: 30px 70px;
        text-align: center;
        color: $c-black;
        border-bottom: 1px solid $c-grey--light;

        @include breakpoint(tablet) {
            @include font(24px, 32px);
        }

        @include breakpoint(phablet) {
            padding-right: 50px;
            padding-left: 50px;
        }

        span {
            @include lightText;
        }
    }

    .fauxdal__secondary-title {
        @include font(20px, 30px);

        text-align: center;
        color: $c-black;
    }

    .fauxdal__section {
        padding: 40px;

        @include breakpoint(phablet) {
            padding: 15px;
        }
    }

    .fauxdal__section--border-left {
        border-left: 1px solid $c-grey--light;

        @include breakpoint(tablet) {
            border-top: 1px solid $c-grey--light;
            border-left: none;
        }
    }

.fauxdal__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: $c-white;
    z-index: 1;

    @include breakpoint(tablet) {
        display: block;
        text-align: center;
    }

    &--border-bottom {
        border-bottom: 1px solid $c-grey--button;
    }
}

.fauxdal__heading {
    @include font(22px, 30px);
    color: $c-black;

    @include breakpoint(phablet) {
        @include font(18px, 22px);
    }
}

.fauxdal__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;

    @include breakpoint(tablet) {
        position: static;
        transform: none;
        margin-bottom: 10px;
    }

    svg {
        display: block;
    }
}
