.blocks .block.block--flush-bottom-mobile {
    @include breakpoint(tablet) {
        margin-bottom: 0;
    }
}

.block--feature {
    --richtext-color: var(--block-text-color, $c-black);
    padding: 0;
    overflow: hidden;
    background: var(--block-background, transparent);

    .wrap {
        @include breakpoint(tablet) {
            padding: 0;
        }

        .wrap & {
            padding: 0;
        }
    }

    &.block--background-white {
        .block__content {
            @include breakpoint(tablet) {
                padding-top: 0;
            }
        }
    }

    &.block--reverse {
        .block__container {
            grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);

            @include breakpoint(tablet) {
                grid-template-columns: minmax(0, 1fr);
            }
        }

            .block__media {
                grid-column: 2 / 3;
                grid-row: 1 / 2;

                @include breakpoint(tablet) {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                }
            }

            .block__content {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                padding-left: 0;
                padding-right: 130px;
                margin-right: unset;
                margin-left: auto;

                @include breakpoint(tablet) {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                    margin: unset;
                    padding: 40px 15px;
                }
            }

                .block__carousel {
                    margin-left: unset;
                    margin-right: calc((100vw - #{$site-max-width}) / -2);

                    @include breakpoint(wrap) {
                        margin-right: 0;
                    }
                }
    }

    &.block--reverse .block__container--has-accents {
        grid-template-columns: 40px 40px minmax(0, 5fr) minmax(0, 7fr);

        @include breakpoint(tablet) {
            grid-template-columns: minmax(0, 1fr) 40px;
        }

            .block__media {
                grid-column: 4 / 5;

                @include breakpoint(tablet) {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                }
            }

            .block__content {
                grid-column: 3 / 4;

                @include breakpoint(tablet) {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                }
            }

            .block__accent-image {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
            }

            .block__accent-bar {
                grid-column: 1 / 2;
                grid-row: 1 / 2;

                @include breakpoint(tablet) {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }

                &--2 {
                    grid-column: 2 / 3;
                    @include breakpoint(tablet) {
                        grid-column: 2 / 3;
                        grid-row: 1 / 2;
                    }
                }
            }
    }

    .block__container {
        margin-right: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);
        margin-left: calc((100vw - #{$site-max-width} + (2 * #{$site-gutter})) / -2);
        color: var(--block-text-color, $c-grey--dark);
        display: grid;
        grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);

        @include breakpoint(wrap) {
            margin-right: calc(#{$site-gutter} * -1);
            margin-left: calc(#{$site-gutter} * -1);
        }

        @include breakpoint(tablet) {
            margin-left: 0;
            margin-right: 0;
            grid-template-columns: minmax(0, 1fr);
        }
    }

    .block__container--has-accents {
        grid-template-columns: minmax(0, 7fr) minmax(0, 5fr) 40px 40px;

        @include breakpoint(tablet) {
            grid-template-columns: minmax(0, 1fr) 40px;
        }

        .block__accent-image {
            @include breakpoint(tablet) {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
            }
        }

        .block__accent-bar {
            @include breakpoint(tablet) {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
            }

            &--2 {
                @include breakpoint(tablet) {
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                }
            }
        }
    }

    &.block--text-light .block__container {
        color: $c-white;
    }

        .block__carousel {
            position: relative;
            height: 100%;
            margin-left: calc((100vw - #{$site-max-width}) / -2);

            @include breakpoint(wrap) {
                margin-left: 0;
            }

            @include breakpoint(tablet) {
                height: auto;
            }
        }

            .block__image {
                height: 100%;
                aspect-ratio: 4 / 3;

                &::after,
                &::before {
                    display: none;
                }

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                }

                img {
                    @include coverImage;

                    height: 100%;
                }
            }

            .block__image--large {
                aspect-ratio: 6 / 5;
            }

            .block__image--wide {
                aspect-ratio: 2 / 1;
            }

            .block__image--offset {
                padding: 35px 0;

                @include breakpoint(tablet) {
                    padding: 0;
                }

                img {
                    top: 35px;
                    height: calc(100% - 70px);

                    @include breakpoint(tablet) {
                        top: 0;
                        height: 100%;
                    }
                }
            }

        .block__media--has-video {
            position: relative;
            aspect-ratio: 5 / 3;
            overflow: hidden;
            
            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100vw;
                height: 100vh;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }

        .block__media--offset {
            margin: 35px 0;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }

        .block__content {
            padding-left: 130px;
            max-width: 435px;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include breakpoint(tablet) {
                padding: 35px $site-gutter--mobile 50px;
                max-width: none;
                margin: unset;
            }

            button {
                margin-right: auto;
            }
        }

            .block__header {
                position: relative;
                margin-bottom: 17px;

                @include breakpoint(tablet) {
                    margin-bottom: 15px;
                }
            }

            .block__header--offset {
                margin-left: -50%;

                @include breakpoint(tablet) {
                    margin-left: 0;
                }
            }

            &.block--reverse .block__header--offset {
                margin-right: -50%;
                margin-left: 0;

                @include breakpoint(tablet) {
                    margin-right: 0;
                }
            }

                .block__subtitle {
                    @include font(18px, 22px);
                    @include lightText;

                    margin-bottom: 7px;
                    letter-spacing: 4px;
                    text-transform: uppercase;
                    color: inherit;

                    @include breakpoint(tablet) {
                        @include font(14px, 17px);

                        margin-bottom: 0;
                        letter-spacing: 3px;
                    }
                }

                .block__title {
                    @include font(38px, 44px);
                    font-family: var(--block-title-font-family, $f-primary-stack);

                    @include breakpoint(tablet) {
                        @include font(26px, 32px);
                    }
                }

            .block__text {
                max-width: 100%;
                margin-bottom: 27px;
                color: $c-detail-subtitle;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .block__accent-image {
                position: relative;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

    &.block--text-light .block__text {
        @extend .richtext--dark;
    }
}
