.feature-grid {
    .feature-grid__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        align-items: center;

        @include breakpoint(phablet) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 12px;
            row-gap: 8px;
            align-items: stretch;
        }

        & > *:nth-child(2) {
            grid-row: 1 / 3;
            grid-column: 2 / 3;

            @include breakpoint(phablet) {
                grid-column: 1 / 2;
            }
        }
    }

        .feature-grid__item {
            position: relative;

            &:hover {
                .feature-grid__title {
                    @include border-line-trigger;
                }

                img {
                    transform: scale(1.2);
                }

                svg {
                    opacity: 1;
                    margin-right: 0;
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 130px;
                opacity: 0.4;
                background: linear-gradient(180deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);
                pointer-events: none;
            }
        }

        .feature-grid__item--portrait {
            grid-column: 2 / 3;
            grid-row: 1 / 3;

            @include breakpoint(phablet) {
                grid-column: 1 / 2;
            }
        }

            .feature-grid__image {
                @include aspect(5 / 3);

                height: 100%;

                @include breakpoint(phablet) {
                    height: auto;
                }

                img {
                    transition: transform 2250ms cubic-bezier(0.19, 1, 0.22, 1);
                    width: 100%;
                    height: auto;
                }
            }

            .feature-grid__image--portrait {
                @include aspect(4 / 5);
            }

            .feature-grid__header {
                position: absolute;
                z-index: 1;
                top: 30px;
                left: 40px;
                right: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                    opacity: 0;
                    width: 12px;
                    height: 22px;
                    fill: $c-white;
                    margin-right: 10px;
                    transition: margin-right 0.3s, opacity .3s;
                }

                @include breakpoint(phablet) {
                    position: relative;
                    display: inline-block;
                    top: unset;
                    left: unset;
                    right: unset;
                    margin-top: 10px;

                    svg {
                        display: none;
                    }
                }
            }

            .feature-grid__title {
                @include font(26px, 38px);
                @include border-line-grow($c-white, false);

                color: $c-white;

                @include breakpoint(phablet) {
                    @include font(16px, 30px);
                    @include border-line-grow;
                    color: $c-black;
                }
            }
}

.feature-grid--no-carousel {
    .feature-grid__grid {
        @include breakpoint(phablet) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 15px;
            row-gap: 30px;
            margin-left: unset;
            margin-right: unset;
        }
    }

        .feature-grid__item {
            @include breakpoint(phablet) {
                &::after {
                    content: none;
                }
            }
        }

            .feature-grid__title {
                @include breakpoint(phablet) {

                }
            }

            .feature-grid__image {
                @include breakpoint(phablet) {
                    height: auto;
                }
            }
}


.feature-grid--carousel {
    .feature-grid__grid {
        @include breakpoint(phablet) {
            display: block;
        }
    }

    .feature-grid__image,
    .feature-grid__image--portrait{
        @include breakpoint(phablet) {
            @include aspect(5 / 6);
        }
    }
}
