.transit-popup {
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    background: $c-white;
    border-radius: 3px;

    @include breakpoint(tablet) {
        max-width: none;
        border-radius: 0;
    }
}

    .transit-popup__title {
        @include lightondark;
        @include font(22px, 27px);

        padding: 15px 30px;
        color: $c-white;
        background: $c-brand;
        text-align: center;

        @include breakpoint(tablet) {
            color: $c-black;
            background: transparent;
            border-bottom: 1px solid $c-grey--button;
        }
    }

    .transit-popup__content {
        padding: 30px;
        text-align: center;
        display: inline-flex;
        width: 100%;

        @include breakpoint(tablet) {
            display: block;
            text-align: left;
        }
    }

        .transit-popup__grid {
            width: 100%;
        }

        .transit-popup__items {
            flex-direction: row;
            justify-content: start;

            .transit-item {
                &:nth-child(n+5) {
                    margin-top: 20px;
                    @include breakpoint(tablet) {
                        margin-top: 0px;
                    }
                }
            }
        }

        .transit-item {
            &#transit-item-total {
                border-left: 1px solid #ededed;
            }

            &__title {
                @include font(12px,20px);
                letter-spacing: 1px;
                color: $c-listing-table-header;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            &__message {
                @include font(14px,17px);
                @include regularText;

                span {
                    @include lightText;
                }
            }

            @include breakpoint(tablet) {
                width: 100%;
                padding: 0 0 20px 0;
                display: inline-flex;
                flex-direction: row;

                &:first-child {
                    padding: 0 0 20px 0;
                }

                &#transit-item-total {
                    padding: 20px 0 0 0;
                    border-left: none;
                    border-top: 1px solid #ededed;
                }

                &__title,
                &__message {
                    width: 50%;
                }

                &__title {
                    margin-bottom: 0;
                }

            }
        }

        #transit-item-template {
            display: none;
        }

        .transit-popup__message {
            display: none;
            padding-top: 20px;
            @include font(12px,18px);
            @include regularText;

            &--active {
                display: block;
            }
        }
