.feed-item {
    margin-bottom: 30px;
}

    .feed-item__title {
        margin-bottom: 10px;
        @include rem(24px);
    }

    .feed-item__changes {
        @include copy;
        margin-bottom: 10px;

        li {
            line-height: 30px;
        }
    }

    .feed-item__link {
        text-decoration: underline;
        @include rem(16px);
    }
