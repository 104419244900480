.filters {
    @include allowForBars((
        default: $header-height + 20px,
    ), top);

    grid-column: 1 / 2;
    grid-row: 1 / 4;
    position: sticky;
    max-height: calc(100vh - $header-height - 50px);
    scrollbar-gutter: stable;
    overflow-y: auto;
    padding-right: 40px;

    @include breakpoint(tablet) {
        display: none;

        &[data-overlay-active] {
            display: block;
            background-color: $c-white;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
            margin: 0;
            padding: 0;
            padding-bottom: 79px;
            max-height: none;
        }

        [data-filtered] & {
            &[data-overlay-active] {
                padding-bottom: 104px;
            }
        }
    }
}

.filters__close {
    display: none;

    @include breakpoint(tablet) {
        [data-overlay-active] & {
            display: grid;
            place-items: center;
            width: 45px;
            height: 45px;
            position: fixed;
            top: 13px;
            right: 3px;
            background-color: transparent;
            z-index: 150;
        }
    }
}

.filters__title {
    @include font(18px, 24px);

    padding-bottom: 11px;
    border-bottom: 1px solid $c-border-light;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(tablet) {
        @include font(22px, 28px);

        justify-content: center;
        position: sticky;
        top: 0;
        background-color: $c-white;
        padding: 20px 0;
    }
}
