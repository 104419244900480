.footer {
    background-color: $c-black;
}

    .footer__top {
        padding: 30px $site-gutter;
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 20px;
        max-width: $site-max-width;
        margin: 0 auto;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            padding-left: $site-gutter--mobile;
            padding-right: $site-gutter--mobile;
        }
    }

        .footer__item--socials {
            grid-column: 6 / 7;

            @include breakpoint(tablet) {
                grid-column: 1 / 2;
            }
        }

    .footer__bottom {
        @include font(12px, 26px);

        position: relative;
        padding: 12px $site-gutter;
        color: $c-white;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $site-max-width;
        margin: 0 auto;

        @include breakpoint(tablet) {
            padding-left: $site-gutter--mobile;
            padding-right: $site-gutter--mobile;
        }

        &::after {
            content: '';
            position: absolute;
            left: $site-gutter;
            right: $site-gutter;
            top: 0;
            height: 1px;
            background-color: rgba($c-white, 0.1);

            @include breakpoint(tablet) {
                left: $site-gutter--mobile;
                right: $site-gutter--mobile;
            }
        }

        a {
            color: inherit;
        }
    }
       
        .footer__title {
            @include font(16px, 19px);

            margin-bottom: 15px;
            color: $c-white;
        }
