.media-category {
    flex-direction: column;
}

    .media-category__content {
        padding: 10px 25px 25px 25px;
        background-color: $c-white;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .button {
            align-self: flex-start;
        }
    }

        .media-category__image {
            @include aspect(3 / 2);

            img {
                width: 100%;
                height: auto;
            }
        }

        .media-category__title {
            @include font(24px, 54px);
        }

        .media-category__intro {
            @include f-light;
            @include font(14px, 22px);

            margin-bottom: 10px;
            flex: 1 0 auto;
        }
