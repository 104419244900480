// Basket table
.basket {
    &.table {
        td {
            vertical-align: top;
        }
    }
}


.basket__thumbnail {
    width: 130px;

    img {
        width: 100%;
        height: auto;
    }
}

.basket__product {
    .product-dispatch-message {
        @include font(14px, 17px);
        font-weight: 400;
        padding-top: 5px;
    }

    .product-stock__item {
        font-weight: 400;
        span {
            font-weight: 300;
        }
    }

    .product-stock__message {
        padding-bottom: 12px;
    }

    .basket__due-stock {
        margin-top: 20px;
        padding-top: 18px;
        padding-bottom: 8px;
        flex: 100%;

        border-top: 1px solid $c-border-light;
        border-bottom: 1px solid $c-border-light;
    }

    .basket__product__accessories {
        display: block;
        margin-top: 22px;
        @include font(14px, 17px);
        color: $c-black;
        font-weight: 300;
        letter-spacing: 0;
        text-decoration: underline;
    }
}

.basket__info-wrap {
    width: 410px;

    @include breakpoint(mobile) {
        width: auto;
    }
}


.basket__quantity {
    width: 120px;

    .flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.basket__quantity__control {
    @include rem(20px);
    border: 1px solid $c-lines;
    font-weight: 400;
    padding: 0px;
    display: block;
    line-height: 1em;
    margin-right: 5px;
    width: 24px;
    height: 24px;

    span {
        display: block;
        margin-top: 0.1em;
    }

    &:hover {
        border-color: $grey2;
    }
}

    .basket__quantity__link {
        line-height: 46px;
        display: inline-block;
    }

        .basket__quantity__link--double {
            line-height: 23px;

            &:first-of-type {
                margin-top: -5px;
            }
        }

.basket__price {
    width: 130px;
}

@include breakpoint(tablet) {
    .table.m-block td.basket__price {
        width: auto;
    }
}

// Basket footer

.basket-footer {
    @include clearfix;
    padding-top: 30px;
    padding-bottom: 10px;
}

.basket-footer__delivery__label {
    display: inline-block;
    @include copy;
    @include rem(14px);
    @include f-regular;
    width: 170px;
}

.basket-footer__delivery__row {
    line-height: 1.5;

    form {
        display: inline-block;

        label {
            margin-left: 15px;
            @include copy;
            @include rem(14px);
            @include f-regular;

            &:first-child {
                margin-left: 0;
            }
        }

        .link {
            text-transform: uppercase;
            margin-left: 15px;
        }
    }
}

.basket-footer__summary {
    table-layout: fixed;
    width: 100%;
    text-align: right;

    td {
        text-align: right;
        vertical-align: middle;
        line-height: 1.5;
        padding-bottom: 15px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    tr:last-child td {
        padding-bottom: 0;
    }
}

.basket-footer__summary__amount {
    width: 140px;
}

.basket-footer__summary__vat {
    color: $c-brand;
}

.basket-footer__summary__total {
    @include rem(24px);
}

.basket-footer__empty {
    text-decoration: underline;
    font-weight: 300;
}

@include breakpoint(tablet) {
    .basket-footer {
        padding-top: 15px;
    }

    .basket-footer__delivery__label {
        display: block;
    }

    .basket-footer__delivery__row {
        label {
            display: block;
            margin-left: 0 !important;
        }
    }
}

// Basket submit

.basket-submit__reference {
    display: none;
    position: relative;

    form {
        display: flex;
        margin-top: 20px;
        margin-bottom: -20px;

        .field--text {
            flex: 1 1 auto;
            margin-right: 20px;
        }
    }
}

.basket-submit__reference__cancel {
    @include rem(15px);

    color: $c-link;

    span {
        border-bottom: 1px solid $c-link;
    }

    &:hover {
        color: $c-copy;

        span {
            border-bottom-color: $c-copy;
        }
    }
}

.basket-submit__actions {
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    text-align: right;

    .button {
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.basket-submit__actions__warning {
    width: 100%;
    text-align: left;

    p {
        @include copy;
        @include rem(14px);
        @include f-regular;
        color: $c-error;
    }
}

.basket-submit__actions__totals {
    display: flex;
    align-items: baseline;
    align-self: center;
}

    .basket-submit__actions__total-label {
        color: $c-listing-table-header;
        @include font(14px, 24px);
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .basket-submit__actions__total-value {
        @include font(26px, 32px);
        margin-left: 10px;

        span {
            @include font(14px, 24px);
        }
    }

.basket-submit__actions__buttons {
    margin-left: auto;
}

@include breakpoint(tablet) {
    .basket-submit__reference {
        float: none;

        form {

            .button {
                display: block;
                width: 100%;
            }
        }
    }

    .basket-submit__actions__warning {
        display: block;
        margin-bottom: 15px;
        text-align: center;
    }
}

@include breakpoint(phablet) {
    .basket-submit__actions {
        flex-direction: column;
    }

    .basket-submit__actions__totals {
        margin-bottom: 20px;
        width: 100%;
    }

        .basket-submit__actions__total-value {
            margin-left: auto;
        }


    .basket-submit__actions__buttons {
        display: flex;
        margin-left: 0;

        .button {
            margin: 0 5px;
            flex-grow: 1;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
