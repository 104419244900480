.plp-overlay {
    padding: 50px 60px;
    background: $c-white;

    @include breakpoint(tablet) {
        padding: 20px;
    }
}

    .plp-overlay__title {
        @extend .detail__title;

        margin-bottom: 20px
    }

    .plp-overlay__copy {
        @include font(18px, 28px);
        @include lightText;

        color: $c-detail-subtitle;
        margin-bottom: 20px;
    }

    .plp-overlay__button {

        &:last-of-type {
            margin-left: 20px;
        }

        @include breakpoint(tablet) {
            
            &:last-of-type {
                margin-left: 0px;
                margin-top: 20px;
            }
        }  
    }