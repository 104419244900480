.social-bar {
    text-align: left;
    font-size: 0;
    word-spacing: 10px;
    margin-bottom: 15px;

    & > * {
        display: inline-block;
    }
}

    .social-bar--dark {
        .social-bar__title {
            color: $c-white;
        }

        .social-bar__link {
            &:before {
                color: $c-brand;
            }

            &:hover {
                background: $c-white;
            }
        }
    }

    .social-bar--centred {
        text-align: center;
    }

    .social-bar__title {
        @include copy;
        @include rem(16px);
        text-transform: uppercase;
    }

    .social-bar__link {
        @include rem(16px);
        display: inline-block;
        @extend .fa;
        position: relative;
        text-indent: -1000em;
        width: 26px;
        height: 26px;
        padding: 7px;
        background: $c-social-bg;
        border-radius: 50%;

        &:hover {
            background: $c-social-bg--hover;
        }

        &:before {
            text-align: center;
            text-indent: 0;
            position: absolute;
            top: 6px;
            left: 0;
            width: 100%;
            color: $c-social-icon;
        }
    }

    .social-bar__link--facebook {
        @extend .fa-facebook;
    }

    .social-bar__link--pinterest {
        @extend .fa-pinterest-p;
    }

    .social-bar__link--twitter {
        @extend .fa-twitter;
    }

// Social options (blog posts)

.social-options {
    display: block;
    font-size: 0;
    margin-top: 8px;

    span {
        display: inline-block;
        @include rem(18px);
        line-height: 40px;
        margin-right: 20px;
        color: $grey2;
    }

    a {
        display: inline-block;
        background: $c-grey--mid;
        color: $grey3;
        @include rem(15px);
        text-align: center;
        line-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        margin-right: 10px;

        &:hover {
            color: $c-white;
        }
    }
}
