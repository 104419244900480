.block--intro {
    @include lightondark;

    --richtext-color: var(--block-text-color, $c-black);
    text-align: center;
    color: var(--block-text-color, $c-black);
    background-color: var(--block-background, transparent);

    .block__title {
        @include font(38px, 44px);

        @include breakpoint(tablet) {
            @include font(26px, 32px);
        }
    }

    .block__text {
        margin-top: 20px;

        @include breakpoint(tablet) {
            margin-top: 10px;
        }
    }

    .block__link {
        margin-top: 20px;

        @include breakpoint(tablet) {
            margin-top: 15px;
        }
    }
}

