.page-view-options {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

    .page-view-options__item {
        display: inline-block;
        vertical-align: middle;
    }

        .page-view-options__label {
            cursor: pointer;
        }

            .page-view-options__input {
                display: none;
            }

            .page-view-options__name {
                @include rem(14px);

                line-height: calc(25 / 14);
                width: 25px;
                height: 25px;
                border: 1px solid transparent;
                color: $grey2;
                text-align: center;

                .page-view-options__input:checked ~ & {
                    border-color: $c-brand;
                    color: $c-black;
                }
            }
