.technical-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.technical-detail--wide {
    max-width: 900px;
    margin: 0 auto;
}

    .technical-detail__title {
        @include font(24px, 46px);
        @include lightText;

        margin-bottom: 10px;
    }

    .technical-detail__table {

        @include breakpoint(phablet) {
            width: 100%;
        }

        th,
        td {
            @include font(14px, 20px);
            @include lightText;

            padding-bottom: 10px;
            vertical-align: top;

            @include breakpoint(phablet) {
                width: 50%;
            }
        }

        th {
            padding-right: 20px;
        }
    }

        .technical-detail__row {}

        .technical-detail__row--finishes {

            th,
            td {
                line-height: 28px;
            }
        }

            .technical-detail__finishes {
                display: flex;
            }

                .technical-detail__finish {
                    width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    border-radius: 100%;

                    &:last-child {
                        margin-right: 0;
                    }

                    img {
                        display: block;
                        width: 28px;
                        height: 28px;
                        border-radius: 100%;
                    }
                }

    .technical-detail__image {

        img,
        svg {
            max-width: 100%;
            height: auto;

            @include breakpoint(tablet) {
                display: block;
                margin: 0 auto;
            }
        }
    }
