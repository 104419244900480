.accessory-panel {
    grid-column: span 2;
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: auto 1fr auto;
}

    .accessory-panel__image {
        aspect-ratio: 1;
        grid-column: 1 / 2;

        @include breakpoint(phablet) {
            grid-row: 1 / 3;
        }
    }

    .accessory-panel__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 20px;
        grid-column: 2 / 3;

        @include breakpoint(phablet) {
            grid-row: 1 / 2;
            padding: 0 12px;
        }
    }

        .accessory-panel__remove {
            display: flex;
            flex-basis: 100%;
            margin-top: 3px;
        }

        .accessory-panel__title {
            @include font(14px, 22px);

            flex: 0 0 100%;
            color: $c-black;
            margin-bottom: 3px;

            @include breakpoint(tablet) {
                @include font(12px, 22px);
            }
        }

        .accessory-panel__price {
            @include font(14px, 22px);

            color: $c-black;

            @include breakpoint(tablet) {
                @include font(12px, 22px);
            }
        }

        .accessory-panel__meta {
            @include font(14px, 22px);
            @include lightText;

            position: relative;
            color: $c-detail-subtitle;
            margin-left: 16px;

            @include breakpoint(tablet) {
                @include font(12px, 22px);
            }

            &::before {
                content: '|';
                position: absolute;
                left: -8px;
                top: 0;
                bottom: 0;
                color: $c-detail-subtitle-separator;
            }
        }

    .accessory-panel__change {
        grid-column: 3 / 4;
    }
