.header {
    @include allowForBars;

    --header-background-colour: #{$c-white};
    --header-content-colour: #{$c-black};
    --header-border-colour: #{$c-border-light};
    position: sticky;
    background-color: var(--header-background-colour);
    color: var(--header-content-colour);
    z-index: 21;
    transition: background-color .3s ease;

    &:not(.header--clear) ~ .page-content {
        @include allowForBars($property: margin-top);
    }

    &:not(.header--clear) {
        border-bottom: 1px solid var(--header-border-colour);
    }
}

.header--clear {
    @include lightondark;

    --header-background-colour: transparent;
    --header-content-colour: #{$c-white};
    --header-border-colour: rgba(234, 234, 234, 0.15);

    &:hover,
    &:has(.header-nav__item--active),
    &[active-item],
    &[data-position="sticky"],
    &.header--search-active,
    &.header--active {
        --header-background-colour: #{$c-white};
        --header-content-colour: #{$c-black};
        --header-border-colour: #{$c-border-light};
    }

    & ~ .page-content {
        margin-top: calc(#{$header-height} * -1);
    }

    & ~ .header-back {
        color: $c-white;
    }
}

    .header__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $header-top-height;
        transition: background-color .3s ease;
        padding: 0 $site-gutter;
        max-width: $site-max-width;
        margin: 0 auto;

        @include breakpoint(tablet) {
            padding: 0 $site-gutter--mobile;
            justify-content: flex-end;
            height: $header-top-height--mobile;
        }
    }

        .header__toggle {
            display: none;

            @include breakpoint(tablet) {
                display: block;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            .nav-icon {
              width: 20px;

                &::after,
                &::before,
                div {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 2px;
                    background-color: var(--header-content-colour);
                    transition: transform .3s ease, opacity .3s ease;
                }

                &::before {
                    .header--active & {
                        transform: rotate(-45deg) translate(-6px, 6px);
                    }
                }

                &::after {
                    .header--active & {
                        transform: rotate(45deg) translate(-7px, -7px);
                    }
                }

                div {
                    margin: 7px 0;

                    .header--active & {
                        opacity: 0;
                    }
                }
            }
        }

        .header__links {
            display: flex;
            align-items: center;
            gap: 30px;

            @include breakpoint(tablet) {
                width: 100%;
                gap: 25px;
            }
        }

            .header__link {
                @include font(12px, 15px);
                @include lightText;

                color: inherit;
                transition: color .3s ease;

                &:hover {
                    color: $c-brand;
                }

                @include breakpoint(tablet) {
                    @include font(14px, 20px);

                    display: block;
                    padding: 15px 20px;
                }

                span {
                    @include boldText;

                    text-decoration: underline;
                }
            }

            .header__link--border-top {
                border-top: 1px solid $c-grey--button;

                @include breakpoint(tablet) {
                    padding-top: 14px;
                }
            }

            .header__link--border-bottom {
                border-bottom: 1px solid $c-grey--button;

                @include breakpoint(tablet) {
                    padding-bottom: 14px;
                }
            }

            .header__link--border-left {
                border-left: 1px solid $c-grey--button;
            }

            .header__icon {
                padding: 0;
                background-color: transparent;

                svg {
                    display: block;
                    fill: var(--header-content-colour);
                }
            }

            .basket-link__link {
                display: flex;

                svg {
                    transition: fill .3s ease;
                    width: 22px;
                    height: 18px;

                    &:hover {
                        fill: $c-brand;
                    }
                }
            }

    .header__nav {
        height: $header-bottom-height;
        display: flex;
        border-top: 1px solid var(--header-border-colour);

        @include breakpoint(tablet) {
            display: none;
    
            .header--activating & {
                display: block;
                opacity: 0;
                position: absolute;
                overflow: auto;
                top: $header-top-height--mobile;
                transition: opacity 150ms ease;
                left: 0;
                width: 100%;
                height: calc(100vh - #{$header-top-height--mobile});
                height: calc(100dvh - #{$header-top-height--mobile});
                background-color: $c-white;
            }
    
            .header--active & {
                opacity: 1;
            }
        }
    }
